/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Service and event manager for pdf hotspot fetch
 *
 * @file HotspotService.js
 */

import Framework, { BaseService } from '@greenville/framework';
import axios from 'axios';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

// let cancelRegionApi = null;

export default class HotspotService extends BaseService {
  constructor() {
    super(constants.PDF_HOTSPOT_REQUESTED, 'hotspotStatus');
    this.cancelRegionApi = null;
  }

  handleEvent(eventName, event) {
    if (event.bookid && event.data) {
      const { bookid, data } = event;
      return this.getClient('evergreen').post(`books/${bookid}/regions/save`, data);
    }
    // cancel previous call if exists
    if (this.cancelRegionApi) {
      this.cancelRegionApi.cancel();
    }
    this.cancelRegionApi = axios.CancelToken.source();
    const options = { cancelToken: this.cancelRegionApi.token };

    const { bookid, pageorder } = event;
    return this.getClient('evergreen').get(`books/${bookid}/regions/${pageorder}`, options);
  }

  handleResponse(response, event) {
    // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('hotspot').setHotspot(response.data, event);
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
    }
  }
}
