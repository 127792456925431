import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
// import Table from './Table';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  IconButton,
  Typography
} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[700]
  },
  table: {
    width: 'max-content'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);

@inject('booksStatus')
@observer
class BookHistory extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    bookHistory: PropTypes.array.isRequired,
    dialogClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  componentDidMount() {}

  handleClose = () => {
    const { dialogClose } = this.props;
    dialogClose();
  };

  render() {
    const { classes, bookHistory } = this.props;
    const { open } = this.state;

    return (
      <div>
        <Dialog maxWidth="true" onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Book History
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date/Time</TableCell>
                      <TableCell align="center">User</TableCell>
                      <TableCell align="center">BookID</TableCell>
                      <TableCell align="center">Version</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookHistory.map((row) => (
                      <TableRow key={row.user}>
                        <TableCell component="th" scope="row">
                          {row.updatedDate}
                        </TableCell>
                        <TableCell align="left" nowrap>
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{row.bookID}</TableCell>
                        <TableCell align="center">{row.version}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={this.handleClose} variant="outlined" color="primary">
            Export book history
            </Button>
          </DialogActions> */}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(BookHistory)));
