/* eslint-disable react/prop-types */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { Component } from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import pearsonTheme from '@greenville/mui-theme'; // eslint-disable-line import/no-extraneous-dependencies
import Framework, { Container } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { withMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';

import modules from './modules';
import languages from './common/languages';
import env from './common/env';
import packageJson from '../package.json';
import utils from './common/utils';

@inject()
@observer
class App extends Component {
  constructor(props) {
    super(props);
    const intlBuilder = Framework.getIntlBuilder();
    languages.forEach((language) => intlBuilder.add(language.locale, language.messages));
    intlBuilder.setDefaultLang('en').addPseudoLocale(env.PSEUDO_LOCALE_CODE).build();
    const moduleBuilder = Framework.getModuleBuilder();

    if (modules.length > 0) {
      modules.forEach((module) => moduleBuilder.add(module));
      modules.length = 0;
      this.modules = moduleBuilder.build();
    }
  }

  componentDidMount() {
    this.callLogin();
    const activeSession = this.getSessionData();
    if (activeSession !== null) {
      utils.setCookie();
      const token = utils.getToken();
      callMsGraph(token);
    }
  }

  componentDidUpdate(prevProps) {
    const { msalContext } = this.props;
    const activeSession = this.getSessionData();
    if (msalContext.accounts !== null && msalContext.accounts !== prevProps.msalContext.accounts) {
      this.callLogin();
      if (activeSession !== null) {
        utils.setCookie();
        const token = utils.getToken();
        callMsGraph(token);
      }
    } else {
      this.callLogin();
      if (activeSession !== null) {
        utils.setCookie();
        const token = utils.getToken();
        callMsGraph(token);
      }
    }
  }

  onInitFn() {
    const userData = Framework.getStoreRegistry().getStore('user');
    const usersId = Framework.getStoreRegistry().getStore('userid');
    const { userdetails } = usersId;
    const userPermissions = Framework.getStoreRegistry().getStore('userPermissions');
    if (userPermissions && userPermissions.permissionList.permissions.length === 0 && userData.id.length > 0) {
      usersId.fetchDetails(userData.email);
      if (userdetails.userId !== null) {
        userPermissions.fetchPermissions(userdetails.userId);
      }
    }
  }

  getSessionData() {
    let keys = window.sessionStorage.getItem('msal.account.keys');
    if (keys !== null && keys !== '[]') {
      keys = keys.replace(/[,"[\]]/g, '');
      let value = window.sessionStorage.getItem(keys);
      value = JSON.parse(value);
      const { username } = value;
      return username;
    }
    return null;
  }

  callLogin() {
    const { msalContext } = this.props;
    const { accounts, instance, inProgress } = msalContext;
    const isAuthenticated = accounts.length > 0;
    const msalInstance = instance;
    let sessionData = null;

    // If a user is not logged in and authentication is not already in progress, invoke login

    if (inProgress === InteractionStatus.None) {
      sessionData = this.getSessionData();
      if (sessionData !== null && !isAuthenticated) {
        const silentRequest = {
          scopes: ['User.Read'],
          loginHint: sessionData
        };
        msalInstance
          .ssoSilent(silentRequest)
          .then(() => {
            utils.setCookie();
          })
          .catch((err) => {
            if (err instanceof InteractionRequiredAuthError || err) {
              msalInstance.loginRedirect(loginRequest).catch(() => {
                // console.log(error1);
              });
            }
          });
      } else if (!isAuthenticated) {
        msalInstance.loginRedirect(loginRequest).catch(() => {
          // console.log(error1);
        });
        if (window.location.pathname === '/') {
          Framework.redirectTo(`/dashboard`);
        }
      }
    }
    return true;
  }

  render() {
    return (
      <ThemeProvider theme={createTheme(pearsonTheme)}>
        <Container
          onInit={this.onInitFn()}
          product={env.PRODUCT}
          version={packageJson.version}
          browserCheck={env.BROWSER_CHECK}
          delayedGTM={env.DELAYED_GTM}
          gtmArgs={{
            gtmId: env.GTM_CONTAINER_ID,
            auth: env.GTM_AUTH,
            preview: env.GTM_PREVIEW
          }}
          modules={this.modules}
          correlationIdPrefix={env.CORRELATION_ID_PREFIX}
          authClientId={env.CLIENT_ID}
          partnerEnv={env.ENVIRONMENT}
          authType="INT"
          partnerEnvConfig={{
            INT_BASE_URL: env.INT_BASE_URL,
            INT_USER_PROFILE_URL: env.INT_USER_PROFILE_URL,
            INT_TIMEOUT: env.INT_TIMEOUT,
            INT_AZURE_CLIENT_ID: env.INT_AZURE_CLIENT_ID
          }}
        />
      </ThemeProvider>
    );
  }
}
export default withMsal(App);
