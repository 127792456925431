/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 */
const BasepathObj = types.model('BasepathObj', {
  linkTypeID: types.maybeNull(types.integer),
  type: types.maybeNull(types.string),
  bookID: types.maybeNull(types.integer),
  basepath: types.maybeNull(types.string),
  uniqueID: types.maybeNull(types.string)
});

const MediaBasepaths = types
  .model('MediaBasepaths', {
    basepathUrl: types.optional(types.array(BasepathObj), [])
  })
  .actions((self) => ({
    fetch(id) {
      Framework.getEventManager().publish(constants.MEDIA_BASEPATHS_DATA_FETCHED, { id });
    },
    set(response) {
      applySnapshot(self.basepathUrl, response.data);
    },
    editSwfdata(data, type) {
      const updatedSelf = toJS(self);
      updatedSelf[type] = data;
      applySnapshot(self[type], data);
    },
    savePayload(payload) {
      Framework.getEventManager().publish(constants.MEDIA_BASEPATHS_POST, { payload });
    }
  }));

export default MediaBasepaths;
