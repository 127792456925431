/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Select,
  DialogActions,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  TextField,
  Divider
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { shapes } from '@greenville/framework';
import Roles from '../models/Roles';
import UserDialogConfig from '../../../common/config/users/UserDialogConfig';
import ValidatePermissions from '../ValidatePermissions';
import TooltipCustom from '../../../common/components/TooltipCustom';
import UsersPermissions from '../models/UsersPermissions';

const styles = (theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    flex: 1,
    textAlign: 'left'
  },
  grid: {
    width: '85%',
    marginLeft: theme.spacing(3)
  },
  textFields: {
    height: 31,
    fontSize: 12,
    width: '30ch'
  },
  textfieldInput: {
    height: 11,
    fontSize: '14px'
  },
  selectfieldInput: {
    height: 31,
    fontSize: 14,
    width: '26ch'
  },
  divider: {
    margin: '0px 24px 12px 24px'
  }
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
@inject('userPermissions')
@observer
class UserDialog extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    closeUserDialog: PropTypes.func.isRequired,
    saveUser: PropTypes.func.isRequired,
    userRoles: shapes.modelOf(Roles).isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired,
    language: shapes.language.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      openDialog: true,
      userData: {
        firstName: null,
        lastName: null,
        userLogin: null,
        email: null,
        roleTypeId: null
      },
      error: false
    };
  }

  componentDidMount() {
    const { userRoles } = this.props;
    this.setState({ userData: { roleTypeId: userRoles.usersRoles[0].roleTypeId } });
  }

  handleSave = () => {
    const { saveUser } = this.props;
    const { userData } = this.state;
    if (userData) {
      Object.keys(userData).map((key) => {
        if (_.isString(userData[key])) {
          userData[key] = userData[key].trim();
        }
        return userData;
      });
    }
    const isValidated = this.validateSave(UserDialogConfig.requiredFields);
    if (isValidated) {
      saveUser(userData);
    } else {
      this.setState({ error: true });
    }
  };

  validateData = (value) => {
    const { userData } = this.state;
    if (_.isEmpty(userData[value]) || _.isNil(userData[value])) {
      return true;
    }
    return false;
  };

  validateSave = (requiredArr) => {
    let bool = true;
    let required = false;
    requiredArr.forEach((value) => {
      required = this.validateData(value);
      if (required) {
        bool = false;
      }
    });
    return bool;
  };

  handleClose = () => {
    const { closeUserDialog } = this.props;
    closeUserDialog();
  };

  handleChange = (e) => {
    const { userData, error } = this.state;
    if (userData[e.target.name] === '0') {
      this.setState({
        error: !error
      });
    } else {
      this.setState({
        error: false
      });
    }
    let updatedObject = {};
    const intProperties = ['roleTypeId'];
    if (intProperties.includes(e.target.name)) {
      const intValue = Number(e.target.value);
      updatedObject = {
        [e.target.name]: intValue
      };
    } else {
      updatedObject = {
        [e.target.name]: e.target.value
      };
    }
    this.setState({
      userData: { ...userData, ...updatedObject }
    });
  };

  render() {
    const { classes, userRoles, userPermissions, language } = this.props;
    const { openDialog, userData } = this.state;
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    return (
      <>
        <ValidatePermissions givenPermissions="user_can_edit">
          <Dialog
            fullWidth="true"
            maxWidth="xs"
            open={openDialog}
            onClose={this.handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar} color="primary">
              <Toolbar>
                <Typography variant="h3" className={classes.title}>
                  Add New User
                </Typography>
                <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider className={classes.divider} />
            <Grid container className={classes.grid}>
              <Grid item xs={10}>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <Typography variant="h5" color="textPrimary">
                      First Name
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      error={this.validateData('firstName')}
                      className={classes.textFields}
                      margin="dense"
                      name="firstName"
                      id="firstName"
                      // placeholder="First Name"
                      variant="outlined"
                      onChange={this.handleChange}
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <Typography variant="h5" color="textPrimary">
                      Last Name
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      className={classes.textFields}
                      error={this.validateData('lastName')}
                      name="lastName"
                      id="lastName"
                      // placeholder="Last Name"
                      onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <Typography variant="h5" color="textPrimary">
                      User ID
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      error={this.validateData('userLogin')}
                      className={classes.textFields}
                      name="userLogin"
                      id="userLogin"
                      // placeholder="User ID"
                      onChange={this.handleChange}
                      variant="outlined"
                      margin="dense"
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <Typography variant="h5" color="textPrimary">
                      Email
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      error={this.validateData('email')}
                      className={classes.textFields}
                      name="email"
                      id="email"
                      // placeholder="Email"
                      onChange={this.handleChange}
                      variant="outlined"
                      margin="dense"
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <Typography variant="h5" color="textPrimary">
                      Role
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Select
                      native
                      variant="outlined"
                      value={userData.roleTypeId}
                      onChange={this.handleChange}
                      // InputProps={{ classes: { input: classes.selectfieldInput } }}
                      name="roleTypeId"
                      margin="dense"
                      className={classes.selectfieldInput}
                    >
                      {userRoles.usersRoles.map((roleValue) => (
                        <option value={roleValue.roleTypeId}>{roleValue.role}</option>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DialogActions>
              <TooltipCustom
                title={
                  !permissionsList.includes('user_can_edit') ? (
                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                  ) : (
                    <FormattedMessage {...language.getText('user.SAVE_USER_DETAILS')} />
                  )
                }
              >
                <span>
                  <Button id="saveBtn" variant="outlined" color="primary" size="small" onClick={this.handleSave}>
                    Save
                  </Button>
                </span>
              </TooltipCustom>
              <TooltipCustom title={<FormattedMessage {...language.getText('user.CANCEL_ADDING_NEW_USER')} />}>
                <span>
                  <Button id="cancelBtn" variant="outlined" color="primary" size="small" onClick={this.handleClose}>
                    Cancel
                  </Button>
                </span>
              </TooltipCustom>
            </DialogActions>
          </Dialog>
        </ValidatePermissions>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(observer(UserDialog)));
