/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper } from '@material-ui/core';
import TopStrap from './TopStrap';
import Notification from '../components/notification/Notification';
import SessionManagement from './SessionManagement';

const HeaderStyles = () => ({
  appHeader: {
    width: '100%',
    backgroundImage: 'linear-gradient(#007a98, #005d83 41%, #002e60 84%)',
    position: 'fixed',
    minHeight: 64,
    borderRadius: 0,
    top: 0,
    boxShadow: 'none',
    zIndex: 999
  }
});

const Header = ({ classes }) => (
  <Paper id="app-header" className={classes.appHeader}>
    <Notification />
    <TopStrap />
    <SessionManagement />
  </Paper>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(HeaderStyles)(Header);
