/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

/**
 * Mobx State Tree Store
 */

const colorThemeObj = types.model('colorThemeObj', {
  themeID: types.maybeNull(types.integer),
  themeValue: types.maybeNull(types.string),
  uniqueID: types.maybeNull(types.string)
});

const colorInfoObj = types.model('colorInfoObj', {
  bookThemes: types.optional(types.array(colorThemeObj), [])
});

const languageInfoObj = types.model('languageInfoObj', {
  audioPlayerOnereader: types.maybeNull(types.string),
  hasMultipleAudioFlow: types.maybeNull(types.string),
  hasNonSynchAudioText: types.maybeNull(types.string),
  isUnderLineHotspot: types.maybeNull(types.string),
  pdzProductID: types.maybeNull(types.string),
  print: types.maybeNull(types.string),
  studyToolOnereader: types.maybeNull(types.string),
  serverSideEnabledOneReader: types.maybeNull(types.string)
});

const InterfaceModel = types
  .model('InterfaceModel', {
    // interfaceState: types.optional(types.array(languageInfoObj), [])
    languageInfo: types.optional(languageInfoObj, {}),
    colorInfo: types.optional(colorInfoObj, {}),
    bookId: types.maybeNull(types.string)
  })
  .views((self) => ({
    getHotspotFeaturesConfig(bookId) {
      const hotspotObj = { ...constants.HOTSPOT_DEFAULT_FEATURE_CONFIG };
      if (self.bookId === bookId) {
        if (self.languageInfo) {
          hotspotObj.isUnderLineHotspot = utils.convertBoolean(self.languageInfo.isUnderLineHotspot);
        }

        if (self.colorInfo && self.colorInfo.bookThemes && self.colorInfo.bookThemes.length > 0) {
          const { bookThemes } = self.colorInfo;
          if (bookThemes.find((theme) => theme.themeID === 8)) {
            hotspotObj.hotSpotColor = bookThemes.find((theme) => theme.themeID === 8).themeValue;
          }
          if (bookThemes.find((theme) => theme.themeID === 14)) {
            hotspotObj.regionHotSpotAlpha = bookThemes.find((theme) => theme.themeID === 14).themeValue;
          }
          if (bookThemes.find((theme) => theme.themeID === 16)) {
            hotspotObj.underLineHotSpotColor = bookThemes.find((theme) => theme.themeID === 16).themeValue;
          }
          if (bookThemes.find((theme) => theme.themeID === 18)) {
            hotspotObj.underLineHotSpotThickness = bookThemes.find((theme) => theme.themeID === 18).themeValue;
          }
          if (bookThemes.find((theme) => theme.themeID === 17)) {
            hotspotObj.underLineHotSpotHoverColor = bookThemes.find((theme) => theme.themeID === 17).themeValue;
          }
        }
      }
      return hotspotObj;
    }
  }))
  .actions((self) => ({
    fetch(id) {
      Framework.getEventManager().publish(constants.COLOR_INTERFACE_DATA_FETCHED, { id });
    },

    set(response, event) {
      const model = {
        languageInfo: response.data.languageInfo,
        colorInfo: response.data.colorInfo,
        bookId: event.id
      };
      applySnapshot(self, model);
    },

    savePayload(payload) {
      Framework.getEventManager().publish(constants.COLOR_INTERFACE_POST, { payload });
    }
  }));

export default InterfaceModel;
