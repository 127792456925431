/* eslint-disable no-param-reassign */
import Framework from '@greenville/framework';
import axios from 'axios';
import env from './common/env';
import utils from './common/utils';

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = {
    Authorization: `Bearer ${accessToken}`
  };
  const resource = 'https://graph.microsoft.com';
  try {
    const response = await axios.get(`${resource}/v1.0/me`, { headers });
    const user = {
      id: response.data.id,
      userName: response.data.givenName,
      firstName: response.data.givenName,
      lastName: response.data.surname,
      initials: response.data.givenName.slice(0, 1) + response.data.surname.slice(0, 1),
      email: response.data.mail
    };
    Framework.getStoreRegistry().getStore('user').set(user);

    const userData = Framework.getStoreRegistry().getStore('user');
    const usersId = Framework.getStoreRegistry().getStore('userid');
    const userPermissions = Framework.getStoreRegistry().getStore('userPermissions');
    if (userData.email && usersId.userdetails.userId === null) {
      const userResponse = await axios.get(`${env.EVERGREEN_API_BASE_URL}/users/email/${userData.email}`, { headers });
      if (userResponse) {
        const { status } = userResponse;
        if (status === 401) {
          utils.redirectToLogin();
        } else if (status === 200) {
          Framework.getStoreRegistry().getStore('userid').setUserDetails(userResponse.data);
        }
      }
    }
    const { userdetails } = usersId;
    if (userPermissions && userPermissions.permissionList.permissions.length === 0 && userdetails.userId !== null) {
      const permResponse = await axios.get(`${env.EVERGREEN_API_BASE_URL}/users/${userdetails.userId}/permissions`, {
        headers
      });
      if (permResponse) {
        const { status } = permResponse;
        if (status === 401) {
          utils.redirectToLogin();
        } else if (status === 200) {
          if (permResponse.data.data.permissions.length > 0) {
            Framework.getStoreRegistry().getStore('userPermissions').setPermissions(permResponse.data);
            const userId = userdetails.userId.toString();
            Framework.getStoreRegistry().getStore('user').setAuthenticated(userId, accessToken, '');
          }
        }
      }
    }
    return user;
  } catch {
    utils.redirectToLogin();
    // console.error(e);
    return null;
  }
}

export default callMsGraph;
