/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Framework, { shapes } from '@greenville/framework';
import { toJS } from 'mobx';
import { Container, Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';
// import { Person, LibraryBooks, Storage, SettingsEthernet, Assessment } from '@material-ui/icons'; uncomment for tiles
import { LibraryBooks, Storage, SettingsEthernet } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import UsersPermissions from '../../users/models/UsersPermissions';
import TooltipCustom from '../../../common/components/TooltipCustom';

const styles = () => ({});

@inject('userPermissions')
@observer
// eslint-disable-next-line react/prefer-stateless-function
class Dashboard extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  render() {
    const { language, userPermissions } = this.props;
    const { permissionList } = userPermissions;
    const { permissions } = permissionList;
    const p = toJS(permissions);

    return (
      <>
        <Container>
          <Typography component="h1" align="center" style={{ fontSize: 40 }}>
            <FormattedMessage {...language.getText('dashboard.HEADER_TEXT')} />
          </Typography>
          <br />
          <br />
          <Grid container spacing={1} align="center">
            <Grid item xs={12} sm={6}>
              <TooltipCustom
                title={
                  !p.includes('etext_can_view') ? (
                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                  ) : (
                    <FormattedMessage {...language.getText('dashboard.ETEXTDB_LABEL')} />
                  )
                }
              >
                <Card variant="outlined">
                  <span>
                    <CardActionArea
                      onClick={() => Framework.redirectTo('/etext/conversion/workflow')}
                      disabled={!p.includes('etext_can_view')}
                    >
                      <CardContent>
                        <Storage style={{ fontSize: 40 }} />
                        <Typography gutterBottom variant="h3" component="h2">
                          <br />
                          <FormattedMessage {...language.getText('dashboard.ETEXTDB_LABEL')} />
                        </Typography>
                        <br />
                        <Typography
                          style={{ fontSize: 16 }}
                          gutterBottom
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          <FormattedMessage {...language.getText('dashboard.ETEXTDB_DESC')} />
                        </Typography>
                      </CardContent>
                      <br />
                    </CardActionArea>
                  </span>
                </Card>
              </TooltipCustom>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TooltipCustom
                title={
                  !p.includes('ingestion_can_view') ? (
                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                  ) : (
                    <FormattedMessage {...language.getText('dashboard.CM_LABEL')} />
                  )
                }
              >
                <Card variant="outlined">
                  <span>
                    <CardActionArea
                      onClick={() => Framework.redirectTo('/cm/ingestion/workflow')}
                      disabled={!p.includes('ingestion_can_view')}
                    >
                      <CardContent>
                        <SettingsEthernet style={{ fontSize: 40 }} />
                        <Typography gutterBottom variant="h3" component="h2">
                          <br />
                          <FormattedMessage {...language.getText('dashboard.CM_LABEL')} />
                        </Typography>
                        <br />
                        <Typography
                          style={{ fontSize: 16 }}
                          gutterBottom
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          <FormattedMessage {...language.getText('dashboard.CM_DESC')} />
                        </Typography>
                        <br />
                      </CardContent>
                    </CardActionArea>
                  </span>
                </Card>
              </TooltipCustom>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TooltipCustom
                title={
                  !p.includes('authoring_can_view') ? (
                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                  ) : (
                    <FormattedMessage {...language.getText('dashboard.AUTHORING_LABEL')} />
                  )
                }
              >
                <Card variant="outlined">
                  <span>
                    <CardActionArea
                      onClick={() => Framework.redirectTo('/books')}
                      disabled={!p.includes('authoring_can_view')}
                    >
                      <CardContent>
                        <LibraryBooks style={{ fontSize: 40 }} />
                        <Typography gutterBottom variant="h3" component="h2">
                          <br />
                          <FormattedMessage {...language.getText('dashboard.AUTHORING_LABEL')} />
                        </Typography>
                        <br />
                        <Typography
                          style={{ fontSize: 16 }}
                          gutterBottom
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          <FormattedMessage {...language.getText('dashboard.AUTHORING_DESC')} />
                        </Typography>
                        <br />
                      </CardContent>
                      <br />
                    </CardActionArea>
                  </span>
                </Card>
              </TooltipCustom>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardActionArea onClick={() => Framework.redirectTo('/users')}>
                  <CardContent>
                    <Person style={{ fontSize: 40 }} />
                    <Typography gutterBottom variant="h3" component="h2">
                      <br />
                      <FormattedMessage {...language.getText('dashboard.USER_MGMT_LABEL')} />
                    </Typography>
                    <br />
                    <Typography
                      style={{ fontSize: 16 }}
                      gutterBottom
                      variant="caption"
                      color="textSecondary"
                      component="p"
                    >
                      <FormattedMessage {...language.getText('dashboard.USER_MGMT_LABEL')} />
                    </Typography>
                    <br />
                  </CardContent>
                  <br />
                </CardActionArea>
              </Card>
            </Grid> */}
            {/* <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardActionArea onClick={() => Framework.redirectTo('/reports')}>
                  <CardContent>
                    <Assessment style={{ fontSize: 40 }} />
                    <Typography gutterBottom variant="h3" component="h2">
                      <br />
                      <FormattedMessage {...language.getText('dashboard.REPORTS_LABEL')} />
                    </Typography>
                    <br />
                    <Typography
                      style={{ fontSize: 16 }}
                      gutterBottom
                      variant="caption"
                      color="textSecondary"
                      component="p"
                    >
                      <FormattedMessage {...language.getText('dashboard.REPORTS_DESC')} />
                    </Typography>
                    <br />
                  </CardContent>
                  <br />
                </CardActionArea>
              </Card>
            </Grid> */}
          </Grid>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(Dashboard)));
