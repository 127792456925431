/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
import eTextConfig from '../../../common/config/eTextConfig';

/**
 * Mobx State Tree Store
 */

const StatusDropDownRecords = types.model('StatusDropDownRecords', {
  statusId: types.maybeNull(types.integer),
  statusText: types.maybeNull(types.string)
});

const DivisionsDropDownRecords = types.model('DivisionsDropDownRecords', {
  divisionId: types.maybeNull(types.integer),
  divisionText: types.maybeNull(types.string)
});

const DisciplineCodeDropDownRecords = types.model('DisciplineCodeDropDownRecords', {
  disciplineCodeId: types.maybeNull(types.integer),
  discipline: types.maybeNull(types.string),
  subDiscipline: types.maybeNull(types.string),
  course: types.maybeNull(types.string),
  disciplineCode: types.maybeNull(types.string)
});

const ArtsAndScienceRecords = types.model('ArtsAndScienceRecords', {
  artsAndScienceId: types.maybeNull(types.integer),
  artsAndScienceText: types.maybeNull(types.string)
});

const ProfessionalAndCareerRecords = types.model('ProfessionalAndCareerRecords', {
  professionalAndCareerId: types.maybeNull(types.integer),
  professionalAndCareerText: types.maybeNull(types.string)
});

const InternationalRecords = types.model('InternationalRecords', {
  internationalId: types.maybeNull(types.integer),
  internationalText: types.maybeNull(types.string)
});

const PtgRecords = types.model('PtgRecords', {
  ptgId: types.maybeNull(types.integer),
  ptgText: types.maybeNull(types.string)
});

const EtextRevisionDetails = types.model('EtextRevisionDetails', {
  reqNo: types.maybeNull(types.integer),
  addemailaddress: types.maybeNull(types.string),
  additionalComments: types.maybeNull(types.string),
  authors: types.maybeNull(types.string),
  bonusChapter: types.maybeNull(types.string),
  changeInContent: types.maybeNull(types.string),
  changeToCover: types.maybeNull(types.string),
  colorInserts: types.maybeNull(types.string),
  compositor: types.maybeNull(types.string),
  contactname: types.maybeNull(types.string),
  copyrightyear: types.maybeNull(types.string),
  coverArtRadioValue: types.maybeNull(types.string),
  coverArtRadioValueBool: types.maybeNull(types.optional(types.boolean, true)),
  eTextIsbn10: types.maybeNull(types.string),
  eTextIsbn13: types.maybeNull(types.string),
  eTextProduct: types.maybeNull(types.string),
  eTextRadioValue: types.maybeNull(types.string),
  eTextRadioValueBool: types.maybeNull(types.optional(types.boolean, true)),
  emailaddress: types.maybeNull(types.string),
  endPapers: types.maybeNull(types.string),
  fileName: types.maybeNull(types.string),
  folderName: types.maybeNull(types.string),
  generalBookId: types.maybeNull(types.string),
  generalText10: types.maybeNull(types.string),
  generalText13: types.maybeNull(types.string),
  hotspotRadioValue: types.maybeNull(types.string),
  hotspotRadioValueBool: types.maybeNull(types.optional(types.boolean, true)),
  infoOthers: types.maybeNull(types.string),
  insideBackCover: types.maybeNull(types.string),
  insideFaceCover: types.maybeNull(types.string),
  ipadView: types.maybeNull(types.string),
  others: types.maybeNull(types.string),
  pageAdditions: types.maybeNull(types.string),
  parent10: types.maybeNull(types.string),
  parent13: types.maybeNull(types.string),
  permissionChange: types.maybeNull(types.string),
  phonenumber: types.maybeNull(types.string),
  divisionText: types.maybeNull(types.string),
  publishRadioValueBool: types.maybeNull(types.optional(types.boolean, true)),
  divisionID: types.maybeNull(types.integer),
  revGlossary: types.maybeNull(types.string),
  revhotspot: types.maybeNull(types.string),
  revisionTextArea: types.maybeNull(types.string),
  sourceFileRadioValue: types.maybeNull(types.string),
  sourceFileRadioValueBool: types.maybeNull(types.optional(types.boolean, true)),
  specialText: types.maybeNull(types.string),
  specialTextArea: types.maybeNull(types.string),
  tearCard: types.maybeNull(types.string),
  titleedition: types.maybeNull(types.string),
  tocChange: types.maybeNull(types.string),
  tocRadioValue: types.maybeNull(types.string),
  tocRadioValueBool: types.maybeNull(types.optional(types.boolean, true)),
  typeRevision: types.maybeNull(types.string),
  typeRevisionBool: types.maybeNull(types.optional(types.boolean, true)),
  updateProduct: types.maybeNull(types.string),
  updateToTitle: types.maybeNull(types.string)
});

const LanguagesRecords = types.model('LanguagesRecords', {
  languageId: types.maybeNull(types.integer),
  languageText: types.maybeNull(types.string)
});

const VendorRecords = types.model('VendorRecords', {
  vendorId: types.maybeNull(types.integer),
  vendorText: types.maybeNull(types.string)
});
const ManagedbyRecords = types.model('ManagedbyRecords', {
  managedById: types.maybeNull(types.integer),
  managedByText: types.maybeNull(types.string)
});

const MetaData = types
  .model('MetaData', {
    statusValues: types.optional(types.array(StatusDropDownRecords), []),
    divisions: types.optional(types.array(DivisionsDropDownRecords), []),
    disciplineCode: types.optional(types.array(DisciplineCodeDropDownRecords), []),
    languages: types.optional(types.array(LanguagesRecords), []),
    vendors: types.optional(types.array(VendorRecords), []),
    managedby: types.optional(types.array(ManagedbyRecords), []),
    // Driven via config
    artAndScience: types.optional(types.array(ArtsAndScienceRecords), []),
    professionalAndCareer: types.optional(types.array(ProfessionalAndCareerRecords), []),
    international: types.optional(types.array(InternationalRecords), []),
    ptg: types.optional(types.array(PtgRecords), []),
    eTextRevisionDetails: types.optional(EtextRevisionDetails, {})
  })
  .actions((self) => ({
    fetchStatusDropdownValues() {
      Framework.getEventManager().publish(constants.ETEXT_DB_STATUS_DROPDOWN_VALUES);
    },
    fetchDivisionsDropdownValues() {
      Framework.getEventManager().publish(constants.ETEXT_DB_DIVISIONS_DROPDOWN_VALUES);
    },
    fetchDisciplineCodeDropdownValues() {
      Framework.getEventManager().publish(constants.ETEXT_DB_DISCIPLINE_CODE_VALUES);
    },
    fetchLanguages() {
      Framework.getEventManager().publish(constants.ETEXT_DB_LANGUAGES_VALUES);
    },
    fetchVendor() {
      Framework.getEventManager().publish(constants.ETEXT_DB_VENDOR_VALUES);
    },
    fetchMangeddby() {
      Framework.getEventManager().publish(constants.ETEXT_DB_MANAGEDBY_VALUES);
    },
    setStatusDropdownRecord(response) {
      applySnapshot(self.statusValues, response.data);
    },
    setDivisionsDropdownRecords(response) {
      applySnapshot(self.divisions, response.data);
    },
    setDisciplineCodeRecords(response) {
      applySnapshot(self.disciplineCode, response.data);
    },
    setLanguages(response) {
      applySnapshot(self.languages, response.data);
    },
    setVendors(response) {
      applySnapshot(self.vendors, response.data);
    },
    setManagedby(response) {
      applySnapshot(self.managedby, response.data);
    },

    // Driven via config
    setArtsAndScience() {
      applySnapshot(self.artAndScience, eTextConfig.publishArtsOption);
    },
    setProfessionalAndCareer() {
      applySnapshot(self.professionalAndCareer, eTextConfig.publishCareerOption);
    },
    setInternational() {
      applySnapshot(self.international, eTextConfig.publishInternationalOption);
    },
    setEtextRevision() {
      applySnapshot(self.eTextRevisionDetails, eTextConfig.publishRevisionDetails);
    },
    setPtg() {
      applySnapshot(self.ptg, eTextConfig.publishPtgOption);
    }
  }));

export default MetaData;
