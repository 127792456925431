import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PropTypes } from 'mobx-react';

const McqDialogConfirms = ({ open, handleClose, handleConfirm, title, content }) => {
  // useEffect(() => {}, [content]);
  const handleConfirmation = async (confirmed) => {
    handleClose();
    await handleConfirm(confirmed);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" size="small" onClick={() => handleConfirmation(false)}>
          Cancel
        </Button>
        <Button onClick={() => handleConfirmation(true)} color="primary" variant="outlined" size="small">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

McqDialogConfirms.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  handleConfirm: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string
};

McqDialogConfirms.defaultProps = {
  open: true,
  title: null,
  content: null,
  handleConfirm: () => {},
  handleClose: () => {}
};

export default McqDialogConfirms;
