/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const ServerSideProcessDetail = types.model('ServerSideProcessDetail', {
  status: types.maybeNull(types.number),
  uuid: types.maybeNull(types.string),
  message: types.maybeNull(types.string)
});

const ServerSideProcessData = types.model('ServerSideProcessData', {
  globalBookId: types.maybeNull(types.string),
  bookId: types.maybeNull(types.number),
  version: types.maybeNull(types.string),
  serverSideProcessUUID: types.maybeNull(types.string),
  serverSideProcessStatus: types.maybeNull(types.number),
  bookFeatuerUpdated: types.maybeNull(types.string),
  serverSideEnabled: types.maybeNull(types.string),
  serverSideProcessed: types.maybeNull(types.string),
  createdDate: types.maybeNull(types.string),
  updatedDate: types.maybeNull(types.string)
});

const ServerSideProcess = types
  .model('ServerSideProcess', {
    serverSideProcess: types.optional(ServerSideProcessDetail, {}),
    ServerSideProcessData: types.optional(types.array(ServerSideProcessData), [])
  })
  .actions((self) => ({
    fetch(globalBookId) {
      Framework.getEventManager().publish(constants.SERVER_SIDE_PROCESS_REQUESTED, { globalBookId });
    },
    fetchSSPData() {
      Framework.getEventManager().publish(constants.GET_SERVER_SIDE_PROCESS_DATA);
    },
    fetchSearchData() {
      Framework.getEventManager().publish(constants.SEARCH_SERVER_SIDE_PROCESS_DATA);
    },
    fetchData(payload) {
      Framework.getEventManager().publish(constants.CANCEL_SERVER_SIDE_PROCESS, { payload });
    },
    set(response) {
      applySnapshot(self.serverSideProcess, response.data);
    },
    setSSPData(response) {
      const output = response.data;
      applySnapshot(self.ServerSideProcessData, output);
    }
  }));

export default ServerSideProcess;
