const bookEditionTypeId = [
  {
    id: 0,
    value: 'Select'
  },
  {
    id: 1,
    value: ' Student Edition'
  },
  {
    id: 2,
    value: 'Teacher Edition'
  },
  {
    id: 3,
    value: 'Common Edition'
  }
];

const typeDropDown = [
  {
    id: 1,
    value: '1'
  },
  {
    id: 2,
    value: '2'
  },
  {
    id: 3,
    value: '3'
  },
  {
    id: 4,
    value: '4'
  },
  {
    id: 5,
    value: '5'
  },
  {
    id: 6,
    value: '6'
  }
  // {
  //   id: 2,
  //   value: '7'
  // },
  // {
  //   id: 6,
  //   value: '6'
  // },
  // {
  //   id: 4,
  //   value: 'All'
  // }
];

const roleDropDown = [
  {
    id: 0,
    value: 'Select'
  },
  {
    id: 1,
    value: 'unit'
  },
  {
    id: 2,
    value: 'chapter'
  },
  {
    id: 3,
    value: 'h1'
  },
  {
    id: 4,
    value: 'h2'
  },
  {
    id: 5,
    value: 'h3'
  },
  {
    id: 6,
    value: 'h4'
  },
  {
    id: 7,
    value: 'frontmatter'
  },
  {
    id: 8,
    value: 'rearmatter'
  },
  {
    id: 9,
    value: 'part'
  },
  {
    id: 10,
    value: 'box'
  }
];

const corverDropdown = [
  {
    id: 1,
    value: 'png'
  },
  {
    id: 2,
    value: 'jpg'
  },
  {
    id: 3,
    value: 'swf'
  },
  {
    id: 4,
    value: 'pdf'
  }
];

const yearDropDown = [
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025'
];

const MetaConfig = {
  bookEditionTypeId,
  typeDropDown,
  roleDropDown,
  yearDropDown,
  corverDropdown
};

module.exports = MetaConfig;
