/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../../../common/constants';

export default class BookMetadataSaveService extends BaseService {
  constructor() {
    super(constants.BOOK_METADATA_POST, 'bookMetadataPostStatus');
  }

  handleEvent(eventName, event) {
    if (event.payload) {
      const mappedData = this.mapPayload(event.payload.data);
      return this.getClient('evergreen').post(`/books/${event.payload.data.bookID}/metadata`, mappedData);
    }
    return null;
  }

  handleResponse(response, event) {
    // eslint-disable-line class-methods-use-this
    Framework.redirectTo(`/books/${event.payload.data.bookID}`);
    const notification = {
      open: true,
      message: 'Book Metadata Saved Successfully!',
      type: 'info'
    };
    Framework.getStoreRegistry().getStore('notificationState').set(notification);
  }

  handleError(error, event) {
    // eslint-disable-line class-methods-use-this
    Framework.redirectTo(`/books/${event.payload.data.bookID}`);
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if ((status === 500 || status === 400) && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message ? data.error.message : data.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
  }

  mapPayload(payload) {
    return {
      bookID: payload.bookID.toString(),
      globalBookID: payload.globalBookID.toString(),
      title: payload.title,
      bookEditionNumber: payload.bookEditionNumber ? payload.bookEditionNumber : '',
      bookEditionID: payload.bookEditionTypeID.toString(),
      bookEditionTypeID: payload.bookEditionTypeID.toString(),
      copyrightYear: payload.copyrightYear.toString(),
      description: payload.description,
      hasTocFlatten: payload.hasTocFlatten,
      thumbnailArt: payload.thumbnailArt,
      thumbnailArtFormatID: payload.thumbnailArtFormatID,
      thumbnailDpi: payload.thumbnailDpi,
      coverArt: payload.coverArt,
      coverArtFormatID: payload.coverArtFormatID.toString(),
      coverDpi: payload.coverDpi.toString(),
      navigationArt: payload.navigationArt,
      navigationArtFormatID: payload.navigationArtFormatID.toString(),
      navigationDpi: payload.navigationDpi.toString(),
      pdfCoverArt: payload.pdfCoverArt,
      pdfCoverFormatID: payload.pdfCoverFormatID.toString(),
      pdfCoverDpi: payload.pdfCoverDpi.toString(),
      minimumHotspotHeight: payload.minimumHotspotHeight.toString(),
      twoPageLayoutLeft: payload.orientation,
      version: payload.version.toString(),
      numberOfLevels: payload.numberOfLevels.toString(),
      numberOfDisplayLevels: payload.numberOfDisplayLevels.toString(),
      authorList: this.mapAuthorList(toJS(payload.authorList)),
      bookLevels: this.mapBookLeveles(toJS(payload.bookLevels)),
      isbnList: this.mapIsbnList(toJS(payload.isbnList), payload.bookID)
    };
  }

  mapAuthorList = (inputPayload) => {
    const authorList = [];
    if (inputPayload && inputPayload.length > 0) {
      for (let i = 0; i < inputPayload.length; i += 1) {
        authorList.push({
          FIRST_NAME: inputPayload[i].firstName,
          LAST_NAME: inputPayload[i].lastName,
          NAME: inputPayload[i].otherName,
          AUTHOR_ID: inputPayload[i].authorID ? inputPayload[i].authorID.toString() : '0'
        });
      }
    }
    return authorList;
  };

  mapBookLeveles = (inputPayload) => {
    const bookLevels = [];
    if (inputPayload && inputPayload.length > 0) {
      for (let i = 0; i < inputPayload.length; i += 1) {
        bookLevels.push({
          LEVEL_TYPE_ID: inputPayload[i].levelTypeID.toString(),
          TYPE: inputPayload[i].type.toString(),
          NAME: inputPayload[i].name.toString()
        });
      }
    }
    return bookLevels;
  };

  mapIsbnList = (inputPayload, bookID) => {
    const isbnList = [];
    if (inputPayload && inputPayload.length > 0) {
      for (let i = 0; i < inputPayload.length; i += 1) {
        isbnList.push({
          BOOK_ID: bookID.toString(),
          ISBN_TYPE_ID: inputPayload[i].isbnTypeID.toString(),
          TYPE: inputPayload[i].type.toString(),
          DISPLAY_ISBN: inputPayload[i].displayISBN.toString()
        });
      }
    }
    return isbnList;
  };
}
