/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { TextField, Box, Typography, DialogContent, Dialog, DialogActions, Select, MenuItem } from '@material-ui/core';
import { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import { toJS } from 'mobx';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import NewRevision from '../../models/NewRevision';
// import * as Constants from '../../../../common/constants';
import MetaData from '../../models/MetaData';
import ContactInformation from '../common/ContactInformation';
import PublishingCompany from '../common/PublishingCompany';
import ParentInformation from '../common/ParentInformation';
// import StandardEbook from '../../../contentManager/components/create/StandardEbook';
import UsersPermissions from '../../../users/models/UsersPermissions';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },

  historyIcon: {
    cursor: 'pointer'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px'
  },
  width: {
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  paddingTopZero: {
    paddingTop: 0
  }
});

@inject('metaData', 'revisionData', 'userPermissions')
@observer
// eslint-disable-next-line react/prefer-stateless-function
class RevisionForm extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    metaData: shapes.modelOf(MetaData).isRequired,
    revisionData: shapes.modelOf(NewRevision).isRequired,
    language: shapes.language.isRequired,
    workflowSearchStatus: shapes.state.isRequired,
    workflowHistoryStatus: shapes.state.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        others: '',
        requestedBy: '',
        phone: '',
        email: '',
        parentISBN10: '',
        parentISBN13: '',
        chargeISBN: '',
        initialComments: '',
        itemsExcludeDesc: '',
        title: '',
        author: '',
        addlEmail: '',
        // copyright: '',
        divisionID: '',
        languageID: 41,
        otherRevisionTypeText: '',
        coverArtRadioValueBool: false,
        publishRadioValueBool: false,
        eTextRadioValueBool: false,
        sourceFileRadioValueBool: false,
        tocRadioValueBool: false,
        hotspotRadioValueBool: false,
        divisionSource: '',
        intlRegion: null,
        typeRevision: '',
        exTypeRevision: false,
        typeRevisionBool: false,
        generalText10: '',
        generalText13: '',
        bookID: '',
        suppSectionDesc: '',
        specialTextArea: '',
        revDescription: '',
        revFTPFolder: '',
        additionalNotes: '',
        permissionChange: false,
        updatedHotspotLinks: false,
        updatedGlossaryContent: false,
        pageAddOrSub: false,
        contentChange: false,
        updateTitleEditionAuthor: false,
        tocChange: false,
        coverImageChange: false,
        iPadViewChange: false,
        dlRequireChange: false,
        revProdTypeDemoPartial: false,
        otherRevChange: false,
        insideFaceCover: 'N',
        insideBackCover: 'N',
        colorInserts: 'N',
        bonusChapters: 'N',
        tearCard: 'N',
        endPapers: 'N',
        othersCheck: 'N'
      },
      revisionProps: {
        revisionBool: true
      },
      formErrorFields: {
        requestedByError: '',
        phoneError: '',
        emailError: '',
        parentISBN10Error: '',
        parentISBN13Error: '',
        titleError: '',
        authorError: '',
        bookIDError: '',
        // copyrightError: '',
        generalText10Error: '',
        generalText13Error: '',
        revFTPFolderError: ''
      }
    };
  }

  componentDidMount = () => {
    const { metaData } = this.props;
    if (metaData.artAndScience.length === 0) {
      metaData.setArtsAndScience();
    }
    if (metaData.professionalAndCareer.length === 0) {
      metaData.setProfessionalAndCareer();
    }
    if (metaData.international.length === 0) {
      metaData.setInternational();
    }
    if (metaData.ptg.length === 0) {
      metaData.setPtg();
    }
    if (metaData.languages.length === 0) {
      metaData.fetchLanguages();
    }
    metaData.setEtextRevision();
  };

  handleCheckbox = (e, textValue) => {
    const { formFields } = this.state;
    const tempCheck = formFields;

    if (textValue) {
      tempCheck.typeRevisionBool = false;
      tempCheck[e.target.name] = e.target.checked ? e.target.checked : e.target.checked;
    } else {
      tempCheck[e.target.name] = e.target.checked ? 'Y' : 'N';
    }

    this.setState({
      formFields: tempCheck
    });
  };

  getInitialState = () => {
    const initialState = {
      others: '',
      requestedBy: '',
      email: '',
      parentISBN10: '',
      parentISBN13: '',
      title: '',
      chargeISBN: '',
      initialComments: '',
      itemsExcludeDesc: '',
      author: '',
      requestedByError: '',
      phone: '',
      addlEmail: '',
      languageID: 41,
      // copyright: '',
      otherRevisionTypeText: '',
      publishRadioValueBool: false,
      eTextRadioValueBool: false,
      coverArtRadioValueBool: false,
      sourceFileRadioValueBool: false,
      tocRadioValueBool: false,
      hotspotRadioValueBool: false,
      divisionID: '',
      divisionSource: '',
      intlRegion: '',
      typeRevision: '',
      exTypeRevision: false,
      typeRevisionBool: false,
      generalText10: '',
      generalText13: '',
      bookID: '',
      suppSectionDesc: '',
      specialTextArea: '',
      revDescription: '',
      revFTPFolder: '',
      additionalNotes: '',
      permissionChange: false,
      updatedHotspotLinks: false,
      updatedGlossaryContent: false,
      pageAdditions: false,
      changeInContent: false,
      updateToTitle: false,
      tocChange: false,
      changeToCover: false,
      iPadViewChange: false,
      updateProduct: false,
      revProdTypeDemoPartial: false,
      dlRequireChange: false,
      otherRevChange: false,
      insideBackCover: 'N',
      insideFaceCover: 'N',
      colorInserts: 'N',
      bonusChapters: 'N',
      tearCard: 'N',
      endPapers: 'N',
      othersCheck: 'N'
    };
    return initialState;
  };

  getInitialErrorState = () => {
    const initialErrorState = {
      requestedByError: '',
      emailError: '',
      parentISBN13Error: '',
      parentISBN10Error: '',
      phoneError: '',
      titleError: '',
      authorError: '',
      bookIDError: '',
      generalText10Error: '',
      generalText13Error: '',
      // copyrightError: '',
      revFTPFolderError: ''
    };
    return initialErrorState;
  };

  handleChange = (event) => {
    const { formFields, formErrorFields } = this.state;
    const temp = formFields;
    const temperror = formErrorFields;
    if (event.target.name === 'bookID') {
      temp[event.target.name] = parseInt(event.target.value, 10);
    } else {
      temp[event.target.name] = event.target.value;
    }

    temperror[`${event.target.name}Error`] = '';
    this.setState({
      formFields: temp,
      formErrorFields: temperror
    });
  };

  handleRadioChange = (event) => {
    const { formFields } = this.state;
    const temp = formFields;

    if (event.target.name === 'divisionID') {
      temp[event.target.name] = parseInt(event.target.value, 10);
    } else {
      temp[event.target.name] = event.target.value;
    }
    temp.divisionSource = '';

    if (event.target.name === 'eTextRadioValue') {
      temp.eTextRadioValueBool = false;
    } else if (event.target.name === 'coverArtRadioValue') {
      temp.coverArtRadioValueBool = false;
    } else if (event.target.name === 'sourceFileRadioValue') {
      temp.sourceFileRadioValueBool = false;
    } else if (event.target.name === 'tocRadioValue') {
      temp.tocRadioValueBool = false;
    } else if (event.target.name === 'hotspotRadioValue') {
      temp.hotspotRadioValueBool = false;
    } else if (event.target.name === 'divisionID' && temp.divisionSource.length >= 0) {
      temp.publishRadioValueBool = false;
    }

    if (temp.divisionSource.length >= 0) {
      temp.publishRadioValueBool = true;
    }
    if (temp.divisionID === 25) {
      temp.publishRadioValueBool = false;
    }

    this.setState({
      formFields: temp
    });
  };

  handleBookData = (data) => {
    const {
      revisionData: { savePayload, setBookRevision }
    } = this.props;
    const { formFields } = this.state;
    const confirmPayload = formFields;
    const confirmFalse = false;
    const nonBookidData = {
      ...confirmPayload,
      isConfirmed: 'Yes'
    };
    if (data) {
      const payload = {
        data: {
          ...nonBookidData,
          revisionStatusText: 'Initiation',
          revisionStatusID: 30,
          sourceProfCar: 'Q',
          revRequestedBy: formFields.requestedBy,
          revAuthor: formFields.author,
          ebookISBN10: formFields.generalText10,
          ebookISBN13: formFields.generalText13
        }
      };
      setBookRevision(confirmFalse);
      savePayload(payload);
    } else {
      setBookRevision(confirmFalse);
    }
  };

  validateRequired = (value) => {
    const { formFields } = this.state;
    return (
      formFields[value] === null ||
      formFields[value].length === 0 ||
      formFields[value].length === '' ||
      formFields[value].length === '' ||
      formFields[value] === 0 ||
      formFields[value] === '0' ||
      formFields[value] === '' ||
      formFields[value] === ''
    );
  };

  validateSave = (requiredArr) => {
    let bool = true;
    let required = false;
    const { formErrorFields } = this.state;
    Object.keys(requiredArr).forEach((fieldError) => {
      const field = fieldError.replace('Error', '');
      required = this.validateRequired(field);
      if (required) {
        bool = false;
        const temp = formErrorFields;
        temp[`${field}Error`] = 'Required';
        this.setState({
          formErrorFields: temp
        });
      }
    });
    return bool;
  };

  handleSave = () => {
    const { formFields, formErrorFields } = this.state;
    this.validateSave(formErrorFields);

    if (
      formFields.divisionID.length === 0 ||
      formFields.divisionSource.length === 0 ||
      formFields.typeRevision.length > 0
    ) {
      if (formFields.divisionID === 25) {
        const temp = formFields;
        temp.publishRadioValueBool = false;
        temp.eTextRadioValueBool = false;
        temp.sourceFileRadioValueBool = false;
        temp.coverArtRadioValueBool = false;
        temp.tocRadioValueBool = false;
        temp.hotspotRadioValueBool = false;
        temp.typeRevisionBool = true;

        this.setState({
          formFields: temp
        });
      } else {
        const temp = formFields;
        temp.publishRadioValueBool = true;
        temp.eTextRadioValueBool = true;
        temp.sourceFileRadioValueBool = true;
        temp.coverArtRadioValueBool = true;
        temp.tocRadioValueBool = true;
        temp.hotspotRadioValueBool = true;
        temp.typeRevisionBool = false;

        this.setState({
          formFields: temp
        });
      }
    } else {
      const temp = formFields;
      temp.publishRadioValueBool = false;
      temp.eTextRadioValueBool = false;
      temp.sourceFileRadioValueBool = false;
      temp.coverArtRadioValueBool = false;
      temp.tocRadioValueBool = false;
      temp.hotspotRadioValueBool = false;

      this.setState({
        formFields: temp
      });
    }
    if (
      formFields.permissionChange === false &&
      formFields.updateTitleEditionAuthor === false &&
      formFields.updatedGlossaryContent === false &&
      formFields.updatedHotspotLinks === false &&
      formFields.updatedGlossaryContent === false &&
      formFields.pageAddOrSub === false &&
      formFields.contentChange === false &&
      formFields.updateTitleEditionAuthor === false &&
      formFields.tocChange === false &&
      formFields.coverImageChange === false &&
      formFields.iPadViewChange === false &&
      formFields.dlRequireChange === false &&
      formFields.revProdTypeDemoPartial === false &&
      formFields.otherRevChange === false
    ) {
      formFields.typeRevisionBool = true;
    } else {
      formFields.exTypeRevision = true;
      formFields.typeRevisionBool = false;
    }

    const errIdx = Object.values(formErrorFields).indexOf('Required');
    if (errIdx >= 0) {
      const elementName = Object.keys(formErrorFields)[errIdx].replace('Error', '');
      document.querySelectorAll(`[name='${elementName}']`)[0].scrollIntoView({ block: 'center' });
    }

    if (
      formFields.exTypeRevision === true &&
      formFields.divisionID > 20 &&
      formFields.typeRevisionBool === false &&
      (formFields.divisionSource !== '' || formFields.divisionID === 25) &&
      (formFields.divisionSource > 0 || formFields.divisionSource === '' || formFields.divisionSource.length > 0) &&
      errIdx === -1
    ) {
      const payload = {
        data: {
          ...formFields,
          revisionStatusText: 'Initiation',
          revisionStatusID: 1,
          sourceProfCar: 'Q',
          revRequestedBy: formFields.requestedBy,
          revAuthor: formFields.author,
          ebookISBN10: formFields.generalText10,
          ebookISBN13: formFields.generalText13,
          divisionSource: formFields.divisionSource.toString()
        }
      };
      const {
        revisionData: { savePayload }
      } = this.props;

      savePayload(payload);
    }
  };

  handleSelectionChange = (event) => {
    const { formFields } = this.state;
    const temp = formFields;
    if (event.target.name === 'languageID') {
      temp.languageID = event.target.value;
    } else {
      temp.divisionSource = event.target.value;
      temp.publishRadioValueBool = false;
    }

    if (temp.divisionID === 23) {
      temp.intlRegion = event.target.value;
    }

    this.setState({
      formFields: temp
    });
  };

  handleClear = () => {
    this.setState({
      formFields: this.getInitialState(),
      formErrorFields: this.getInitialErrorState()
    });
  };

  render() {
    const { language, classes, metaData, revisionData, userPermissions } = this.props;
    const { dataBookRevision } = revisionData || false;
    const { formFields, formErrorFields, divisionID, publishRadioValueBool, revisionProps } = this.state;
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    return (
      <>
        <br />
        <Typography align="center" variant="h2">
          <FormattedMessage {...language.getText('eTextrequest.HEDINTL')} />
        </Typography>
        <Paper elevation={3}>
          <Box my={2} mx={2} p={2} pb={4}>
            <ContactInformation
              formErrorFields={formErrorFields}
              formFields={formFields}
              handleChange={($event) => this.handleChange($event)}
            />
            <PublishingCompany
              formErrorFields={formErrorFields}
              formFields={formFields}
              handleChange={($event) => this.handleChange($event)}
              handleRadioChange={($event) => this.handleRadioChange($event)}
              handleSelectionChange={($event) => this.handleSelectionChange($event)}
              divisionID={divisionID}
              publishRadioValueBool={publishRadioValueBool}
              artsAndScience={metaData.artAndScience}
              professionalAndCareer={metaData.professionalAndCareer}
              international={metaData.international}
              ptg={metaData.ptg}
            />
          </Box>
        </Paper>

        <Paper elevation={3}>
          <Box my={2} mx={2} p={2} pb={4}>
            <ParentInformation
              handleChange={($event) => this.handleChange($event)}
              formErrorFields={formErrorFields}
              formFields={formFields}
              revisionProps={revisionProps}
            />
            <br />
            <Grid container item xs={12} direction="row" justify="space-between">
              <Typography align="center" variant="h5">
                <FormattedMessage {...language.getText('eTextrequest.GENERAL')} />
              </Typography>
            </Grid>{' '}
            <br />
            <Grid container item xs={12} direction="row" justify="space-between">
              <TextField
                label={<FormattedMessage {...language.getText('eTextreivsion.ISBN-10')} />}
                required
                margin="dense"
                error={formErrorFields.generalText10Error.length > 0}
                helperText={formErrorFields.generalText10Error}
                name="generalText10"
                onChange={($event) => this.handleChange($event)}
                value={formFields.generalText10}
                variant="outlined"
              />
              <TextField
                label={<FormattedMessage {...language.getText('eTextreivsion.ISBN-13')} />}
                margin="dense"
                required
                name="generalText13"
                error={formErrorFields.generalText13Error.length > 0}
                helperText={formErrorFields.generalText13Error}
                onChange={($event) => this.handleChange($event)}
                value={formFields.generalText13}
                variant="outlined"
              />
              <TextField
                label={<FormattedMessage {...language.getText('eTextreivsion.BOOKID')} />}
                required
                margin="dense"
                name="bookID"
                error={formErrorFields.bookIDError.length > 0}
                onChange={($event) => this.handleChange($event)}
                helperText={formErrorFields.bookIDError}
                value={formFields.bookID || ''}
                variant="outlined"
              />
            </Grid>
          </Box>
        </Paper>

        <Typography align="center" variant="h2">
          <FormattedMessage {...language.getText('eTextrequest.SPECIAL')} />
        </Typography>
        <Paper elevation={3}>
          <Box my={2} mx={5} p={2} pb={4}>
            <Grid container direction="row" spacing={5}>
              <Grid container item xs={12} direction="column">
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <Typography variant="h6">
                      <FormattedMessage {...language.getText('eTextreivsion.SPECIALBOOK')} /> <span> * </span>{' '}
                    </Typography>
                  </FormLabel>
                  <FormGroup aria-label="hotspotRadioValue" row onChange={(e) => this.handleCheckbox(e, false)}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={formFields.insideFaceCover === 'Y'}
                          className={classes.paddingTopZero}
                          name="insideFaceCover"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.INSIDE')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZero}
                          checked={formFields.insideBackCover === 'Y'}
                          name="insideBackCover"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.INSIDEBACK')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={formFields.colorInserts === 'Y'}
                          className={classes.paddingTopZero}
                          name="colorInserts"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.COLORINSERTS')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZero}
                          checked={formFields.bonusChapters === 'Y'}
                          name="bonusChapters"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.BONUSCHAPTER')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={formFields.tearCard === 'Y'}
                          className={classes.paddingTopZero}
                          name="tearCard"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.TEARCARD')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZero}
                          checked={formFields.endPapers === 'Y'}
                          name="endPapers"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.ENDPAPERS')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZero}
                          checked={formFields.othersCheck === 'Y'}
                          name="othersCheck"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.OTHER')} />}
                    />
                  </FormGroup>
                  <TextField
                    margin="dense"
                    name="others"
                    onChange={($event) => this.handleChange($event)}
                    value={formFields.others}
                    variant="outlined"
                  />
                </FormControl>
                <Typography variant="subtitle1" gutterBottom>
                  <FormattedMessage {...language.getText('eTextreivsion.PLEASEINDICATE')} />
                </Typography>
                <TextareaAutosize
                  name="suppSectionDesc"
                  value={formFields.suppSectionDesc}
                  aria-label="minimum height"
                  rowsMin={6}
                  rowsMax={8}
                  onChange={($event) => this.handleChange($event)}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Typography align="center" variant="h2">
          <FormattedMessage {...language.getText('eTextrequest.REVISIONS')} />
        </Typography>
        <Paper elevation={3}>
          <Box my={2} mx={5} p={2} pb={4}>
            <Typography align="center" variant="subtitle2" gutterBottom>
              <b>
                <FormattedMessage {...language.getText('eTextrequest.INDICATE')} />
              </b>
            </Typography>
            <br />
            <Grid container direction="row" spacing={5}>
              <Grid container item xs={12} direction="column">
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <Typography variant="h6">
                      <FormattedMessage {...language.getText('eTextreivsion.TYPESREVISION')} /> <span> * </span>{' '}
                      {formFields.typeRevisionBool ? (
                        <Typography variant="caption">
                          <span style={{ color: '#ca4644' }}>
                            {' '}
                            <FormattedMessage {...language.getText('eTextconvergence.ATLEAST')} />
                          </span>
                        </Typography>
                      ) : null}
                    </Typography>
                  </FormLabel>
                  <FormGroup
                    onChange={(e) => this.handleCheckbox(e, true)}
                    name="exTypeRevision"
                    value={formFields.exTypeRevision}
                    row
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZero}
                          checked={formFields.permissionChange === true}
                          name="permissionChange"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.PERMISSIONCHANGE')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZero}
                          checked={formFields.updatedHotspotLinks === true}
                          name="updatedHotspotLinks"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.NEWORREVHOTSPOT')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZero}
                          checked={formFields.updatedGlossaryContent === true}
                          name="updatedGlossaryContent"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.NEWORREVGLOSSARY')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZero}
                          checked={formFields.pageAddOrSub === true}
                          name="pageAddOrSub"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.PAGEADDITIONS')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={formFields.contentChange === true}
                          name="contentChange"
                          className={classes.paddingTopZero}
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.CHANGEINCONTENT')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={formFields.updateTitleEditionAuthor === true}
                          name="updateTitleEditionAuthor"
                          className={classes.paddingTopZero}
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.UPDATETOTITLE')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields.tocChange === true}
                          name="tocChange"
                          color="primary"
                          className={classes.paddingTopZero}
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.TOCCHANGE')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields.coverImageChange === true}
                          name="coverImageChange"
                          color="primary"
                          className={classes.paddingTopZero}
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.CHANGETOCOVER')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields.iPadViewChange === true}
                          name="iPadViewChange"
                          color="primary"
                          className={classes.paddingTopZero}
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.IPADVIEW')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields.dlRequireChange === true}
                          name="dlRequireChange"
                          color="primary"
                          className={classes.paddingTopZero}
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.UPDATEPRODUCTTYPE')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields.revProdTypeDemoPartial === true}
                          name="revProdTypeDemoPartial"
                          color="primary"
                          className={classes.paddingTopZero}
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.UPDATEPRODUCTIONTYPE')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields.otherRevChange === true}
                          name="otherRevChange"
                          color="primary"
                          className={classes.paddingTopZero}
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.OTHER')} />}
                      labelPlacement="end"
                    />
                  </FormGroup>
                  <TextField
                    margin="dense"
                    name="otherRevisionTypeText"
                    onChange={($event) => this.handleChange($event)}
                    value={formFields.otherRevisionTypeText}
                    variant="outlined"
                  />
                </FormControl>
                <Typography variant="subtitle2" gutterBottom>
                  <FormattedMessage {...language.getText('eTextrequest.LIST')} />
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  <FormattedMessage {...language.getText('eTextrequest.DESCRIPTION')} />
                </Typography>
                <TextareaAutosize
                  value={formFields.revDescription}
                  onChange={($event) => this.handleChange($event)}
                  name="revDescription"
                  aria-label="minimum height"
                  rowsMin={6}
                  rowsMax={8}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Typography align="center" variant="h2">
          <FormattedMessage {...language.getText('eTextrequest.FILELOCATION')} />
        </Typography>
        <Paper elevation={3}>
          <Box my={2} mx={5} p={2} pb={4}>
            <Grid container direction="column" spacing={5}>
              <Grid container item xs={12} direction="column">
                <Typography variant="subtitle2">
                  <FormattedMessage {...language.getText('eTextrequest.GUIDELINES')} />
                </Typography>
                <br />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextreivsion.FOLDERNAME')} />}
                  margin="dense"
                  required
                  error={formErrorFields.revFTPFolderError.length > 0}
                  helperText={formErrorFields.revFTPFolderError}
                  name="revFTPFolder"
                  onChange={($event) => this.handleChange($event)}
                  value={formFields.revFTPFolder}
                  variant="outlined"
                />
                <br />
                <Typography variant="subtitle1">
                  <FormattedMessage {...language.getText('eTextrequest.PLEASEREVIEW')} />
                </Typography>
              </Grid>
              {/* DO NOT DELETE
              <Typography align="center" variant="h5">
                <FormattedMessage {...language.getText('eTextrequest.UPLOAD_SOURCE_FILES')} />
              </Typography>
              <StandardEbook {...this.props} isCMScreen={false} /> */}
            </Grid>
          </Box>
        </Paper>

        <Typography align="center" variant="h2">
          <FormattedMessage {...language.getText('eTextrequest.COMMENTS')} />
        </Typography>
        <Paper elevation={3}>
          <Box my={2} mx={5} p={2} pb={4}>
            <Grid container direction="row" spacing={5}>
              <Grid container item xs={12} direction="column">
                <TextareaAutosize
                  placeholder="Please provide additional details here regarding the location of Source Assets or PDFs. Along with the additional comments (If any)."
                  value={formFields.additionalNotes}
                  onChange={($event) => this.handleChange($event)}
                  name="additionalNotes"
                  aria-label="minimum height"
                  rowsMin={6}
                  rowsMax={8}
                />
                <br />
              </Grid>
              <Grid container justify="space-evenly">
                <TooltipCustom
                  title={
                    !permissionsList.includes('ingestion_can_edit') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('eTextrevision.SUBMIT_ETEXT_REVISION')} />
                    )
                  }
                >
                  <span>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={this.handleSave}
                      disabled={!permissionsList.includes('etext_can_edit')}
                    >
                      <FormattedMessage {...language.getText('contentmanager.SUBMIT')} />
                    </Button>
                  </span>
                </TooltipCustom>
                <TooltipCustom title={<FormattedMessage {...language.getText('common.RESET_ALL_FIELDS')} />}>
                  <Button variant="outlined" size="small" color="primary" onClick={this.handleClear}>
                    <FormattedMessage {...language.getText('common.CLEARFORM')} />
                  </Button>
                </TooltipCustom>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Dialog open={dataBookRevision} className={classes.root}>
          <DialogContent className={classes.message}>
            <Typography align="center" variant="h6">
              <FormattedMessage {...language.getText('eTextrevision.BOOKIDTEXT')} />
            </Typography>
            <br />
            <Typography variant="h6">
              <FormattedMessage {...language.getText('eTextrevision.DUMMYTEXT')} />
            </Typography>
            <Select
              name="languageID"
              onChange={($event) => this.handleSelectionChange($event)}
              value={formFields.languageID}
              defaultValue="41"
            >
              {metaData.languages.length > 0 &&
                metaData.languages.map((lang) => {
                  return <MenuItem value={lang.languageId}>{lang.languageText}</MenuItem>;
                })}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" size="small" onClick={() => this.handleBookData(true)}>
              <FormattedMessage {...language.getText('common.YES')} />
            </Button>
            <Button variant="outlined" color="primary" size="small" onClick={() => this.handleBookData(false)}>
              <FormattedMessage {...language.getText('common.NO')} />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(RevisionForm)));
