/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Base environment settings. This base environment config can be overriden in environment-specific configs.
 */

const base = {
  ENVIRONMENT: 'stg',
  PARTNER_ENV: 'stg',
  EVERGREEN_API_BASE_URL: '/api/v1',
  EVERGREEN_API_TIMEOUT: 60000,
  PRODUCT: 'SAeT',
  BROWSER_REQUIREMENTS_URL:
    'https://www.pearson.com/us/higher-education/products-services-teaching/' +
    'course-content/textbooks-and-etexts/pearson-etext/system-requirements.html',
  BROWSER_CHECK: false,
  CLIENT_ID: '29s6iaLv3PblrAbzCP9kHlFY5ntGeNN7',
  INT_LOGIN_URL: 'https://mycloud.pearson.com/redirect',
  INT_LOGOUT_URL: 'https://mycloud.pearson.com/logout',
  INT_BASE_URL: '/api/v1',
  INT_USER_PROFILE_URL: 'https://graph.microsoft.com/v1.0/me',
  INT_TIMEOUT: 60000,
  INT_AZURE_CLIENT_ID: '0257b9a8-eff1-482d-b310-bc7175a92a56',
  AZURE_TENANT_ID: '209a71f6-c9bb-48f1-a23d-a26d275b75e2',
  AZURE_REDIRECT_URI: 'https://local.evergreen.pearson.com:3000',
  GTM_CONTAINER_ID: '',
  GTM_AUTH: '',
  GTM_PREVIEW: '',
  DELAYED_GTM: true,
  PSEUDO_LOCALE_CODE: 'pt',
  CORRELATION_ID_PREFIX: 'EVG',
  NEWRELIC_CONFIG_NAME: '/newrelic/newrelic.js',
  NEWRELIC_ENABLED: false,
  NEWRELIC_CONFIG: {
    accountId: '',
    agentId: '',
    applicationId: '',
    licenseKey: '',
    trustKey: ''
  }
};

export default base;
