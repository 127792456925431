/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import _ from 'lodash';
import * as constants from '../../../common/constants';

export default class ColorInterfaceSaveService extends BaseService {
  constructor() {
    super(constants.COLOR_INTERFACE_POST, 'colorLanguagePostStatus');
  }

  handleEvent(eventName, event) {
    if (event.payload) {
      const mappedData = this.mapPayload(event.payload.data);
      return this.getClient('evergreen').post(`books/${event.payload.id}/interfaceColorsAndLanguage`, mappedData);
    }
    return null;
  }

  async handleResponse(response, event) {
    // eslint-disable-line class-methods-use-this
    await Framework.redirectTo(`/books/${event.payload.id}`);
    const notification = {
      open: true,
      message: 'InterfaceColors data saved successfully!',
      type: 'info'
    };
    Framework.getStoreRegistry().getStore('notificationState').set(notification);
  }

  handleError(error, event) {
    // eslint-disable-line class-methods-use-this
    Framework.redirectTo(`/books/${event.payload.id}`);
  }

  mapPayload(payload) {
    // eslint-disable-line class-methods-use-this
    return _.omit(payload, ['bookId']);
  }
}
