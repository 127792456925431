/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {
  Typography,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent,
  Box,
  // DialogContentText,
  Paper,
  IconButton
} from '@material-ui/core';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import TooltipCustom from '../../../../common/components/TooltipCustom';
// import eTextConfig from '../../../../common/config/eTextConfig';
// import utils from '../../../../common/utils';
// import Loader from '../../../../common/Loader';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '90vh',
    maxWidth: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  labelWidht: {
    width: '100%'
  },
  bckground: {
    backgroundColor: '#d7d7d78f'
  },
  boxWidth: {
    width: '27%'
  },
  boxCoverWidth: {
    width: '90%'
  },
  paddingTopZeroCheckbox: {
    paddingTop: 0
  },
  spanClass: {
    fontSize: 12,
    color: '#808080',
    fontFamily: 'inherit'
  }
});

const rows = [
  {
    flags: 'bookinfo',
    flag: 'nonsynchaudio',
    Comments: 'Is digital library field in database blank or does it show no?'
  },
  {
    flags: 'regioninfo',
    flag: 'zoominfo',
    Comments: 'Request submitted.'
  },
  {
    flags: 'replaceregions',
    flag: 'printfile',
    Comments: 'Is digital library field in database blank or does it show no?'
  },
  {
    flags: 'glossary',
    flag: 'bookpreference',
    Comments: 'Request submitted.'
  },
  {
    flags: 'basket',
    flag: 'customicon',
    Comments: 'Is digital library field in database blank or does it show no?'
  },
  {
    flags: 'pdffiles',
    flag: 'replaceTOC',
    Comments: 'Request submitted.'
  },
  {
    flags: 'ipadfiles',
    flag: 'lowresprocessing',
    Comments: 'Is digital library field in database blank or does it show no?'
  },
  {
    flags: 'audiotext',
    flag: 'library',
    Comments: 'Request submitted.'
  }
];

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

const ManifestInfo = ({ language, classes, openManifest, close }) => (
  <Dialog
    open={openManifest}
    onClose={close()}
    fullWidth="true"
    fullScreen
    direction="column"
    classes={{ paper: classes.dialogPaper }}
    aria-labelledby="draggable-dialog-title"
  >
    <DialogTitle
      id="customized-dialog-title"
      onClose={close()}
      align="center"
      toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
    >
      <Typography variant="h3">Manage Manifest XML Flags</Typography>
    </DialogTitle>
    <br />

    <DialogContent>
      <Paper elevation={4}>
        <Box m={2} pt={1} pb={1}>
          <Grid container direction="row" justify="space-between">
            <Grid container item xs={12} justify="space-between">
              <Typography variant="h5" className={classes.heading} align="center" gutterBottom>
                Manage Book Info
              </Typography>
            </Grid>
            {/* <Paper style={{ width: '100%' }}> */}
            <Box m={1} style={{ width: '100%' }}>
              <Grid container item xs={12} justify="space-between">
                <Typography variant="h6" className={classes.heading} align="center">
                  Global Book ID/Book ID : <span className={classes.spanClass}> CM27955600</span>
                  <br />
                </Typography>
              </Grid>
              <br />
              <Grid container item xs={12} justify="space-between">
                <Typography variant="h6" className={classes.heading} align="center">
                  Title : <span className={classes.spanClass}> </span>
                </Typography>
                <Typography variant="h6" className={classes.heading} align="center">
                  Action Users :
                </Typography>
                <Typography variant="h6" className={classes.heading} align="center">
                  Identifier/eText ISBN 10/eText ISBN 13 :
                </Typography>
              </Grid>
              <br />
              <Grid container item xs={12} justify="space-between" spacing={2}>
                <Grid item xs={6}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Flags</TableCell>
                          <TableCell align="right">Content Manager</TableCell>
                          <TableCell align="right">Authoring</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                              {row.flags}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={6}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Flags</TableCell>
                          <TableCell align="right">Content Manager</TableCell>
                          <TableCell align="right">Authoring</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                              {row.flag}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
            {/* </Paper> */}
            <br />
          </Grid>
        </Box>
      </Paper>

      <br />

      <br />
      <br />
      <div>
        {' '}
        <Grid container direction="row" justify="center">
          <Button color="primary" style={{ marginRight: '15px' }} variant="outlined">
            Update BookInfo
          </Button>
          {/* <Button color="primary" style={{ marginRight: '15px' }} variant="outlined">
            Close
          </Button> */}
        </Grid>
      </div>
      <br />
      <br />
    </DialogContent>
  </Dialog>
);

ManifestInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  openManifest: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(ManifestInfo)));
