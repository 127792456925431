/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

const _ = require('lodash'); // eslint-disable-line import/no-extraneous-dependencies
const PDF_CONSTANTS = require('./constants');

const createCanvasElPage = (currentPageIndex) => {
  const hotspotCanvasId = 'hotspot_Canvas_Id';
  const parentPageId = `docViewer_ViewContainer_PageContainer_${currentPageIndex}`;
  const parentPageEle = document.getElementById(parentPageId);
  let canvas = document.getElementById(hotspotCanvasId);
  if (canvas === null) {
    canvas = document.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.cursor = 'default';
    canvas.style.zIndex = 1;
    canvas.setAttribute('id', hotspotCanvasId);
    parentPageEle.appendChild(canvas);
  }
  const docViewerId = `docViewer_ViewContainer_T_${currentPageIndex}`;
  const docViewerEle = document.getElementById(docViewerId);
  canvas.height = docViewerEle.clientHeight;
  canvas.width = docViewerEle.clientWidth;
  return canvas;
};

const getPageScale = (currentPageIndex) => {
  const docViewerId = `docViewer_ViewContainer_T_${currentPageIndex}`;
  const docViewerElement = document.getElementById(docViewerId);

  const pageWidth = docViewerElement && docViewerElement.offsetWidth;
  const pageHeight = docViewerElement && docViewerElement.offsetHeight;
  const originalPdfWidth = window.WebPDF.Tool.readerApp.getPDFDoc().getPage(0).getPageWidth();
  const originalPdfHeight = window.WebPDF.Tool.readerApp.getPDFDoc().getPage(0).getPageHeight();
  const widthScale = pageWidth / originalPdfWidth;
  const heightScale = pageHeight / originalPdfHeight;

  return {
    widthScale,
    heightScale,
    originalPdfWidth,
    originalPdfHeight
  };
};

const initialCreateHotspotData = (hotspotRect, props) => {
  const { x, y, h, w } = hotspotRect;
  return {
    alternateMediaUri: null,
    assetLastModifiedDate: null,
    assetSize: null,
    description: '',
    downloadable: 'N',
    downloadUrl: null,
    globalBookId: `${props.globalBookId}`,
    glossary: null,
    guid: null,
    hasPlatformIcon: 'N',
    height: `${h}`,
    iconType: null,
    iconTypeId: `${props.iconTypeId}`,
    imageName: null,
    imageUri: '',
    isBrowserView: false,
    isIcon: '',
    isIpad: false,
    linkHeight: null,
    linkSearch: null,
    linkType: null,
    linkTypeId: `${props.linkTypeId}`,
    linkTypeLocation: null,
    linkUri: '',
    linkWidth: null,
    linkX: null,
    linkY: null,
    mediaHeight: 0,
    mediaWidth: 0,
    name: '',
    note: '',
    page: `${props.page}`,
    pageOrder: null,
    pearsonSmartPlayer: false,
    platformId: props.platformId ? `${props.platformId}` : null,
    readyToPublish: true,
    regionId: null,
    regionType: null,
    regionTypeId: `${props.regionTypeId}`,
    roleType: null,
    roleTypeId: 1,
    sequenceId: null,
    transparent: false,
    useCustom: 'N',
    width: `${w}`,
    x: `${x}`,
    y: `${y}`
  };
};

const getHotspotType = (regionUrl) => {
  const regionLink = regionUrl.toLowerCase();
  const regionType = {};

  if (regionLink.endsWith('.doc') || regionLink.endsWith('.docx')) {
    regionType.region = 'DOCUMENT';
  }
  if (regionLink.endsWith('.xls') || regionLink.endsWith('.xlsx')) {
    regionType.region = 'DOCUMENT';
  }
  if (regionLink.endsWith('.ppt') || regionLink.endsWith('.pptx')) {
    regionType.region = 'DOCUMENT';
  }
  if (regionLink.endsWith('.pdf')) {
    regionType.region = 'DOCUMENT';
  } else if (regionLink.endsWith('.mp4') || regionLink.endsWith('.m4v')) {
    regionType.region = 'VIDEO';
  } else if (regionLink.endsWith('.mp3')) {
    regionType.region = 'AUDIO';
  } else if (
    regionLink.endsWith('.jpg') ||
    regionLink.endsWith('.jpeg') ||
    regionLink.endsWith('.png') ||
    regionLink.endsWith('.gif')
  ) {
    regionType.region = 'IMAGE';
  } else if (regionLink.endsWith('.flv')) {
    regionType.region = 'FLV';
  } else if (regionLink.endsWith('.swf')) {
    regionType.region = 'SWF';
  } else {
    regionType.region = 'URL';
  }

  return regionType;
};

const getBasepaths = (regionDetails, mediaBasepaths) => {
  let basepathObj = {};
  let basepath = '';
  if (mediaBasepaths) {
    basepathObj = mediaBasepaths.find((item) => item.linkTypeID === regionDetails.linkTypeId);
  }
  if (!_.isEmpty(basepathObj)) {
    basepath = basepathObj.basepath;
  }
  return basepath;
};

const getTypeFromRegion = (regionId, linkUri) => {
  let hotspotType;
  let regionData;

  switch (regionId) {
    case PDF_CONSTANTS.REGION_TYPE.AUDIO:
      hotspotType = 'AUDIO';
      break;
    case PDF_CONSTANTS.REGION_TYPE.IMAGE:
      hotspotType = 'IMAGE';
      break;
    case PDF_CONSTANTS.REGION_TYPE.MEDIA:
    case PDF_CONSTANTS.REGION_TYPE.URL:
      regionData = getHotspotType(linkUri);
      hotspotType = regionData.region;
      break;
    case PDF_CONSTANTS.REGION_TYPE.POWERPOINT:
    case PDF_CONSTANTS.REGION_TYPE.EXCEL:
    case PDF_CONSTANTS.REGION_TYPE.PDF:
    case PDF_CONSTANTS.REGION_TYPE.WORD_DOC:
      hotspotType = 'DOCUMENT';
      break;
    case PDF_CONSTANTS.REGION_TYPE.VIDEO:
      hotspotType = 'VIDEO';
      break;
    case PDF_CONSTANTS.REGION_TYPE.FLASH:
      hotspotType = 'FLASH';
      break;
    default:
      hotspotType = 'URL';
  }
  return hotspotType;
};

const getRegionDetails = (regionClicked) => {
  const youtubeRegex = new RegExp(
    ['^((?:https?:)?//)?((?:www|m).)?', '((?:youtube.com|youtu.be))(/(?:[w-]+?v=|embed/|v/)?)([w-]+)(S+)?$'].join('')
  );
  const clickedRegionDetails = regionClicked;

  clickedRegionDetails.hotspotType = '';
  switch (clickedRegionDetails.linkTypeId) {
    case PDF_CONSTANTS.LINK_TYPE.IMAGE:
      clickedRegionDetails.hotspotType = 'IMAGE';
      break;
    case PDF_CONSTANTS.LINK_TYPE.FLV:
      clickedRegionDetails.hotspotType = 'FLV';
      break;
    case PDF_CONSTANTS.LINK_TYPE.H264:
      clickedRegionDetails.hotspotType = 'VIDEO';
      break;
    case PDF_CONSTANTS.LINK_TYPE.MP3:
    case PDF_CONSTANTS.LINK_TYPE.FACELESSAUDIO:
      clickedRegionDetails.hotspotType = 'AUDIO';
      break;
    case PDF_CONSTANTS.LINK_TYPE.PAGE_NUMBER:
      clickedRegionDetails.hotspotType = 'PAGENUMBER';
      break;
    case PDF_CONSTANTS.LINK_TYPE.EMAIL:
      clickedRegionDetails.hotspotType = 'EMAIL';
      break;
    case PDF_CONSTANTS.LINK_TYPE.LTILINK:
      clickedRegionDetails.hotspotType = 'LTILINK';
      break;
    case PDF_CONSTANTS.LINK_TYPE.SWF:
      clickedRegionDetails.hotspotType = 'SWF';
      break;
    case PDF_CONSTANTS.LINK_TYPE.URL:
    case PDF_CONSTANTS.LINK_TYPE.VIRTUAL_LEARNING_ASSET:
    case PDF_CONSTANTS.LINK_TYPE.CHROMELESS_URL:
      clickedRegionDetails.hotspotType = getTypeFromRegion(
        clickedRegionDetails.regionTypeId,
        clickedRegionDetails.linkUri
      );
      break;
    case PDF_CONSTANTS.LINK_TYPE.GLOSSARY_TERM:
      clickedRegionDetails.hotspotType = 'GLOSSARY_TERM';
      break;
    case PDF_CONSTANTS.LINK_TYPE.IPADAPP:
      clickedRegionDetails.hotspotType = 'IPADAPP';
      break;
    case PDF_CONSTANTS.LINK_TYPE.JAZZASSET:
      clickedRegionDetails.hotspotType = 'JAZZASSET';
      break;
    default:
      clickedRegionDetails.hotspotType = 'URL';
  }
  if (
    clickedRegionDetails.hotspotType !== 'PAGENUMBER' ||
    clickedRegionDetails.hotspotType !== 'EMAIL' ||
    clickedRegionDetails.hotspotType !== 'LTILINK'
  ) {
    const { linkUri } = clickedRegionDetails;

    if (
      linkUri &&
      (linkUri.startsWith('https://mediaplayer.pearsoncmg.com/assets') ||
        linkUri.startsWith('http://mediaplayer.pearsoncmg.com/assets'))
    ) {
      clickedRegionDetails.hotspotType = 'SPPASSET';
    }
    if (clickedRegionDetails.hotspotType === 'URL') {
      if (youtubeRegex.test(clickedRegionDetails.linkUri)) {
        clickedRegionDetails.hotspotType = 'VIDEO';
      }
    }
    if (
      clickedRegionDetails.hotspotType !== 'SPPASSET' &&
      clickedRegionDetails.hotspotType !== 'VIDEO' &&
      clickedRegionDetails.hotspotType !== 'IMAGE' &&
      clickedRegionDetails.hotspotType !== 'AUDIO' &&
      clickedRegionDetails.hotspotType !== 'FLV' &&
      clickedRegionDetails.hotspotType !== 'LTILINK'
    ) {
      if (
        linkUri &&
        (linkUri.endsWith('.htm') ||
          linkUri.endsWith('.html') ||
          linkUri.startsWith('http://mediaplayer.pearsoncmg.com') ||
          linkUri.startsWith('https://mediaplayer.pearsoncmg.com'))
      ) {
        clickedRegionDetails.hotspotType = 'URL';
      }
    }
  }
  return clickedRegionDetails;
};

const HotspotUtils = {
  createCanvasElPage,
  getPageScale,
  initialCreateHotspotData,
  getRegionDetails,
  getBasepaths
};

module.exports = HotspotUtils;
