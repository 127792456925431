/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 */

const Children = types.model('Children', {
  title: types.maybeNull(types.string),
  linkUri: types.maybeNull(types.string),
  uri: types.maybeNull(types.string),
  pageOrder: types.maybeNull(types.union(types.string, types.integer, types.number)),
  pageNumber: types.maybeNull(types.union(types.string, types.integer, types.number)),
  type: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  linkTypeId: types.maybeNull(types.union(types.string, types.integer, types.number)),
  regionTypeId: types.maybeNull(types.union(types.string, types.integer, types.number)),
  // role: types.maybeNull(types.string),
  children: types.maybeNull(types.array(types.late(() => Children)))
});
// eslint-disable-next-line no-unused-vars
const TocNode = types.model('TocNode', {
  id: types.maybeNull(types.union(types.string, types.integer, types.number)),
  title: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  children: types.maybeNull(types.array(Children)),
  ProductId: types.maybeNull(types.union(types.string, types.integer, types.number))
});

const AuthorDetail = types.model('AuthorDetail', {
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  otherName: types.maybeNull(types.string),
  authorID: types.maybeNull(types.integer)
});

const Slates = types.model('Slates', {
  chapterId: types.maybeNull(types.union(types.string, types.integer, types.number)),
  chapterTitle: types.maybeNull(types.string),
  href: types.maybeNull(types.string),
  id: types.maybeNull(types.union(types.string, types.integer, types.number)),
  pagenumber: types.maybeNull(types.union(types.string, types.integer, types.number)),
  parentid: types.maybeNull(types.union(types.string, types.integer, types.number)),
  playOrder: types.maybeNull(types.union(types.string, types.integer, types.number)),
  printDisabled: types.maybeNull(types.union(types.string, types.boolean)),
  title: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  uri: types.maybeNull(types.string)
});

const Asset = types
  .model('Asset', {
    bookId: types.maybeNull(types.union(types.string, types.integer, types.number)),
    globalBookId: types.maybeNull(types.string),
    slates: types.optional(types.array(Slates), []),
    children: types.maybeNull(TocNode),
    basePath: types.maybeNull(types.string),
    thumbnailArt: types.maybeNull(types.string),
    authorList: types.optional(types.array(AuthorDetail), [])
  })
  .actions((self) => ({
    fetch(id) {
      Framework.getEventManager().publish(constants.TOC_DATA_FETCHED, { id });
    },
    set(response) {
      applySnapshot(self, response.data);
    }
  }));

export default Asset;
