/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { toJS } from 'mobx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Checkbox,
  NativeSelect,
  Fab
} from '@material-ui/core';
// import Framework from '@greenville/framework';
import Framework, { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import Breadcrumbs from '../../books/components/commonitems/Breadcrumbs';
import UsersPermissions from '../../users/models/UsersPermissions';
import TooltipCustom from '../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    width: '98%',
    padding: theme.spacing(0, 1)
  },
  formControl: {
    minWidth: 60,
    alignItems: 'center'
  },
  padinstyle: {
    margin: theme.spacing(1, 0.5)
  },
  selectwidth: {
    width: '80px',
    fontSize: '12px'
  },
  typofont: {
    fontSize: '13px',
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },
  padclass: {
    padding: 1,
    fontSize: '12px'
  },
  textfont: {
    fontSize: '12px'
  },
  mtop: {
    marginTop: '2px'
  },
  buttonspace: {
    '& > *': {
      margin: theme.spacing(0.8)
    },
    textspancolor: {
      fontSize: 9,
      fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
      fontWeight: 400,
      lineHeight: 1.43,
      color: '#000'
    },
    textcolor: {
      color: '#6a7070',
      fontSize: 13
    }
  }
});
@inject('userPermissions')
@observer
class EntitlementHome extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    moduleListState: PropTypes.array.isRequired,
    saveAndPublish: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    entitlementState: PropTypes.object.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      bookModule: []
    };
  }

  componentDidMount() {
    const { moduleListState } = this.props;
    this.setState({ bookModule: toJS(moduleListState) });
  }

  createHeader = (headerObj, classes) => (
    <TableHead>
      <TableRow>
        {headerObj.map((item) => (
          <TableCell>
            <Typography className={classes.typofont}>{item}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  handleCheckbox = (event, item) => {
    const { bookModule } = this.state;
    const updatedModuleList = bookModule;
    updatedModuleList.forEach((parent) => {
      parent.rows.forEach((child) => {
        if (child.scenarioID === item.scenarioID) {
          child.moduleList.forEach((obj) => {
            if (JSON.stringify(obj) === JSON.stringify(item)) {
              obj[event.target.name] = this.convertBoolToString(event.target.checked); // eslint-disable-line no-param-reassign
            }
          });
        }
      });
    });

    if (
      (item.scenarioID === 1 || item.scenarioID === 16) &&
      (event.target.name === 'isMobile' ||
        event.target.name === 'isAndroid' ||
        event.target.name === 'isDownloadable' ||
        event.target.name === 'isAssetsDownloadable')
    ) {
      updatedModuleList.forEach((parent) => {
        parent.rows.forEach((child) => {
          if (child.scenarioID === item.scenarioID) {
            child.moduleList.forEach((obj) => {
              if (obj.siteID === item.siteID) {
                obj[event.target.name] = this.convertBoolToString(event.target.checked); // eslint-disable-line no-param-reassign
              }
            });
          }
        });
      });
    }

    this.setState({ bookModule: updatedModuleList });
  };

  handleText = (event, item) => {
    const { bookModule } = this.state;
    const updatedModuleList = bookModule;
    updatedModuleList.forEach((parent) => {
      parent.rows.forEach((child) => {
        if (child.scenarioID === item.scenarioID) {
          child.moduleList.forEach((obj) => {
            if (JSON.stringify(obj) === JSON.stringify(item)) {
              if (event.target.name === 'purchaseType') {
                obj[event.target.name] = Number(event.target.value); // eslint-disable-line no-param-reassign
              } else {
                obj[event.target.name] = event.target.value; // eslint-disable-line no-param-reassign
              }
            }
          });
        }
      });
    });

    this.setState({ bookModule: updatedModuleList });
  };

  saveAndPublish = (value) => {
    const { bookModule } = this.state;
    const payload = {
      data: bookModule,
      isPublish: value
    };

    const { saveAndPublish } = this.props;
    saveAndPublish(payload);
  };

  addRow = (item) => {
    const newModule = { ...item };
    const { bookModule } = this.state;
    const updatedModuleList = bookModule;

    newModule.isPrimary = 'N';
    newModule.moduleID = '';
    newModule.isActive = 'N';

    updatedModuleList.forEach((parent) => {
      parent.rows.forEach((child) => {
        if (child.scenarioID === newModule.scenarioID) {
          child.moduleList.push(newModule);
        }
      });
    });

    this.setState({ bookModule: updatedModuleList });
  };

  removeRow = (item) => {
    const { bookModule } = this.state;
    const updatedModuleList = bookModule;
    updatedModuleList.forEach((parent) => {
      parent.rows.forEach((child) => {
        if (child.scenarioID === item.scenarioID) {
          child.moduleList.forEach((obj) => {
            if (obj.moduleID === item.moduleID) {
              child.moduleList = child.moduleList.filter((i) => i.moduleID !== item.moduleID); // eslint-disable-line no-param-reassign
            }
          });
        }
      });
    });

    this.setState({ bookModule: updatedModuleList });
  };

  convertBoolToString = (value) => {
    let checkboxValue = null;

    if (value) {
      checkboxValue = 'Y';
    } else {
      checkboxValue = 'N';
    }

    return checkboxValue;
  };

  convertStringToBool = (value) => {
    let val = false;
    switch (value) {
      case 'Y':
      case null:
        val = true;
        break;
      case 'N':
        val = false;
        break;
      default:
        val = false;
    }
    return val;
  };

  createRows = (rowObj, classes) => (
    <TableBody>
      {rowObj.map((item) =>
        item.moduleList.map((moduleProp) => (
          <TableRow>
            <>
              {this.convertStringToBool(moduleProp.isPrimary) ? (
                <TableCell>
                  <Grid style={{ width: '5px' }}>{item.scenarioLabel}</Grid>
                </TableCell>
              ) : (
                <TableCell />
              )}
              {this.convertStringToBool(moduleProp.isPrimary) ? (
                <TableCell>
                  <Grid style={{ width: '365px' }}>
                    <Typography gutterBottom variant="subtitle2" color="inherit">
                      {item.title}
                    </Typography>
                    {this.convertStringToBool(moduleProp.isPrimary) &&
                    (item.scenarioID === 1 || item.scenarioID === 16) ? (
                      <Grid container>
                        <Grid item>
                          <Checkbox
                            className={classes.padclass}
                            name="isMobile"
                            color="primary"
                            onChange={(event) => this.handleCheckbox(event, moduleProp)}
                            checked={this.convertStringToBool(moduleProp.isMobile)}
                          />
                          <Typography variant="subtitle2" display="inline">
                            Mobile
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Checkbox
                            className={classes.padclass}
                            name="isAndroid"
                            color="primary"
                            onChange={(event) => this.handleCheckbox(event, moduleProp)}
                            checked={this.convertStringToBool(moduleProp.isAndroid)}
                          />
                          <Typography variant="subtitle2" display="inline">
                            Android
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Checkbox
                            className={classes.padclass}
                            name="isDownloadable"
                            color="primary"
                            onChange={(event) => this.handleCheckbox(event, moduleProp)}
                            checked={this.convertStringToBool(moduleProp.isDownloadable)}
                          />
                          <Typography variant="subtitle2" display="inline">
                            Download
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Checkbox
                            name="isAssetsDownloadable"
                            className={classes.padclass}
                            color="primary"
                            onChange={(event) => this.handleCheckbox(event, moduleProp)}
                            checked={this.convertStringToBool(moduleProp.isAssetsDownloadable)}
                          />
                          <Typography variant="subtitle2" display="inline">
                            Asset Download
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null}
                    {this.convertStringToBool(moduleProp.isPrimary) ? (
                      <Typography variant="caption">{item.description}</Typography>
                    ) : null}
                  </Grid>
                </TableCell>
              ) : (
                <TableCell />
              )}
              {this.convertStringToBool(moduleProp.isPrimary) ? (
                <TableCell align="center">
                  {' '}
                  <Grid style={{ width: '50px' }}>
                    <Typography variant="caption">{moduleProp.siteID}</Typography>
                  </Grid>
                </TableCell>
              ) : (
                <TableCell />
              )}
              {this.convertStringToBool(moduleProp.isPrimary) ? (
                <TableCell align="center">
                  <Grid style={{ width: '80px' }}>
                    <Checkbox
                      name="isPDFPlayer"
                      className={classes.padclass}
                      color="primary"
                      onChange={(event) => this.handleCheckbox(event, moduleProp)}
                      checked={this.convertStringToBool(moduleProp.isPDFPlayer)}
                    />
                  </Grid>
                </TableCell>
              ) : (
                <TableCell />
              )}
              <TableCell align="center">
                <TextField
                  InputClassName="textfont"
                  size="small"
                  name="moduleID"
                  variant="outlined"
                  onChange={(event) => this.handleText(event, moduleProp)}
                  value={moduleProp.moduleID}
                  InputProps={{
                    classes: {
                      input: classes.textfont
                    }
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  name="isActive"
                  className={classes.padclass}
                  color="primary"
                  onChange={(event) => this.handleCheckbox(event, moduleProp)}
                  checked={this.convertStringToBool(moduleProp.isActive)}
                />
              </TableCell>
              {this.convertStringToBool(moduleProp.isPrimary) ? (
                <TableCell align="center">
                  <TextField
                    InputClassName="textfont"
                    size="small"
                    name="productID"
                    variant="outlined"
                    onChange={(event) => this.handleText(event, moduleProp)}
                    value={moduleProp.productID}
                    InputProps={{
                      classes: {
                        input: classes.textfont
                      }
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell />
              )}
              {this.convertStringToBool(moduleProp.isPrimary) ? (
                <TableCell align="center">
                  <TextField
                    InputClassName="textfont"
                    size="small"
                    name="url"
                    variant="outlined"
                    onChange={(event) => this.handleText(event, moduleProp)}
                    value={moduleProp.url}
                    InputProps={{
                      classes: {
                        input: classes.textfont
                      }
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell />
              )}
              {this.convertStringToBool(moduleProp.isPrimary) ? (
                <TableCell align="center">
                  <NativeSelect
                    value={moduleProp.purchaseType}
                    onChange={(event) => this.handleText(event, moduleProp)}
                    name="purchaseType"
                    className={classes.selectwidth}
                    inputProps={{ 'aria-label': 'type' }}
                  >
                    <option value="1">None</option>
                    <option value="2">Product ID</option>
                    <option value="3">URL</option>
                  </NativeSelect>
                </TableCell>
              ) : (
                <TableCell />
              )}
              {this.convertStringToBool(moduleProp.isPrimary) ? (
                <TableCell align="center">
                  <Checkbox
                    name="isPrimary"
                    className={classes.padclass}
                    color="primary"
                    disabled
                    onChange={(event) => this.handleCheckbox(event, moduleProp)}
                    checked={this.convertStringToBool(moduleProp.isPrimary)}
                  />
                </TableCell>
              ) : (
                <TableCell />
              )}
              {this.convertStringToBool(moduleProp.isPrimary) ? (
                <TableCell align="center">
                  <Fab color="primary" aria-label="add" size="small" onClick={() => this.addRow(moduleProp)}>
                    <AddIcon />
                  </Fab>
                </TableCell>
              ) : (
                <TableCell align="center">
                  <Fab
                    color="secondary !important"
                    aria-label="delete"
                    size="small"
                    onClick={() => this.removeRow(moduleProp)}
                  >
                    <DeleteIcon />
                  </Fab>
                </TableCell>
              )}
            </>
          </TableRow>
        ))
      )}
    </TableBody>
  );

  render() {
    const { classes, language, match, entitlementState, userPermissions } = this.props;
    const { bookModule } = this.state;
    const permissionsList = toJS(userPermissions.permissionList.permissions);

    return (
      <>
        {bookModule && (
          <div className={classes.root}>
            <Breadcrumbs
              data={<FormattedMessage {...language.getText('breadcrumbs.ENTITLEMENT')} />}
              id={match.params.id}
            />
            <br />
            <br />

            <Grid container justify="center">
              <Grid item>
                <Typography gutterBottom variant="h2">
                  <FormattedMessage {...language.getText('entitle.ENTER')} />
                  <span style={{ color: '#9f1800' }}>
                    {' '}
                    <b>
                      <FormattedMessage {...language.getText('entitle.SMS')} />
                    </b>{' '}
                  </span>
                  <FormattedMessage {...language.getText('entitle.OR')} />
                  <span style={{ color: '#00bcd4' }}>
                    {' '}
                    <b>
                      <FormattedMessage {...language.getText('entitle.RUMBA')} />
                    </b>{' '}
                  </span>
                  <FormattedMessage {...language.getText('entitle.TITLE_TEXT')} />
                </Typography>
                <Typography color="inherit" variant="subtitle2">
                  <FormattedMessage {...language.getText('entitle.SUBTITLE_TEXT')} />
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {bookModule.map((obj) => (
              <Grid container spacing={2}>
                <Grid item>
                  <Paper className={classes.paper}>
                    <Typography
                      className={classes.padinstyle}
                      style={{ color: obj.color }}
                      variant="subtitle2"
                      noWrap
                      display="inline"
                    >
                      <strong>{obj.title}</strong>
                    </Typography>
                    <Typography noWrap display="inline" variant="caption">
                      {obj.description}
                    </Typography>
                    <Divider className={classes.mtop} />
                    <TableContainer component={Paper}>
                      <Table>
                        {this.createHeader(obj.headers, classes)}
                        {this.createRows(obj.rows, classes)}
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            ))}
            <br />
            <Grid item container direction="column" xs={12} alignItems="center">
              <Grid item>
                <div className={classes.buttonspace}>
                  <TooltipCustom
                    title={
                      !permissionsList.includes('authoring_can_edit' || 'authoring_can_publish') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('entitle.SAVE_ENTITLEMENT_SETTINGS')} />
                      )
                    }
                  >
                    <span>
                      <Button
                        color="primary"
                        disabled={!permissionsList.includes('authoring_can_edit' || 'authoring_can_publish')}
                        onClick={() => this.saveAndPublish(false)}
                        size="small"
                        variant="outlined"
                      >
                        <FormattedMessage {...language.getText('common.SAVE')} />
                      </Button>
                    </span>
                  </TooltipCustom>
                  <TooltipCustom
                    title={<FormattedMessage {...language.getText('entitle.CANCEL_EDIT_ENTITLEMENT_SETTINGS')} />}
                  >
                    <Button
                      color="primary"
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        Framework.redirectTo(`/books/${match.params.id}`);
                      }}
                    >
                      <FormattedMessage {...language.getText('common.CANCEL')} />
                    </Button>
                  </TooltipCustom>
                </div>
              </Grid>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  <FormattedMessage {...language.getText('entitle.DESCRIPTION')} />
                </Typography>
                <Box mb={0.6}>
                  <Typography noWrap>
                    {entitlementState.publishedToViewer ? (
                      <span className={classes.textspancolor}>
                        {' '}
                        <FormattedMessage {...language.getText('entitle.BOOK_STATUS')} /> :{' '}
                        {entitlementState.publishedToViewer}{' '}
                      </span>
                    ) : null}
                    <span className={classes.textcolor} />
                  </Typography>
                </Box>
                <Box mb={0.6}>
                  <Typography noWrap>
                    {entitlementState.datePublishedToViewer ? (
                      <span className={classes.textspancolor}>
                        {' '}
                        <FormattedMessage {...language.getText('entitle.PUBLISH_DATE')} /> :{' '}
                        {entitlementState.datePublishedToViewer}{' '}
                      </span>
                    ) : null}
                    <span className={classes.textcolor} />
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <div className={classes.buttonspace}>
                  <TooltipCustom
                    title={
                      !permissionsList.includes('authoring_can_publish') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('entitle.PUBLISH_TITLE_ETEXT')} />
                      )
                    }
                  >
                    <span>
                      <Button
                        color="primary"
                        disabled={!permissionsList.includes('authoring_can_publish')}
                        onClick={() => this.saveAndPublish(true)}
                        size="small"
                        variant="outlined"
                      >
                        <FormattedMessage {...language.getText('entitle.PUBLISH_ETEXT')} />
                      </Button>
                    </span>
                  </TooltipCustom>

                  {/* <Button color="primary" variant="outlined" size="small">
                  <FormattedMessage {...language.getText('entitle.UNPUBLISH_ETEXT')} /></Button>
                  <Button color="primary" variant="outlined" size="small">
                  <FormattedMessage {...language.getText('entitle.PUBILSH_DIGITAL_LIBRARY')} /></Button> */}
                </div>
              </Grid>
            </Grid>
            <br />
            <br />
          </div>
        )}
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(EntitlementHome)));
