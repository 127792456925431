/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import React from 'react';
// import { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ErrorBanner from './ErrorBanner';

const ErrorStatus = ({ valid, message }) => (valid ? <ErrorBanner message={message} /> : null);

ErrorStatus.propTypes = {
  valid: PropTypes.bool,
  message: PropTypes.string
};

ErrorStatus.defaultProps = {
  valid: false,
  message: ''
};

export default inject()(observer(ErrorStatus));
