/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import moment from 'moment';
import React from 'react';
import { withStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { TextField, Box, Typography, Container, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Pagination from '@material-ui/lab/Pagination';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as constants from '../../../../common/constants';
import utils from '../../../../common/utils';
import Loader from '../../../../common/Loader';
import NoDataFound from '../../../../common/components/NoDataFound';
import Request from '../../models/Request';
import ExportRequest from '../../models/ExportRequest';
import MetaData from '../../models/MetaData';
import NewRequest from '../../models/NewRequest';
import NewRevision from '../../models/NewRevision';
import EtextResultTable from './EtextResultTable';
import EtextConversion from './EtextConversion';
import EtextRevision from './EtextRevision';
import env from '../../../../common/env';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  datePicker: {
    width: '170px',
    marginTop: 'inherit'
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  formControl: {
    minWidth: 208
  },
  historyIcon: {
    cursor: 'pointer'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px'
  },
  tablePos: {
    position: 'relative'
  },
  posClass: {
    position: 'absolute',
    bottom: '2%',
    right: '2%'
  }
});

@inject(
  'request',
  'exportRequest',
  'metaData',
  'requestQueueSearchStatus',
  'requestData',
  'revisionData',
  'newRevisionGetStatus',
  'newRequestGetStatus',
  'newRequestUpdateStatus',
  'notificationState'
)
@observer
// eslint-disable-next-line react/prefer-stateless-function
class RequestQueue extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    request: shapes.modelOf(Request).isRequired,
    exportRequest: shapes.modelOf(ExportRequest).isRequired,
    metaData: shapes.modelOf(MetaData).isRequired,
    requestData: shapes.modelOf(NewRequest).isRequired,
    revisionData: shapes.modelOf(NewRevision).isRequired,
    language: shapes.language.isRequired,
    requestQueueSearchStatus: shapes.state.isRequired,
    newRevisionGetStatus: shapes.state.isRequired,
    newRequestGetStatus: shapes.state.isRequired,
    newRequestUpdateStatus: shapes.state.isRequired,
    notificationState: shapes.state.isRequired
  };

  constructor(props) {
    super(props);

    this.defaultSearch = {
      requestNo: '',
      ebookISBN10: '',
      ebookISBN13: '',
      title: '',
      author: '',
      status: '',
      globalBookId: '',
      bookId: '',
      dateSubmittedTo: null,
      totalCount: '',
      pageNumber: 1
    };
    this.state = {
      open: false,
      openRevision: false,
      etextConvForm: false,
      etextRevForm: false,
      expanded: 'panel1',
      deletePage: 1,
      searchedFields: {},
      requestQueueSearchCall: {
        dateSubmittedTo: null
      },

      formFields: {
        some: {
          permissionChange: true,
          updatedHotspotLinks: true,
          updatedGlossaryContent: false,
          customBasketChange: false,
          nonModalAudioChange: false,
          pageAddOrSub: false,
          contentChange: false,
          updateTitleEditionAuthor: false,
          tocChange: true,
          coverImageChange: false,
          iPadViewChange: true,
          dlRequireChange: false,
          revProdTypeDemoPartial: false,
          otherRevChange: false,
          otherRevisionTypeText: null,
          revDescription: null,
          insideFaceCover: 'N',
          insideBackCover: 'N',
          colorInserts: 'N',
          bonusChapters: 'N',
          tearCard: 'N',
          endPapers: 'N',
          othersCheck: 'N',
          others: null,
          suppSectionDesc: ''
        },

        etextGeneral: {
          revisionID: '',
          requestID: '',
          etextISBN10: '',
          etextISBN13: '',
          parentISBN10: '',
          parentISBN13: '',
          bookID: '',
          globalBookID: '',
          chargeISBN: '',
          title: '',
          author: '',
          copyright: '',
          name: '',
          email: '',
          addlEmail: ''
        }
      }
    };
    this.handleeTextData = this.handleeTextData.bind(this);
    this.handleeTextRevision = this.handleeTextRevision.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleRevisionClose = this.handleRevisionClose.bind(this);
    this.handleChangeRevision = this.handleChangeRevision.bind(this);
    this.handleChangeRequest = this.handleChangeRequest.bind(this);
  }

  updateQueueData = () => {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  };

  componentDidMount = () => {
    const { request, metaData, handleTabChange } = this.props;
    const payload = {};
    handleTabChange(null, 0);
    if (metaData.statusValues.length === 0) {
      metaData.fetchStatusDropdownValues();
    }
    if (metaData.divisions.length === 0) {
      metaData.fetchDivisionsDropdownValues();
    }
    if (metaData.disciplineCode.length === 0) {
      metaData.fetchDisciplineCodeDropdownValues();
    }
    if (metaData.international.length === 0) {
      metaData.setInternational();
    }
    if (metaData.languages.length === 0) {
      metaData.fetchLanguages();
    }
    if (metaData.vendors.length === 0) {
      metaData.fetchVendor();
    }
    if (metaData.managedby.length === 0) {
      metaData.fetchMangeddby();
    }
    metaData.setEtextRevision();
    request.fetchRequestQueueSearch(payload);
  };

  handleeTextData = (data) => {
    const { open } = this.state;
    const { requestData } = this.props;
    this.setState({
      open: !open
    });
    requestData.fetchRequest(data);
    requestData.setRequestStatus();
  };

  handleeTextRevision = (data) => {
    const { openRevision } = this.state;
    const { revisionData } = this.props;
    this.setState({
      openRevision: !openRevision
    });
    revisionData.fetchRevision(data);
    revisionData.setRevisionStatus();
  };

  handleClose = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      etextConvForm: false,
      etextRevForm: false
    });
  };

  handleRevisionClose = () => {
    const { openRevision } = this.state;
    this.setState({
      openRevision: !openRevision
    });
  };

  handlePanelChange = (panel) => {
    this.setState({
      expanded: panel
    });
  };

  handleCheckboxRevision = (e, etext) => {
    const { revisionData } = this.props;
    const { dataRevision } = revisionData;
    const { etextRevisionDetails, etextSpecialSectionInfo } = dataRevision;

    if (etext === 'revisionCheck') {
      let updatedObject = {};
      updatedObject = {
        [e.target.name]: e.target.checked ? e.target.checked : e.target.checked
      };

      const updatedEtextGeneral = { etextRevisionDetails: { ...etextRevisionDetails, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRevision,
        ...updatedEtextGeneral
      };

      const modifiedData = {
        dataRevision: updatedDataRequest
      };
      const {
        revisionData: { setAlteredRevision }
      } = this.props;
      setAlteredRevision(modifiedData.dataRevision);
    }

    if (etext === 'specialValue') {
      let updatedObject = {};
      updatedObject = {
        [e.target.name]: e.target.checked ? 'Y' : 'N'
      };

      const updatedEtextGeneral = { etextSpecialSectionInfo: { ...etextSpecialSectionInfo, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRevision,
        ...updatedEtextGeneral
      };

      const modifiedData = {
        dataRevision: updatedDataRequest
      };
      const {
        revisionData: { setAlteredRevision }
      } = this.props;
      setAlteredRevision(modifiedData.dataRevision);
    }
  };

  handleCheckboxSection = (e) => {
    const { requestData } = this.props;
    const { dataRequest } = requestData;
    const { etextInfo } = dataRequest;

    let updatedObject = {};
    updatedObject = {
      [e.target.name]: e.target.checked ? 'Y' : 'N'
    };
    const updatedEtextGeneral = { etextInfo: { ...etextInfo, ...updatedObject } };
    const updatedDataRequest = {
      ...dataRequest,
      ...updatedEtextGeneral
    };
    const modifiedData = {
      dataRequest: updatedDataRequest
    };
    const {
      requestData: { setAlteredRequest }
    } = this.props;
    setAlteredRequest(modifiedData.dataRequest);
  };

  handleSave = () => {
    const { revisionData } = this.props;
    const { openRevision, deletePage } = this.state;
    const payload = {
      data: { ...revisionData }
    };
    const {
      revisionData: { saveUpdate }
    } = this.props;
    if (revisionData.dataRevision.etextGeneral.email.length > 0) {
      saveUpdate(payload);
    } else {
      this.setState({
        etextRevForm: true
      });
      document.querySelectorAll(`[name='email']`)[0].scrollIntoView({ block: 'center' });
    }
    Framework.getEventManager().on(constants.ETEXT_DB_UPDATE_REVISION, () => {
      this.handleChangePage(null, deletePage);
      this.setState({
        openRevision: !openRevision
      });
    });
  };

  handleReqSave = () => {
    const { requestData } = this.props;
    const { open, deletePage } = this.state;
    const payload = {
      data: { ...requestData }
    };
    const {
      requestData: { saveUpdate }
    } = this.props;
    if (requestData.dataRequest.etextGeneral.email.length > 0) {
      saveUpdate(payload);
    } else {
      this.setState({
        etextConvForm: true
      });
      document.querySelectorAll(`[name='email']`)[0].scrollIntoView({ block: 'center' });
    }

    Framework.getEventManager().on(constants.ETEXT_DB_POST_UPDATE, () => {
      this.handleChangePage(null, deletePage);
      this.setState({
        open: !open
      });
    });
  };

  handleDelete = (e, data) => {
    const { requestData } = this.props;
    const { open, deletePage } = this.state;
    requestData.deleteRequest(data);

    Framework.getEventManager().on(constants.ETEXT_DB_POST_DELETE, () => {
      this.handleChangePage(null, deletePage);
      this.setState({
        open: !open
      });
    });
  };

  handleRevDelete = (dataRev) => {
    const { revisionData } = this.props;
    const { openRevision, deletePage } = this.state;
    revisionData.deleteRevision(dataRev);

    Framework.getEventManager().on(constants.ETEXT_DB_POST_DELETE, () => {
      this.handleChangePage(null, deletePage);
      this.setState({
        openRevision: !openRevision
      });
    });
  };

  handleDateChange = (date, name) => {
    const { requestQueueSearchCall } = this.state;
    const fieldChanged = {};
    fieldChanged[name] = moment(date).format(constants.MM_DD_YYYY_FORMAT);
    this.setState({
      requestQueueSearchCall: { ...requestQueueSearchCall, ...fieldChanged }
    });
  };

  handleChange = (i, data, e) => {
    const { requestQueueSearchCall } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      requestQueueSearchCall: { ...requestQueueSearchCall, ...fieldChanged }
    });
  };

  handleChangeRequest = (e, etext) => {
    const { requestData } = this.props;
    const { dataRequest } = requestData;
    const { etextGeneral, etextScheduleStatus, etextInfo, etextAvailableRevision } = dataRequest;

    if (etextGeneral.email.length > 0) {
      this.setState({
        etextConvForm: false
      });
    }

    if (etext === true) {
      let updatedObject = {};
      if (e.target.name === 'bookID') {
        updatedObject = {
          [e.target.name]: parseInt(e.target.value, 10) || null
        };
      } else {
        updatedObject = {
          [e.target.name]: e.target.value
        };
      }

      const updatedEtextGeneral = { etextGeneral: { ...etextGeneral, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRequest,
        ...updatedEtextGeneral
      };
      const modifiedData = {
        dataRequest: updatedDataRequest
      };

      const {
        requestData: { setAlteredRequest }
      } = this.props;
      setAlteredRequest(modifiedData.dataRequest);
    }

    if (etext === 'status') {
      let updatedObject = {};
      if (e.target.name === 'holdDays' || e.target.name === 'reviewCount') {
        updatedObject = {
          [e.target.name]: parseInt(e.target.value, 10) || 0
        };
      } else {
        updatedObject = {
          [e.target.name]: e.target.value
        };
      }
      const updatedEtextGeneral = { etextScheduleStatus: { ...etextScheduleStatus, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRequest,
        ...updatedEtextGeneral
      };
      const modifiedData = {
        dataRequest: updatedDataRequest
      };

      const {
        requestData: { setAlteredRequest }
      } = this.props;
      setAlteredRequest(modifiedData.dataRequest);
    }

    if (etext === 'info') {
      let updatedObject = {};
      if (e.target.name === 'pageCount') {
        updatedObject = {
          [e.target.name]: parseInt(e.target.value, 10) || 0
        };
      } else {
        updatedObject = {
          [e.target.name]: e.target.value
        };
      }
      const updatedEtextGeneral = { etextInfo: { ...etextInfo, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRequest,
        ...updatedEtextGeneral
      };
      const modifiedData = {
        dataRequest: updatedDataRequest
      };

      const {
        requestData: { setAlteredRequest }
      } = this.props;
      setAlteredRequest(modifiedData.dataRequest);
    }

    if (etext === 'revision') {
      let updatedObject = {};
      updatedObject = {
        [e.target.name]: e.target.value
      };
      const updatedEtextGeneral = { etextAvailableRevision: { ...etextAvailableRevision, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRequest,
        ...updatedEtextGeneral
      };
      const modifiedData = {
        dataRequest: updatedDataRequest
      };

      const {
        requestData: { setAlteredRequest }
      } = this.props;
      setAlteredRequest(modifiedData.dataRequest);
    }
  };

  handleChangeRevision = (e, etext) => {
    const { revisionData } = this.props;
    const { dataRevision } = revisionData;
    const { etextGeneral, etextSpecialSectionInfo, etextScheduleStatus, etextRevisionDetails } = dataRevision;

    if (etextGeneral.email.length > 0) {
      this.setState({
        etextRevForm: false
      });
    }

    if (etext === true) {
      let updatedObject = {};
      if (e.target.name === 'bookID') {
        updatedObject = {
          [e.target.name]: parseInt(e.target.value, 10) || 0
        };
      } else {
        updatedObject = {
          [e.target.name]: e.target.value
        };
      }
      const updatedEtextGeneral = { etextGeneral: { ...etextGeneral, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRevision,
        ...updatedEtextGeneral
      };
      const modifiedData = {
        dataRevision: updatedDataRequest
      };

      const {
        revisionData: { setAlteredRevision }
      } = this.props;
      setAlteredRevision(modifiedData.dataRevision);
    }

    if (etext === 'status') {
      let updatedObject = {};
      if (e.target.name === 'reviewCount' || e.target.name === 'holdDays') {
        updatedObject = {
          [e.target.name]: parseInt(e.target.value, 10) || null
        };
      } else {
        updatedObject = {
          [e.target.name]: e.target.value
        };
      }

      const updatedEtextGeneral = { etextScheduleStatus: { ...etextScheduleStatus, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRevision,
        ...updatedEtextGeneral
      };
      const modifiedData = {
        dataRevision: updatedDataRequest
      };

      const {
        revisionData: { setAlteredRevision }
      } = this.props;

      setAlteredRevision(modifiedData.dataRevision);
    }

    if (etext === 'revision') {
      let updatedObject = {};
      updatedObject = {
        [e.target.name]: e.target.value
      };
      const updatedEtextGeneral = { etextRevisionDetails: { ...etextRevisionDetails, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRevision,
        ...updatedEtextGeneral
      };
      const modifiedData = {
        dataRevision: updatedDataRequest
      };

      const {
        revisionData: { setAlteredRevision }
      } = this.props;
      setAlteredRevision(modifiedData.dataRevision);
    }

    if (etext === 'special') {
      let updatedObject = {};
      updatedObject = {
        [e.target.name]: e.target.value
      };
      const updatedEtextGeneral = { etextSpecialSectionInfo: { ...etextSpecialSectionInfo, ...updatedObject } };

      const updatedDataRequest = {
        ...dataRevision,
        ...updatedEtextGeneral
      };
      const modifiedData = {
        dataRevision: updatedDataRequest
      };

      const {
        revisionData: { setAlteredRevision }
      } = this.props;
      setAlteredRevision(modifiedData.dataRevision);
    }
  };

  handleChangePage = (e, pageNumber) => {
    const { searchedFields } = this.state;
    const { request } = this.props;
    searchedFields.pageNumber = Number(pageNumber);
    if (request.requestQueueSearch.totalCount) {
      searchedFields.totalCount = request.requestQueueSearch.totalCount;
    }
    if (!searchedFields.dateSubmittedTo) {
      searchedFields.dateSubmittedTo = null;
    }
    const payload = searchedFields;
    request.fetchRequestQueueSearch(payload);
    this.setState({
      requestQueueSearchCall: payload,
      deletePage: pageNumber
    });
  };

  handleSearch = () => {
    const { requestQueueSearchCall, searchedFields } = this.state;
    const payload = requestQueueSearchCall;
    let apiPayload = _.cloneDeep(payload);
    apiPayload = _.omit(apiPayload, ['pageNumber', 'totalCount']);

    if (!payload.dateSubmittedTo) {
      payload.dateSubmittedTo = null;
    }
    const { request } = this.props;
    request.fetchRequestQueueSearch(apiPayload);
    this.setState({
      searchedFields: { ...searchedFields, ...payload }
    });
  };

  handleExport = async () => {
    const { notificationState } = this.props;
    const { requestQueueSearchCall } = this.state;

    const isnull = Object.values(requestQueueSearchCall).every((value) => {
      if (value === null) {
        return true;
      }
      return false;
    });

    if (isnull) {
      const notification = {
        open: true,
        message: `Please select any of the above fields !`,
        type: 'error'
      };
      notificationState.set(notification);
    } else {
      const response = await axios({
        method: 'post',
        url: `${env.EVERGREEN_API_BASE_URL}${constants.CM_POST_EXPORT_URL}`,
        responseType: 'blob',
        data: requestQueueSearchCall
      });

      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();

      today = `${dd}/${mm}/${yyyy}`;
      const outputFilename = `eText_Project_Status_Report ${today}.xlsx`;

      const url = URL.createObjectURL(
        // eslint-disable-next-line no-undef
        new Blob([response.data], {
          type: response.headers['content-type']
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    }
  };

  handleReset = () => {
    const { requestQueueSearchCall, searchedFields } = this.state;
    delete requestQueueSearchCall.dateSubmittedFrom;
    delete requestQueueSearchCall.dateSubmittedTo;
    delete searchedFields.dateSubmittedFrom;
    delete searchedFields.dateSubmittedTo;
    this.setState({
      requestQueueSearchCall: this.defaultSearch,
      searchedFields: this.defaultSearch
    });
  };

  render() {
    const {
      language,
      request,
      metaData,
      requestQueueSearchStatus,
      newRevisionGetStatus,
      newRequestGetStatus,
      newRequestUpdateStatus,
      classes,
      requestData,
      revisionData
    } = this.props;
    const { open, openRevision, expanded, requestQueueSearchCall, formFields, etextConvForm, etextRevForm } =
      this.state;

    const pageCount =
      (request.requestQueueSearch.totalCount / 8).toFixed() > 0
        ? (request.requestQueueSearch.totalCount / 8).toFixed()
        : 1;

    let disciplineCode = [];
    if (metaData.disciplineCode.length > 0) {
      disciplineCode = utils.disciplineCodeStructure(metaData.disciplineCode);
    }

    return (
      <LoadingHandler
        loading={requestQueueSearchStatus.isPending || newRequestGetStatus.isPending || newRevisionGetStatus.isPending}
        isError={
          (requestQueueSearchStatus.isError &&
            requestQueueSearchStatus.error &&
            requestQueueSearchStatus.error.status === 401) ||
          (newRequestGetStatus.isError && newRequestGetStatus.error && newRequestGetStatus.error.status === 401) ||
          (newRevisionGetStatus.isError && newRevisionGetStatus.error && newRevisionGetStatus.error.status)
        }
        errorContent={<StateErrorDisplay error={requestQueueSearchStatus.error} showDetails />}
        content={
          <>
            <Container disableGutters>
              <br />
              <Typography align="center" variant="h2">
                <FormattedMessage {...language.getText('eTextconversion.TEXT')} />
              </Typography>
              <Paper elevation={3}>
                <Box my={2} p={2} pb={3}>
                  <Grid container direction="row" spacing={3}>
                    <Grid container item xs={4} direction="column">
                      <TextField
                        label="Request No"
                        margin="dense"
                        name="requestNo"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={requestQueueSearchCall.requestNo}
                        variant="outlined"
                      />
                      <TextField
                        label="ISBN-10"
                        margin="dense"
                        name="ebookISBN10"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={requestQueueSearchCall.ebookISBN10}
                        variant="outlined"
                      />
                      <TextField
                        label="ISBN-13"
                        margin="dense"
                        name="ebookISBN13"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={requestQueueSearchCall.ebookISBN13}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid container item xs={4} direction="column">
                      <TextField
                        label="Title"
                        margin="dense"
                        name="title"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={requestQueueSearchCall.title}
                        variant="outlined"
                      />
                      <TextField
                        label="Author"
                        margin="dense"
                        name="author"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={requestQueueSearchCall.author}
                        variant="outlined"
                      />
                      <FormControl className={classes.formControl}>
                        <InputLabel>
                          <FormattedMessage {...language.getText('label.STATUS')} />
                        </InputLabel>
                        <Select
                          name="status"
                          onChange={($event) => this.handleChange(null, null, $event)}
                          value={requestQueueSearchCall.status}
                        >
                          {metaData.statusValues &&
                            metaData.statusValues.length > 0 &&
                            metaData.statusValues.map((filter) => (
                              <MenuItem value={filter.statusText}>{filter.statusText}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container item xs={4} direction="column">
                      <TextField
                        label={<FormattedMessage {...language.getText('contentmanager.GLOBAL_BOOK_ID')} />}
                        margin="dense"
                        name="globalBookId"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={requestQueueSearchCall.globalBookId}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('contentmanager.BOOK_ID')} />}
                        margin="dense"
                        name="bookId"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={requestQueueSearchCall.bookId}
                        variant="outlined"
                      />
                      <Box display="flex" justifyContent="space-between">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <KeyboardDatePicker
                              className={classes.datePicker}
                              margin="normal"
                              label={<FormattedMessage {...language.getText('label.DATE_FROM')} />}
                              format={constants.MM_DD_YYYY_FORMAT}
                              autoOk
                              variant="inline"
                              disableFuture
                              value={
                                requestQueueSearchCall.dateSubmittedFrom
                                  ? requestQueueSearchCall.dateSubmittedFrom
                                  : null
                              }
                              onChange={(date) => this.handleDateChange(date, 'dateSubmittedFrom')}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                            />

                            <KeyboardDatePicker
                              className={classes.datePicker}
                              margin="normal"
                              label={<FormattedMessage {...language.getText('label.DATE_TO')} />}
                              format={constants.MM_DD_YYYY_FORMAT}
                              autoOk
                              variant="inline"
                              disableFuture
                              value={requestQueueSearchCall.dateSubmittedTo}
                              onChange={(date) => this.handleDateChange(date, 'dateSubmittedTo')}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Grid>

                    <Grid container justify="space-evenly">
                      <Button variant="outlined" size="small" color="primary" onClick={this.handleSearch}>
                        <FormattedMessage {...language.getText('common.SEARCH')} />
                      </Button>
                      <Button variant="outlined" size="small" color="primary" onClick={this.handleReset}>
                        <FormattedMessage {...language.getText('common.RESET')} />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              {requestQueueSearchStatus.isError || request.requestQueueSearch.results.length === 0 ? (
                <NoDataFound isError={requestQueueSearchStatus.isError} />
              ) : (
                <Paper elevation={2} variant="outlined" className={classes.tablePos}>
                  <Box m={1}>
                    <EtextResultTable
                      data={request.requestQueueSearch.results}
                      handleeTextData={this.handleeTextData}
                      handleeTextRevision={this.handleeTextRevision}
                    />
                    <Pagination
                      className={classes.pagination}
                      component="div"
                      color="primary"
                      count={pageCount}
                      defaultPage={requestQueueSearchCall.pageNumber}
                      onChange={(e, page) => this.handleChangePage(e, page)}
                      showFirstButton
                      showLastButton
                    />
                  </Box>
                  <Button
                    className={classes.posClass}
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={this.handleExport}
                  >
                    <FormattedMessage {...language.getText('contentmanager.TITLE_EXPORT_EXCEL')} />
                  </Button>
                </Paper>
              )}
              <Paper elevation={3}>
                {open ? (
                  <Box m={1}>
                    <EtextConversion
                      open={open}
                      newRequestGetStatus={newRequestGetStatus}
                      newRequestUpdateStatus={newRequestUpdateStatus}
                      close={() => this.handleClose}
                      expanded={expanded}
                      divisions={metaData.divisions}
                      statusRequest={metaData.statusValues}
                      disciplineCode={disciplineCode}
                      handlePanelChange={(panel) => this.handlePanelChange(panel)}
                      languageInfo={metaData.languages}
                      vendorInfo={metaData.vendors}
                      manageInfo={metaData.managedby}
                      requestNew={requestData.dataRequest}
                      etextConvForm={etextConvForm}
                      handleReqSave={() => this.handleReqSave()}
                      requestData={requestData.dataRequest}
                      handleDelete={(e, data) => this.handleDelete(e, data)}
                      handleCheckboxSection={(e) => this.handleCheckboxSection(e)}
                      handleChangeRequest={($event, etext) => this.handleChangeRequest($event, etext)}
                    />
                  </Box>
                ) : null}
              </Paper>
              <Paper>
                <EtextRevision
                  openRevision={openRevision}
                  close={() => this.handleRevisionClose}
                  expanded={expanded}
                  etextRevForm={etextRevForm}
                  divisions={metaData.divisions}
                  international={metaData.international}
                  formFields={formFields}
                  status={metaData.statusValues}
                  handleCheckboxRevision={(e, etext) => this.handleCheckboxRevision(e, etext)}
                  vendorInfo={metaData.vendors}
                  manageInfo={metaData.managedby}
                  revisionData={revisionData.dataRevision}
                  handlePanelChange={(panel) => this.handlePanelChange(panel)}
                  handleCheckbox={(e) => this.handleCheckbox(e)}
                  handleSave={() => this.handleSave()}
                  handleChangeRevision={(e, etext) => this.handleChangeRevision(e, etext)}
                  handleRevDelete={(data) => this.handleRevDelete(data)}
                />
              </Paper>
            </Container>
          </>
        }
        loadingContent={<Loader />}
      />
    );
  }
}

export default withStyles(styles)(observer(inject('language')(RequestQueue)));
