/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ClickAwayListener, withStyles } from '@material-ui/core';
import { shapes } from '@greenville/framework';
import { ProfileDropdown } from '@greenville/mui-theme';
import UserProfile from './UserProfile';
import utils from '../utils';
import * as events from '../events';

const styles = () => ({
  userProfileContainer: {
    border: '2px solid transparent',
    borderRadius: 8
  }
});
const Profile = ({ id, language, user, classes }) => {
  const [profileOpen, setProfileOpen] = useState(false);
  const logOut = () => {
    utils.logEvent({
      event: events.USER_CARD,
      category: events.USER_CARD,
      action: events.USER_SIGNOUT,
      label: events.USER_SIGNOUT
    });
    // Framework.logout();
    utils.redirectToLogin();
  };

  return (
    <ClickAwayListener onClickAway={() => setProfileOpen(false)}>
      <div id={`${id}-profile`} className="profileContainer">
        <ProfileDropdown
          id={`${id}-profile-dropdown`}
          aria-label={user.fullName}
          text={user.initials}
          open={profileOpen}
          onChange={(newState) => setProfileOpen(newState)}
        >
          <UserProfile
            id={`${id}-user-profile`}
            language={language}
            user={user}
            onLogoutRequest={() => logOut()}
            className={classes.userProfileContainer}
          />
        </ProfileDropdown>
      </div>
    </ClickAwayListener>
  );
};

Profile.defaultProps = {
  id: 'topstrap'
};

Profile.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  user: shapes.user.isRequired
};

export default withStyles(styles)(inject('language', 'user')(observer(Profile)));
