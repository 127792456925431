/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';

import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { toJS } from 'mobx';
import Button from '@material-ui/core/Button';
import { Typography, Dialog, Select, MenuItem, DialogContent, TextField, Box, IconButton } from '@material-ui/core';
import { LoadingHandler, shapes, StateErrorDisplay } from '@greenville/framework';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import EtextInfo from '../common/EtextInfo';
import eTextConfig from '../../../../common/config/eTextConfig';
import UsersPermissions from '../../../users/models/UsersPermissions';

// import StandardEbook from '../../../contentManager/components/create/StandardEbook';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },

  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '90vh',
    maxWidth: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  labelWidht: {
    width: '100%'
  },
  bckground: {
    backgroundColor: '#d7d7d78f'
  },
  boxWidth: {
    width: '27%'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

const EtextConversion = ({
  language,
  classes,
  open,
  close,
  handlePanelChange,
  expanded,
  divisions,
  disciplineCode,
  languageInfo,
  vendorInfo,
  manageInfo,
  etextConvForm,
  statusRequest,
  requestNew,
  handleReqSave,
  handleChangeRequest,
  handleCheckboxSection,
  requestData,
  newRequestGetStatus,
  handleDelete,
  userPermissions
  // uploadProps
}) => {
  /* const [tootTipTitle, setToolTipTitle] = React.useState('yuuu'); */
  return (
    <LoadingHandler
      loading={newRequestGetStatus.isPending}
      isError={newRequestGetStatus.isError}
      errorContent={<StateErrorDisplay error={newRequestGetStatus.error} showDetails />}
      content={
        <Dialog
          open={open}
          onClose={close()}
          fullWidth="true"
          fullScreen
          direction="column"
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={close()}
            align="center"
            toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
          >
            {' '}
            <Typography variant="h3">
              <FormattedMessage {...language.getText('eTextrequest.CONVERSION')} />
            </Typography>
          </DialogTitle>
          <br />

          <DialogContent>
            <div className={classes.root}>
              {/* DO NOT DELETE
            <Accordion expanded={expanded === 'panel5'} onChange={() => handlePanelChange('panel5')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
                aria-controls="panel5a-content"
                id="panel5a-header"
                className={classes.bckground}
              >
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('contentmanager.UPLOAD_CONTENT_IF_NOT_UPLOADED')} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <StandardEbook {...uploadProps} isCMScreen={false} />
              </AccordionDetails>
            </Accordion> */}
              <Accordion
                style={{ minHeight: '0px' }}
                expanded={expanded === 'panel1'}
                onChange={() => handlePanelChange('panel1')}
              >
                <AccordionSummary
                  className={classes.bckground}
                  expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
                >
                  <Typography variant="h5" className={classes.heading} align="center">
                    <FormattedMessage {...language.getText('eTextrequest.GENERALINFO')} />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row" justify="space-between">
                    <Grid container item xs={12} justify="space-between">
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.REQUEST')} />}
                        margin="dense"
                        name="requestID"
                        inputProps={{ readOnly: true }}
                        value={requestData.etextGeneral.requestID}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.ISBN')} />}
                        required
                        margin="dense"
                        name="etextISBN10"
                        value={requestData.etextGeneral.etextISBN10}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.ISBNS')} />}
                        margin="dense"
                        name="etextISBN13"
                        value={requestData.etextGeneral.etextISBN13}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.PARENTISBN')} />}
                        margin="dense"
                        name="parentISBN10"
                        value={requestData.etextGeneral.parentISBN10}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.PARENTISBNS')} />}
                        margin="dense"
                        name="parentISBN13"
                        value={requestData.etextGeneral.parentISBN13}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.BOOKID')} />}
                        margin="dense"
                        type="number"
                        name="bookID"
                        value={requestData.etextGeneral.bookID}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.GLOABALBOOKID')} />}
                        margin="dense"
                        name="globalBookID"
                        value={requestData.etextGeneral.globalBookID}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.CHARGEISBN')} />}
                        margin="dense"
                        name="chargeISBN"
                        value={requestData.etextGeneral.chargeISBN}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.TITLE')} />}
                        margin="dense"
                        name="title"
                        value={requestData.etextGeneral.title}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.AUTHOR')} />}
                        margin="dense"
                        name="author"
                        value={requestData.etextGeneral.author}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.COPYRIGHT')} />}
                        margin="dense"
                        type="number"
                        name="copyright"
                        value={requestData.etextGeneral.copyright}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.NAME')} />}
                        margin="dense"
                        name="name"
                        value={requestData.etextGeneral.name}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        required
                        error={etextConvForm}
                        label={<FormattedMessage {...language.getText('eTextconversion.EMAIL')} />}
                        margin="dense"
                        name="email"
                        value={requestData.etextGeneral.email}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('eTextconversion.ADDITIONALEMAIL')} />}
                        margin="dense"
                        name="addlEmail"
                        value={requestData.etextGeneral.addlEmail}
                        onChange={($event) => handleChangeRequest($event, true)}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid container direction="row" item xs={12} style={{ marginTop: '3%' }} justify="space-between">
                      <Box mb={0.5} className={classes.boxWidth}>
                        <Typography variant="subtitle2">
                          <FormattedMessage {...language.getText('eTextgeneral.DIVISION')} />
                        </Typography>
                        <Select
                          className={classes.labelWidht}
                          name="divisionID"
                          onChange={($event) => handleChangeRequest($event, true)}
                          defaultValue={requestData.etextGeneral.divisionID}
                        >
                          {divisions.length > 0 &&
                            divisions.map((filter) => {
                              return <MenuItem value={filter.divisionId}>{filter.divisionText}</MenuItem>;
                            })}
                        </Select>
                      </Box>

                      <Box mb={0.5} className={classes.boxWidth}>
                        <Typography variant="subtitle1">
                          {' '}
                          <FormattedMessage {...language.getText('eTextgeneral.LANGUAGE')} />
                        </Typography>
                        <Select
                          className={classes.labelWidht}
                          name="uilanguage"
                          defaultValue={requestData.etextGeneral.uilanguage}
                          onChange={($event) => handleChangeRequest($event, true)}
                        >
                          {languageInfo.length > 0 &&
                            languageInfo.map((lang) => {
                              return <MenuItem value={lang.languageId}>{lang.languageText}</MenuItem>;
                            })}
                        </Select>
                      </Box>
                      <Box mb={0.5} className={classes.boxWidth}>
                        <Typography variant="subtitle1">
                          {' '}
                          <FormattedMessage {...language.getText('eTextgeneral.VENDOR')} />
                        </Typography>
                        <Select
                          className={classes.labelWidht}
                          name="vendor"
                          onChange={($event) => handleChangeRequest($event, true)}
                          defaultValue={requestData.etextGeneral.vendor}
                        >
                          <MenuItem disabled value="">
                            <FormattedMessage {...language.getText('common.select')} />
                          </MenuItem>
                          {vendorInfo.length > 0 &&
                            vendorInfo.map((vendor) => {
                              return <MenuItem value={vendor.vendorText}>{vendor.vendorText}</MenuItem>;
                            })}
                        </Select>
                      </Box>
                      <Box mb={0.5} className={classes.boxWidth}>
                        <Typography variant="subtitle1">
                          <FormattedMessage {...language.getText('eTextgeneral.MANAGEDBY')} />
                        </Typography>
                        <Select
                          name="managedBy"
                          className={classes.labelWidht}
                          onChange={($event) => handleChangeRequest($event, true)}
                          defaultValue={requestData.etextGeneral.managedBy}
                        >
                          <MenuItem disabled value="">
                            <FormattedMessage {...language.getText('common.select')} />
                          </MenuItem>
                          {manageInfo.length > 0 &&
                            manageInfo.map((manage) => {
                              return <MenuItem value={manage.managedByText}>{manage.managedByText}</MenuItem>;
                            })}
                          {/* {eTextConfig.manageOption.map((filter) => (
                          <MenuItem value={filter.id}>{filter.value}</MenuItem>
                        ))} */}
                        </Select>
                      </Box>

                      <Box mb={0.5} className={classes.boxWidth}>
                        <Typography variant="subtitle1">
                          <FormattedMessage {...language.getText('eTextgeneral.IPADVIEW')} />
                        </Typography>
                        <Select
                          className={classes.labelWidht}
                          name="ipadView"
                          onChange={($event) => handleChangeRequest($event, true)}
                          defaultValue={requestData.etextGeneral.ipadView}
                        >
                          <MenuItem disabled value="">
                            <FormattedMessage {...language.getText('common.select')} />
                          </MenuItem>
                          {eTextConfig.etextGeneralYesNo.map((filter) => (
                            <MenuItem value={filter.id}>{filter.value}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box mb={0.5} className={classes.boxWidth}>
                        <Typography variant="subtitle1">
                          <FormattedMessage {...language.getText('eTextgeneral.DIGITALLIBRARY')} />
                        </Typography>
                        <Select
                          className={classes.labelWidht}
                          name="digitalLibrary"
                          onChange={($event) => handleChangeRequest($event, true)}
                          defaultValue={requestData.etextGeneral.digitalLibrary}
                        >
                          <MenuItem disabled value="">
                            <FormattedMessage {...language.getText('common.select')} />
                          </MenuItem>
                          {eTextConfig.etextGeneralYesNo.map((filter) => (
                            <MenuItem value={filter.id}>{filter.value}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box mb={0.5} className={classes.boxWidth}>
                        <Typography variant="subtitle1">
                          <FormattedMessage {...language.getText('eTextgeneral.UIPAGE')} />
                        </Typography>
                        <Select
                          className={classes.labelWidht}
                          name="firstPage"
                          onChange={($event) => handleChangeRequest($event, true)}
                          defaultValue={requestData.etextGeneral.firstPage}
                        >
                          <MenuItem disabled value="">
                            <FormattedMessage {...language.getText('common.select')} />
                          </MenuItem>
                          {eTextConfig.userInterfaceOpt.map((filter) => (
                            <MenuItem value={filter.value}>{filter.value}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box mb={0.5} className={classes.boxWidth}>
                        <Typography variant="subtitle1">
                          <FormattedMessage {...language.getText('eTextgeneral.INTLREGION')} />
                        </Typography>
                        <Select
                          className={classes.labelWidht}
                          name="intlRegion"
                          defaultValue={requestData.etextGeneral.intlRegion}
                          onChange={($event) => handleChangeRequest($event, true)}
                        >
                          <MenuItem disabled value="">
                            <FormattedMessage {...language.getText('common.select')} />
                          </MenuItem>
                          {eTextConfig.sourceOption.map((filter) => (
                            <MenuItem value={filter.id}>{filter.value}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: '3%' }}>
                      <Typography align="center" variant="body2">
                        <br />
                        <FormattedMessage {...language.getText('eTextrequest.PRODUCTION')} />
                      </Typography>

                      <TextareaAutosize
                        rowsMin={4}
                        color="inherit"
                        name="productionNotes"
                        className={classes.labelWidht}
                        onChange={($event) => handleChangeRequest($event, true)}
                        aria-label="maximum height"
                        value={requestData.etextGeneral.productionNotes}
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      <Typography align="center" variant="body2" gutterBottom>
                        <br />
                        <FormattedMessage {...language.getText('eTextrequest.MOST_RECENT')} />
                      </Typography>

                      <TextField
                        multiline
                        inputProps={{ readOnly: true }}
                        input
                        fullWidth="true"
                        rows={4}
                        variant="outlined"
                        name="initialComments"
                        className={classes.labelWidht}
                        aria-label="maximum height"
                        onChange={($event) => handleChangeRequest($event, true)}
                        value={requestData.etextGeneral.initialComments}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={() => handlePanelChange('panel2')}>
                <AccordionSummary
                  className={classes.bckground}
                  expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h5" className={classes.heading}>
                    <FormattedMessage {...language.getText('eTextrequest.SCHEDULE_STATUS')} />
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container item xs={12} direction="row" justify="space-between">
                    <Box mb={0.5} className={classes.boxWidth}>
                      <Typography variant="subtitle1">
                        <FormattedMessage {...language.getText('eTextgeneral.PRODUCTION')} />
                      </Typography>
                      <Select
                        className={classes.labelWidht}
                        name="productionStatus"
                        onChange={($event) => handleChangeRequest($event, 'status')}
                        defaultValue={requestData.etextScheduleStatus.productionStatus || ''}
                      >
                        <MenuItem disabled value="">
                          <FormattedMessage {...language.getText('common.select')} />
                        </MenuItem>

                        {statusRequest.length > 0 &&
                          statusRequest.map((status) => {
                            return <MenuItem value={status.statusId}>{status.statusText}</MenuItem>;
                          })}
                      </Select>
                    </Box>

                    <TextField
                      label={<FormattedMessage {...language.getText('eTextconversion.REQFORM')} />}
                      margin="dense"
                      name="requestFormSubmitted"
                      value={requestData.etextScheduleStatus.requestFormSubmitted || ''}
                      onChange={($event) => handleChangeRequest($event, 'status')}
                      inputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                    <TextField
                      label={<FormattedMessage {...language.getText('eTextconversion.COMPLETION')} />}
                      margin="dense"
                      name="estimatedProjectCompletion"
                      value={requestData.etextScheduleStatus.estimatedProjectCompletion || ''}
                      onChange={($event) => handleChangeRequest($event, 'status')}
                      inputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                    <TextField
                      label={<FormattedMessage {...language.getText('eTextconversion.FOLDERNAME')} />}
                      margin="dense"
                      name="folderName"
                      value={requestData.etextScheduleStatus.folderName || ''}
                      onChange={($event) => handleChangeRequest($event, 'status')}
                      variant="outlined"
                    />
                    <TextField
                      label={<FormattedMessage {...language.getText('eTextconversion.REVIEWCOUNT')} />}
                      margin="dense"
                      name="reviewCount"
                      value={requestData.etextScheduleStatus.reviewCount || ''}
                      onChange={($event) => handleChangeRequest($event, 'status')}
                      variant="outlined"
                    />
                    <TextField
                      label={<FormattedMessage {...language.getText('eTextconversion.PROJECT')} />}
                      margin="dense"
                      name="projectInitiated"
                      value={requestData.etextScheduleStatus.projectInitiated || ''}
                      onChange={($event) => handleChangeRequest($event, 'status')}
                      variant="outlined"
                    />
                    <TextField
                      label={<FormattedMessage {...language.getText('eTextconversion.ADJUSTED')} />}
                      margin="dense"
                      name="adjustedProjection"
                      value={requestData.etextScheduleStatus.adjustedProjection || ''}
                      onChange={($event) => handleChangeRequest($event, 'status')}
                      variant="outlined"
                    />
                    <TextField
                      label={<FormattedMessage {...language.getText('eTextconversion.HOLDDAYS')} />}
                      margin="dense"
                      name="holdDays"
                      min={0}
                      defaultValue={0}
                      value={requestData.etextScheduleStatus.holdDays || ''}
                      onChange={($event) => handleChangeRequest($event, 'status')}
                      variant="outlined"
                    />
                    <TextField
                      label={<FormattedMessage {...language.getText('eTextconversion.OVERALLDATE')} />}
                      margin="dense"
                      name="overAllCompletionDate"
                      value={requestData.etextScheduleStatus.overAllCompletionDate || ''}
                      onChange={($event) => handleChangeRequest($event, 'status')}
                      // value={workflowFields.identifier}
                      variant="outlined"
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={() => handlePanelChange('panel3')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                  className={classes.bckground}
                >
                  <Typography variant="h5">
                    <FormattedMessage {...language.getText('eTextrequest.INFORMATION')} />{' '}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <EtextInfo
                    requestInfo={requestNew}
                    requestData={requestData}
                    eTextConfig={eTextConfig}
                    divisions={divisions}
                    disciplineCode={disciplineCode}
                    handleChangeRequest={(e, etext) => handleChangeRequest(e, etext)}
                    handleCheckboxSection={(e) => handleCheckboxSection(e)}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={() => handlePanelChange('panel4')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                  className={classes.bckground}
                >
                  <Typography variant="h5">
                    <FormattedMessage {...language.getText('eTextconversion.AVAILABLE')} />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row" justify="space-between">
                    {requestData.etextAvailableRevision[0] &&
                      requestData.etextAvailableRevision.map((row) => (
                        <Grid container item xs={12} justify="space-between">
                          <Grid item xs={2}>
                            <TextField
                              label={<FormattedMessage {...language.getText('eTextInfo.REVISIONID')} />}
                              margin="dense"
                              name="revisionID"
                              inputProps={{ readOnly: true }}
                              defaultValue={row.revisionID || ''}
                              onChange={(e) => handleChangeRequest(e, 'revision')}
                              variant="outlined"
                            />
                          </Grid>

                          <Grid item xs={9}>
                            <TextField
                              label="Typerevision"
                              fullWidth="true"
                              margin="dense"
                              inputProps={{ readOnly: true }}
                              name="typesOfRevision"
                              value={row.typesOfRevision.join(',')}
                              onChange={(e) => handleChangeRequest(e, 'info')}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
            <br />
            <br />
            <div>
              {' '}
              <Grid container direction="row" justify="center">
                <TooltipCustom
                  title={
                    !toJS(userPermissions.permissionList.permissions).includes('etext_can_edit') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('eTextconversion.UPDATE_ETEXT_CONVERSION')} />
                    )
                  }
                >
                  <span>
                    <Button
                      color="primary"
                      style={{ marginRight: '15px' }}
                      variant="outlined"
                      onClick={() => handleReqSave()}
                      disabled={!toJS(userPermissions.permissionList.permissions).includes('etext_can_edit')}
                    >
                      <FormattedMessage {...language.getText('eTextrevision.UPDATE')} />
                    </Button>
                  </span>
                </TooltipCustom>

                <TooltipCustom
                  title={
                    !toJS(userPermissions.permissionList.permissions).includes('etext_can_delete') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('eTextconversion.DELTE_ETEXT_CONVERSION')} />
                    )
                  }
                >
                  <span>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={(e) => handleDelete(e, requestData.etextGeneral.requestID)}
                      disabled={!toJS(userPermissions.permissionList.permissions).includes('etext_can_delete')}
                    >
                      <FormattedMessage {...language.getText('common.DELETE')} />
                    </Button>
                  </span>
                </TooltipCustom>
              </Grid>
            </div>
            <br />
            <br />
          </DialogContent>
        </Dialog>
      }
    />
  );
};
EtextConversion.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  open: PropTypes.bool.isRequired,
  newRequestGetStatus: PropTypes.bool.isRequired,
  // newRequestUpdateStatus: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  handlePanelChange: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  etextConvForm: PropTypes.string.isRequired,
  divisions: PropTypes.array.isRequired,
  disciplineCode: PropTypes.array.isRequired,
  languageInfo: PropTypes.array.isRequired,
  vendorInfo: PropTypes.array.isRequired,
  manageInfo: PropTypes.array.isRequired,
  statusRequest: PropTypes.array.isRequired,
  requestNew: PropTypes.array.isRequired,
  requestData: PropTypes.object.isRequired,
  handleChangeRequest: PropTypes.func.isRequired,
  handleCheckboxSection: PropTypes.func.isRequired,
  // uploadProps: PropTypes.object.isRequired,
  handleReqSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired
};

export default withStyles(styles)(inject('language', 'userPermissions')(observer(EtextConversion)));
