import Box from '@mui/material/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import ChatGptUtilityTrends from './ChatGptUtilityTrends';
// import EffectivenessComponent from './effectiveness/EffectivenessComponent';
import EffectivenessMainComponent from './effectiveness/EffectivenessMainComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const ChatGptClusterTrendingList = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const history = useHistory();
  useEffect(() => {
    if (history && history.location.pathname === '/chatgptutility/aistudytools/trends/effectiveness') {
      setValue(1);
    }
  }, []);

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="inherit" variant="fullWidth">
          <Tab label="Clustering" />
          <Tab label="Effectiveness" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ChatGptUtilityTrends />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EffectivenessMainComponent />
      </TabPanel>
    </Box>
  );
};

// ChatGptClusterTrendingList.propTypes = {
//   history: PropTypes.object.isRequired
// };

export default ChatGptClusterTrendingList;
