import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import utils from '../utils';

function SessionManagement() {
  const { instance, accounts } = useMsal();

  async function acquireTokenSilent() {
    try {
      await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
        .then((response) => {
          const cookieValue = document.cookie
            .split('; ')
            .find((cookie) => cookie.startsWith(`${'myAzureProxySession'}=`))
            .split('=')[1];
          if (cookieValue !== response.accessToken) {
            utils.setCookie();
          }
        });
    } catch (error) {
      await instance.acquireTokenRedirect({ ...loginRequest, account: accounts[0] });
    }
  }

  useEffect(() => {
    const tokenRenewalInterval = setInterval(acquireTokenSilent, 540000);

    return () => {
      clearInterval(tokenRenewalInterval);
    };
  }, [instance, accounts]);

  return null;
}

export default SessionManagement;
