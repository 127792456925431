import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const listingData = types.model('listingData', {
  clusterName: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  clusterId: types.maybeNull(types.string),
  bookId: types.maybeNull(types.string),
  userCommand: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  dateStamp: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string)
});

const Listing = types
  .model('Listing', {
    data: types.optional(types.array(listingData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHATGPT_LISTING_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.GET_CHATGPT_LISTING_MODEL_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
    }
  }));

export default Listing;
