/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField, FormHelperText, Box } from '@material-ui/core';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormattedMessage } from 'react-intl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
// import { validate } from '@material-ui/pickers';

const styles = () => ({
  width: {
    width: '100%'
  },
  labelWidht: {
    width: '100%'
  },
  radioJustify: {
    justifyContent: 'space-between'
  },
  boxWidth: {
    width: '27%'
  }
});

const EtextHotspot = ({ language, formFields, handleRadioChange, handleChange, handleCheckboxSection }) => (
  <Grid container direction="row" spacing={5}>
    <Grid container item xs={6} direction="column">
      <FormControl margin="dense">
        <FormLabel>
          <Typography variant="h5">
            <FormattedMessage {...language.getText('eTextrequest.HOTSPOT')} />
            <span> * </span>
            {formFields.hotspotRadioValueBool ? (
              <Typography variant="caption">
                <span style={{ color: '#ca4644' }} id="req_text">
                  {' '}
                  <FormattedMessage {...language.getText('eTextconvergence.ATLEAST')} />
                </span>
              </Typography>
            ) : null}
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-label="hotspotUI"
          onChange={($event) => handleRadioChange($event)}
          name="hotspotUI"
          value={formFields.hotspotUI}
        >
          <FormControlLabel
            value="Visible hotspots (default peach color)"
            control={<Radio color="primary" />}
            label="Visible hotspots (default peach color)"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Visible hotspots, other color"
            control={<Radio color="primary" />}
            label="Visible hotspots: different hotspot color than the default peach color - Hex number:"
            labelPlacement="end"
          />
          <TextField
            margin="dense"
            name="visibleHotspotHex"
            value={formFields.visibleHotspotHex}
            onChange={($event) => handleChange($event)}
            variant="outlined"
          />
          <FormHelperText variant="filled">
            <Button size="small" style={{ color: '#005d83' }}>
              what&apos;s this ?
            </Button>
          </FormHelperText>
          <FormControlLabel
            value="Transparent"
            control={<Radio color="primary" />}
            label="Transparent"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Underline hotspots"
            control={<Radio color="primary" />}
            label="Underline hotspots - check all that apply"
          />
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormGroup
          row
          aria-label="underlineHexValue"
          onChange={($event) => handleCheckboxSection($event)}
          // name="underlineHexValue"
          // value={formFields.underlineHexValue}
        >
          <FormControlLabel
            // value="underlinehotspot"
            control={
              <Checkbox
                color="primary"
                style={{ paddingTop: '1px' }}
                checked={formFields.underlineHotspot === 'Y'}
                name="underlineHotspot"
              />
            }
            label=" Underline hotspot color - enter Hex number:"
            labelPlacement="end"
          />
        </FormGroup>
        <Box display="flex">
          <TextField
            margin="dense"
            name="underlineHotspotHex"
            value={formFields.underlineHotspotHex}
            onChange={($event) => handleChange($event)}
            variant="outlined"
          />
          <FormHelperText variant="filled">
            <Button size="small" style={{ color: '#005d83' }}>
              what&apos;s this ?
            </Button>
          </FormHelperText>
        </Box>
        <FormGroup onChange={($event) => handleCheckboxSection($event)}>
          <FormControlLabel
            value="hotspothex"
            control={
              <Checkbox
                color="primary"
                style={{ paddingTop: '1px' }}
                checked={formFields.underlineHotspotHover === 'Y'}
                name="underlineHotspotHover"
              />
            }
            label="Underline hotspot hover color - enter Hex number:"
            labelPlacement="end"
          />
        </FormGroup>
        <Box display="flex">
          <TextField
            margin="dense"
            name="underlineHotspotHoverHex"
            value={formFields.underlineHotspotHoverHex}
            onChange={($event) => handleChange($event)}
            variant="outlined"
          />
          <FormHelperText variant="filled">
            <Button size="small" style={{ color: '#005d83' }}>
              what&apos;s this ?
            </Button>
          </FormHelperText>
        </Box>
      </FormControl>
    </Grid>

    <Grid container item xs={6} direction="column">
      <FormControl margin="dense">
        <FormLabel>
          <Typography variant="h5">
            <FormattedMessage {...language.getText('eTextrequest.CUSTOMICONS')} />
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-label="custIcons"
          onChange={($event) => handleRadioChange($event)}
          name="custIcons"
          value={formFields.custIcons}
        >
          <FormControlLabel
            value="Supplied"
            control={<Radio color="primary" />}
            label="Supplied"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Not necessary for this title"
            control={<Radio color="primary" />}
            label="Not necessary for this title"
            labelPlacement="end"
          />
          <FormHelperText variant="filled">
            <Button size="small" style={{ color: '#005d83' }}>
              what&apos;s this ?
            </Button>
          </FormHelperText>
        </RadioGroup>
      </FormControl>
      <FormControl margin="dense">
        <FormLabel>
          <FormattedMessage {...language.getText('eTextrequest.CUSTOMBASKETS')} />
        </FormLabel>
        <RadioGroup
          row
          aria-label="customBasketSpreadSheet"
          onChange={($event) => handleRadioChange($event)}
          name="customBasketSpreadSheet"
          value={formFields.customBasketSpreadSheet}
        >
          <FormControlLabel
            value="Supplied"
            control={<Radio color="primary" />}
            label="Supplied"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Not necessary for this title"
            control={<Radio color="primary" />}
            label="Not necessary for this title"
            labelPlacement="end"
          />
          <FormHelperText variant="filled">
            <Button size="small" style={{ color: '#005d83' }}>
              what&apos;s this ?
            </Button>
          </FormHelperText>
        </RadioGroup>
      </FormControl>
      <FormControl margin="dense">
        <FormLabel>
          <FormattedMessage {...language.getText('eTextrequest.NONMODAL')} />
        </FormLabel>
        <RadioGroup
          row
          aria-label="audio"
          onChange={($event) => handleRadioChange($event)}
          name="audio"
          value={formFields.audio}
        >
          <FormControlLabel
            value="Non-Modal Audio without Synchronized Highlighting"
            control={<Radio color="primary" />}
            label="Non-Modal Audio without Synchronized Highlighting
          (Editorial will supply CustomAudioText spreadsheet. No highlighting or underlining.)"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Non-Modal Audio with Synchronized Highlighting"
            control={<Radio color="primary" />}
            label="Non-Modal Audio with Synchronized Highlighting (Editorial will work with vendor to receive
            Audiosynch content which will be ingested into eText.)"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Not necessary for this title"
            control={<Radio color="primary" />}
            label="Not necessary for this title"
            labelPlacement="end"
          />
        </RadioGroup>
        <FormHelperText variant="filled">
          <Button size="small" style={{ color: '#005d83' }}>
            what&apos;s this ?
          </Button>
        </FormHelperText>
      </FormControl>
    </Grid>
  </Grid>
);

EtextHotspot.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  eTextConfig: PropTypes.string.isRequired,
  formFields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheckboxSection: PropTypes.func.isRequired,
  // formErrorFields: PropTypes.object.isRequired
  handleRadioChange: PropTypes.func.isRequired
  //   emailValidate: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(EtextHotspot)));
