/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import * as React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Paper,
  Grid,
  withStyles,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { shapes } from '@greenville/framework';
import Header from '../../../common/header/Header';
import QueryBuilder from '../models/QueryBuilder';
import TooltipCustom from '../../../common/components/TooltipCustom';

const styles = () => ({
  margin: {
    marginTop: 100,
    marginBottom: 10
  },
  button: {
    marginTop: -20
  },
  header: {
    marginLeft: 20
  },
  textField: {
    marginLeft: 40
  },
  container: {
    maxHeight: 440
  }
});

@inject('queryBuilder')
@observer
class QueryBuilderContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    queryBuilder: shapes.modelOf(QueryBuilder).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      query: '',
      rowsPerPage: 10,
      page: 0,
      dbName: ''
    };
  }

  componentDidMount() {
    const currrentLocation = window.location.pathname;
    const dbNameValue = currrentLocation.replace('/querytester/', '');
    this.setState({ dbName: dbNameValue });
  }

  handleClear = () => {
    const { queryBuilder } = this.props;
    this.setState({ query: '' });
    queryBuilder.resetStoreValues();
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  handleClickOpen = () => () => {
    const { query, dbName } = this.state;
    const { queryBuilder, language } = this.props;
    const selectpattern = _.toLower(_.trim(query));
    if (_.startsWith(selectpattern, 'select')) {
      if (_.includes(selectpattern, 'from')) {
        if (_.includes(selectpattern, 'where')) {
          queryBuilder.fetchDetails(query, dbName);
        } else {
          queryBuilder.setNotification({ ...language.getText('query.WHERE_CLAUSE_IS_MISSING') }.defaultMessage);
        }
      } else {
        queryBuilder.setNotification({ ...language.getText('query.FROM_KEYWORD_MISSING') }.defaultMessage);
      }
    } else {
      queryBuilder.setNotification({ ...language.getText('query.ONLY_SELECT_QUERY') }.defaultMessage);
    }
  };

  render() {
    const { classes, language, queryBuilder } = this.props;
    const { query, rowsPerPage, page } = this.state;
    const columnValue = toJS(queryBuilder.resultColumn);
    const rowValue = toJS(queryBuilder.queryList);

    return (
      <>
        <Header />
        <Paper elevation={3} className={classes.margin}>
          <Box my={2} mx={5} p={2} pb={4}>
            <Grid container direction="row" spacing={5}>
              <Grid container item xs={12} direction="column">
                <TextareaAutosize
                  placeholder={{ ...language.getText('query.ENTER_QUERY') }.defaultMessage}
                  rowsMin={6}
                  value={query}
                  name="queryText"
                  onChange={(event) => this.setState({ query: event.target.value })}
                />
                <br />
              </Grid>
              <Grid container justify="space-evenly">
                <TooltipCustom
                  title={
                    query.length > 0 ? (
                      <FormattedMessage {...language.getText('query.GET_QUERY_RESULT')} />
                    ) : (
                      <FormattedMessage {...language.getText('query.ENTER_QUERY')} />
                    )
                  }
                >
                  <span>
                    <Button variant="contained" onClick={this.handleClickOpen()} disabled={!query.length > 0}>
                      <FormattedMessage {...language.getText('common.SEND')} />
                    </Button>
                  </span>
                </TooltipCustom>
                <TooltipCustom
                  title={
                    query.length > 0 ? (
                      <FormattedMessage {...language.getText('query.RESET_QUERY')} />
                    ) : (
                      <FormattedMessage {...language.getText('query.ENTER_QUERY')} />
                    )
                  }
                >
                  <span>
                    {' '}
                    <Button variant="contained" onClick={this.handleClear} disabled={!query.length > 0}>
                      <FormattedMessage {...language.getText('common.RESET')} />
                    </Button>
                  </span>
                </TooltipCustom>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {columnValue.length > 0 && (
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 440 }} className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columnValue.map((column) => (
                      <TableCell key={column} style={{ minWidth: 170, align: 'right' }}>
                        <h3>
                          <b> {column}</b>
                        </h3>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowValue.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((obj) => (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      {Object.values(obj).map((val) => {
                        return <TableCell>{val}</TableCell>;
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rowValue.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </>
    );
  }
}

export default withStyles(styles)(inject('language', 'queryBuilder')(observer(QueryBuilderContainer)));
