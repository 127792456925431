/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { inject } from 'mobx-react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Framework, { shapes } from '@greenville/framework';

import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  posClass: {
    cursor: 'pointer'
  },
  textClass: {
    cursor: 'text'
  }
});

class Breadcrumbss extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    id: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    subData: PropTypes.object.isRequired
  };

  backHomeClick = (value) => {
    Framework.redirectTo(value);
  };

  render() {
    const { language, classes, data, id, subData } = this.props;
    const editData = data && data.props.defaultMessage;
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography onClick={() => this.backHomeClick('/dashboard')} color="textPrimary" className={classes.posClass}>
            {' '}
            <FormattedMessage {...language.getText('common.HOME')} />
          </Typography>
          {editData === 'Book' ? (
            <Typography onClick={() => this.backHomeClick('/books')} className={classes.posClass} color="textPrimary">
              Books
            </Typography>
          ) : (
            ''
          )}

          {editData === 'eText Project' || editData === 'Ingestion' || editData === 'Users' ? (
            <Typography color="textPrimary">
              {' '}
              <FormattedMessage {...language.getText(editData)} /> {'   '}
              {subData !== '' && (
                <Typography color="textPrimary" variant="caption2">
                  ({subData})
                </Typography>
              )}
            </Typography>
          ) : (
            <Typography
              className={editData === 'Book' ? classes.textClass : classes.posClass}
              color="textPrimary"
              onClick={editData === 'Books' ? '' : () => this.backHomeClick(`/books/${id}`)}
            >
              {' '}
              {editData === 'Books' ? (
                <div className={classes.textClass}>
                  <FormattedMessage {...language.getText('common.BOOKS')} />
                </div>
              ) : (
                <div>
                  <FormattedMessage {...language.getText('common.BACkHOME')} />
                </div>
              )}
            </Typography>
          )}

          {editData === 'eText Project' ||
          editData === 'Ingestion' ||
          editData === 'Book' ||
          editData === 'Books' ||
          editData === 'Users' ? (
            ''
          ) : (
            <Typography color="textPrimary">
              {' '}
              <FormattedMessage {...language.getText(editData)} />
            </Typography>
          )}
        </Breadcrumbs>
      </div>
    );
  }
}

export default withStyles(styles)(inject('language')(Breadcrumbss));
