/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { applySnapshot, types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const QueryBuilder = types
  .model('QueryBuilder', {
    resultColumn: types.optional(types.array(types.string), []),
    queryList: types.array(types.map(types.maybeNull(types.union(types.string, types.number))), [])
  })
  .actions((self) => ({
    fetchDetails(query, dbName) {
      Framework.getEventManager().publish(constants.GET_QUERY_RESULT, { query, dbName });
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.resultColumn, initialState);
      applySnapshot(self.queryList, initialState);
    },
    setQueryResponse(response) {
      const columnValue = Object.keys(response[0]);
      applySnapshot(self.resultColumn, columnValue);
      applySnapshot(self.queryList, response);
    },
    setNotification(notificationValue) {
      const notification = {
        open: true,
        message: notificationValue,
        type: 'error'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    }
  }));
export default QueryBuilder;
