/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Framework, { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { FormControl, TextField, Typography, Button, Grid, Paper, Box } from '@material-ui/core';
import ServerSideSearchTable from './ServerSideSearchTable';
import ServerSideProcess from '../models/ServerSideProcess';
import NoDataFound from '../../../common/components/NoDataFound';
import * as constants from '../../../common/constants';

const styles = (theme) => ({
  container: {
    marginBottom: 20
  },
  gridItem: {
    marginTop: 20
  },
  formControl: {
    marginLeft: 20,
    marginRight: 20,
    minWidth: 120
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  textareaWidth: {
    width: '92ch'
  }
});
@inject('serverSideProcessState', 'serverSideProcessStatus')
@observer
class ServerSideProcessUtility extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    serverSideProcessState: shapes.modelOf(ServerSideProcess).isRequired,
    serverSideProcessStatus: shapes.state.isRequired,
    start: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { searchText } = this.props;
    this.state = {
      globalBookId: searchText || ''
    };
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel = (row) => {
    const { serverSideProcessState } = this.props;
    const payload = {
      globalBookId: row.globalBookId,
      bookId: row.bookId,
      serverSideProcessUUID: row.serverSideProcessUUID,
      version: row.version,
      serverSideProcessStatus: row.serverSideProcessStatus
    };
    serverSideProcessState.fetchData(payload);
    Framework.getEventManager().on(constants.SEARCH_SERVER_SIDE_PROCESS_DATA, () => {
      serverSideProcessState.fetchSSPData();
    });
  };

  handleTextChange = (value) => {
    this.setState({
      globalBookId: value
    });
  };

  handleClick = () => {
    const { start } = this.props;
    const { globalBookId } = this.state;
    start(globalBookId);
  };

  render() {
    const { classes, language, data, serverSideProcessStatus, serverSideProcessState } = this.props;
    const { globalBookId } = this.state;

    return (
      <div className={classes.container}>
        <Paper className={classes.paper} elevation={3} mb={3}>
          <Typography variant="h3" align="center" gutterBottom spacing={3}>
            <FormattedMessage {...language.getText('server.TITLE_TEXT')} />
          </Typography>
          <Grid container direction="column" alignItems="center" justify="space-between">
            <Grid item>
              <FormControl className={classes.formControl}>
                <TextField
                  label={<FormattedMessage {...language.getText('server.ENTER_MULTI_GLOBAL_BOOK_ID_HERE')} />}
                  multiline
                  rows={7}
                  className={classes.textareaWidth}
                  value={globalBookId}
                  onChange={(e) => this.handleTextChange(e.target.value)}
                  InputProps={{}}
                />
              </FormControl>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Button variant="outlined" color="primary" size="small" onClick={() => this.handleClick()}>
                <FormattedMessage {...language.getText('common.START')} />
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <br />
        {serverSideProcessStatus.isError || serverSideProcessState.ServerSideProcessData.length === 0 ? (
          <NoDataFound error="SSR No Data in Queue" />
        ) : (
          <Paper variant="outlined">
            <Box m={1}>
              <ServerSideSearchTable data={data} handleCancel={this.handleCancel} />
            </Box>
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(ServerSideProcessUtility)));
