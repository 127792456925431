/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { shapes } from '@greenville/framework';
import PropTypes from 'prop-types';
import { withStyles, Typography, Box, IconButton, Collapse } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { inject, observer } from 'mobx-react';
import { Icon } from '@greenville/mui-theme';

const styles = () => ({
  errorHeader: {
    paddingLeft: '40%',
    '@media (max-width: 767px)': {
      paddingLeft: '25%'
    },
    '@media (max-width: 480px)': {
      paddingLeft: '15%'
    }
  },
  errorText: {
    width: '100%',
    display: 'flex'
  }
});

const ErrorBanner = ({ language, classes, message }) => {
  const [open, setOpen] = useState(true);
  const headerStatus = message;

  return (
    <Collapse in={open}>
      <Alert
        variant="standard"
        severity="warning"
        icon={false}
        className={classes.errorHeader}
        action={
          <IconButton
            id="remove-button"
            data-testid="remove-button"
            onClick={() => {
              setOpen(false);
            }}
            aria-label={language.getMessage('common.REMOVE')}
          >
            <Icon id="remove-icon" name="Remove" color="disabled" fontSize="small" />
          </IconButton>
        }
      >
        <Box className={classes.errorText}>
          <Typography variant="body2">
            <FormattedMessage {...language.getText(headerStatus)} />
          </Typography>
        </Box>
      </Alert>
    </Collapse>
  );
};

ErrorBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  message: PropTypes.string
};

ErrorBanner.defaultProps = {
  message: ''
};

export default withStyles(styles)(inject('language')(observer(ErrorBanner)));
