const publishArtsOption = [
  {
    artsAndScienceId: 1,
    artsAndScienceText: '0'
  },
  {
    artsAndScienceId: 2,
    artsAndScienceText: '1'
  },
  {
    artsAndScienceId: 3,
    artsAndScienceText: 'M'
  },
  {
    artsAndScienceId: 4,
    artsAndScienceText: 'N'
  },
  {
    artsAndScienceId: 5,
    artsAndScienceText: 'R'
  }
];

const publishCareerOption = [
  {
    professionalAndCareerId: 1,
    professionalAndCareerText: 'D'
  },
  {
    professionalAndCareerId: 2,
    professionalAndCareerText: 'G'
  },
  {
    professionalAndCareerId: 3,
    professionalAndCareerText: 'J'
  },
  {
    professionalAndCareerId: 4,
    professionalAndCareerText: 'L'
  },
  {
    professionalAndCareerId: 5,
    professionalAndCareerText: 'Q'
  }
];

const publishInternationalOption = [
  {
    internationalId: 6,
    internationalText: 'Asia'
  },
  {
    internationalId: 7,
    internationalText: 'Australia'
  },
  {
    internationalId: 8,
    internationalText: 'Brazil'
  },
  {
    internationalId: 9,
    internationalText: 'Canada'
  },
  {
    internationalId: 10,
    internationalText: 'Europe'
  }
];

const publishInternationalCommon = [
  {
    internationalId: '1',
    internationalText: 'Asia'
  },
  {
    internationalId: '2',
    internationalText: 'Australia'
  },
  {
    internationalId: '3',
    internationalText: 'Brazil'
  },
  {
    internationalId: '4',
    internationalText: 'Canada'
  },
  {
    internationalId: '5',
    internationalText: 'Europe'
  }
];

const publishPtgOption = [
  {
    ptgId: 1,
    ptgText: '2'
  },
  {
    ptgId: 2,
    ptgText: '3'
  },
  {
    ptgId: 3,
    ptgText: 'E'
  },
  {
    ptgId: 4,
    ptgText: 'H'
  },
  {
    ptgId: 5,
    ptgText: 'T'
  },
  {
    ptgId: 6,
    ptgText: 'W'
  }
];

const divisionOptionGeneral = [
  {
    id: 21,
    value: 'Arts & Science'
  },
  {
    id: 22,
    value: 'Professional & Career'
  },
  {
    id: 23,
    value: 'International'
  },
  {
    id: 24,
    value: 'PTG'
  },
  {
    id: 25,
    value: 'PLS'
  }
];

const divisionOption = [
  {
    id: 1,
    value: 'Arts & Science'
  },
  {
    id: 2,
    value: 'International'
  },
  {
    id: 3,
    value: 'Professional & Career'
  },
  {
    id: 4,
    value: 'PLS'
  },
  {
    id: 5,
    value: 'PTG'
  }
];

const manageOption = [
  {
    id: '0',
    value: 'Ann'
  },
  {
    id: '1',
    value: 'BU/Comp Vendor'
  },
  {
    id: '2',
    value: 'Jessica'
  },
  {
    id: '3',
    value: 'Tyler'
  }
];

const sourceOption = [
  {
    id: 0,
    value: 'M'
  },
  {
    id: 1,
    value: '0'
  },
  {
    id: 2,
    value: '1'
  },
  {
    id: 3,
    value: '2'
  },
  {
    id: 4,
    value: '3'
  },
  {
    id: 5,
    value: 'CG'
  },
  {
    id: 6,
    value: 'D'
  },
  {
    id: 7,
    value: 'E'
  },
  {
    id: 8,
    value: 'G'
  },
  {
    id: 9,
    value: 'H'
  },
  {
    id: 10,
    value: 'J'
  },
  {
    id: 11,
    value: 'L'
  },
  {
    id: 12,
    value: 'M'
  },
  {
    id: 13,
    value: 'N'
  },
  {
    id: 14,
    value: 'Q'
  },
  {
    id: 15,
    value: 'R'
  },
  {
    id: 16,
    value: 'T'
  },
  {
    id: 17,
    value: 'W'
  },
  {
    id: 18,
    value: 'V'
  }
];

const vendorOption = [
  {
    id: '0',
    value: 'Aptara'
  },
  {
    id: '1',
    value: 'B2R'
  },
  {
    id: '2',
    value: 'Code Mantra'
  },
  {
    id: '3',
    value: 'Element'
  }
];

const businessUnit = [
  {
    id: 1,
    value: 'HigherEd'
  },
  {
    id: 2,
    value: 'Curriculum'
  },
  {
    id: 3,
    value: 'Custom'
  },
  {
    id: 4,
    value: 'International'
  },
  {
    id: 5,
    value: 'CTG'
  }
];

const titleWorkflow = [
  {
    id: 0,
    value: 'Simple eBook'
  },
  {
    id: 1,
    value: 'eBook'
  }
];

const audioSynch = [
  {
    id: 0,
    value: 'Non-Modal Audio without Synchronized Highlighting'
  },
  {
    id: 1,
    value: 'Non-Modal Audio with Synchronized Highlighting'
  },
  {
    id: 2,
    value: 'Not necessary for this title'
  }
];

const yesNo = [
  {
    id: 'N',
    value: 'No'
  },
  {
    id: 'Y',
    value: 'Yes'
  }
];

const etextGeneralYesNo = [
  {
    id: 'Y',
    value: 'Yes'
  },
  {
    id: 'N',
    value: 'No'
  }
];

const etextCustom = [
  {
    id: 0,
    value: 'Supplied'
  },
  {
    id: 1,
    value: 'Not necessary for this title'
  }
];

const etextProduction = [
  {
    id: 1,
    value: 'Initiation'
  },
  {
    id: 2,
    value: 'In Production'
  },
  {
    id: 3,
    value: 'CM Tool Issue'
  },
  {
    id: 4,
    value: 'Content Issue'
  },
  {
    id: 5,
    value: 'Other Issue'
  },
  {
    id: 6,
    value: 'First Review'
  },
  {
    id: 7,
    value: 'First Corrections'
  },
  {
    id: 8,
    value: 'Linking'
  },
  {
    id: 9,
    value: 'Second Review'
  },
  {
    id: 10,
    value: 'Second Corrections'
  },
  {
    id: 11,
    value: 'Third Corrections'
  },
  {
    id: 12,
    value: 'Review'
  },
  {
    id: 13,
    value: 'Corrections'
  },
  {
    id: 14,
    value: 'CD Master in Progress'
  },
  {
    id: 15,
    value: 'CD Master Approved'
  },
  {
    id: 16,
    value: 'On Hold'
  },
  {
    id: 17,
    value: 'Live'
  },
  {
    id: 18,
    value: 'Canceled'
  }
];

const editionCm = [
  {
    id: 0,
    value: 'Common Edition'
  },
  {
    id: 1,
    value: 'Student Edition'
  },
  {
    id: 2,
    value: 'Teacher Edition'
  }
];

const userInterfaceOpt = [
  { id: 0, value: 'Right side (recto)' },
  { id: 1, value: 'Left side (verso)' }
];

const publishRevisionDetails = {
  reqNo: 16722,
  addemailaddress: 'dfd',
  additionalComments: 'additional comments',
  authors: 'N',
  bonusChapter: 'Y',
  changeInContent: 'Y',
  changeToCover: 'Y',
  colorInserts: 'Y',
  compositor: '',
  contactname: 'Name',
  copyrightyear: '2021',
  coverArtRadioValue: '',
  coverArtRadioValueBool: false,
  eTextIsbn10: '',
  eTextIsbn13: '',
  eTextProduct: '',
  eTextRadioValue: '',
  eTextRadioValueBool: false,
  emailaddress: 'ad@gmail.com',
  endPapers: 'Y',
  fileName: 'file locaiton',
  folderName: '',
  generalBookId: '1232',
  generalText10: 'dd',
  generalText13: '13',
  hotspotRadioValue: '',
  hotspotRadioValueBool: false,
  infoOthers: 'Y',
  insideBackCover: 'Y',
  insideFaceCover: 'Y',
  ipadView: 'Y',
  others: 'Y',
  pageAdditions: 'Y',
  parent10: 'p10',
  parent13: 'p13',
  permissionChange: 'Y',
  phonenumber: '2',
  divisionText: 'Professional',
  publishRadioValueBool: false,
  divisionID: 4,
  revGlossary: 'Y',
  revhotspot: 'Y',
  revisionTextArea: 'dddddd',
  sourceFileRadioValue: '',
  sourceFileRadioValueBool: false,
  specialText: 'N',
  specialTextArea: '',
  tearCard: 'Y',
  titleedition: 'title',
  tocChange: 'Y',
  tocRadioValue: '',
  tocRadioValueBool: false,
  typeRevision: '',
  typeRevisionBool: false,
  updateProduct: 'Y',
  updateToTitle: 'Y'
};

const eTextConfig = {
  publishArtsOption,
  publishCareerOption,
  publishInternationalOption,
  publishInternationalCommon,
  publishPtgOption,
  divisionOption,
  divisionOptionGeneral,
  manageOption,
  sourceOption,
  vendorOption,
  businessUnit,
  yesNo,
  etextGeneralYesNo,
  etextProduction,
  userInterfaceOpt,
  publishRevisionDetails,
  editionCm,
  titleWorkflow,
  audioSynch,
  etextCustom
};

module.exports = eTextConfig;
