/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Card, CardActionArea, CardMedia, Typography, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import HistoryIcon from '@material-ui/icons/History';
import ListItemText from '@material-ui/core/ListItemText';
import { FormattedMessage } from 'react-intl';
import PublishIcon from '@material-ui/icons/Publish';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BuildIcon from '@material-ui/icons/Build';
import PaletteIcon from '@material-ui/icons/Palette';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
// import BuildIcon from '@material-ui/icons/Build';
import * as constants from '../../../../common/constants';
import Books from '../../models/Books';
import Breadcrumbs from '../commonitems/Breadcrumbs';
import FavoriteIcon from '../Favorite';
import utils from '../../../../common/utils';
import BookHistory from '../BookHistory';
import Loader from '../../../../common/Loader';
import UsersPermissions from '../../../users/models/UsersPermissions';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  rootbtn: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  favicon: {
    top: 15,
    position: 'absolute',
    right: 5
  },
  pointer: {
    cursor: 'pointer'
  },
  disabled: {
    cursor: 'not-allowed'
  },
  root1: {
    position: 'relative'
  },
  area: {
    height: 225
  },
  content: {
    padding: 4
  },
  media: {
    margin: 4,
    height: 210,
    backgroundSize: '95px 115px',
    backgroundColor: '#f5f4f4'
  },
  space: {
    justifyContent: 'space-evenly'
  },
  textcolor: {
    color: '#6a7070',
    fontSize: 13
  },
  textspancolor: {
    fontSize: 14,
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    color: '#000'
  },
  progressBar: {
    position: 'absolute',
    left: '50%',
    top: window.innerHeight / 2.4
  },
  sspStatusSpanColor: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    fontSize: 14,
    color: 'red'
  },
  sspStatusColor: {
    fontSize: 13,
    color: 'red'
  }
});
@inject('booksState', 'booksStatus', 'userPermissions')
@observer
class BookViewDetail extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    booksState: shapes.modelOf(Books).isRequired,
    booksStatus: shapes.state.isRequired,
    match: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    bookFavorite: PropTypes.func.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.dialogClose = this.dialogClose.bind(this);
  }

  componentDidMount() {
    const { booksState, match } = this.props;
    booksState.fetchBook(match.params.bookid);
    booksState.fetchBookHistory(match.params.bookid);
  }

  dialogOpen = () => {
    this.setState({
      open: true
    });
  };

  dialogClose = () => {
    this.setState({
      open: false
    });
  };

  successStatus = (successPages, totalPages) => {
    const { language } = this.props;
    if (successPages !== null) {
      return (
        <FormattedMessage
          {...language.getText('value.SERVER_SIDE_PROCESS_STATUS')}
          values={{
            successPages,
            totalPages
          }}
        />
      );
    }
    return null;
  };

  render() {
    const { booksState, classes, booksStatus, language, bookFavorite, match, userPermissions } = this.props;
    const { open } = this.state;
    const permissionsList = toJS(userPermissions.permissionList.permissions);

    const { bookDetail, bookHistory } = booksState;
    const pdfViewerDisabled = bookDetail.isS3Uploaded === 'N' && bookDetail.isPdfPlayerSupported === 'N';
    let sspStatus = utils.serverSideProcessStatus(bookDetail.serverSideProcessStatus);
    const entitlementDisabled =
      bookDetail.serverSideProcessLogStatus !== constants.SSP_LOG_STATUS.COMPLETED &&
      bookDetail.serverSideProcessLogStatus !== constants.SSP_LOG_STATUS.NOT_IN_OUEUE &&
      bookDetail.serverSideProcessLogStatus !== constants.SSP_LOG_STATUS.CANCELLED &&
      bookDetail.serverSideProcessLogStatus !== constants.SSP_LOG_STATUS.FAILED;
    let highlight = true;
    let showPagesStatus = true;
    let showCoverSlatesFailed = false;
    let failedReason = null;
    if (sspStatus === 'common.SUCCESS') {
      highlight = false;
    }
    if (sspStatus === 'common.NOT_STARTED') {
      showPagesStatus = false;
    }
    if (sspStatus === 'common.COVER_PAGE_NOT_FOUND' || sspStatus === 'common.SLATES_NOT_FOUND') {
      showPagesStatus = false;
      showCoverSlatesFailed = true;
      failedReason =
        sspStatus === 'common.COVER_PAGE_NOT_FOUND' ? 'common.COVER_PAGE_NOT_FOUND' : 'common.SLATES_NOT_FOUND';
      sspStatus = 'common.FAILED';
    }
    const successPages = bookDetail.successPageStatusCount;
    const totalPages = bookDetail.pageCount;

    return (
      <LoadingHandler
        loading={booksStatus.isPending}
        isError={booksStatus.isError && booksStatus.error && booksStatus.error.status === 401}
        loadingContent={<Loader />}
        errorContent={<StateErrorDisplay error={booksStatus.error} showDetails />}
        content={
          <div>
            <div className={classes.textfont}>
              <Breadcrumbs data={<FormattedMessage {...language.getText('label.BOOK')} />} id={match.params.bookid} />
            </div>
            <br />
            <br />
            <Grid container alignItems="flex-start" spacing={5}>
              <Grid item xs={3}>
                <Box mt={1}>
                  <Card className={classes.root1}>
                    <CardActionArea className={classes.area}>
                      <CardMedia
                        className={classes.media}
                        image={bookDetail.thumbnail}
                        // title={data.title}
                      />
                    </CardActionArea>
                    <div className={classes.favicon}>
                      <FavoriteIcon favorite={bookDetail} bookFavorite={bookFavorite} window={constants.INTERMEDIARY} />
                    </div>
                  </Card>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Grid item xs container direction="column">
                  <Grid item xs zeroMinWidth>
                    <Box mt={1}>
                      <Box mb={0.6}>
                        <Typography>
                          <span className={classes.textspancolor}>
                            <FormattedMessage {...language.getText('label.TITLE')} /> :{' '}
                          </span>
                          <span
                            title={bookDetail.title + utils.getEditionTypeValue(bookDetail.editionTypeID)}
                            className={classes.textcolor}
                          >
                            {bookDetail.title}
                            {utils.getEditionTypeValue(bookDetail.editionTypeID)}
                          </span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.GLOBAL_BOOK_ID')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}>{bookDetail.globalBookID}</span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.EDITION')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}>
                            {(bookDetail.bookEditionNumber || bookDetail.bookEditionNumber === '') &&
                              (bookDetail.bookEditionNumber.trim() ? bookDetail.bookEditionNumber : 'N/A')}
                          </span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.BOOK_ID')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}>{bookDetail.bookID}</span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.MODIFIED_BY')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}>{bookDetail.modifiedBy}</span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.LAST_MODIFIED')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}>{bookDetail.lastModified}</span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.STATUS')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}> {bookDetail.status}</span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.AUTHOR')} /> :{' '}
                          </span>
                          <span className={classes.textcolor} title={utils.authorFullName(bookDetail.authorList)}>
                            {' '}
                            {utils.authorFullName(bookDetail.authorList)}
                          </span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.ISBN')} /> :{' '}
                          </span>
                          <span className={classes.textcolor} title={utils.isbnDisplayName(bookDetail.isbnList)}>
                            {utils.isbnDisplayName(bookDetail.isbnList)}
                          </span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <i>
                          <Typography>
                            <span className={highlight ? classes.sspStatusSpanColor : classes.textspancolor}>
                              {' '}
                              <FormattedMessage {...language.getText('label.SERVER_SIDE_PROCESS_STATUS')} /> :{' '}
                            </span>
                            {sspStatus ? (
                              <span className={highlight ? classes.sspStatusSpanColor : classes.textcolor}>
                                <FormattedMessage {...language.getText(sspStatus)} />
                                {showPagesStatus && (
                                  <FormattedMessage
                                    {...language.getText('value.SERVER_SIDE_PROCESS_STATUS')}
                                    values={{
                                      successPages,
                                      totalPages
                                    }}
                                  />
                                )}
                                {showCoverSlatesFailed && (
                                  <FormattedMessage
                                    {...language.getText(failedReason)}
                                    values={{
                                      successPages,
                                      totalPages
                                    }}
                                  />
                                )}
                              </span>
                            ) : (
                              ''
                            )}
                          </Typography>
                        </i>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.DI_SUPPORTED')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}>{bookDetail.isDLSupported}</span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.SERVER_SIDE_ENABLED')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}> {bookDetail.isServerSideEnabled}</span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.S3_UPLOAD_STATUS')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}> {bookDetail.isS3Uploaded}</span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.PDF_PLAYER_SUPPORTED')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}> {bookDetail.isPdfPlayerSupported}</span>
                        </Typography>
                      </Box>
                      <Box mb={0.6}>
                        <Typography noWrap>
                          <span className={classes.textspancolor}>
                            {' '}
                            <FormattedMessage {...language.getText('label.PAGE_COUNT')} /> :{' '}
                          </span>
                          <span className={classes.textcolor}> {bookDetail.pageCount}</span>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={5}>
                <List className={classes.root}>
                  <TooltipCustom
                    title={
                      !permissionsList.includes('authoring_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('label.EDIT')} />
                      )
                    }
                  >
                    <span>
                      <Button disabled={!permissionsList.includes('authoring_can_edit')}>
                        <ListItem alignItems="end">
                          <ListItemAvatar>
                            <span disabled={!permissionsList.includes('authoring_can_edit')}>
                              <EditIcon
                                onClick={() => Framework.redirectTo(`${match.params.bookid}/edit`)}
                                style={{ fontSize: 32 }}
                                className={classes.pointer}
                              />
                            </span>
                          </ListItemAvatar>

                          <ListItemText
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  <FormattedMessage {...language.getText('label.EDIT')} />
                                </Typography>
                                {' - '} <FormattedMessage {...language.getText('desc.EDIT')} />
                              </>
                            }
                          />
                        </ListItem>
                      </Button>
                    </span>
                  </TooltipCustom>

                  <Divider variant="inset" component="li" />

                  <TooltipCustom
                    title={
                      (!permissionsList.includes('authoring_can_view') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('label.PREVIEW')} />
                      )) ||
                      (pdfViewerDisabled ? (
                        <FormattedMessage {...language.getText('title.DISABLED_PREVIEW')} />
                      ) : (
                        <FormattedMessage {...language.getText('label.PREVIEW')} />
                      ))
                    }
                  >
                    <span>
                      <Button disabled={!permissionsList.includes('authoring_can_view')}>
                        <ListItem alignItems="end">
                          <ListItemAvatar>
                            {/* <Tooltip
                              title={
                                pdfViewerDisabled ? (
                                  <FormattedMessage {...language.getText('title.DISABLED_PREVIEW')} />
                                ) : (
                                  <FormattedMessage {...language.getText('label.PREVIEW')} />
                                )
                              }
                            > */}
                            <VisibilityIcon
                              onClick={() =>
                                !pdfViewerDisabled ? Framework.redirectTo(`${match.params.bookid}/preview`) : null
                              }
                              className={pdfViewerDisabled ? classes.disabled : classes.pointer}
                              style={{ fontSize: 32 }}
                            />
                            {/*   </Tooltip> */}
                          </ListItemAvatar>

                          <ListItemText
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  <FormattedMessage {...language.getText('label.PREVIEW')} />
                                </Typography>
                                {' - '} <FormattedMessage {...language.getText('desc.PREVIEW')} />
                              </>
                            }
                          />
                        </ListItem>
                      </Button>
                    </span>
                  </TooltipCustom>

                  <Divider variant="inset" component="li" />
                  <TooltipCustom
                    title={
                      (!permissionsList.includes('authoring_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('label.ENTITLEMENT_PUBLISH')} />
                      )) ||
                      (entitlementDisabled ? (
                        <FormattedMessage {...language.getText('label.DISABLED_ENTITLEMENT_PUBLISH')} />
                      ) : (
                        <FormattedMessage {...language.getText('label.ENTITLEMENT_PUBLISH')} />
                      ))
                    }
                  >
                    <span>
                      <Button disabled={!permissionsList.includes('authoring_can_edit')}>
                        <ListItem alignItems="end">
                          <ListItemAvatar>
                            {/* <Tooltip
                              title={
                                entitlementDisabled ? (
                                  <FormattedMessage {...language.getText('label.DISABLED_ENTITLEMENT_PUBLISH')} />
                                ) : (
                                  <FormattedMessage {...language.getText('label.ENTITLEMENT_PUBLISH')} />
                                )
                              }
                            > */}
                            <PublishIcon
                              disabled={!permissionsList.includes('authoring_can_edit')}
                              onClick={() =>
                                !entitlementDisabled ? Framework.redirectTo(`${match.params.bookid}/entitlement`) : null
                              }
                              className={entitlementDisabled ? classes.disabled : classes.pointer}
                              style={{ fontSize: 32 }}
                            />
                            {/*  </Tooltip> */}
                          </ListItemAvatar>
                          <ListItemText
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  <FormattedMessage {...language.getText('label.ENTITLEMENT_PUBLISH')} />
                                </Typography>
                                {' - '} <FormattedMessage {...language.getText('desc.ENTITLEMENT_PUBLISH')} />
                              </>
                            }
                          />
                        </ListItem>
                      </Button>
                    </span>
                  </TooltipCustom>
                  <Divider variant="inset" component="li" />
                  <TooltipCustom
                    title={
                      !permissionsList.includes('authoring_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('label.SPECIFY_BASEPATH')} />
                      )
                    }
                  >
                    <span>
                      <Button disabled={!permissionsList.includes('authoring_can_edit')}>
                        <ListItem alignItems="end">
                          <ListItemAvatar>
                            {/* <Tooltip title={<FormattedMessage {...language.getText('label.SPECIFY_BASEPATH')} />}> */}
                            <LinearScaleIcon
                              disabled={!permissionsList.includes('authoring_can_edit')}
                              onClick={() => Framework.redirectTo(`${match.params.bookid}/basepath`)}
                              className={classes.pointer}
                              style={{ fontSize: 32 }}
                            />
                            {/*  </Tooltip> */}
                          </ListItemAvatar>
                          <ListItemText
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  <FormattedMessage {...language.getText('label.SPECIFY_BASEPATH')} />
                                </Typography>
                                {' - '} <FormattedMessage {...language.getText('desc.SPECIFY_BASEPATH')} />
                              </>
                            }
                          />
                        </ListItem>
                      </Button>
                    </span>
                  </TooltipCustom>

                  <Divider variant="inset" component="li" />
                  <TooltipCustom
                    title={
                      !permissionsList.includes('authoring_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('label.ICON')} />
                      )
                    }
                  >
                    <span>
                      <Button disabled={!permissionsList.includes('authoring_can_edit')}>
                        <ListItem alignItems="end">
                          <ListItemAvatar>
                            {/* <Tooltip title={<FormattedMessage {...language.getText('label.ICON')} />}> */}
                            <BuildIcon
                              disabled={!permissionsList.includes('authoring_can_edit')}
                              onClick={() => Framework.redirectTo(`${match.params.bookid}/customicon`)}
                              className={classes.pointer}
                              style={{ fontSize: 32 }}
                            />
                            {/*  </Tooltip> */}
                          </ListItemAvatar>
                          <ListItemText
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  <FormattedMessage {...language.getText('label.ICON')} />
                                </Typography>
                                {' - '} <FormattedMessage {...language.getText('desc.CUSTOM_ICON')} />
                              </>
                            }
                          />
                        </ListItem>
                      </Button>
                    </span>
                  </TooltipCustom>
                  <Divider variant="inset" component="li" />
                  <TooltipCustom
                    title={
                      !permissionsList.includes('authoring_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('label.INTERFACE_COLOR')} />
                      )
                    }
                  >
                    <span>
                      <Button disabled={!permissionsList.includes('authoring_can_edit')}>
                        <ListItem alignItems="end">
                          <ListItemAvatar>
                            <PaletteIcon
                              disabled={!permissionsList.includes('authoring_can_edit')}
                              onClick={() => Framework.redirectTo(`${match.params.bookid}/interfacecolors`)}
                              className={classes.pointer}
                              style={{ fontSize: 32 }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  <FormattedMessage {...language.getText('label.INTERFACE_COLOR')} />
                                </Typography>
                                {' - '} <FormattedMessage {...language.getText('desc.INTERFACE_COLORS')} />
                              </>
                            }
                          />
                        </ListItem>
                      </Button>
                    </span>
                  </TooltipCustom>

                  {/* <Divider variant="inset" component="li" />
                  <ListItem alignItems="end">
                    <ListItemAvatar>
                      <Tooltip title={<FormattedMessage {...language.getText('label.ICON')} />}>
                        <BuildIcon className={classes.pointer} style={{ fontSize: 32 }} />
                      </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <>
                          <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                            <FormattedMessage {...language.getText('label.ICON')} />
                          </Typography>
                          {' - '} <FormattedMessage {...language.getText('desc.CUSTOM_ICON')} />
                        </>
                      }
                    />
                  </ListItem> */}

                  <Divider variant="inset" component="li" />
                  <TooltipCustom title={<FormattedMessage {...language.getText('label.HISTORY')} />}>
                    <Button>
                      <ListItem alignItems="end">
                        <ListItemAvatar>
                          <HistoryIcon onClick={this.dialogOpen} className={classes.pointer} style={{ fontSize: 32 }} />

                          {open && <BookHistory bookHistory={bookHistory} dialogClose={this.dialogClose} />}
                        </ListItemAvatar>
                        <ListItemText
                          secondary={
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                <FormattedMessage {...language.getText('label.HISTORY')} />
                              </Typography>
                              {' - '}
                              <FormattedMessage {...language.getText('desc.HISTORY')} />
                            </>
                          }
                        />
                      </ListItem>
                    </Button>
                  </TooltipCustom>
                </List>
              </Grid>
            </Grid>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles)(observer(inject('language')(BookViewDetail)));
