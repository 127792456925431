/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { shapes } from '@greenville/framework';
import Logo from './Logo';
import Profile from './Profile';

const styles = () => ({
  pearsonLogo: {
    fontSize: 48
  },
  headerContainer: {
    width: '100%',
    padding: '0 20px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    '@media (max-width: 480px)': {
      padding: '0'
    }
  }
});

const TopStrap = ({ id, language, classes, user, ...rest }) => (
  <header id={id} className={classes.headerContainer} {...rest}>
    <Logo />
    {user.id !== '' && <Profile />}
  </header>
);

TopStrap.defaultProps = {
  id: 'topstrap'
};

TopStrap.propTypes = {
  id: PropTypes.string,
  language: shapes.language.isRequired,
  classes: PropTypes.object.isRequired,
  user: shapes.user.isRequired
};

TopStrap.defaultProps = {};

export default withStyles(styles)(inject('language', 'user')(observer(TopStrap)));
