/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Service and event manager for pdf save hotspot
 *
 * @file SaveHotspotService.js
 */

import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class SaveHotspotService extends BaseService {
  constructor() {
    super(constants.PDF_SAVE_HOTSPOT_REQUESTED, 'saveHotspotStatus');
  }

  handleEvent(eventName, event) {
    const { bookid, data } = event;
    return this.getClient('evergreen').post(`books/${bookid}/regions/save`, data);
  }

  handleResponse(response, event) {
    // eslint-disable-line class-methods-use-this
    const { positionToggle } = event;
    let msg = 'Hotspot data saved successfully!';
    msg = positionToggle ? `${msg} New position at X=${response.data.x} and Y=${response.data.y}` : msg;
    Framework.getStoreRegistry().getStore('hotspot').setSaveHotspotData(response.data, event);
    const notification = {
      open: true,
      message: msg,
      type: 'info'
    };
    if (!event.removeDuplicate) {
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
      Framework.getEventManager().publish(constants.PDF_HOTSPOT_FETCHED, {});
      if (status === 500 && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
  }
}
