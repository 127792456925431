import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const roleObj = types.model('roleObj', {
  prompt: types.maybeNull(types.string),
  role: types.maybeNull(types.string)
});

const contentfilterResponse = types.model('contentfilterResponse', {
  Category: types.maybeNull(types.string),
  Severity: types.maybeNull(types.string)
});

const blockListResult = types.model('blockListResult', {
  blocklistName: types.maybeNull(types.string),
  blockItemText: types.maybeNull(types.string)
});

const contentFilterAllValues = types.model('contentFilterAllValues', {
  blocklistsMatchResults: types.maybeNull(types.optional(types.array(blockListResult), [])),
  Category_Severity: types.maybeNull(types.optional(types.array(contentfilterResponse), []))
});

const channelsResponse = types.model('channelsResponse', {
  correlationId: types.maybeNull(types.string),
  channelsId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(
    types.union(types.array(types.string), types.string, types.optional(contentFilterAllValues, {}))
  ),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  finalStatus: types.maybeNull(types.string),
  finalResponse: types.maybeNull(types.string)
});

const ChannelsModel = types
  .model('ChannelsSubType', {
    channelsResult: types.optional(types.array(channelsResponse), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHANNELS_MODEL_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.CHANNELS_MODEL_RESPONSE);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.channelsResult, initialState);
      Framework.getEventManager().publish(constants.CHANNELS_MODEL_RESPONSE);
    }
  }));

export default ChannelsModel;
