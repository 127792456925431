/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const UserRolePermissions = types.model('UserRolePermissions', {
  status: types.maybeNull(types.string),
  roleTypeId: types.maybeNull(types.integer),
  roleName: types.maybeNull(types.string),
  roleDesc: types.maybeNull(types.string),
  permissions: types.optional(types.array(types.string), [])
});

const UsersPermissions = types
  .model('UsersPermissions', {
    permissionList: types.compose(UserRolePermissions)
  })
  .actions((self) => ({
    fetchPermissions(id) {
      Framework.getEventManager().publish(constants.MANAGE_USERS_PERMISSIONS, { id });
    },
    setPermissions(response) {
      if (response.data.permissions.length > 0) {
        applySnapshot(self.permissionList, response.data);
      }
    }
  }));
export default UsersPermissions;
