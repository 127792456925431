import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
// import { toJS } from 'mobx';
import * as constants from '../../../common/constants';

const summaryData = types.model('summaryData', {
  bookId: types.maybeNull(types.string),
  modifiedBy: types.maybeNull(types.string),
  productId: types.maybeNull(types.string),
  productModel: types.maybeNull(types.string),
  pageId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  lifeCycleStatus: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  summaryText: types.maybeNull(types.string)
});

const Summary = types
  .model('Summary', {
    data: types.optional(types.array(summaryData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHATGPT_SUMMARY_DATA, {
        payload
      });
    },
    updateSummary(payload) {
      Framework.getEventManager().publish(constants.UPDATE_CHATGPT_SUMMARY_DATA, {
        payload
      });
    },
    updateSummaryStatus(payload) {
      Framework.getEventManager().publish(constants.UPDATE_CHATGPT_SUMMARY_DATA_STATUS, {
        payload
      });
    },
    setResponse(response) {
      const { data } = response;
      applySnapshot(self, { data });
      Framework.getEventManager().publish(constants.SET_SUMMARY_DATA);
    },
    setUpdateResponse() {
      Framework.getEventManager().publish(constants.SET_UPDATE_SUMMARY_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_SUMMARY_DATA);
    }
  }));

export default Summary;
