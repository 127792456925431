/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { Icon } from '@greenville/mui-theme';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { shapes } from '@greenville/framework';

const styles = () => ({
  errorText: {
    paddingRight: 6
  }
});

const ErrorCard = ({ message, varient, errorStyle, language, classes }) => (
  <Box className={errorStyle}>
    {varient !== 'default' ? (
      <Icon
        data-testid="warning-icon"
        name="Warning"
        color={varient === 'secondary' ? 'secondary' : 'disabled'}
        fontSize={varient === 'inline' || varient === 'secondary' ? 'small' : 'large'}
        className={classes.errorText}
      />
    ) : null}
    <Typography
      data-testid="warning-message"
      variant={varient === 'default' ? 'subtitle2' : 'body2'}
      color={varient === 'secondary' ? 'secondary' : 'disabled'}
    >
      <FormattedMessage {...language.getText(message)} />
    </Typography>
  </Box>
);

ErrorCard.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  message: PropTypes.string.isRequired,
  varient: PropTypes.string.isRequired,
  errorStyle: PropTypes.object.isRequired
};

export default withStyles(styles)(inject('language')(observer(ErrorCard)));
