/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { shapes } from '@greenville/framework';
import { Button, IconButton, Menu, MenuItem, withStyles } from '@material-ui/core';
import { inject } from 'mobx-react';
import IconHotspotIcon from '@material-ui/icons/MenuOpenOutlined';
import { toJS } from 'mobx';
import * as constants from '../../../../common/constants';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import DialogConfig from '../../../../common/config/DialogConfig';
import UsersPermissions from '../../../users/models/UsersPermissions';

const styles = () => ({
  left: {
    marginLeft: '2px !important'
  },
  button: {
    borderRadius: 3,
    borderWidth: 2,
    padding: 1
  }
});

const IconHotspotMenu = ({ language, classes, handleClick, disableMenu, userPermissions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hotspotObject, sethotspotObject] = useState(DialogConfig.hotspotIconDropDown[0]);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (selected) => {
    if (selected) {
      sethotspotObject(selected);
    }
    setAnchorEl(null);
  };

  const handleSelectedClick = () => {
    handleClick(constants.ICON_HOTSPOT, hotspotObject);
  };

  return (
    <>
      <Button
        id="iconHotspotMenuButtonId"
        className={classes.button}
        disabled={disableMenu || hotspotObject.regionTypeId === DialogConfig.hotspotIconDropDown[0].regionTypeId}
        variant="outlined"
        size="small"
        onClick={() => handleSelectedClick()}
      >
        {hotspotObject.value}
      </Button>

      <TooltipCustom
        title={
          !toJS(userPermissions.permissionList.permissions.includes('authoring_can_edit')) ? (
            <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
          ) : (
            <FormattedMessage {...language.getText('tooltip.ICON_HOTSPOT')} />
          )
        }
      >
        <span>
          <IconButton
            id="iconHotspotMenuIconId"
            aria-label="Icon hotspot"
            aria-controls="iconHotspotMenuId"
            aria-haspopup="true"
            className={classes.left}
            disabled={disableMenu || !toJS(userPermissions.permissionList.permissions.includes('authoring_can_edit'))}
            onClick={handleMenuClick}
          >
            <IconHotspotIcon fontSize="large" color="action" />
          </IconButton>
        </span>
      </TooltipCustom>
      <Menu
        id="iconHotspotMenuId"
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => handleMenuClose()}
        PaperProps={{
          style: {
            maxHeight: 200,
            width: '20ch'
          }
        }}
      >
        {DialogConfig.hotspotIconDropDown.map((option) => (
          <MenuItem
            key={`icon_id_${option.regionTypeId}_${option.value}`}
            selected={option.regionTypeId === hotspotObject.regionTypeId}
            onClick={() => handleMenuClose(option)}
          >
            {option.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

IconHotspotMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  handleClick: PropTypes.func.isRequired,
  disableMenu: PropTypes.bool.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired
};

export default withStyles(styles)(inject('language', 'userPermissions')(IconHotspotMenu));
