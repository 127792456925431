/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Typography, Box, withStyles, Link } from '@material-ui/core';
import { shapes } from '@greenville/framework';
import env from './env';

const styles = () => ({
  rootBox: {
    margin: '0 52px 0 0',
    bottom: 0,
    height: '30px',
    position: 'fixed',
    width: '100%',
    '@media (max-width: 808px)': {
      height: '45px'
    },
    '@media (max-width: 450px)': {
      height: '60px'
    }
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    '@media (max-width: 808px)': {
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'unset'
    }
  },
  privacy: {
    margin: '0px 7px'
  },
  accessibility: {
    marginRight: 7
  },
  terms: {
    marginLeft: 7
  }
});

const Footer = ({ language, classes }) => (
  <footer className={classes.rootBox}>
    <Typography variant="subtitle2" className={classes.contentBox}>
      <FormattedMessage
        {...language.getText('footer.COPYRIGHT')}
        values={{
          year: new Date().getFullYear()
        }}
      />
      <Box component="span">
        <Link
          href="https://www.pearson.com/us/accessibility.html"
          color="inherit"
          target="_blank"
          rel="noopener"
          underline="always"
          classes={{ root: classes.accessibility }}
          aria-describedby="new-tab"
        >
          <Typography variant="subtitle2" component="span">
            <FormattedMessage {...language.getText('footer.ACCESSIBILITY')} />
          </Typography>
        </Link>
        <Box component="span">&#124;</Box>
        <Link
          href={`${env.CONSOLE_URL}/account/manage/privacy`}
          color="inherit"
          target="_blank"
          rel="noopener"
          underline="always"
          classes={{ root: classes.privacy }}
          aria-describedby="new-tab"
        >
          <Typography variant="subtitle2" component="span">
            <FormattedMessage {...language.getText('footer.PRIVACY')} />
          </Typography>
        </Link>
        <Box component="span">&#124;</Box>
        <Link
          href={`${env.CONSOLE_URL}/account/manage/tos`}
          color="inherit"
          target="_blank"
          rel="noopener"
          underline="always"
          classes={{ root: classes.terms }}
          aria-describedby="new-tab"
        >
          <Typography variant="subtitle2" component="span">
            <FormattedMessage {...language.getText('footer.TERMS_COND')} />
          </Typography>
        </Link>
      </Box>
    </Typography>
  </footer>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired
};

export default withStyles(styles)(inject('language')(observer(Footer)));
