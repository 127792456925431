/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import ListIcon from '@material-ui/icons/List';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import PropTypes from 'prop-types';
import BookGridItem from './BookGridItem';
import BookSearch from './BookSearch';
import BookListItem from './BookListItem';
import Loader from '../../../../common/Loader';
import Books from '../../models/Books';
import NoDataFound from '../../../../common/components/NoDataFound';
import Breadcrumbs from '../commonitems/Breadcrumbs';

const BookComponent = ({
  toggle,
  toggleView,
  bookSearch,
  bookFavorite,
  booksState,
  language,
  booksStatus,
  appliedFilter
}) => (
  <LoadingHandler
    loading={booksStatus.isPending}
    isError={booksStatus.isError && booksStatus.error && booksStatus.error.status === 401}
    errorContent={<StateErrorDisplay error={booksStatus.error} showDetails />}
    content={
      <>
        <Breadcrumbs data={<FormattedMessage {...language.getText('common.BOOKS')} />} />
        <br />
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <BookSearch bookSearch={bookSearch} appliedFilter={appliedFilter} />
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" onClick={toggleView}>
              {!toggle ? <AppsIcon fontSize="large" /> : <ListIcon fontSize="large" />}
            </IconButton>
          </Grid>
        </Grid>

        {(booksStatus.isError || booksState.bookList.length === 0) && <NoDataFound isError={booksStatus.isError} />}

        {!booksStatus.isError &&
          booksState.bookList.length > 0 &&
          (!toggle ? (
            <BookListItem data={booksState.bookList} />
          ) : (
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
              {booksState.bookList.map((bookInfo, index) => (
                <Grid item xs={12} sm={4} md={3} lg={3} xl={2} key={`${bookInfo}-${index}`}>
                  <BookGridItem data={bookInfo} bookFavorite={bookFavorite} />
                </Grid>
              ))}
            </Grid>
          ))}
      </>
    }
    loadingContent={<Loader />}
  />
);

BookComponent.propTypes = {
  toggle: PropTypes.bool,
  toggleView: PropTypes.func,
  bookSearch: PropTypes.func,
  bookFavorite: PropTypes.func,
  booksState: shapes.modelOf(Books).isRequired,
  booksStatus: shapes.state.isRequired,
  language: shapes.language.isRequired,
  appliedFilter: PropTypes.object
};

BookComponent.defaultProps = {
  toggle: false,
  toggleView: '',
  bookSearch: '',
  bookFavorite: '',
  appliedFilter: {}
};

export default inject('booksState', 'booksStatus', 'language')(observer(BookComponent));
