/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const styles = () => ({
  width: {
    width: '100%'
  },
  labelWidht: {
    width: '90%'
  },
  radioJustify: {
    justifyContent: 'space-between'
  }
});

const ParentInformation = ({ language, formFields, formErrorFields, handleChange, revisionProps }) => (
  <>
    <Grid container item xs={12} direction="row" justify="space-between">
      <Typography align="center" variant="h5">
        <FormattedMessage {...language.getText('eTextrequest.PARENT')} />
      </Typography>
    </Grid>
    <br />
    <Grid container item xs={12} direction="row" justify="space-between">
      <TextField
        label={<FormattedMessage {...language.getText('eTextrevision.PARENTISBN10')} />}
        required
        margin="dense"
        name="parentISBN10"
        error={formErrorFields.parentISBN10Error.length > 0}
        helperText={formErrorFields.parentISBN10Error}
        value={formFields.parentISBN10}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextrevision.PARENTISBN13')} />}
        required
        margin="dense"
        name="parentISBN13"
        error={formErrorFields.parentISBN13Error.length > 0}
        helperText={formErrorFields.parentISBN13Error}
        value={formFields.parentISBN13}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextconversion.TITLEEDITION')} />}
        required
        margin="dense"
        name="title"
        error={formErrorFields.titleError.length > 0}
        helperText={formErrorFields.titleError}
        value={formFields.title}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextconversion.AUTHORS')} />}
        margin="dense"
        required
        name="author"
        error={formErrorFields.authorError.length > 0}
        helperText={formErrorFields.authorError}
        value={formFields.author}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      {revisionProps.revisionBool ? (
        <TextField
          label={<FormattedMessage {...language.getText('eTextgeneral.CHARGEISBNREV')} />}
          margin="dense"
          name="chargeISBN"
          value={formFields.chargeISBN}
          onChange={($event) => handleChange($event)}
          variant="outlined"
        />
      ) : (
        <TextField
          label={<FormattedMessage {...language.getText('metadata.COPYRIGHTYEAR')} />}
          margin="dense"
          name="copyright"
          error={formErrorFields.copyrightError.length > 0}
          helperText={formErrorFields.copyrightError}
          value={formFields.copyright}
          onChange={($event) => handleChange($event)}
          variant="outlined"
        />
      )}
    </Grid>
  </>
);

ParentInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  data: PropTypes.string.isRequired,
  formFields: PropTypes.object.isRequired,
  revisionProps: PropTypes.object.isRequired,
  formErrorFields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(ParentInformation)));
