/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import PropTypes from 'prop-types';
import PdfViewer from './components/PdfViewer';
import Asset from './model/Asset';
import Loader from '../../common/Loader';
import Notification from '../../common/components/notification/Notification';
import MediaBasepaths from '../books/models/MediaBasepaths';
import InterfaceColors from '../books/models/InterfaceModel';
import ValidatePermissions from '../users/ValidatePermissions';

@inject('asset', 'tocStatus', 'basepath', 'mediaBasepathStatus', 'colorInterface')
@observer
class PdfViewerContainer extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    asset: shapes.modelOf(Asset).isRequired,
    tocStatus: shapes.state.isRequired,
    basepath: shapes.modelOf(MediaBasepaths).isRequired,
    mediaBasepathStatus: shapes.state.isRequired,
    colorInterface: shapes.modelOf(InterfaceColors).isRequired
  };

  componentDidMount() {
    const { asset, match, basepath, colorInterface } = this.props;
    colorInterface.fetch(match.params.bookid);
    asset.fetch(match.params.bookid);
    basepath.fetch(match.params.bookid);
  }

  render() {
    const { tocStatus, mediaBasepathStatus } = this.props;

    return (
      <ValidatePermissions givenPermissions="authoring_can_view" fallElse>
        <LoadingHandler
          loading={tocStatus.isPending || mediaBasepathStatus.isPending}
          isError={
            (tocStatus.isError && tocStatus.error && tocStatus.error.status === 401) ||
            (mediaBasepathStatus.isError && mediaBasepathStatus.error && mediaBasepathStatus.error.status === 401)
          }
          loadingContent={<Loader />}
          errorContent={<StateErrorDisplay error={tocStatus.error || mediaBasepathStatus.error} showDetails />}
          content={
            <>
              <Notification />
              <PdfViewer {...this.props} />
            </>
          }
        />
      </ValidatePermissions>
    );
  }
}

export default PdfViewerContainer;
