/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 */

const SubBusinessCode = types.model('SubBusinessCode', {
  businessUnitId: types.maybeNull(types.integer),
  subBusinessUnitId: types.maybeNull(types.integer),
  subBusinessUnitName: types.maybeNull(types.string)
});
const CmDirectoryCode = types.model('CmDirectoryCode', {
  subBusinessUnitId: types.maybeNull(types.integer),
  directoryId: types.maybeNull(types.integer),
  directoryName: types.maybeNull(types.string)
});

const TitleActions = types
  .model('TitleActions', {
    subBusiness: types.optional(types.array(SubBusinessCode), []),
    cmdirectory: types.optional(types.array(CmDirectoryCode), [])
  })

  .actions((self) => ({
    fetchSubBusiness() {
      Framework.getEventManager().publish(constants.CM_TITLE_SUB_BUSINESS_CODE);
    },
    fetchDirectory() {
      Framework.getEventManager().publish(constants.CM_TITLE_DIRECTORY);
    },

    setSubBusiness(response) {
      applySnapshot(self.subBusiness, response.data);
    },
    setDirectory(response) {
      applySnapshot(self.cmdirectory, response.data);
    },
    savePayload(payload) {
      Framework.getEventManager().publish(constants.CM_TITLEACTION_POST, { payload });
    },
    saveBackupPayload(payload) {
      Framework.getEventManager().publish(constants.CM_TITLEACTION_POST_BACKUP, { payload });
    },
    saveUploadPayload(payload) {
      Framework.getEventManager().publish(constants.CM_TITLEACTION_POST_UPLOAD, { payload });
    },
    saveExportPayload(payload) {
      Framework.getEventManager().publish(constants.CM_TITLEACTION_EXPORT_EXCEL, { payload });
    },
    saveUPDFPayload(payload) {
      Framework.getEventManager().publish(constants.CM_TITLEACTION_UPDF, { payload });
    },
    verifyParentId(payload) {
      Framework.getEventManager().publish(constants.CM_VERIFY_PARENT_ID, { payload });
    }
  }));

export default TitleActions;
