import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const allRoles = types.model('AllRoles', {
  role: types.maybeNull(types.string),
  roleTypeId: types.maybeNull(types.number)
});

const Roles = types
  .model('Roles', {
    usersRoles: types.optional(types.array(allRoles), [])
  })
  .actions((self) => ({
    fetch() {
      Framework.getEventManager().publish(constants.GET_USER_ROLES, {});
    },
    set(response) {
      applySnapshot(self.usersRoles, response.data);
    }
  }));

export default Roles;
