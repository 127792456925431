import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class AllUserRoleService extends BaseService {
  constructor() {
    super(constants.GET_USER_ROLES, 'userRoleStatus');
  }

  handleEvent() {
    return this.getClient('evergreen').get('/users/roles');
  }

  handleResponse(response) {
    // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('userRoles').set(response.data);
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
    }
  }
}
