const status = [
  {
    id: 0,
    value: 'Not Started'
  },
  {
    id: 1,
    value: 'In Progress'
  },
  {
    id: 2,
    value: 'Success'
  },
  {
    id: 3,
    value: 'Failed'
  },
  {
    id: 4,
    value: 'Not In Queue'
  },
  {
    id: 5,
    value: 'Cancelled'
  }
];

const ServerSideConfig = {
  status
};

module.exports = ServerSideConfig;
