/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../../../common/constants';
import EntitlementConfig from '../config/EntitlementConfig';

/**
 * Mobx State Tree Store
 */
const BookModuleObj = types.model('BokModuleObj', {
  productID: types.maybeNull(types.string),
  isPDFPlayer: types.maybeNull(types.string),
  isActive: types.maybeNull(types.string),
  isAssetsDownloadable: types.maybeNull(types.string),
  isDownloadable: types.maybeNull(types.string),
  isPrimary: types.maybeNull(types.string),
  isMobile: types.maybeNull(types.string),
  isAndroid: types.maybeNull(types.string),
  noOfDownloadbleChapters: types.maybeNull(types.integer),
  url: types.maybeNull(types.string),
  bookID: types.maybeNull(types.integer),
  purchaseType: types.maybeNull(types.integer),
  isNoLimit: types.maybeNull(types.string),
  siteID: types.maybeNull(types.integer),
  moduleID: types.maybeNull(types.string),
  scenarioID: types.maybeNull(types.integer)
});

const RowObj = types.model('RowObj', {
  scenarioID: types.maybeNull(types.integer),
  scenarioLabel: types.maybeNull(types.integer),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  moduleList: types.maybeNull(types.array(BookModuleObj), [])
});

const EntitlementObj = types.model('EntitlementObj', {
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  headers: types.maybeNull(types.array(types.string, '')),
  rows: types.maybeNull(types.array(RowObj), []),
  color: types.maybeNull(types.string)
});

const Entitlement = types
  .model('Entitlement', {
    statusID: types.maybeNull(types.integer),
    datePublishedToViewer: types.maybeNull(types.string),
    isDLSupported: types.maybeNull(types.string),
    isPDFSupported: types.maybeNull(types.string),
    publishedToViewer: types.maybeNull(types.string),
    isIpadSupported: types.maybeNull(types.string),
    totalNoOfChapters: types.maybeNull(types.integer),
    defaultDownloadableChapters: types.maybeNull(types.integer),
    bookModuleList: types.maybeNull(types.array(BookModuleObj), []),
    bookModuleListConfig: types.maybeNull(types.array(EntitlementObj), [])
  })
  .actions((self) => ({
    /* eslint-disable no-param-reassign */
    fetch(bookID) {
      Framework.getEventManager().publish(constants.MODULE_LIST_FETCHED, { bookID });
    },
    saveAndPublish(payload) {
      Framework.getEventManager().publish(constants.MODULE_LIST_FETCHED, { payload });
    },
    // eslint-disable-next-line no-unused-vars
    set(entitlement) {
      entitlement.bookModuleListConfig = JSON.parse(JSON.stringify(EntitlementConfig.entitlementList));
      entitlement.bookModuleListConfig.forEach((parent) => {
        parent.rows.forEach((child) => {
          entitlement.bookModuleList.forEach((res) => {
            if (child.scenarioID === res.scenarioID) {
              child.moduleList.push(res);
            }
          });
        });
      });
      applySnapshot(self, entitlement);
    },
    setSavePublish(response) {
      if (response.saveStatus) {
        if (response.bookModuleList) {
          const updatedSelf = JSON.parse(JSON.stringify(toJS(self)));
          updatedSelf.bookModuleList = response.bookModuleList;
          updatedSelf.bookModuleListConfig = JSON.parse(JSON.stringify(EntitlementConfig.entitlementList));
          updatedSelf.bookModuleListConfig.forEach((parent) => {
            parent.rows.forEach((child) => {
              response.bookModuleList.forEach((res) => {
                if (child.scenarioID === res.scenarioID) {
                  child.moduleList.push(res);
                }
              });
            });
          });
          applySnapshot(self, updatedSelf);
        } else {
          applySnapshot(self, self);
        }
      } else if (response.publishStatus) {
        applySnapshot(self, self);
      } else {
        applySnapshot(self, self);
      }
    }
  }));

export default Entitlement;
