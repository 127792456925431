/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 */

const RequestQueueSearchResult = types.model('RequestQueueSearchResult', {
  requestNo: types.maybeNull(types.integer),
  globalBookId: types.maybeNull(types.string),
  bookId: types.maybeNull(types.integer),
  title: types.maybeNull(types.string),
  ebookISBN10: types.maybeNull(types.string),
  ebookISBN13: types.maybeNull(types.string),
  author: types.maybeNull(types.string),
  originalRequestDate: types.maybeNull(types.string),
  revisionNo: types.maybeNull(types.integer),
  requestedBy: types.maybeNull(types.string),
  status: types.maybeNull(types.string)
});

const RequestQueueSearchResp = types.model('RequestQueueSearchResp', {
  totalCount: types.maybeNull(types.integer),
  results: types.optional(types.array(RequestQueueSearchResult), [])
});

const Request = types
  .model('Request', {
    requestQueueSearch: types.optional(RequestQueueSearchResp, {})
  })
  .actions((self) => ({
    fetchRequestQueueSearch(payload) {
      Framework.getEventManager().publish(constants.ETEXT_DB_REQUEST_QUEUE_SEARCH, { payload });
    },
    setRequestQueueSearch(response) {
      const output = self.requestQueueSearch;
      output.results =
        response && response.data && response.data.results && response.data.results.length > 0
          ? response.data.results.map((eachValue, index) => ({
              id: index + 1,
              ...eachValue
            }))
          : [];
      output.totalCount = response.data.totalCount;
      applySnapshot(self.requestQueueSearch, output);
    }
  }));

export default Request;
