/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../../../common/constants';

export default class MediaBasepathSaveservice extends BaseService {
  constructor() {
    super(constants.MEDIA_BASEPATHS_POST, 'mediaBasepathPostStatus');
  }

  handleEvent(eventName, event) {
    if (event.payload) {
      const mappedData = this.mapPayload(event.payload.data);
      return this.getClient('evergreen').post(`books/${event.payload.id}/regions/basepaths`, mappedData);
    }
    return null;
  }

  async handleResponse(response, event) {
    // eslint-disable-line class-methods-use-this
    await Framework.redirectTo(`/books/${event.payload.id}`);
    const notification = {
      open: true,
      message: 'Mediabasepath data saved successfully!',
      type: 'info'
    };
    Framework.getStoreRegistry().getStore('notificationState').set(notification);
  }

  handleError(error, event) {
    // eslint-disable-line class-methods-use-this
    Framework.redirectTo(`/books/${event.payload.id}`);
  }

  mapPayload(payload) {
    return {
      swfFilePath: null,
      bookLinkTypeLocationList: this.mapBasepathList(toJS(payload))
    };
  }

  mapBasepathList = (inputPayload) => {
    const basepathList = [];
    if (inputPayload && inputPayload.length > 0) {
      for (let i = 0; i < inputPayload.length; i += 1) {
        basepathList.push({
          LOCATION: inputPayload[i].basepath,
          LINK_TYPE_ID: inputPayload[i].linkTypeID,
          TYPE: inputPayload[i].type,
          UNIQUE_ID: inputPayload[i].uniqueID,
          BOOK_ID: inputPayload[i].bookID
        });
      }
    }
    return basepathList;
  };
}
