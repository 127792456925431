/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
/**
 * Event definitions
 */

export const FILTER = 'Filter';
export const USER_CARD = 'User card';
export const USER_SIGNOUT = 'Signout';
export const USER_LOGO_REDIRECT = 'Redirect';
export const USER_LOGO_BACK_CONSOLE = 'Back to console';
export const USER_ACCOUNT = 'Click on account settings';
