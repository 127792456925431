import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/* eslint-disable */
const data = types.model('data', {
  bookId: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string)
});

const typeString = types.model('typeString', {
  S: types.maybeNull(types.string)
});
const lastEvaluvatedKey = types.model('lastEvaluvatedKey', {
  bookId: types.maybeNull(typeString),
  serverDateTime: types.maybeNull(typeString),
  type: types.maybeNull(typeString),
  feedbackId: types.maybeNull(typeString),
  userId: types.maybeNull(typeString)
});

const ExclusiveStartKey = types.model('ExclusiveStartKey', {
  ExclusiveStartKey: types.optional(lastEvaluvatedKey, {})
});

const ChatgptUtility = types
  .model('ChatgptUtility', {
    data: types.optional(types.array(data), []),
    isNextPageKey: types.optional(ExclusiveStartKey, {}),
    isGetAllBookData: types.optional(types.boolean, false),
    explainNextPageKey: types.optional(ExclusiveStartKey, {}),
    problemSolveNextPageKey: types.optional(ExclusiveStartKey, {}),
    quizNextPageKey: types.optional(ExclusiveStartKey, {}),
    summaryNextPageKey: types.optional(ExclusiveStartKey, {}),
    listOfType: types.optional(types.array(types.string), [])
  })
  .actions((self) => ({
    fetch(payload) {
      if (self.isGetAllBookData) {
        this.setListOfType(payload.type);
      }
      Framework.getEventManager().publish(constants.GET_CHATGPT_DATA, {
        payload
      });
    },
    resetPageNextKey() {
      self.explainNextPageKey = {};
      self.problemSolveNextPageKey = {};
      self.quizNextPageKey = {};
      self.summaryNextPageKey = {};
      self.listOfType = [];
    },
    setAllBookSearch(bookSearch) {
      let boolValue = false;
      if (bookSearch.allBookSearch) {
        boolValue = true;
      }
      self.isGetAllBookData = boolValue;
    },
    setListOfType(type) {
      self.listOfType.push(type);
    },
    setResponse(response) {
      if (self.isGetAllBookData && response.data && response.data.data) {
        const { isNextPageKey } = response.data;
        const firstObject = response.data.data[0];
        switch (firstObject?.type) {
          case constants.SUMMARY_TYPE:
            applySnapshot(self.summaryNextPageKey, isNextPageKey);
            break;
          case constants.QUIZ_MCQ_TYPE:
            applySnapshot(self.quizNextPageKey, isNextPageKey);
            break;
          case constants.PROBLEM_SOLVE:
            applySnapshot(self.problemSolveNextPageKey, isNextPageKey);
            break;
          case constants.EXPLAIN_TYPE:
            applySnapshot(self.explainNextPageKey, isNextPageKey);
            break;
          default:
        }
      } else {
        self.isNextPageKey = response.data.isNextPageKey;
        self.listOfType = [];
      }
      applySnapshot(self.data, response.data.data);
      Framework.getEventManager().publish(constants.GPT_RESPONSE);
    },
    getAllBookStatus() {
      return self.isGetAllBookData;
    },
    resetStoreValues() {
      if (!self.isGetAllBookData) {
        const initialState = [];
        applySnapshot(self.data, initialState);
        applySnapshot(self.isNextPageKey, {});
      }
      Framework.getEventManager().publish(constants.GPT_RESPONSE);
    }
  }));

export default ChatgptUtility;
