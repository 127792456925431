/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
import { Route, Redirect } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Header from '../../common/header/Header';
import Dashboard from './components/Dashboard';

const styles = () => ({
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80
  }
});

@inject()
@observer
class DashboardContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header />
        <main className={classes.contentWrapper}>
          <Route exact path={['/', '/dashboard/']}>
            <AuthenticatedTemplate>
              <Redirect to="/dashboard" />
            </AuthenticatedTemplate>
          </Route>
          <Route path="/dashboard" exact render={() => <Dashboard {...this.props} />} />
        </main>
      </>
    );
  }
}

export default withStyles(styles)(DashboardContainer);
