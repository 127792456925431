/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { Typography, Paper, Box, Button } from '@material-ui/core';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { FormattedMessage } from 'react-intl';
import BackupIcon from '@material-ui/icons/Backup';
// import DescriptionIcon from '@material-ui/icons/Description';
// import OfflineShareIcon from '@mui/icons-material/OfflineShare';
// import AlarmIcon from '@material-ui/icons/Alarm';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ImportExportIcon from '@material-ui/icons/ImportExport';
// import WebAssetIcon from '@material-ui/icons/WebAsset';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
// import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PublishIcon from '@material-ui/icons/Publish';
// import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/Confirmation';
import IdentifierSearch from '../common/IdentifierSearch';
import UploadSourceFile from '../common/UploadSourceFile';
// import OfflineShareIcon from '@material-ui/icons/OfflineShareIcon';
// import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import UploadContent from '../upload/UploadContent';
import ManageInfo from '../info/ManageInfo';
import ManifestInfo from '../info/ManifestInfo';
import ManageDerivative from '../info/ManageDerivative';
import HistoryDialog from '../workflow/HistoryDialog';
import eTextConfig from '../../../../common/config/eTextConfig';
import CmMetadata from '../../models/CmMetadata';
import Workflow from '../../models/Workflow';
import TitleActions from '../../models/TitleActions';
import Loader from '../../../../common/Loader';
import * as constants from '../../../../common/constants';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/Confirmation';
import Upload from '../../models/Upload';
import UsersPermissions from '../../../users/models/UsersPermissions';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 1)
  },
  pointer: {
    cursor: 'pointer'
  },
  displayFlex: {
    display: 'flex',
    marginTop: '2%'
  },
  flexColumnCenter: {
    flexDirection: 'column',
    textAlign: 'center'
  },
  clickableLink: {
    cursor: 'pointer',
    color: '#0000EE',
    textAlign: 'center',
    fontSize: 12,
    textDecoration: 'underline'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  },
  iconPosition: {
    position: 'relative'
  },
  posIcon: {
    position: 'absolute',
    right: '0',
    cursor: 'pointer'
  },
  btnClass: {
    position: 'absolute',
    bottom: '15%',
    right: '2%'
  },
  gridClass: {
    position: 'relative'
  }
});

/* const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#000',
    boxShadow: theme.shadows[2],
    fontSize: 14,
    padding: '8px'
  }
}))(Tooltip); */

@inject(
  'workflow',
  'cmmetaData',
  'upload',
  'titleActionData',
  'cmSubbusinessStatus',
  'cmDirectoryStatus',
  'getCreateStatus',
  'getCreateBuketFoldersStatus',
  'userPermissions'
)
@observer

// eslint-disable-next-line react/prefer-stateless-function
class TitleAction extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    workflow: shapes.modelOf(Workflow).isRequired,
    workflowHistoryStatus: shapes.state.isRequired,
    match: PropTypes.object.isRequired,
    cmmetaData: shapes.modelOf(CmMetadata).isRequired,
    titleActionData: shapes.modelOf(TitleActions).isRequired,
    upload: shapes.modelOf(Upload).isRequired,
    savePayload: PropTypes.func.isRequired,
    saveBackupPayload: PropTypes.func.isRequired,
    saveUploadPayload: PropTypes.func.isRequired,
    saveExportPayload: PropTypes.func.isRequired,
    saveUPDFPayload: PropTypes.func.isRequired,
    verifyParentId: PropTypes.func.isRequired,
    cmSubbusinessStatus: shapes.state.isRequired,
    cmDirectoryStatus: shapes.state.isRequired,
    getCreateStatus: shapes.state.isRequired,
    getCreateBuketFoldersStatus: shapes.state.isRequired,
    radioTitle: PropTypes.object.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      openUploadContent: false,
      open: false,
      openhistory: false,
      openManifest: false,
      openDerivative: false,
      rowData: {},
      showFile: false,
      chooseFileData: '',
      showuPDF: true,
      uploadText: 'Upload your batch spreadsheet for uPDF creation',
      selectedFileValue: '',
      chooseFileHandleStatus: true,
      tabUrl: '',
      uploadContentPath: '',
      businessUnitId: 1,
      derivativeBusinessUnitId: 1,
      subBusinessUnitId: null,
      derivativeSubBusinessUnitId: null,
      directoryId: null,
      derivativeDirectoryId: null,
      commonSubBusinessUnit: false,
      commonDirectory: false,
      commonIdentifier: false,
      openConfirmationDialog: false,
      eBookData: {
        contentPath: '',
        identifier: '',
        businessUnit: eTextConfig.businessUnit.find((data) => data.id === 1).value,
        subBusinessUnit: '',
        directory: '',
        workflow: constants.SIMPLE_EBOOK_WORKFLOW
      },
      eBookDataDerivative: {
        contentPath: '',
        // identifier: '',
        businessUnit: eTextConfig.businessUnit.find((data) => data.id === 1).value,
        subBusinessUnit: '',
        directory: '',
        workflow: constants.SIMPLE_EBOOK_WORKFLOW
      },
      derivativeFields: {
        parentEbook: '',
        packageIdentifier: '',
        subBusinessDropdown: '',
        subBusinessDropdownBool: false,
        derivativeBool: false
      },
      derivativeFieldsError: {
        parentEbookError: '',
        packageIdentifierError: ''
      }
    };
    this.handleOpenUploadContent = this.handleOpenUploadContent.bind(this);
    this.closeUploadContent = this.closeUploadContent.bind(this);
    this.chooseFileHandle = this.chooseFileHandle.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    const { match, cmmetaData, titleActionData } = this.props;
    this.setState({
      tabUrl: match.path.split('/').pop()
    });

    if (cmmetaData.subBusiness.length === 0) {
      cmmetaData.fetchSubBusiness();
    }
    if (cmmetaData.cmdirectory.length === 0) {
      cmmetaData.fetchDirectory();
    }
    if (titleActionData.subBusiness.length === 0) {
      titleActionData.fetchSubBusiness();
    }
    if (titleActionData.cmdirectory.length === 0) {
      titleActionData.fetchDirectory();
    }

    if (cmmetaData.subBusiness.length > 0 && cmmetaData.cmdirectory.length > 0) {
      this.radioButtonData();
    }
    Framework.getEventManager().on(constants.CM_INGEST_UNITS, () => {
      if (cmmetaData.subBusiness.length > 0 && cmmetaData.cmdirectory.length > 0) {
        this.radioButtonData();
      }
    });
  }

  radioButtonData = () => {
    const { radioTitle, cmmetaData } = this.props;
    const { eBookData } = this.state;
    const radioData = {};
    if (radioTitle.identifier) {
      if (cmmetaData.subBusiness.length > 0 && cmmetaData.cmdirectory.length > 0) {
        radioData.identifier = radioTitle.identifier;
        radioData.businessUnit = radioTitle.businessUnitId;
        const businessUnitDetail = cmmetaData.subBusiness.filter(
          (data) => data.businessUnitId === radioTitle.businessUnitId
        );
        radioData.subBusinessUnit = businessUnitDetail.find(
          (data) => data.subBusinessUnitName.toLowerCase() === radioTitle.subBusinessUnit.toLowerCase()
        ).subBusinessUnitId;
        const directoryDetail = cmmetaData.cmdirectory.filter(
          (data) => data.subBusinessUnitId === radioData.subBusinessUnit
        );
        radioData.directory = directoryDetail.find(
          (data) => data.directoryName.toLowerCase() === radioTitle.directory.toLowerCase()
        ).directoryId;
        this.setState({
          eBookData: {
            ...eBookData,
            identifier: radioTitle.identifier,
            businessUnit: radioTitle.businessUnit.toLowerCase(),
            subBusinessUnit: radioTitle.subBusinessUnit.toLowerCase(),
            directory: radioTitle.directory.toLowerCase(),
            workflow: radioTitle.workflowName,
            contentPath: ''
          },
          directoryId: radioData.directory,
          subBusinessUnitId: radioData.subBusinessUnit,
          businessUnitId: radioData.businessUnit
        });
      }
    }
  };

  handleOpenUploadContent = () => {
    this.setState({ openUploadContent: true });
    Framework.getEventManager().on(constants.CM_BUCKET_CLOSE, () => {
      this.setState({ openUploadContent: false });
    });
    Framework.getEventManager().on(constants.CM_CREATE_NEW_BUCKET, () => {
      this.setState({ openUploadContent: false, openConfirmationDialog: true });
    });
  };

  handleConfirm = (confirmResponse) => {
    const { upload } = this.props;
    const { uploadContentPath } = this.state;
    this.setState({ openConfirmationDialog: false });
    if (confirmResponse) {
      upload.createBucketFolders({ directory: uploadContentPath, isBulkUtility: false });
    }
  };

  handleRadioButton = (selectedRadioButton) => {
    const { eBookData, eBookDataDerivative } = this.state;
    this.setState({
      eBookData: { ...eBookData, workflow: selectedRadioButton },
      eBookDataDerivative: { ...eBookDataDerivative, workflow: selectedRadioButton }
    });
  };

  createContentPath = (eBookData) => {
    const workflowFolder =
      eBookData.workflow === constants.SIMPLE_EBOOK_WORKFLOW
        ? constants.SIMPLE_EBOOK_FOLDER
        : constants.STANDARD_EBOOK_FOLDER;
    let contentPath = `${workflowFolder}${constants.SEPARATOR}${eBookData.businessUnit}${constants.SEPARATOR}`;
    contentPath = `${contentPath}${eBookData.subBusinessUnit}${constants.SEPARATOR}${eBookData.directory}`;
    return contentPath.toLowerCase();
  };

  handleSearch = () => {
    const { eBookData } = this.state;
    const data = eBookData;
    let uploadContentPathUrl = this.createContentPath(data);
    uploadContentPathUrl += `${constants.SEPARATOR}${data.identifier}${constants.SEPARATOR}`;
    this.setState({
      eBookData: {
        ...eBookData,
        contentPath: this.createContentPath(data)
      },
      uploadContentPath: uploadContentPathUrl
    });
  };

  closeUploadContent = () => {
    this.setState({
      openUploadContent: false
    });
  };

  chooseFileHandle = (childdata) => {
    if (childdata) {
      this.setState({
        chooseFileData: childdata,
        uploadText: (
          <Typography>
            {' '}
            Below file is selected for uPDF creation, click <b>Create uPDF to continue</b> OR{' '}
            <b>Upload your batch spreadsheet for uPDF creation</b>
          </Typography>
        ),
        selectedFileValue: childdata
      });
    }
  };

  // handleOpenInfo = () => {
  //   const { open } = this.state;
  //   this.setState({
  //     open: !open,
  //     showFile: false
  //   });
  // };

  handleManageClose = () => {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  };

  // handleTitleHistory = () => {
  //   const { openhistory } = this.state;
  //   this.setState({
  //     openhistory: !openhistory
  //   });
  // };

  handleHistoryClose = () => {
    const { openhistory } = this.state;
    this.setState({
      openhistory: !openhistory
    });
  };

  // handleManifestInfo = () => {
  //   const { openManifest } = this.state;
  //   this.setState({
  //     openManifest: !openManifest,
  //     showFile: false
  //   });
  // };

  handleManifestClose = () => {
    const { openManifest } = this.state;
    this.setState({
      openManifest: !openManifest
    });
  };

  handleManageDerivative = () => {
    const { openDerivative } = this.state;
    this.setState({
      openDerivative: !openDerivative
    });
  };

  handleUpdf = () => {
    const { eBookData } = this.state;
    this.commonValidate();
    delete eBookData.contentPath;
    if (eBookData.subBusinessUnit.length === 0 && eBookData.directory.length === 0) {
      this.setState({
        commonSubBusinessUnit: true,
        commonDirectory: true
      });
    }
    if (eBookData.identifier.length > 0 && eBookData.directory.length > 0 && eBookData.subBusinessUnit.length > 0) {
      // this.setState({ showFile: true });
      const payload = {
        data: eBookData
      };
      const {
        titleActionData: { saveUPDFPayload }
      } = this.props;
      saveUPDFPayload(payload);
    }
  };

  commonValidate = () => {
    const { eBookData, commonSubBusinessUnit, commonDirectory, commonIdentifier } = this.state;
    if (eBookData.subBusinessUnit.length === 0) {
      this.setState({
        commonSubBusinessUnit: !commonSubBusinessUnit
      });
    }
    if (eBookData.directory.length === 0) {
      this.setState({
        commonDirectory: !commonDirectory
      });
    }
    if (eBookData.identifier.length === 0 && commonIdentifier === false) {
      this.setState({
        commonIdentifier: !commonIdentifier
      });
    }
  };

  handleExport = () => {
    const { eBookData } = this.state;

    this.commonValidate();
    delete eBookData.contentPath;
    if (eBookData.subBusinessUnit.length === 0 && eBookData.directory.length === 0) {
      this.setState({
        commonSubBusinessUnit: true,
        commonDirectory: true
      });
    }
    if (eBookData.identifier.length > 0 && eBookData.directory.length > 0 && eBookData.subBusinessUnit.length > 0) {
      const payload = {
        data: eBookData
      };
      const {
        titleActionData: { saveExportPayload }
      } = this.props;
      saveExportPayload(payload);
    }
  };

  handlePublish = () => {
    const { eBookData } = this.state;
    this.commonValidate();
    delete eBookData.contentPath;
    if (eBookData.subBusinessUnit.length === 0 && eBookData.directory.length === 0) {
      this.setState({
        commonSubBusinessUnit: true,
        commonDirectory: true
      });
    }
    if (eBookData.identifier.length > 0 && eBookData.directory.length > 0 && eBookData.subBusinessUnit.length > 0) {
      const payload = {
        data: eBookData
      };
      const {
        titleActionData: { saveUploadPayload }
      } = this.props;
      saveUploadPayload(payload);
    }
  };

  handleBackup = () => {
    const { eBookData } = this.state;
    this.commonValidate();
    delete eBookData.contentPath;
    const payload = {
      data: eBookData
    };
    if (eBookData.subBusinessUnit.length === 0 && eBookData.directory.length === 0) {
      this.setState({
        commonSubBusinessUnit: true,
        commonDirectory: true
      });
    }

    if (eBookData.subBusinessUnit.length > 0 && eBookData.directory.length > 0 && eBookData.identifier.length > 0) {
      const {
        titleActionData: { saveBackupPayload }
      } = this.props;
      saveBackupPayload(payload);
    }
  };

  handleVerify = () => {
    const { derivativeFields, derivativeFieldsError } = this.state;
    const temp = derivativeFields.parentEbook;
    if (derivativeFields.parentEbook.length === 0) {
      const tempBool = derivativeFieldsError;
      tempBool.parentEbookError = 'Required';
      this.setState({
        derivativeFieldsError: tempBool
      });
    }

    if (derivativeFields.parentEbook.length > 0) {
      const {
        titleActionData: { verifyParentId }
      } = this.props;
      verifyParentId(temp);
    }
  };

  handleDerivativeChange = () => {
    const { derivativeFields, eBookDataDerivative } = this.state;
    const temp = derivativeFields;
    delete eBookDataDerivative.contentPath;

    if (eBookDataDerivative.subBusinessUnit.length === 0 && eBookDataDerivative.directory.length === 0) {
      temp.subBusinessDropdownBool = true;
      temp.derivativeBool = true;
    }
    this.setState({
      derivativeFields: temp
    });

    this.validateSave(temp);
    // const derivativeContentPath = this.createContentPath(eBookDataDerivative);
    const tempObj = {
      ...eBookDataDerivative,
      // contentPath: derivativeContentPath,
      identifier: temp.packageIdentifier,
      globalBookId: temp.parentEbook
    };

    if (
      derivativeFields.packageIdentifier.length > 0 &&
      derivativeFields.parentEbook.length > 0 &&
      eBookDataDerivative.subBusinessUnit.length > 0 &&
      eBookDataDerivative.directory.length > 0
    ) {
      const payload = {
        data: tempObj
      };
      const {
        titleActionData: { savePayload }
      } = this.props;
      savePayload(payload);
    }
  };

  // verifyParentId

  handleChange = (event) => {
    const { derivativeFields, derivativeFieldsError } = this.state;
    const temp = derivativeFields;
    const temperror = derivativeFieldsError;

    temp[event.target.name] = event.target.value;
    temperror[`${event.target.name}Error`] = '';
    this.setState({
      derivativeFields: temp,
      derivativeFieldsError: temperror
    });
  };

  validateRequired = (value) => {
    const { derivativeFields } = this.state;
    return (
      derivativeFields[value] === null ||
      derivativeFields[value].length === 0 ||
      derivativeFields[value].length === '' ||
      derivativeFields[value].length === '' ||
      derivativeFields[value] === 0 ||
      derivativeFields[value] === '0' ||
      derivativeFields[value] === '' ||
      derivativeFields[value] === ''
    );
  };

  validateSave = (requiredArr) => {
    let bool = true;
    let required = false;
    Object.keys(requiredArr).forEach((field) => {
      required = this.validateRequired(field);
      if (required) {
        bool = false;
        const { derivativeFieldsError } = this.state;
        const temp = derivativeFieldsError;
        temp[`${field}Error`] = 'Required';
        this.setState({
          derivativeFieldsError: temp
        });
      }
    });
    return bool;
  };

  handleSelectionChange = (event, value) => {
    const { eBookData, eBookDataDerivative, derivativeFields } = this.state;
    const { cmmetaData, titleActionData } = this.props;
    const temp = derivativeFields;
    if (value === 'businessUnit') {
      this.setState({
        eBookData: {
          ...eBookData,
          businessUnit: eTextConfig.businessUnit.find((data) => data.id === event.target.value).value.toLowerCase(),
          subBusinessUnit: '',
          directory: '',
          contentPath: ''
        },
        businessUnitId: event.target.value,
        subBusinessUnitId: null,
        directoryId: null
      });
    } else if (value === 'derivativeBusinessUnit') {
      temp.subBusinessDropdownBool = false;
      this.setState({
        eBookDataDerivative: {
          ...eBookDataDerivative,
          businessUnit: eTextConfig.businessUnit.find((data) => data.id === event.target.value).value.toLowerCase(),
          subBusinessUnit: '',
          directory: '',
          contentPath: ''
        },
        derivativeBusinessUnitId: event.target.value,
        derivativeFields: temp,
        derivativeSubBusinessUnitId: null,
        derivativeDirectoryId: null
      });
    } else if (value === 'cmSubbusiness') {
      temp.subBusinessDropdownBool = false;
      this.setState({
        eBookData: {
          ...eBookData,
          subBusinessUnit: cmmetaData.subBusiness
            .find((data) => data.subBusinessUnitId === event.target.value)
            .subBusinessUnitName.toLowerCase(),
          directory: '',
          contentPath: ''
        },
        commonSubBusinessUnit: false,
        derivativeFields: temp,
        subBusinessUnitId: event.target.value,
        directoryId: null
      });
    } else if (value === 'derivativeSubbusiness') {
      temp.subBusinessDropdownBool = false;
      this.setState({
        eBookDataDerivative: {
          ...eBookDataDerivative,
          subBusinessUnit: titleActionData.subBusiness
            .find((data) => data.subBusinessUnitId === event.target.value)
            .subBusinessUnitName.toLowerCase(),
          directory: '',
          contentPath: ''
        },
        derivativeFields: temp,
        derivativeSubBusinessUnitId: event.target.value,
        derivativeDirectoryId: null
      });
    } else if (value === 'cmDirectory') {
      temp.derivativeBool = false;
      this.setState({
        eBookData: {
          ...eBookData,
          directory: cmmetaData.cmdirectory.find((data) => data.directoryId === event.target.value).directoryName,
          contentPath: ''
        },
        directoryId: event.target.value,
        commonDirectory: false
      });
    } else if (value === 'derivativeDirectory') {
      temp.derivativeBool = false;
      this.setState({
        eBookDataDerivative: {
          ...eBookDataDerivative,
          directory: titleActionData.cmdirectory.find((data) => data.directoryId === event.target.value).directoryName,
          contentPath: ''
        },
        derivativeFields: temp,
        derivativeDirectoryId: event.target.value
      });
    } else if (value === 'identifier') {
      this.setState({
        eBookData: {
          ...eBookData,
          identifier: event.target.value,
          contentPath: ''
        },
        commonIdentifier: false
      });
    } else {
      // temp.packageIdentifier = event.target.value;
      this.setState({
        eBookDataDerivative: {
          ...eBookDataDerivative,
          identifier: event.target.value,
          contentPath: '',
          workflow: event.target.value
        },
        derivativeFields: temp
      });
    }
  };

  render() {
    const {
      classes,
      language,
      workflowHistoryStatus,
      cmmetaData,
      titleActionData,
      cmSubbusinessStatus,
      cmDirectoryStatus,
      getCreateStatus,
      getCreateBuketFoldersStatus,
      userPermissions
    } = this.props;
    const {
      openUploadContent,
      open,
      openhistory,
      openManifest,
      openDerivative,
      rowData,
      showFile,
      selectedFileValue,
      chooseFileData,
      showuPDF,
      uploadText,
      tabUrl,
      uploadContentPath,
      chooseFileHandleStatus,
      eBookData,
      businessUnitId,
      derivativeBusinessUnitId,
      subBusinessUnitId,
      derivativeSubBusinessUnitId,
      directoryId,
      derivativeDirectoryId,
      derivativeFields,
      derivativeFieldsError,
      commonSubBusinessUnit,
      commonDirectory,
      openConfirmationDialog,
      commonIdentifier
    } = this.state;

    if (
      eBookData.identifier &&
      eBookData.businessUnit &&
      eBookData.subBusinessUnit &&
      eBookData.directory &&
      (!eBookData.contentPath || !uploadContentPath)
    ) {
      this.handleSearch();
    }
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    return (
      <>
        {openConfirmationDialog && (
          <ConfirmationDialog
            message={{ ...language.getText('upload.CREATE_FOLDER_CONFIRMATION') }.defaultMessage}
            open
            callback={this.handleConfirm}
          />
        )}
        <LoadingHandler
          loading={
            (cmSubbusinessStatus.isPending && cmDirectoryStatus.isPending) ||
            getCreateStatus.isPending ||
            getCreateBuketFoldersStatus.isPending
          }
          isError={
            (cmSubbusinessStatus.isError &&
              cmSubbusinessStatus.error &&
              cmSubbusinessStatus.error.status === 401 &&
              cmDirectoryStatus.isError &&
              cmDirectoryStatus.error &&
              cmDirectoryStatus.error.status === 401) ||
            (getCreateStatus.isError && getCreateStatus.error && getCreateStatus.error.status === 401) ||
            (getCreateBuketFoldersStatus.isError &&
              getCreateBuketFoldersStatus.error &&
              getCreateBuketFoldersStatus.error.status === 401)
          }
          loadingContent={<Loader />}
          errorContent={
            <StateErrorDisplay
              error={
                (cmSubbusinessStatus.error && cmDirectoryStatus.error) ||
                getCreateStatus.error ||
                getCreateBuketFoldersStatus.error
              }
              showDetails
            />
          }
          content={
            <>
              <Grid container direction>
                <Grid item xs={12}>
                  <br /> <br />
                  <Typography align="center" variant="h2">
                    <FormattedMessage {...language.getText('contentmanager.TITLE_ACTION')} />
                  </Typography>
                </Grid>

                <Grid container justify="space-around">
                  <Grid item xs={12} className={classes.gridClass}>
                    <Paper elevation={4}>
                      <Box m={2} pt={1} pb={1}>
                        <IdentifierSearch
                          handleOpenUploadContent={() => this.handleOpenUploadContent}
                          handleRadioButton={this.handleRadioButton}
                          handleSearch={this.handleSearch}
                          ingestionDeatils={eBookData}
                          data={tabUrl}
                          eTextConfig={eTextConfig}
                          cmSubbusiness={cmmetaData.subBusiness}
                          cmDirectory={cmmetaData.cmdirectory}
                          handleSelectionChange={($event, cmsub) => this.handleSelectionChange($event, cmsub)}
                          cmBusinessValue={businessUnitId}
                          cmSubBusinessUnit={subBusinessUnitId}
                          directoryId={directoryId}
                          cmIdentifier={eBookData.identifier}
                          cmSubbusinessStatus={cmSubbusinessStatus}
                          commonSubBusinessUnit={commonSubBusinessUnit}
                          commonDirectory={commonDirectory}
                          commonIdentifier={commonIdentifier}
                          isIngestTab={false}
                        />
                        {/* <Typography variant="h6" align="right" className={classes.btnClass}>
                          <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={this.handleTitleHistory}
                            aria-hidden="true"
                          >
                            <FormattedMessage {...language.getText('contentmanager.TITLEHISTORY')} />
                          </Button>
                        </Typography> */}
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>

                <Paper elevation={4} style={{ width: '100%' }}>
                  <Box m={2} pt={1} pb={1} display="flex">
                    <Grid container justify="space-around">
                      <Grid item>
                        <Box justifyContent="center" display="flex">
                          <TooltipCustom
                            title={
                              !permissionsList.includes('ingestion_can_edit') ? (
                                <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                              ) : (
                                <FormattedMessage {...language.getText('contentmanager.INGEST')} />
                              )
                            }
                          >
                            <span>
                              <Button disabled={!permissionsList.includes('ingestion_can_edit')}>
                                <PublishIcon
                                  style={{ fontSize: 40 }}
                                  className={classes.pointer}
                                  onClick={() => this.handlePublish()}
                                />
                              </Button>
                            </span>
                          </TooltipCustom>
                        </Box>
                        <Box alignItems="center">
                          <Typography align="center" variant="h6">
                            <FormattedMessage {...language.getText('contentmanager.TITLE_UPLOAD_AUTHORING')} />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box justifyContent="center" display="flex">
                          <TooltipCustom title={<FormattedMessage {...language.getText('contentmanager.BACKUP')} />}>
                            <BackupIcon
                              style={{ fontSize: 40 }}
                              className={classes.pointer}
                              onClick={() => this.handleBackup()}
                            />
                          </TooltipCustom>
                        </Box>
                        <Box>
                          <Typography variant="h6">
                            <FormattedMessage {...language.getText('contentmanager.TITLE_BACKUP')} />
                          </Typography>
                        </Box>
                      </Grid>
                      {/* <Grid item>
                      <Box justifyContent="center" display="flex">
                        <LightTooltip
                          title={<FormattedMessage {...language.getText('contentmanager.BOOKINFO')} />}
                          className={classes.tooltipShadow}
                        >
                          <ImportContactsIcon
                            onClick={this.handleOpenInfo}
                            style={{ fontSize: 40 }}
                            className={classes.pointer}
                          />
                        </LightTooltip>
                      </Box>
                      <Box>
                        <Typography variant="h6" align="center">
                          <FormattedMessage {...language.getText('contentmanager.TITLE_MANAGE_BOOKINFO')} />
                        </Typography>
                      </Box>
                    </Grid> */}

                      {/* <Grid item>
                      <Box justifyContent="center" display="flex">
                        <LightTooltip
                          title={<FormattedMessage {...language.getText('contentmanager.MANIFEST')} />}
                          className={classes.tooltipShadow}
                        >
                          <DescriptionIcon
                            onClick={this.handleManifestInfo}
                            style={{ fontSize: 40 }}
                            className={classes.pointer}
                          />
                        </LightTooltip>
                      </Box>
                      <Box>
                        <Typography variant="h6">
                          <FormattedMessage {...language.getText('contentmanager.TITLE_MANAGE_MANIFST')} />
                        </Typography>
                      </Box>
                    </Grid> */}

                      <Grid item>
                        <Box justifyContent="center" display="flex">
                          <TooltipCustom title={<FormattedMessage {...language.getText('contentmanager.EXPORT')} />}>
                            <ImportExportIcon
                              style={{ fontSize: 40 }}
                              className={classes.pointer}
                              onClick={() => this.handleExport()}
                            />
                          </TooltipCustom>
                        </Box>
                        <Box>
                          <Typography variant="h6">
                            <FormattedMessage {...language.getText('contentmanager.TITLE_EXPORT_EXCEL')} />
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item className={classes.iconPosition}>
                        <Box justifyContent="center" display="flex">
                          {/* <LightTooltip
                            title={
                              <div>
                                <FormattedMessage {...language.getText('contentmanager.CREATEPACKAGE')} />
                              </div>
                            }
                            className={classes.tooltipShadow}
                          > */}
                          <TooltipCustom
                            title={<FormattedMessage {...language.getText('contentmanager.CREATEPACKAGE')} />}
                          >
                            <ShowChartIcon
                              onClick={() => this.handleManageDerivative()}
                              style={{ fontSize: 40 }}
                              className={classes.pointer}
                            />
                            {/*  </LightTooltip> */}
                          </TooltipCustom>
                        </Box>
                        <Box>
                          <Typography variant="h6">
                            <FormattedMessage {...language.getText('contentmanager.CREATEPACKAGE')} />
                          </Typography>
                        </Box>
                      </Grid>

                      {/* <Grid item className={classes.iconPosition}>
                      <Box justifyContent="center" display="flex">
                        <LightTooltip
                          title={<FormattedMessage {...language.getText('contentmanager.AUTO')} />}
                          className={classes.tooltipShadow}
                        >
                          <WebAssetIcon style={{ fontSize: 40 }} className={classes.pointer} />
                        </LightTooltip>
                      </Box>
                      <Box>
                        <Typography variant="h6">
                          <FormattedMessage {...language.getText('contentmanager.TITLE_CREATE_ASSETS')} />
                        </Typography>
                      </Box>
                    </Grid> */}

                      <Grid item>
                        <Box justifyContent="center" display="flex">
                          {/* <LightTooltip
                            title={<FormattedMessage {...language.getText('contentmanager.UPDF_BATCH')} />}
                            className={classes.tooltipShadow}
                          > */}
                          <TooltipCustom
                            title={<FormattedMessage {...language.getText('contentmanager.UPDF_BATCH')} />}
                          >
                            <PictureAsPdfIcon
                              onClick={() => this.handleUpdf()}
                              style={{ fontSize: 40 }}
                              className={classes.pointer}
                            />
                            {/* </LightTooltip> */}
                          </TooltipCustom>
                        </Box>
                        <Box>
                          <Typography variant="h6">
                            <FormattedMessage {...language.getText('contentmanager.UPDF')} />
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                {showFile ? (
                  <Grid container justify="space-around">
                    <Grid item xs={12}>
                      <Paper elevation={4}>
                        <Box m={2} pt={1} pb={1}>
                          <UploadSourceFile
                            showuPDF={showuPDF}
                            uploadText={uploadText}
                            selectedFileValue={selectedFileValue}
                            chooseFileData={chooseFileData}
                            handleOpenUploadContent={() => this.handleOpenUploadContent}
                            data={eBookData}
                          />
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>

              {openUploadContent && (
                <UploadContent
                  chooseFileHandle={(childdata) => this.chooseFileHandle(childdata)}
                  chooseFileHandleStatus={chooseFileHandleStatus}
                  openUploadContent={openUploadContent}
                  closeUploadContent={() => this.closeUploadContent}
                  uploadContentPath={uploadContentPath}
                />
              )}
              <ManageInfo open={open} close={() => this.handleManageClose} />
              <ManifestInfo openManifest={openManifest} close={() => this.handleManifestClose} />
              <ManageDerivative
                cmBusinessValue={derivativeBusinessUnitId}
                handleSelectionChange={($event, cmsub) => this.handleSelectionChange($event, cmsub)}
                handleDerivativeChange={() => this.handleDerivativeChange()}
                handleVerify={() => this.handleVerify()}
                openDerivative={openDerivative}
                close={() => this.handleManageDerivative}
                eTextConfig={eTextConfig}
                cmSubbusiness={titleActionData.subBusiness}
                cmDirectory={titleActionData.cmdirectory}
                cmSubBusinessUnit={derivativeSubBusinessUnitId}
                derivativeDirectoryId={derivativeDirectoryId}
                cmSubbusinessStatus={cmSubbusinessStatus}
                derivativeFieldsError={derivativeFieldsError}
                derivativeFields={derivativeFields}
                handleChange={($event) => this.handleChange($event)}
              />
              <HistoryDialog
                openhistory={openhistory}
                close={() => this.handleHistoryClose}
                rowData={rowData}
                workflowHistory=""
                workflowHistoryStatus={workflowHistoryStatus}
              />
            </>
          }
        />
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(TitleAction)));
