/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  Typography,
  Dialog,
  DialogContent,
  Box,
  Select,
  MenuItem,
  Paper,
  IconButton,
  TextField
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    height: '95vh',
    maxWidth: '50%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  labelWidht: {
    width: '100%'
  },
  bckground: {
    backgroundColor: '#d7d7d78f'
  },
  boxWidth: {
    // width: '27%'
  },
  boxCoverWidth: {
    width: '90%'
  },
  paddingTopZeroCheckbox: {
    paddingTop: 0
  },
  spanClass: {
    fontSize: 12,
    color: '#808080',
    fontFamily: 'inherit'
  },
  gridClass: {
    display: 'flex'
  },
  gridBtnClass: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: ' space-between'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

const ManageDerivative = ({
  language,
  classes,
  openDerivative,
  close,
  cmBusinessValue,
  eTextConfig,
  handleSelectionChange,
  handleDerivativeChange,
  cmSubBusinessUnit,
  cmSubbusiness,
  derivativeDirectoryId,
  cmDirectory,
  derivativeFields,
  derivativeFieldsError,
  handleChange,
  handleVerify
}) => (
  <Dialog
    open={openDerivative}
    onClose={close()}
    fullWidth="false"
    fullScreen
    direction="column"
    classes={{ paper: classes.dialogPaper }}
    aria-labelledby="draggable-dialog-title"
  >
    <DialogTitle
      id="customized-dialog-title"
      onClose={close()}
      align="center"
      toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
    >
      <Typography variant="h3">
        <FormattedMessage {...language.getText('contentmanager.GENERATE_DERIVATIVE')} />
      </Typography>
    </DialogTitle>

    <DialogContent>
      <Paper elevation={4}>
        <Box m={2} pt={1} pb={1}>
          <Grid item={12} className={classes.gridBtnClass}>
            <div>
              <TextField
                required
                className={classes.labelWidht}
                label={<FormattedMessage {...language.getText('contentmanager.PARENT_EBOOK')} />}
                margin="dense"
                variant="outlined"
                name="parentEbook"
                value={derivativeFields.parentEbook}
                error={derivativeFieldsError.parentEbookError.length > 0}
                helperText={derivativeFieldsError.parentEbookError}
                onChange={($event) => handleChange($event)}
              />
            </div>

            <div>
              <Button variant="outlined" size="small" color="primary" onClick={(event) => handleVerify(event)}>
                <FormattedMessage {...language.getText('contentmanager.VERIFY')} />
              </Button>
            </div>
          </Grid>

          <Grid container spacing={2} className={classes.gridClass}>
            <Grid item xs={12}>
              <Grid className={classes.marginBottom} direction="column" justify="space-between">
                <Box mb={0.5} className={classes.boxWidth}>
                  <TextField
                    className={classes.labelWidht}
                    label={<FormattedMessage {...language.getText('contentmanager.IDENTIFIER')} />}
                    margin="dense"
                    name="packageIdentifier"
                    variant="outlined"
                    value={derivativeFields.packageIdentifier}
                    error={derivativeFieldsError.packageIdentifierError.length > 0}
                    helperText={derivativeFieldsError.packageIdentifierError}
                    onChange={($event) => handleChange($event)}
                  />
                </Box>{' '}
                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle2">
                    <FormattedMessage {...language.getText('label.WORKFLOW')} />
                  </Typography>

                  <Select
                    className={classes.labelWidht}
                    name="workflow"
                    defaultValue="Simple eBook"
                    onChange={(event) => handleSelectionChange(event, 'workflow')}
                  >
                    {eTextConfig.titleWorkflow.map((filter) => (
                      <MenuItem value={filter.value}>{filter.value}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle2">
                    <FormattedMessage {...language.getText('contentmanager.BUSINESS_UNIT')} />
                  </Typography>

                  <Select
                    className={classes.labelWidht}
                    name="derivativeBusinessUnit"
                    defaultValue="1"
                    value={cmBusinessValue}
                    onChange={(event) => handleSelectionChange(event, 'derivativeBusinessUnit')}
                  >
                    {eTextConfig.businessUnit.map((filter) => (
                      <MenuItem value={filter.id}>{filter.value}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle2">
                    <FormattedMessage {...language.getText('contentmanager.SUBBUSINESS_UNIT')} />
                  </Typography>

                  <Select
                    className={classes.labelWidht}
                    error={derivativeFields.subBusinessDropdownBool}
                    name="derivativeSubbusiness"
                    value={cmSubBusinessUnit}
                    onChange={(event) => handleSelectionChange(event, 'derivativeSubbusiness')}
                  >
                    {cmSubbusiness
                      .filter((value) => value.businessUnitId === cmBusinessValue)
                      .map((cmvalue) => {
                        return <MenuItem value={cmvalue.subBusinessUnitId}>{cmvalue.subBusinessUnitName}</MenuItem>;
                      })}
                  </Select>
                </Box>
                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle2">
                    <FormattedMessage {...language.getText('contentmanager.DIRECTORY')} />
                  </Typography>
                  <Select
                    className={classes.labelWidht}
                    name="derivativeDirectory"
                    error={derivativeFields.derivativeBool}
                    value={derivativeDirectoryId}
                    onChange={(event) => handleSelectionChange(event, 'derivativeDirectory')}
                  >
                    <MenuItem value="directorySelect" disabled>
                      select
                    </MenuItem>

                    {cmDirectory
                      .filter((value) => value.subBusinessUnitId === cmSubBusinessUnit)
                      .map((cmvalue) => {
                        return (
                          <MenuItem value={cmvalue.directoryId} name={cmvalue.directoryName}>
                            {cmvalue.directoryName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Box>
                <br />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={12} className={classes.gridBtnClass}>
            <div>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={(event) => handleDerivativeChange(event)}
              >
                <FormattedMessage {...language.getText('contentmanager.GENERATE_DERIVATIVE_EBOOK')} />
              </Button>
            </div>
            <div>
              {/* <Button variant="outlined" size="small" color="primary">
                <FormattedMessage {...language.getText('contentmanager.COPYSERVER')} />
              </Button> */}
            </div>
          </Grid>
        </Box>
      </Paper>
    </DialogContent>
  </Dialog>
);

ManageDerivative.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  openDerivative: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  cmBusinessValue: PropTypes.string.isRequired,
  cmSubBusinessUnit: PropTypes.string.isRequired,
  derivativeDirectoryId: PropTypes.string.isRequired,
  ingestionDeatils: PropTypes.object.isRequired,
  cmSubbusiness: PropTypes.object.isRequired,
  cmDirectory: PropTypes.object.isRequired,
  eTextConfig: PropTypes.object.isRequired,
  derivativeFields: PropTypes.object.isRequired,
  derivativeFieldsError: PropTypes.object.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  handleDerivativeChange: PropTypes.func.isRequired,
  handleVerify: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(ManageDerivative)));
