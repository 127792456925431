import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Framework, { shapes, LoadingHandler } from '@greenville/framework';
import {
  Button,
  Grid,
  Paper,
  Box,
  Typography,
  IconButton,
  TextField,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { FormattedMessage } from 'react-intl';
import * as constants from '../../../common/constants';
import ChatgptConfig from '../../../common/config/ChatgptConfig';
import ChatgptAdmin from '../models/ChatgptAdmin';
import TooltipCustom from '../../../common/components/TooltipCustom';
import Loader from '../../../common/Loader';
import UsersPermissions from '../../users/models/UsersPermissions';

const styles = () => ({
  headingClass: {
    padding: '15px',
    fontWeight: 'bold'
  },
  selectTenant: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  textFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem'
  },
  textField: {
    margin: '5px'
  },
  textFields: {
    margin: '5px',
    marginTop: '-20px'
  },
  groupName: {
    marginLeft: '500px'
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const ChatgptUtilityAdmin = (props) => {
  const {
    classes,
    language,
    chatgptAdmin,
    ChatGptUtilityAdminStatus,
    ChatGptUtilityGetAdminStatus,
    ChatGptUtilityEditAdminStatus,
    userPermissions
  } = props;
  const [selectedTenant, setSelectedTenant] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [showGroupText, setShowGroupText] = useState(false);
  const [newFields, setNewFields] = useState([]);
  // const [showPropertyText, setShowPropertyText] = useState(false);
  const [editProperties, setProperties] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [newGroupName, setNewGroupName] = useState('');
  const [existingGroups, setGroups] = useState([]);

  const permissionsList = toJS(userPermissions.permissionList.permissions);
  const handleChange = (event) => {
    setSelectedTenant(event.target.value);
    chatgptAdmin.fetchTenantData(event.target.value);
    Framework.getEventManager().on(constants.SET_ADMIN_DATA, () => {
      const { groups } = chatgptAdmin;
      if (groups && groups.length > 0) {
        setGroupData(groups);
      }
    });
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    const selectedGroupData = groupData.find((group) => group.name === event.target.value);
    if (selectedGroupData) {
      setProperties(selectedGroupData.properties);
    }
    setShowGroupText(false);
  };
  const handleShowText = () => {
    setShowGroupText(true);
    setSelectedGroup('');
  };
  const handleAddGroup = () => {
    if (newGroupName) {
      setGroups([...existingGroups, { name: newGroupName, properties: [] }]);
      setNewGroupName('');
    }
  };

  const handleAddNewFields = () => {
    setNewFields([...newFields, { id: '', display_name: '' }]);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...newFields];
    if (updatedFields[index]) {
      updatedFields[index][field] = value;
    } else {
      updatedFields[index] = { [field]: value };
    }
    const filteredFields = updatedFields.filter((x) => x.id.trim() !== '' || x.display_name.trim() !== '');
    setNewFields(filteredFields);
  };

  const handleAddProperty = (index) => {
    const newGroups = [...existingGroups];
    if (!newGroups[index].properties) {
      newGroups[index].properties = [];
    }
    newGroups[index].properties.push({ id: '', display_name: '' });
    setGroups(newGroups);
  };

  const handlePropertyChange = (groupIndex, propertyIndex, field, value) => {
    const updatedGroups = [...existingGroups];
    if (!updatedGroups[groupIndex].properties) {
      updatedGroups[groupIndex].properties = [];
    }
    updatedGroups[groupIndex].properties[propertyIndex][field] = value;
    setGroups(updatedGroups);
  };

  const handleEditProperty = (index, field, value) => {
    const updatedProperties = [...editProperties];
    updatedProperties[index] = { ...updatedProperties[index], [field]: value };
    const filteredProperties = updatedProperties.filter((x) => x.id.trim() !== '' || x.display_name.trim() !== '');
    setProperties(filteredProperties);
  };

  const handleReset = () => {
    setSelectedTenant('');
    setNewGroupName('');
    setGroups([]);
    setSelectedGroup('');
    setNewFields([]);
    setGroupData([]);
    setShowGroupText(false);
    setProperties([]);
  };
  const handleUpdate = () => {
    let updated = {};
    if (newFields.length > 0) {
      const updatedProperties = [...editProperties, ...newFields];
      const filteredProperties = updatedProperties.filter(
        (field) => field.id.trim() !== '' || field.display_name.trim() !== ''
      );
      setProperties(filteredProperties);
      updated = {
        tenantName: selectedTenant,
        groups: {
          name: selectedGroup,
          properties: filteredProperties.map((x) => ({
            id: x.id,
            display_name: x.display_name
          }))
        }
      };
    } else {
      updated = {
        tenantName: selectedTenant,
        groups: {
          name: selectedGroup,
          properties: editProperties.map((x) => ({
            id: x.id,
            display_name: x.display_name
          }))
        }
      };
    }
    chatgptAdmin.updateData(updated);
    handleReset();
  };
  const handleSave = () => {
    let savedData = {};
    const groupName = [];
    const tenantName = ChatgptConfig.tenantData.find((item) => item.name === selectedTenant)?.name;

    if (!savedData[tenantName]) {
      savedData = { groups: [] };
    }

    existingGroups.forEach((group) => {
      const { name, properties } = group;
      const groupsData = {
        name,
        properties
      };

      savedData.groups.push(groupsData);
      groupName.push(groupsData.name);
    });
    const payload = {
      tenantName,
      groupName,
      savedData
    };
    chatgptAdmin.saveData(payload);
    handleReset();
  };

  return (
    <>
      <LoadingHandler
        loading={
          ChatGptUtilityAdminStatus.isPending ||
          ChatGptUtilityGetAdminStatus.isPending ||
          ChatGptUtilityEditAdminStatus.isPending
        }
        loadingContent={<Loader />}
        content={
          <Paper>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ p: 3 }}>
                <Typography variant="h5" align="center" gutterBottom spacing={9}>
                  {constants.ADMIN_SCREEN}
                </Typography>
              </Box>
              <Box>
                <Grid container direction="row" alignItems="center" className={classes.selectTenant}>
                  {/* <Typography className={classes.headingClass}>Select Tenant</Typography> */}
                  <FormControl sx={{ minWidth: '32%', maxWidth: '90%' }} size="small">
                    <InputLabel id="tenantLabelId">Select Tenant</InputLabel>
                    <Select label="Select Tenant" name="tenantId" value={selectedTenant} onChange={handleChange}>
                      {ChatgptConfig.tenantData.map((item) => (
                        <MenuItem value={item.name}>{item.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box mt={1} ml={2}>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  {/* <Typography className={classes.headingClass}>Select Group</Typography>{' '} */}
                  <FormControl sx={{ minWidth: '32%', maxWidth: '60%' }} size="small">
                    <InputLabel id="groupLabelId">Select Group</InputLabel>{' '}
                    <Select label="Select Group" name="groupId" value={selectedGroup} onChange={handleGroupChange}>
                      {groupData.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TooltipCustom title="Add New Group">
                    <IconButton color="primary" onClick={handleShowText}>
                      <AddIcon />
                    </IconButton>
                  </TooltipCustom>
                </Grid>
              </Box>
              <Box mt={2}>
                {selectedTenant && showGroupText && (
                  <Grid container direction="row" alignItems="center" justifyContent="center">
                    <TextField
                      label="New Group Name"
                      value={newGroupName}
                      onChange={(event) => setNewGroupName(event.target.value)}
                      className={classes.textField}
                    />
                    {/* <IconButton color="primary" onClick={handleAddGroup}>
                      <AddIcon />
                    </IconButton> */}
                    <ThemeProvider theme={themes}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleAddGroup}
                        className={classes.buttonStyle}
                      >
                        Add Group
                      </Button>
                    </ThemeProvider>
                  </Grid>
                )}
              </Box>
              {existingGroups.map((group, groupIndex) => (
                <Box key={groupIndex} sx={{ p: 1 }}>
                  <Grid container direction="row" alignItems="center" className={classes.groupName}>
                    <Typography variant="h6" align="center">
                      Group: {group.name}
                    </Typography>
                    <TooltipCustom title="Add New Property">
                      <IconButton color="primary" onClick={() => handleAddProperty(groupIndex)}>
                        <AddIcon />
                      </IconButton>
                    </TooltipCustom>
                  </Grid>
                  {group.properties.map((property, propertyIndex) => (
                    <Box key={propertyIndex} className={classes.textFieldContainer}>
                      <TextField
                        label="Id"
                        value={property.id}
                        onChange={(event) => handlePropertyChange(groupIndex, propertyIndex, 'id', event.target.value)}
                        className={classes.textFields}
                      />
                      <TextField
                        label="Display Name"
                        value={property.display_name}
                        onChange={(event) =>
                          handlePropertyChange(groupIndex, propertyIndex, 'display_name', event.target.value)
                        }
                        className={classes.textFields}
                      />
                    </Box>
                  ))}
                </Box>
              ))}
              {selectedGroup && !showGroupText && (
                <Box display="flex" justifyContent="center" mt={2}>
                  <TableContainer component={Paper} style={{ maxWidth: '500px' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell> <TableCell>Display Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {editProperties.map((property, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <TextField
                                value={property.id}
                                onChange={(event) => handleEditProperty(index, 'id', event.target.value)}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={property.display_name}
                                onChange={(event) => handleEditProperty(index, 'display_name', event.target.value)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}

                        {newFields.map((field, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <TextField
                                value={field.id}
                                onChange={(event) => handleFieldChange(index, 'id', event.target.value)}
                                placeholder="New ID"
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={field.display_name}
                                onChange={(event) => handleFieldChange(index, 'display_name', event.target.value)}
                                placeholder="New Display Name"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TooltipCustom title="Add New Property">
                    <IconButton color="primary" onClick={handleAddNewFields}>
                      <AddIcon />
                    </IconButton>
                  </TooltipCustom>
                </Box>
              )}

              <Box sx={{ p: 5 }}>
                <Grid container className={classes.buttonGroupStyle}>
                  <ThemeProvider theme={themes}>
                    {showGroupText && (
                      <TooltipCustom
                        title={
                          !permissionsList.includes('admin_can_edit') ? (
                            <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                          ) : (
                            <FormattedMessage {...language.getText('admin.ADD')} />
                          )
                        }
                      >
                        <span>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={handleSave}
                            className={classes.buttonStyle}
                            disabled={!permissionsList.includes('admin_can_edit')}
                          >
                            ADD
                          </Button>
                        </span>
                      </TooltipCustom>
                    )}

                    {!showGroupText && (
                      <TooltipCustom
                        title={
                          !permissionsList.includes('admin_can_edit') ? (
                            <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                          ) : (
                            <FormattedMessage {...language.getText('admin.UPDATE')} />
                          )
                        }
                      >
                        <span>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={handleUpdate}
                            className={classes.buttonStyle}
                            disabled={!permissionsList.includes('admin_can_edit')}
                          >
                            UPDATE
                          </Button>
                        </span>
                      </TooltipCustom>
                    )}

                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={handleReset}
                      className={classes.buttonStyle}
                    >
                      RESET
                    </Button>
                  </ThemeProvider>
                </Grid>
                <br />
              </Box>
            </Box>
          </Paper>
        }
      />
    </>
  );
};

ChatgptUtilityAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  chatgptAdmin: shapes.modelOf(ChatgptAdmin).isRequired,
  ChatGptUtilityAdminStatus: shapes.state.isRequired,
  ChatGptUtilityGetAdminStatus: shapes.state.isRequired,
  ChatGptUtilityEditAdminStatus: shapes.state.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired
};

export default withStyles(styles)(
  inject(
    'ChatGptUtilityAdminStatus',
    'chatgptAdmin',
    'ChatGptUtilityGetAdminStatus',
    'ChatGptUtilityEditAdminStatus',
    'userPermissions',
    'language'
  )(observer(ChatgptUtilityAdmin))
);
