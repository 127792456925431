const roleType = [
  {
    id: 1,
    value: 'Super User'
  },
  {
    id: 2,
    value: 'Authoring User'
  },
  {
    id: 3,
    value: 'Read Only User'
  }
];

const requiredFields = ['firstName', 'lastName', 'userLogin', 'email'];

const userDialogConfig = {
  roleType,
  requiredFields
};

module.exports = userDialogConfig;
