// import React, { useEffect } from 'react';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import {
  Paper,
  TableBody,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
  Grid,
  Box
} from '@material-ui/core';
// import { LoadingHandler, StateErrorDisplay } from '@greenville/framework';
// import PieCharts from '../Charts';
import Listings from '../Listing';
// import ListingTrends from '../ListingTrends';
// import Loader from '../../../../common/Loader';
import * as constants from '../../../../common/constants';

const styles = () => ({
  root: {
    width: '100%'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif !important',
    fontWeight: '600 !important',
    fontSize: '14px !important'
  },
  radioButton: {
    textAlign: 'center'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: '14px !important'
  },
  tableDateWidth: {
    width: '110px'
  },
  tableBookWidth: {
    width: '120px'
  },
  tableTitleWidth: {
    width: '120px'
  },
  tableLinkWidth: {
    width: '80px'
  },
  tableFilterTextWidth: {
    width: '150px'
  },
  tableFilterLikeWidth: {
    width: '145px'
  },
  tableCommentsWidth: {
    width: '160px'
  },
  tableUnansWidth: {
    width: '185px'
  },
  tableTrendsWidth: {
    width: '185px'
  },
  tableQuizWidth: {
    width: '170px'
  },
  tableSummaryWidth: {
    width: '180px'
  },
  tableExplainWidth: {
    width: '165px'
  }
});

const ChatTrendsGrid = ({
  classes,
  chatgptUtilityTrendsData,
  chatgptUtilityListingData,
  sorting,
  setOrder,
  setDirectionValue,
  selectRadioOption,
  selectListingOption
}) => {
  const [isTrendsUnanswered, setIsTrendsUnanswered] = useState(true);
  useEffect(() => {
    if (window.location.pathname === '/chatgptutility/aistudytools/trends/unanswered') {
      setIsTrendsUnanswered(true);
    }
  }, []);
  return (
    <>
      {selectRadioOption && (
        <TableContainer component={Paper} className={classes.root}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableDateWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GPT_TRENDS_DATE}
                    <TableSortLabel
                      onClick={() => sorting('date')}
                      active
                      direction={setDirectionValue === 'date' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableTitleWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GPT_TRENDS_BOOKTITLE}
                    <TableSortLabel
                      onClick={() => sorting('title')}
                      active
                      direction={setDirectionValue === 'title' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableBookWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.BOOK_ID}
                    <TableSortLabel
                      onClick={() => sorting('bookId')}
                      active
                      direction={setDirectionValue === 'bookId' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableLinkWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GPT_TRENDS_LIKECOUNT}
                    <TableSortLabel
                      onClick={() => sorting('likeCount')}
                      active
                      direction={setDirectionValue === 'likeCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableDislikeWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GPT_TRENDS_DISLIKECOUNT}
                    <TableSortLabel
                      onClick={() => sorting('disLikeCount')}
                      active
                      direction={setDirectionValue === 'disLikeCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableExplainWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GPT_TRENDS_EXPLAINCOUNT}
                    <TableSortLabel
                      onClick={() => sorting('explainCount')}
                      active
                      direction={setDirectionValue === 'explainCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableSummaryWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GPT_TRENDS_SUMMARYCOUNT}
                    <TableSortLabel
                      onClick={() => sorting('summaryCount')}
                      active
                      direction={setDirectionValue === 'summaryCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableQuizWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GPT_TRENDS_QUIZCOUNT}
                    <TableSortLabel
                      onClick={() => sorting('quizMcqCount')}
                      active
                      direction={setDirectionValue === 'quizMcqCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                {isTrendsUnanswered && (
                  <>
                    {' '}
                    <TableCell align="center" className={classes.tableQuizWidth}>
                      <Typography className={classes.columnStyle} variant="h6">
                        {constants.GPT_TRENDS_UNANSWERED_NO_CSG_DOCS_COUNT}
                        <TableSortLabel
                          onClick={() => sorting('unAnsweredNoCSGDocCount')}
                          active
                          direction={
                            setDirectionValue === 'unAnsweredNoCSGDocCount' && setOrder === 'DSC' ? 'asc' : 'desc'
                          }
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiTableSortLabel-root:hover': {
                              cursor: 'pointer'
                            }
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.tableQuizWidth}>
                      <Typography className={classes.columnStyle} variant="h6">
                        {constants.GPT_TRENDS_UNANSWERED_UNKNOWN_INTENT_COUNT}
                        <TableSortLabel
                          onClick={() => sorting('unAnsweredUnknownIntentCount')}
                          active
                          direction={
                            setDirectionValue === 'unAnsweredUnknownIntentCount' && setOrder === 'DSC' ? 'asc' : 'desc'
                          }
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiTableSortLabel-root:hover': {
                              cursor: 'pointer'
                            }
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.tableQuizWidth}>
                      <Typography className={classes.columnStyle} variant="h6">
                        {constants.GPT_TRENDS_UNANSWERED_NO_TOPIC_FOUND_COUNT}
                        <TableSortLabel
                          onClick={() => sorting('unAnsweredNoTopicFoundCount')}
                          active
                          direction={
                            setDirectionValue === 'unAnsweredNoTopicFoundCount' && setOrder === 'DSC' ? 'asc' : 'desc'
                          }
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiTableSortLabel-root:hover': {
                              cursor: 'pointer'
                            }
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.tableQuizWidth}>
                      <Typography className={classes.columnStyle} variant="h6">
                        {constants.GPT_TRENDS_BLOCKED_COUNT}
                        <TableSortLabel
                          onClick={() => sorting('blockWordsCount')}
                          active
                          direction={setDirectionValue === 'blockWordsCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiTableSortLabel-root:hover': {
                              cursor: 'pointer'
                            }
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.tableQuizWidth}>
                      <Typography className={classes.columnStyle} variant="h6">
                        Unanswered (problem solve)
                        <TableSortLabel
                          onClick={() => sorting('blockWordsCount')}
                          active
                          direction={setDirectionValue === 'blockWordsCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiTableSortLabel-root:hover': {
                              cursor: 'pointer'
                            }
                          }}
                        />
                      </Typography>
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {chatgptUtilityTrendsData &&
                Array.isArray(chatgptUtilityTrendsData) &&
                chatgptUtilityTrendsData.length > 0 &&
                chatgptUtilityTrendsData.map((data) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={classes.radioButton}>
                          <Typography className={classes.tableText}>{data.date}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableFilterTextWidth}>
                          <Typography className={classes.tableText}>{data.title}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}> {data.bookId}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableFilterLikeWidth}>
                          <Typography className={classes.tableText}> {data.likeCount}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}> {data.disLikeCount}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}> {data.explainCount}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}> {data.summaryCount}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}> {data.quizMcqCount}</Typography>
                        </TableCell>
                        {isTrendsUnanswered && (
                          <>
                            <TableCell>
                              <Typography className={classes.tableText}> {data.unAnsweredNoCSGDocCount}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableText}>
                                {' '}
                                {data.unAnsweredUnknownIntentCount}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableText}>
                                {' '}
                                {data.unAnsweredNoTopicFoundCount && data.unAnsweredNoTopicFoundCount}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableText}> {data.blockWordsCount}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableText}> {data.unAnsweredProblemSolveCount}</Typography>
                            </TableCell>
                          </>
                        )}
                        {/* <TableCell>
                          <Typography className={classes.tableText}> {data.blockWordsCount}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}>
                            {' '}
                            {data.unAnsweredCount && data.unAnsweredCount}
                          </Typography>
                        </TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* {selectRadioOption && !selectListingOption && (
        <Paper>
          <Grid item xs={12}>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px', height: '450px' }}>
              <br />
              <PieCharts />
            </Box>
          </Grid>
        </Paper>
      )} */}
      {selectListingOption && (
        // <LoadingHandler
        //   loading={chatListStatus.isPending}
        //   isError={
        //     chatListStatus.isError &&
        //     chatListStatus.error &&
        //     chatListStatus.error.status === 401 &&
        //     chatListStatus.error.status === 500
        //   }
        //   content={
        //     chatListDatas.data &&
        //     chatListDatas.data.length > 0 && (
        <Paper>
          <Grid item xs={12}>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
              <br />
              <Listings chatgptUtilityListingData={chatgptUtilityListingData} />
            </Box>
          </Grid>
        </Paper>
        //     )
        //   }
        //   loadingContent={<Loader loaderStatusResponse={loaderStatusResponse} />}
        //   errorContent={<StateErrorDisplay error={chatListStatus.error} showDetails />}
        // />
      )}
    </>
  );
};

ChatTrendsGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  chatgptUtilityTrendsData: PropTypes.array.isRequired,
  chatgptUtilityListingData: PropTypes.array.isRequired,
  sorting: PropTypes.func.isRequired,
  setDirectionValue: PropTypes.string.isRequired,
  selectRadioOption: PropTypes.string.isRequired,
  selectListingOption: PropTypes.string.isRequired,
  setOrder: PropTypes.bool.isRequired
};

export default withStyles(styles)(ChatTrendsGrid);
