/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import UsersList from './components/UsersList';
import Users from './models/Users';
import Roles from './models/Roles';
import Breadcrumbs from '../books/components/commonitems/Breadcrumbs';
import Header from '../../common/header/Header';
import Loader from '../../common/Loader';
import ValidatePermissions from './ValidatePermissions';

const styles = {
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80
  }
};
@inject('usersState', 'usersStatus', 'userRoles', 'addUserStatus', 'editUserstatus')
@observer
class UsersContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    usersState: shapes.modelOf(Users).isRequired,
    userRoles: shapes.modelOf(Roles).isRequired,
    usersStatus: shapes.state.isRequired,
    editUserstatus: shapes.state.isRequired,
    addUserStatus: shapes.state.isRequired,
    language: shapes.language.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    showEditAddUserslist: PropTypes.func.isRequired
  };

  // shistory = useHistory();

  constructor(props) {
    super(props);
    this.state = {
      tabState: ''
    };
  }

  componentDidMount() {
    const { usersState, userRoles } = this.props;
    usersState.fetch();
    userRoles.fetch();
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { tabState } = this.state;
    if (match.path === '/users' && tabState.length > 0) {
      this.updateBreadcrumbs('');
    }
  }

  updateBreadcrumbs = (data) => {
    this.setState({
      tabState: data
    });
  };

  pathUrl = (data) => {
    const { history } = this.props;
    let url = '';
    if (data === '') {
      url = '/users';
    } else {
      url = `/users/${data.toLowerCase()}`;
    }
    history.push(url);
  };

  render() {
    const { usersState, classes, language, usersStatus, match } = this.props;
    const { tabState } = this.state;
    return (
      <>
        <Header />
        <ValidatePermissions givenPermissions="user_can_view">
          <main className={classes.contentWrapper} id="mainContent" role="main">
            <Box ml={3}>
              <Breadcrumbs
                subData={tabState}
                data={<FormattedMessage {...language.getText('user.USERS')} />}
                id={match.params.bookid}
              />
              <br />
            </Box>
            <Container maxWidth="xl">
              <LoadingHandler
                loading={usersStatus.isPending}
                isError={usersStatus.isError && usersStatus.error && usersStatus.error.status}
                content={
                  <UsersList
                    data={usersState.userList.slice()}
                    usersState={usersState}
                    updateBreadcrumbs={this.updateBreadcrumbs}
                    pathUrl={this.pathUrl}
                    showEditAddUserslist={this.showEditAddUserslist}
                    {...this.props}
                  />
                }
                loadingContent={<Loader />}
                errorContent={<StateErrorDisplay error={usersStatus.error} showDetails />}
              />
            </Container>
          </main>
        </ValidatePermissions>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(observer(UsersContainer)));
