/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField, Select, MenuItem, Box } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormattedMessage } from 'react-intl';
import FormLabel from '@material-ui/core/FormLabel';
import eTextConversionConfig from '../../../../common/config/eTextConversionConfig';
// import { validate } from '@material-ui/pickers';

const styles = () => ({
  width: {
    width: '100%'
  },
  labelWidht: {
    width: '100%'
  },
  radioJustify: {
    justifyContent: 'space-between'
  },
  boxWidth: {
    width: '27%'
  },
  paddingTopZeroCheckbox: {
    paddingTop: 0
  }
});

const EtextInfo = ({
  classes,
  eTextConfig,
  language,
  disciplineCode,
  requestData,
  handleChangeRequest,
  handleCheckboxSection
}) => (
  <Grid container direction="row" justify="space-between">
    <Grid container item xs={12} justify="space-between">
      <TextField
        label={<FormattedMessage {...language.getText('eTextInfo.COMPOSITOR')} />}
        margin="dense"
        name="compositor"
        value={requestData.etextInfo.compositor}
        onChange={(e) => handleChangeRequest(e, 'info')}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextInfo.PIMSINFO')} />}
        margin="dense"
        name="pimsInfo"
        value={requestData.etextInfo.pimsInfo}
        onChange={(e) => handleChangeRequest(e, 'info')}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextInfo.PAGECOUNT')} />}
        margin="dense"
        name="pageCount"
        value={requestData.etextInfo.pageCount}
        onChange={(e) => handleChangeRequest(e, 'info')}
        variant="outlined"
      />
    </Grid>
    <Grid container direction="row" item xs={12} style={{ marginTop: '3%' }} justify="space-between">
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle2">
          <FormattedMessage {...language.getText('eTextInfo.SOURCEFILE')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          name="sourceFile"
          onChange={(e) => handleChangeRequest(e, 'info')}
          defaultValue={requestData.etextInfo.sourceFile}
        >
          {eTextConversionConfig.yesNo.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>

      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.DISCIPLINE')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          name="disciplineCode"
          defaultValue={requestData.etextInfo.disciplineCode}
        >
          {disciplineCode.length > 0 &&
            disciplineCode.map((discipline) => <MenuItem value={discipline.id}>{discipline.value}</MenuItem>)}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        {' '}
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('metadata.COVERART')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          name="coverArt"
          onChange={(e) => handleChangeRequest(e, 'info')}
          defaultValue={requestData.etextInfo.coverArt}
        >
          {/* <MenuItem disabled value="">
            Select
          </MenuItem> */}
          {eTextConversionConfig.coverArtSupplied.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.TYPEETEXT')} />
        </Typography>
        <Select
          name="etextType"
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          defaultValue={requestData.etextInfo.etextType}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConversionConfig.etextDemo.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.REMOVEALL')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          name="removeCaption"
          onChange={(e) => handleChangeRequest(e, 'info')}
          defaultValue={requestData.etextInfo.removeCaption}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConversionConfig.etextYesNo.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
    </Grid>

    <Grid container item xs={12} style={{ marginTop: '3%' }}>
      <Typography align="center" variant="body2" gutterBottom>
        <br />
        <FormattedMessage {...language.getText('eTextInfo.RETAINFIGS')} />
      </Typography>
      <TextareaAutosize
        rowsMax={4}
        rowsMin={4}
        name="retainCaptionList"
        color="inherit"
        className={classes.labelWidht}
        aria-label="maximum height"
        onChange={(e) => handleChangeRequest(e, 'info')}
        defaultValue={requestData.etextInfo.retainCaptionList}
      />
      <br />
      <br />
    </Grid>
    <Grid container item xs={12} style={{ marginTop: '3%' }}>
      <Grid item xs={12}>
        <Typography variant="body2">
          <FormattedMessage {...language.getText('eTextInfo.NONEXCLUDE')} />
        </Typography>
        <TextareaAutosize
          rowsMax={4}
          rowsMin={4}
          name="nonPermissionedExclude"
          color="inherit"
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          aria-label="maximum height"
          placeholder="Maximum 4 rows"
          defaultValue={requestData.etextInfo.nonPermissionedExclude}
        />
      </Grid>

      <Grid container item xs={12} direction="row">
        <Grid container direction="row">
          <FormControl size="small" margin="dense" component="fieldset">
            <FormLabel component="span">
              <Typography variant="body2">
                <FormattedMessage {...language.getText('eTextrequest.ARTWORK')} />
              </Typography>
            </FormLabel>

            <FormGroup row aria-label="coverArtRadioValue" onChange={(e) => handleCheckboxSection(e)}>
              <FormControlLabel
                value="inside"
                control={
                  <Checkbox
                    checked={requestData.etextInfo.insideFaceCover === 'Y'}
                    name="insideFaceCover"
                    className={classes.paddingTopZeroCheckbox}
                    defaultValue={requestData.etextInfo.insideFaceCover}
                    color="primary"
                  />
                }
                label={<FormattedMessage {...language.getText('eTextInfo.INSIDEFRONTCOVER')} />}
                labelPlacement="end"
              />
              <FormControlLabel
                value="inside"
                control={
                  <Checkbox
                    checked={requestData.etextInfo.insideBackCover === 'Y'}
                    name="insideBackCover"
                    defaultValue={requestData.etextInfo.insideBackCover}
                    className={classes.paddingTopZeroCheckbox}
                    color="primary"
                  />
                }
                label={<FormattedMessage {...language.getText('eTextreivsion.INSIDEBACK')} />}
                labelPlacement="end"
              />
              <FormControlLabel
                value="inside"
                control={
                  <Checkbox
                    checked={requestData.etextInfo.colorInserts === 'Y'}
                    name="colorInserts"
                    className={classes.paddingTopZeroCheckbox}
                    color="primary"
                  />
                }
                label={<FormattedMessage {...language.getText('eTextreivsion.COLORINSERTS')} />}
                labelPlacement="end"
              />
              <FormControlLabel
                value="inside"
                control={
                  <Checkbox
                    checked={requestData.etextInfo.bonusChapters === 'Y'}
                    name="bonusChapters"
                    className={classes.paddingTopZeroCheckbox}
                    color="primary"
                  />
                }
                label={<FormattedMessage {...language.getText('eTextreivsion.BONUSCHAPTER')} />}
                labelPlacement="end"
              />
              <FormControlLabel
                value="inside"
                control={
                  <Checkbox
                    checked={requestData.etextInfo.tearCard === 'Y'}
                    name="tearCard"
                    className={classes.paddingTopZeroCheckbox}
                    color="primary"
                  />
                }
                label={<FormattedMessage {...language.getText('eTextreivsion.TEARCARD')} />}
                labelPlacement="end"
              />
              <FormControlLabel
                value="inside"
                control={
                  <Checkbox
                    checked={requestData.etextInfo.endPapers === 'Y'}
                    name="endPapers"
                    className={classes.paddingTopZeroCheckbox}
                    color="primary"
                  />
                }
                label={<FormattedMessage {...language.getText('eTextreivsion.ENDPAPERS')} />}
                labelPlacement="end"
              />

              <FormControlLabel
                value="inside"
                control={
                  <Checkbox
                    checked={requestData.etextInfo.othersCheck === 'Y'}
                    name="othersCheck"
                    className={classes.paddingTopZeroCheckbox}
                    color="primary"
                  />
                }
                label={<FormattedMessage {...language.getText('eTextrevision.OTHERS')} />}
                labelPlacement="end"
              />
            </FormGroup>
            <TextField
              name="others"
              value={requestData.etextInfo.others}
              onChange={(e) => handleChangeRequest(e, 'info')}
              margin="dense"
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          <br />
          <FormattedMessage {...language.getText('eTextInfo.SECINFO')} />
        </Typography>
        <TextareaAutosize
          rowsMax={4}
          rowsMin={3}
          onChange={(e) => handleChangeRequest(e, 'info')}
          color="inherit"
          name="suppSectionDesc"
          className={classes.labelWidht}
          aria-label="maximum height"
          placeholder="Maximum 4 rows"
          defaultValue={requestData.etextInfo.suppSectionDesc}
        />
      </Grid>
    </Grid>

    <Grid container direction="row" item xs={12} style={{ marginTop: '3%' }} justify="space-between">
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle2">
          <FormattedMessage {...language.getText('eTextInfo.TOCSPREAD')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          name="tocSpreadSheet"
          defaultValue={requestData.etextInfo.tocSpreadSheet}
          onChange={(e) => handleChangeRequest(e, 'info')}
        >
          {eTextConversionConfig.etextTocSpreadsheet.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>

      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.GLOSSARY')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          name="glossarySpreadSheet"
          defaultValue={requestData.etextInfo.glossarySpreadSheet}
          onChange={(e) => handleChangeRequest(e, 'info')}
        >
          {eTextConversionConfig.etextGlossary.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        {' '}
        <Typography variant="subtitle1">
          {' '}
          <FormattedMessage {...language.getText('eTextInfo.CONTENTSLINK')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          name="contentsLinks"
          defaultValue={requestData.etextInfo.contentsLinks}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConversionConfig.etextContentLink.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.INDEXLINKS')} />
        </Typography>
        <Select
          name="indexLinks"
          onChange={(e) => handleChangeRequest(e, 'info')}
          className={classes.labelWidht}
          defaultValue={requestData.etextInfo.indexLinks}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConversionConfig.etextIndexLink.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.CROSSREF')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          name="crossRefHotspot"
          defaultValue={requestData.etextInfo.crossRefHotspot}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConversionConfig.etextCrossRef.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.HOTSPOTSPREADSHEET')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          name="hotSpotSpreadSheet"
          defaultValue={requestData.etextInfo.hotSpotSpreadSheet}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConversionConfig.etextHotspotSpreadsheet.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>

      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextrequest.CUSTOMICONS')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          name="custIcons"
          defaultValue={requestData.etextInfo.custIcons}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConfig.etextCustom.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextrequest.CUSTOMBASKETS')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          name="customBasketSpreadSheet"
          defaultValue={requestData.etextInfo.customBasketSpreadSheet}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConfig.etextCustom.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.HOTSPOTUI')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          name="hotspotUI"
          defaultValue={requestData.etextInfo.hotspotUI}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConversionConfig.etextHotspot.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.UNDERLINECOLOR')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          onChange={(e) => handleChangeRequest(e, 'info')}
          name="underlineHotspot"
          defaultValue={requestData.etextInfo.underlineHotspot}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConversionConfig.etextYesNo.map((filter) => (
            <MenuItem value={filter.id}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.UNDERLINEHOVER')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          name="underlineHotspotHover"
          onChange={(e) => handleChangeRequest(e, 'info')}
          defaultValue={requestData.etextInfo.underlineHotspotHover}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConversionConfig.etextYesNo.map((filter) => (
            <MenuItem value={filter.id}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>

      <Box mb={0.5} className={classes.boxWidth}>
        <Typography variant="subtitle1">
          <FormattedMessage {...language.getText('eTextInfo.AUDIOSYNCH')} />
        </Typography>
        <Select
          className={classes.labelWidht}
          name="audioSync"
          onChange={(e) => handleChangeRequest(e, 'info')}
          defaultValue={requestData.etextInfo.audioSync}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {eTextConfig.audioSynch.map((filter) => (
            <MenuItem value={filter.value}>{filter.value}</MenuItem>
          ))}
        </Select>
      </Box>

      <Box mb={0.5} className={classes.boxWidth}>
        <TextField
          label={<FormattedMessage {...language.getText('eTextInfo.HEXNUMBER')} />}
          margin="dense"
          name="visibleHotspotHex"
          onChange={(e) => handleChangeRequest(e, 'info')}
          value={requestData.etextInfo.visibleHotspotHex}
          variant="outlined"
        />
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <TextField
          label={<FormattedMessage {...language.getText('eTextInfo.UNDERLINE')} />}
          margin="dense"
          name="underlineHotspotHex"
          value={requestData.etextInfo.underlineHotspotHex}
          onChange={(e) => handleChangeRequest(e, 'info')}
          variant="outlined"
        />
      </Box>
      <Box mb={0.5} className={classes.boxWidth}>
        <TextField
          label={<FormattedMessage {...language.getText('eTextInfo.HOVER')} />}
          margin="dense"
          name="underlineHotspotHoverHex"
          onChange={(e) => handleChangeRequest(e, 'info')}
          value={requestData.etextInfo.underlineHotspotHoverHex}
          variant="outlined"
        />
      </Box>
    </Grid>
  </Grid>
);

EtextInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  eTextConfig: PropTypes.object.isRequired,
  // divisions: PropTypes.array.isRequired,
  disciplineCode: PropTypes.array.isRequired,
  requestData: PropTypes.array.isRequired,
  handleChangeRequest: PropTypes.func.isRequired,
  handleCheckboxSection: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(EtextInfo)));
