import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const trendsData = types.model('trendsData', {
  channelsId: types.maybeNull(types.string),
  dateStamp: types.maybeNull(types.string),
  relevantTopicsCount: types.maybeNull(types.number),
  videoSummaryCount: types.maybeNull(types.number),
  questionIntentCount: types.maybeNull(types.number),
  questionSolutionCount: types.maybeNull(types.number),
  questionStepsSolutionCount: types.maybeNull(types.number)
});

const ChannelsTrends = types
  .model('Trends', {
    data: types.optional(types.array(trendsData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHANNELS_TRENDS_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.GET_CHANNELS_TRENDS_MODEL_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.GET_CHANNELS_TRENDS_MODEL_DATA);
    }
  }));

export default ChannelsTrends;
