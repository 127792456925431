import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import App from './App';
import env from './common/env';
import * as serviceWorker from './serviceWorker';
import newrelic from './modules/newrelic/newrelic';

const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
/* Dynamicly includes the Newrelic */
if (env.NEWRELIC_ENABLED) {
  newrelic.init();
}
