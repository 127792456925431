/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { shapes } from '@greenville/framework';
import { IconButton } from '@material-ui/core';
import { inject } from 'mobx-react';
import PageHotspotIcon from '@material-ui/icons/BorderColorOutlined';
import { toJS } from 'mobx';
import * as constants from '../../../../common/constants';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import UsersPermissions from '../../../users/models/UsersPermissions';

const PageHotspotMenu = ({ language, handleClick, disableMenu, userPermissions }) => {
  const handleOnClick = () => {
    handleClick(constants.PAGE_HOTSPOT);
  };

  return (
    <TooltipCustom
      title={
        !toJS(userPermissions.permissionList.permissions.includes('authoring_can_edit')) ? (
          <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
        ) : (
          <FormattedMessage {...language.getText('tooltip.PAGE_HOTSPOT')} />
        )
      }
    >
      <span>
        <IconButton
          id="pageHotspotIconId"
          aria-label="Page Hotspot"
          aria-controls="pageHotspotMenuId"
          aria-haspopup="true"
          onClick={handleOnClick}
          disabled={disableMenu || !toJS(userPermissions.permissionList.permissions.includes('authoring_can_edit'))}
        >
          <PageHotspotIcon fontSize="large" color="action" />
        </IconButton>
      </span>
    </TooltipCustom>
  );
};

PageHotspotMenu.propTypes = {
  language: shapes.language.isRequired,
  handleClick: PropTypes.func.isRequired,
  disableMenu: PropTypes.bool.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired
};

export default inject('language', 'userPermissions')(PageHotspotMenu);
