/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

const pdfConstants = {
  LINK_TYPE: {
    AUDIO_TEXT_SYNCH: 10,
    CHROMELESS_URL: 15,
    EMAIL: 8,
    FACELESSAUDIO: 12,
    FLV: 2,
    GLOSSARY_TERM: 3,
    H264: 13,
    IMAGE: 1,
    IPADAPP: 14,
    JAZZASSET: 16,
    LTILINK: 11,
    MP3: 4,
    PAGE_NUMBER: 5,
    SWF: 6,
    URL: 7,
    VIRTUAL_LEARNING_ASSET: 9
  },
  REGION_TYPE: {
    AUDIO: 1,
    CROSS_REFERENCE: 2,
    EMAIL: 3,
    EXCEL: 13,
    FLASH: 4,
    GLOSSARY_TERM: 5,
    IMAGE: 6,
    INDEX_LINK: 7,
    IPADAPP: 17,
    JAZZASSET: 18,
    LTILINK: 16,
    MEDIA: 8,
    PDF: 14,
    POWERPOINT: 9,
    TOC_LINK: 10,
    URL: 11,
    VIDEO: 12,
    WORD_DOC: 15
  },
  HOTSPOT_TYPE: {
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO',
    IMAGE: 'IMAGE',
    DOCUMENT: 'DOCUMENT',
    URL: 'URL',
    PAGENUMBER: 'PAGENUMBER',
    EMAIL: 'EMAIL',
    LTILINK: 'LTILINK',
    SPPASSET: 'SPPASSET',
    GLOSSARY_TERM: 'GLOSSARY_TERM',
    FLV: 'FLV',
    SWF: 'SWF',
    IPADAPP: 'IPADAPP',
    JAZZASSET: 'JAZZASSET',
    FLASH: 'FLASH'
  }
};

module.exports = pdfConstants;
