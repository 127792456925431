import React from 'react';
// import PropTypes from 'prop-types';
// import Typography from '@material-ui/core/Typography/Typography';
// import { createTheme } from '@material-ui/core/styles';
// import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
// import Popover from '@mui/material/Popover';
// import { inject, observer } from 'mobx-react';
// import { Pagination, Stack, Table } from '@mui/material';
// import { Stack, Table } from '@mui/material';
// import { toJS } from 'mobx';
// import Framework, { shapes } from '@greenville/framework';
import {
  //   Box,
  //   Button,
  //   Radio,
  //   TableBody,
  //   TableCell,
  //   TableContainer,
  //   TableHead,
  //   TableRow,
  //   TableSortLabel,
  withStyles
} from '@material-ui/core';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import ChatDialogComponent from './ChatDialogComponent';
// import ResponseModel from '../../models/Response';
// import ChatgptUtilityIESUser from '../../models/ChatgptIESUser';
// import ChatgptUtilityMapping from '../../models/ChatgptMapping';
// import * as constants from '../../../../common/constants';
import TitleMcqSearch from './datagrid/TitleMcqSearch';
import ValidatePermissions from '../../../users/ValidatePermissions';

// const themes = createTheme({
//   palette: {
//     primary: {
//       light: '#047a9c',
//       main: '#005d83',
//       dark: '#003558',
//       contrastText: '#ffffff'
//     }
//   },
//   overrides: {
//     MuiButton: {
//       root: {
//         borderRadius: 30
//       }
//     }
//   }
// });

const styles = () => ({
  tableCustomWidth: {
    width: '2px'
  },
  tableDateWidth: {
    width: '190px'
  },
  tableBookWidth: {
    width: '160px'
  },
  tableTitleWidth: {
    width: '150px'
  },
  tableFeedWidth: {
    width: '140px'
  },
  tableReasonWidth: {
    width: '200px'
  },
  tableCommentsWidth: {
    width: '160px'
  },
  tableTypeWidth: {
    width: '100px'
  },
  tableUserWidth: {
    width: '160px'
  },
  tableUserIdWidth: {
    width: '100px'
  },
  tableFilterDateWidth: {
    width: '190px'
  },
  tableFilterTextWidth: {
    width: '150px'
  },
  tableFilterFeedWidth: {
    width: '150px'
  },
  tableFilterReasonWidth: {
    width: '180px'
  },
  tableFilterTooltipWidth: {
    width: '100px'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  tableTypeText: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  radioButton: {
    textAlign: 'center'
  },
  buttonStyle: {
    cursor: 'pointer',
    width: '170px',
    margin: '10px',
    fontSize: '14px',
    background: '#005d83 !important'
  },
  root: {
    width: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  },
  tableTextAlongWidth: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    width: 160
  },
  columnWidth: {
    width: 200
  },
  contentText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

/* eslint-disable no-shadow */
function McqSearch() {
  const showDashboardEnabled = false;
  return (
    <>
      <ValidatePermissions givenPermissions="mcq_can_view" showDashboardEnabled={showDashboardEnabled}>
        <TitleMcqSearch />
      </ValidatePermissions>
    </>
  );
}

McqSearch.propTypes = {};

McqSearch.defaultProps = {};

// export default withStyles(styles)(
//   observer(
//     inject(
//       'chatgptResponseUtility',
//       'chatgptUtilityResponseStatus',
//       'ChatgptUtilityIESUserData',
//       'chatgptMappingUtility'
//     )(ChatMcqSearch)
//   )
// );
export default withStyles(styles)(McqSearch);
