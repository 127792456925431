/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import ServerSideProcessUtility from './components/ServerSideProcessUtility';
import ServerSideProcess from './models/ServerSideProcess';
import Header from '../../common/header/Header';
import Loader from '../../common/Loader';
import * as constants from '../../common/constants';

const styles = {
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80
  }
};
@inject('serverSideProcessState', 'serverSideProcessStatus')
@observer
class ServerSideProcessContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    serverSideProcessState: shapes.modelOf(ServerSideProcess).isRequired,
    serverSideProcessStatus: shapes.state.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      searchText: null
    };
    this.startServerSideProcess = this.startServerSideProcess.bind(this);
  }

  componentDidMount() {
    const { serverSideProcessState } = this.props;
    serverSideProcessState.fetchSSPData();
  }

  startServerSideProcess = (globalBookId) => {
    const { serverSideProcessState } = this.props;
    const booksToProcessArr = globalBookId.replace(/\s+/g, '').split(',').filter(String);
    const payload = { booksToProcess: booksToProcessArr };
    serverSideProcessState.fetch(payload);
    this.setState({ searchText: globalBookId });
    Framework.getEventManager().on(constants.SEARCH_SERVER_SIDE_PROCESS_DATA, () => {
      serverSideProcessState.fetchSSPData();
    });
  };

  render() {
    const { serverSideProcessStatus, classes, serverSideProcessState } = this.props;
    const { searchText } = this.state;
    return (
      <>
        <Header />
        <main className={classes.contentWrapper} id="mainContent" role="main">
          <Container maxWidth="xl">
            <LoadingHandler
              loading={serverSideProcessStatus.isPending}
              content={
                <div>
                  <ServerSideProcessUtility
                    start={this.startServerSideProcess}
                    searchText={searchText}
                    data={serverSideProcessState.ServerSideProcessData}
                  />
                  <br />
                </div>
              }
              loadingContent={<Loader />}
            />
          </Container>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(ServerSideProcessContainer);
