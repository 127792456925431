/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class UserRolePermissionsService extends BaseService {
  constructor() {
    super(constants.MANAGE_USERS_PERMISSIONS, 'permissionsStatus');
  }

  handleEvent(event, id) {
    return this.getClient('evergreen').get(`/users/${id.id}/permissions`);
  }

  handleResponse(response) {
    // eslint-disable-line class-methods-use-this
    if (response.data.data.permissions.length > 0) {
      Framework.getStoreRegistry().getStore('userPermissions').setPermissions(response.data);
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
    }
  }
}
