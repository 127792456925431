import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname.includes('chatgptutility')) {
      history.push('/chatgptutility');
    }
  }, []);
  return <></>;
};

export default NotFound;
