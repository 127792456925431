import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { inject } from 'mobx-react';
import { shapes } from '@greenville/framework';

const styles = (theme) => ({
  root: {},
  message: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '15px'
  }
});

/*
Alert object details :
  message - Confirmation Message in Dialog
  open - true/false
  callback - Action to perform based on the selection.
*/
class Confirmation extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired
  };

  constructor(props) {
    super(props);
    const { open } = this.props;
    this.state = {
      openDialog: open || true
    };
  }

  handleClick = (action) => {
    const { callback } = this.props;
    this.setState({ openDialog: false });
    callback(action);
  };

  render() {
    const { openDialog } = this.state;
    const { classes, language, message } = this.props;
    return (
      <div>
        <Dialog open={openDialog} className={classes.root}>
          <DialogContent className={classes.message}>
            <Typography align="center">{message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" size="small" onClick={() => this.handleClick(false)}>
              <FormattedMessage {...language.getText('common.NO')} />
            </Button>
            <Button variant="outlined" color="primary" size="small" onClick={() => this.handleClick(true)}>
              <FormattedMessage {...language.getText('common.YES')} />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(inject('language')(Confirmation));
