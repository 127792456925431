/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import EntitlementService from './services/EntitlementService';
import Entitlement from './models/Entitlement';
import EntitlementContainer from './EntitlementContainer';

export default {
  path: ['/books/:id/entitlement'],
  exact: true,
  services: [new EntitlementService()],
  stores: [
    {
      name: 'entitlementState',
      spec: Entitlement,
      saveLocally: false,
      initialState: {
        entitlementData: []
      }
    }
  ],
  render: (props) => (
    <EntitlementContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
