import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const BookSearchModel = types.model('BookSearchModel', {
  bookId: types.maybeNull(types.string),
  author: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  status: types.maybeNull(types.string)
});

const McqSearch = types
  .model('McqSearch', {
    data: types.optional(types.array(BookSearchModel), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.FETCH_MCQ_SEARCH_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_MCQ_SEARCH_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_MCQ_SEARCH_DATA);
    }
  }));

export default McqSearch;
