/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
// import TitlePreview from '../components/workflow/TitlePreview';

/**
 * Mobx State Tree Store
 */
const WorkFlowObj = types.model('WorkFlowObj', {
  businessUnitId: types.maybeNull(types.integer),
  businessUnitName: types.maybeNull(types.string)
});

const WorkFlowList = types.model('WorkFlowList', {
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  middleName: types.maybeNull(types.string)
});

const WorkFlowHistoryPost = types.model('WorkFlowHistoryPost', {
  date: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  displayName: types.maybeNull(types.string),
  authoringStatus: types.maybeNull(types.string),
  bookId: types.maybeNull(types.integer),
  updfEpsUrl: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  actionUser: types.maybeNull(types.string)
});

const WorkFlowPost = types.model('WorkFlowPost', {
  id: types.maybeNull(types.integer),
  actionUser: types.maybeNull(types.string),
  authorsList: types.optional(types.array(WorkFlowList), []),
  authoringServerId: types.maybeNull(types.integer),
  authoringStatus: types.maybeNull(types.string),
  bookId: types.maybeNull(types.integer),
  bookPagecount: types.maybeNull(types.integer),
  bookTitle: types.maybeNull(types.string),
  businessUnit: types.maybeNull(types.string),
  contectId: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  directory: types.maybeNull(types.string),
  displayName: types.maybeNull(types.string),
  eBookIsbn10: types.maybeNull(types.string),
  eBookIsbn13: types.maybeNull(types.string),
  identifier: types.maybeNull(types.string),
  isDL: types.maybeNull(types.string),
  isDLLive: types.maybeNull(types.string),
  isPreview: types.maybeNull(types.boolean),
  isbn10: types.maybeNull(types.string),
  isbn13: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  modifiedDate: types.maybeNull(types.string),
  reorderToc: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  subBusinessUnit: types.maybeNull(types.string),
  updfEpsUrl: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  workflow: types.maybeNull(types.string),
  globalBookId: types.maybeNull(types.string),
  processStatus: types.maybeNull(types.string),
  edition: types.maybeNull(types.string)
});

const WorkflowResp = types.model('WorkflowResp', {
  totalCount: types.maybeNull(types.integer),
  results: types.optional(types.array(WorkFlowPost), [])
});

const CoverPreview = types.model('CoverPreview', {
  coverImage: types.maybeNull(types.string),
  iPadCoverImage: types.maybeNull(types.string)
});

const TitlepreviewVersion = types.model('TitlepreviewVersion', {
  version: types.maybeNull(types.string)
});

const PreviewUrls = types.model('PreviewUrls', {
  pdfFileUrl: types.maybeNull(types.string),
  optimizedPdfFileUrl: types.maybeNull(types.string),
  ipadpdfsFileUrl: types.maybeNull(types.string),
  swfsFileUrl: types.maybeNull(types.string),
  thumbnailsFileUrl: types.maybeNull(types.string),
  txtFileUrl: types.maybeNull(types.string)
});

const TitlePreviewUrl = types.model('TitlePreviewUrl', {
  pageNumber: types.maybeNull(types.integer),
  previewVersion: types.maybeNull(types.string),
  totalPageCount: types.maybeNull(types.integer),
  logicalPage: types.maybeNull(types.string),
  previewUrls: types.maybeNull(PreviewUrls, {})
});

const ContentManagementTitleData = types.model('ContentManagementTitleData', {
  cover: types.maybeNull(CoverPreview, {}),
  previewVersion: types.maybeNull(types.array(TitlepreviewVersion), []),
  preview: types.maybeNull(TitlePreviewUrl, {})
});

const Workflow = types
  .model('Workflow', {
    workflowData: types.optional(types.array(WorkFlowObj), []),
    workflowPostData: types.optional(WorkflowResp, {}),
    workflowHistoryData: types.optional(types.array(WorkFlowHistoryPost), []),
    contentManagementPreviewData: types.optional(ContentManagementTitleData, {})
  })
  .actions((self) => ({
    fetch() {
      Framework.getEventManager().publish(constants.CM_GET_BUSINESS_UNIT);
    },
    fetchHistory(cmValue) {
      Framework.getEventManager().publish(constants.CM_WORKFLOW_STATUS_HISTORY_GET, {
        data: cmValue
      });
    },
    fetchPreviewData(cmTitle, bookValue) {
      Framework.getEventManager().publish(constants.CM_PREVIEW_TITLE_DATA, {
        data: cmTitle,
        previewData: bookValue
      });
    },
    fetchCancelIngestionData(data) {
      Framework.getEventManager().publish(constants.CM_CANCEL_INGESTION_DATA, {
        data
      });
    },
    set(response) {
      applySnapshot(self.workflowData, response.data);
    },
    setWorkflowSearch(response) {
      const output = self.workflowPostData;
      output.results =
        response && response.data && response.data.results && response.data.results.length > 0
          ? response.data.results.map((eachValue, index) => ({
              id: index + 1,
              ...eachValue
            }))
          : [];
      output.totalCount = response.data.totalCount;
      applySnapshot(self.workflowPostData, output);
      Framework.getEventManager().publish(constants.CM_WORKFLOW_SEARCH, {});
    },
    setWorkflowHistory(response) {
      applySnapshot(self.workflowHistoryData, response.data);
    },
    setPreviewData(response) {
      applySnapshot(self.contentManagementPreviewData, response.data);
      Framework.getEventManager().publish(constants.CM_PREVIEW_DATA_FETCHED);
    },
    setPreviewTitleChanges(response) {
      applySnapshot(self.contentManagementPreviewData, response.data);
      Framework.getEventManager().publish(constants.CM_TITLE_DATA_FETCHED_NAVIGATION);
    },
    setCancelIngestion() {
      Framework.getEventManager().publish(constants.CM_INGESTION_CANCEL);
    },
    fetchPreviewTitleChanges(globalBookId, payload) {
      Framework.getEventManager().publish(constants.CM_PREVIEW_TITLE_CHANGES_POST, {
        data: globalBookId,
        previewData: payload
      });
    },
    savePayload(payload) {
      Framework.getEventManager().publish(constants.CM_WORKFLOW_STATUS_SEARCH_POST, { payload });
    }
  }));

export default Workflow;
