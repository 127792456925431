const linkTypeDropDown = [
  {
    id: 0,
    value: 'Select',
    link: [
      {
        id: 0,
        value: 'Select'
      }
    ]
  },
  {
    id: 15,
    value: 'Chromelessurl',
    link: [
      {
        id: 11,
        value: 'Url'
      }
    ],
    disabled: ['linkXYWH', 'transparent', 'glossary', 'sequenceID'],
    required: ['linkUri', 'regionTypeId', 'roleTypeId']
  },
  {
    id: 8,
    value: 'Email',
    link: [
      {
        id: 3,
        value: 'Email'
      }
    ],
    disabled: [
      'linkXYWH',
      'mediaWH',
      'transparent',
      'smartPearsonPlayer',
      'glossary',
      'sequenceID',
      'isBrowserView',
      'downloadable',
      'downloadUrl',
      'altLink'
    ],
    required: ['linkUri', 'regionTypeId', 'roleTypeId']
  },
  {
    id: 12,
    value: 'Facelessaudio',
    link: [
      {
        id: 1,
        value: 'Audio'
      }
    ],
    disabled: [
      'linkXYWH',
      'mediaWH',
      'transparent',
      'smartPearsonPlayer',
      'glossary',
      'sequenceID',
      'isBrowserView',
      'downloadUrl',
      'altLink'
    ],
    required: ['linkUri', 'regionTypeId', 'roleTypeId', 'name']
  },
  // {
  //   id: 2,
  //   value: 'Flv',
  //   link: [
  //     {
  //       id: 12,
  //       value: 'Video'
  //     }
  //   ],
  //   disabled: [
  //     'linkXYWH', 'transparent', 'smartPearsonPlayer', 'glossary', 'sequenceID', 'isBrowserView',
  //     'downloadUrl', 'altLink'
  //   ],
  //   required: [
  //     'linkUri', 'regionTypeId', 'roleTypeId', 'name'
  //   ]
  // },
  {
    id: 3,
    value: 'Glossaryterm',
    link: [
      {
        id: 5,
        value: 'Glossaryterm'
      }
    ],
    disabled: [
      'linkXYWH',
      'mediaWH',
      'transparent',
      'smartPearsonPlayer',
      'sequenceID',
      'isBrowserView',
      'downloadable',
      'downloadUrl',
      'altLink'
    ],
    required: ['linkUri', 'regionTypeId', 'roleTypeId', 'glossary']
  },
  {
    id: 13,
    value: 'H264',
    link: [
      {
        id: 12,
        value: 'Video'
      }
    ],
    disabled: [
      'linkXYWH',
      'transparent',
      'smartPearsonPlayer',
      'glossary',
      'sequenceID',
      'isBrowserView',
      'downloadUrl',
      'altLink'
    ],
    required: ['linkUri', 'regionTypeId', 'roleTypeId', 'name']
  },
  {
    id: 1,
    value: 'Image',
    link: [
      {
        id: 6,
        value: 'Image'
      }
    ],
    disabled: [
      'linkXYWH',
      'transparent',
      'smartPearsonPlayer',
      'glossary',
      'sequenceID',
      'isBrowserView',
      'downloadUrl',
      'altLink'
    ],
    required: ['linkUri', 'regionTypeId', 'roleTypeId', 'name']
  },
  // {
  //   id: 14,
  //   value: 'Ipadapp',
  //   link: [
  //     {
  //       id: 17,
  //       value: 'Ipadapp'
  //     }
  //   ],
  //   disabled: [
  //     'transparent', 'smartPearsonPlayer', 'sequenceID', 'isIpad', 'isBrowserView', 'downloadable',
  //     'downloadUrl', 'altLink'
  //   ],
  //   required: [
  //     'linkUri', 'regionTypeId', 'roleTypeId'
  //   ]
  // },
  // {
  //   id: 16,
  //   value: 'Jazzasset',
  //   link: [
  //     {
  //       id: 18,
  //       value: 'Jazzasset'
  //     }
  //   ],
  //   disabled: [
  //     'linkXYWH', 'transparent', 'smartPearsonPlayer', 'glossary', 'sequenceID', 'isIpad',
  //     'isBrowserView', 'downloadable', 'downloadUrl', 'altLink'
  //   ],
  //   required: [
  //     'linkUri', 'regionTypeId', 'roleTypeId'
  //   ]
  // },
  {
    id: 11,
    value: 'Ltilink',
    link: [
      {
        id: 16,
        value: 'Ltilink'
      }
    ],
    disabled: [
      'linkXYWH',
      'transparent',
      'smartPearsonPlayer',
      'glossary',
      'sequenceID',
      'isBrowserView',
      'downloadable',
      'downloadUrl',
      'altLink'
    ],
    required: ['linkUri', 'regionTypeId', 'roleTypeId']
  },
  {
    id: 4,
    value: 'Mp3',
    link: [
      {
        id: 1,
        value: 'Audio'
      }
    ],
    disabled: [
      'linkXYWH',
      'mediaWH',
      'transparent',
      'smartPearsonPlayer',
      'glossary',
      'sequenceID',
      'isBrowserView',
      'downloadUrl',
      'altLink'
    ],
    required: ['linkUri', 'regionTypeId', 'roleTypeId', 'name']
  },
  {
    id: 5,
    value: 'Pagenumber',
    link: [
      {
        id: 0,
        value: 'Select'
      },
      {
        id: 2,
        value: 'Crossreference'
      },
      {
        id: 7,
        value: 'IndexLink'
      },
      {
        id: 10,
        value: 'Toclink'
      }
    ],
    disabled: [
      'mediaWH',
      'transparent',
      'smartPearsonPlayer',
      'glossary',
      'sequenceID',
      'isBrowserView',
      'downloadUrl',
      'altLink'
    ],
    required: ['linkUri', 'regionTypeId', 'roleTypeId']
  },
  // {
  //   id: 6,
  //   value: 'Swf',
  //   link: [
  //     {
  //       id: 4,
  //       value: 'Flash'
  //     }
  //   ],
  //   disabled: [
  //     'linkXYWH', 'transparent', 'smartPearsonPlayer', 'glossary', 'sequenceID', 'isBrowserView',
  //     'downloadUrl'
  //   ],
  //   required: [
  //     'linkUri', 'regionTypeId', 'roleTypeId', 'name'
  //   ]
  // },
  {
    id: 7,
    value: 'Url',
    link: [
      {
        id: 0,
        value: 'Select'
      },
      {
        id: 1,
        value: 'Audio'
      },
      {
        id: 6,
        value: 'Image'
      },
      {
        id: 8,
        value: 'Media'
      },
      {
        id: 9,
        value: 'PowerPoint'
      },
      {
        id: 11,
        value: 'Url'
      },
      {
        id: 12,
        value: 'Video'
      },
      {
        id: 13,
        value: 'Excel'
      },
      {
        id: 14,
        value: 'Pdf'
      },
      {
        id: 15,
        value: 'Word'
      }
    ],
    disabled: ['linkXYWH', 'transparent', 'smartPearsonPlayer', 'glossary', 'sequenceID', 'isBrowserView'],
    required: ['linkUri', 'regionTypeId', 'roleTypeId']
  },
  {
    id: 9,
    value: 'Virtuallearningasset',
    link: [
      {
        id: 0,
        value: 'Select'
      },
      {
        id: 1,
        value: 'Audio'
      },
      {
        id: 6,
        value: 'Image'
      },
      {
        id: 8,
        value: 'Media'
      },
      {
        id: 9,
        value: 'PowerPoint'
      },
      {
        id: 11,
        value: 'Url'
      },
      {
        id: 12,
        value: 'Video'
      },
      {
        id: 13,
        value: 'Excel'
      },
      {
        id: 14,
        value: 'Pdf'
      },
      {
        id: 15,
        value: 'Word'
      }
    ],
    disabled: ['linkXYWH', 'transparent', 'glossary', 'sequenceID', 'isBrowserView'],
    required: ['linkUri', 'regionTypeId', 'roleTypeId']
  }
];

// const typeDropDown = [
//   'Select',
//   'Audio',
//   'Image',
//   'Media',
//   'PowerPoint',
//   'URL',
//   'Video',
//   'Excel',
//   'Pdf',
//   'Word'
// ];

const roleDropDown = [
  {
    id: 0,
    value: 'Select'
  },
  {
    id: 1,
    value: 'All'
  },
  {
    id: 2,
    value: 'Student'
  },
  {
    id: 3,
    value: 'Teacher'
  }
];

const iconDropDown = [
  {
    id: 0,
    value: 'Select'
  },
  {
    id: 1,
    value: 'None'
  },
  {
    id: 2,
    value: 'Audio'
  },
  {
    id: 3,
    value: 'Email'
  },
  {
    id: 4,
    value: 'Excel'
  },
  {
    id: 5,
    value: 'Media'
  },
  {
    id: 6,
    value: 'Pdf'
  },
  {
    id: 7,
    value: 'Powerpoint'
  },
  {
    id: 8,
    value: 'Url'
  },
  {
    id: 9,
    value: 'Video'
  },
  {
    id: 10,
    value: 'Word'
  },
  {
    id: 11,
    value: 'Custom 1'
  },
  {
    id: 12,
    value: 'Custom 2'
  },
  {
    id: 13,
    value: 'Custom 3'
  },
  {
    id: 14,
    value: 'Custom 4'
  },
  {
    id: 15,
    value: 'Custom 5'
  },
  {
    id: 16,
    value: 'Custom 6'
  },
  {
    id: 17,
    value: 'Custom 7'
  },
  {
    id: 18,
    value: 'Custom 8'
  },
  {
    id: 19,
    value: 'Custom 9'
  },
  {
    id: 20,
    value: 'Custom 10'
  },
  {
    id: 21,
    value: 'Custom 11'
  },
  {
    id: 22,
    value: 'Custom 12'
  },
  {
    id: 26,
    value: 'itilink'
  }
  // {
  //   id: 24,
  //   value: 'Ipadapp'
  // },
  // {
  //   id: 25,
  //   value: 'Jazzasset'
  // }
];

const platform = [
  {
    id: 1,
    value: 'Default Platform'
  },
  {
    id: 1000,
    value: 'Testing for Mastering'
  },
  {
    id: 1001,
    value: 'Mastering Physics'
  },
  {
    id: 1002,
    value: 'Mastering Chemistry'
  },
  {
    id: 1003,
    value: 'Mastering Astronomy'
  },
  {
    id: 1004,
    value: 'Mastering Biology'
  },
  {
    id: 1005,
    value: 'Mastering Engineering'
  },
  {
    id: 1006,
    value: 'Mastering AandP'
  },
  {
    id: 1007,
    value: 'MyAnthropologyLab'
  },
  {
    id: 1008,
    value: 'Pearson MyLab or Pearson Learning Management Platform'
  },
  {
    id: 1009,
    value: 'WPS AP Biology'
  },
  {
    id: 1010,
    value: 'Course Compass Biology'
  },
  {
    id: 1011,
    value: 'Course Compass AP Biology'
  },
  {
    id: 1012,
    value: 'Course Compass Econ'
  },
  {
    id: 1013,
    value: 'MyAutomotiveLab'
  },
  {
    id: 1014,
    value: 'MyHVACLab'
  },
  {
    id: 1015,
    value: 'MyCompLab'
  },
  {
    id: 1016,
    value: 'MyPsychologyLab'
  },
  {
    id: 1017,
    value: 'MySocLab'
  },
  {
    id: 1018,
    value: 'MyMathLab'
  },
  {
    id: 1019,
    value: 'MyNursingLab'
  },
  {
    id: 1020,
    value: 'MySpeechLab'
  },
  {
    id: 1021,
    value: 'MyCommunicationLab'
  },
  {
    id: 1022,
    value: 'MyDevelopmentLab'
  },
  {
    id: 1023,
    value: 'MyPoliSciLab'
  },
  {
    id: 1024,
    value: 'MyCulinaryLab'
  },
  {
    id: 1025,
    value: 'MyEdLab'
  },
  {
    id: 1026,
    value: 'Pegasus'
  },
  {
    id: 1027,
    value: 'WPS'
  },
  {
    id: 1028,
    value: 'Course Compass'
  },
  {
    id: 1029,
    value: 'EPIC'
  },
  {
    id: 1030,
    value: 'Math XL'
  },
  {
    id: 1031,
    value: 'My Business Lab'
  },
  {
    id: 1032,
    value: 'MyOBLab'
  },
  {
    id: 1033,
    value: 'Synapse--DO NOT USE--USE PROD2'
  },
  {
    id: 1034,
    value: 'Pegasus 4.0'
  },
  {
    id: 1035,
    value: 'eCollege Course Aware'
  },
  {
    id: 1036,
    value: 'eCollege Non-Course Aware'
  },
  {
    id: 1037,
    value: 'Pearson Higher Ed'
  },
  {
    id: 1038,
    value: 'MyTechCommLab'
  },
  {
    id: 1039,
    value: 'Connected Sampling'
  },
  {
    id: 1040,
    value: 'myITLab'
  },
  {
    id: 1041,
    value: 'MasteringMicrobiology'
  },
  {
    id: 1042,
    value: 'MasteringEnvironmentalScience'
  },
  {
    id: 1043,
    value: 'MasteringGeoscience'
  },
  {
    id: 1044,
    value: 'France-Standalone'
  },
  {
    id: 1045,
    value: 'MyPhilosophyLab'
  },
  {
    id: 1046,
    value: 'MyManagementLab'
  },
  {
    id: 1047,
    value: 'MyMarketingLab'
  },
  {
    id: 1048,
    value: 'MyBizLab'
  },
  {
    id: 1049,
    value: 'MyBCommLab'
  },
  {
    id: 1050,
    value: 'MyIBLab'
  },
  {
    id: 1051,
    value: 'MyMISLab'
  },
  {
    id: 1052,
    value: 'MyITLab'
  },
  {
    id: 1053,
    value: 'Synpase 5.0--DO NOT USE--USE PROD2'
  },
  {
    id: 1054,
    value: 'MasteringGeography'
  },
  {
    id: 1055,
    value: 'MasteringGeology'
  },
  {
    id: 1056,
    value: 'MasteringGenetics'
  },
  {
    id: 1057,
    value: 'MyMusicLab'
  },
  {
    id: 1058,
    value: 'Test Platform ID: Do Not Use'
  },
  {
    id: 1059,
    value: 'MyMathLab Global'
  },
  {
    id: 1065,
    value: 'MyFashionLab'
  },
  {
    id: 1066,
    value: 'Epic - pre-authoring'
  },
  {
    id: 1077,
    value: 'BTEC HN eLearning'
  },
  {
    id: 1078,
    value: 'Pegasus 5.7 HED MyITLab Prod'
  },
  {
    id: 1079,
    value: 'MyManagementLab - MMND'
  },
  {
    id: 1080,
    value: 'MyMarketingLab - MMND'
  },
  {
    id: 1081,
    value: 'MyBcommLab - MMND'
  },
  {
    id: 1082,
    value: 'MyBizlab - MMND'
  },
  {
    id: 1083,
    value: 'OLE Integration'
  },
  {
    id: 1084,
    value: 'MyEducationLab - MMND'
  },
  {
    id: 1085,
    value: 'MyEdLeadershipLab - MMND'
  },
  {
    id: 1086,
    value: 'MyCounselingLab â€“ MMND'
  },
  {
    id: 1087,
    value: 'OpenClass'
  },
  {
    id: 1088,
    value: 'Health Science MyLabs'
  },
  {
    id: 1089,
    value: 'Pearson Custom Library'
  },
  {
    id: 1090,
    value: 'International MyLabs (iML)'
  },
  {
    id: 1091,
    value: 'New My English Lab (NMEL)'
  },
  {
    id: 1092,
    value: 'Equella Production 5.1+'
  },
  {
    id: 1093,
    value: 'Equella Production 6.1+'
  },
  {
    id: 1095,
    value: 'learning.com'
  },
  {
    id: 1096,
    value: 'Alberta core (Equella)'
  },
  {
    id: 1097,
    value: 'MasteringNutrition'
  },
  {
    id: 1098,
    value: 'York (Equella)'
  },
  {
    id: 1099,
    value: 'Realize (Based on OLE Platform)'
  },
  {
    id: 1100,
    value: 'MyLiteratureLab'
  },
  {
    id: 1101,
    value: 'MyFoundationsLab'
  },
  {
    id: 1102,
    value: 'MyReadingLab'
  },
  {
    id: 1103,
    value: 'MyWritingLab'
  },
  {
    id: 1104,
    value: 'MasteringEngineering'
  },
  {
    id: 1105,
    value: 'MyStudentSuccessLab'
  },
  {
    id: 1106,
    value: 'Brazil-Standalone'
  },
  {
    id: 1107,
    value: 'South Africa Schools-Standalone'
  },
  {
    id: 1108,
    value: 'Mexico-Standalone'
  },
  {
    id: 1109,
    value: 'MySkillsLab'
  },
  {
    id: 1110,
    value: 'Pearson ERPI Access Portal'
  },
  {
    id: 1111,
    value: 'Leonardo'
  },
  {
    id: 1112,
    value: 'PELS (Pearson English Learning System)'
  },
  {
    id: 1113,
    value: 'MyProgrammingLab'
  },
  {
    id: 1114,
    value: 'XL Platform Global'
  },
  {
    id: 1115,
    value: 'XL Platform School'
  },
  {
    id: 1116,
    value: 'My Pearson Place (Italia)'
  },
  {
    id: 1117,
    value: 'Alberta testcore (Equella)'
  },
  {
    id: 1118,
    value: 'PSN-Prod-ICD'
  },
  {
    id: 1119,
    value: 'EasyBridge'
  }
];

const hotspotIconDropDown = [
  {
    iconTypeId: 0,
    linkTypeId: 0,
    regionTypeId: 99,
    value: 'Select'
  },
  {
    iconTypeId: 2,
    linkTypeId: 12,
    regionTypeId: 1,
    value: 'Audio'
  },
  {
    iconTypeId: 3,
    linkTypeId: 8,
    regionTypeId: 3,
    value: 'Email'
  },
  {
    iconTypeId: 4,
    linkTypeId: 7,
    regionTypeId: 13,
    value: 'Excel'
  },
  {
    iconTypeId: 5,
    linkTypeId: 7,
    regionTypeId: 8,
    value: 'Media'
  },
  {
    iconTypeId: 6,
    linkTypeId: 7,
    regionTypeId: 14,
    value: 'Pdf'
  },
  {
    iconTypeId: 7,
    linkTypeId: 7,
    regionTypeId: 9,
    value: 'Powerpoint'
  },
  {
    iconTypeId: 8,
    linkTypeId: 15,
    regionTypeId: 11,
    value: 'Url'
  },
  {
    iconTypeId: 9,
    linkTypeId: 7,
    regionTypeId: 12,
    value: 'Video'
  },
  {
    iconTypeId: 10,
    linkTypeId: 7,
    regionTypeId: 15,
    value: 'Word'
  },
  {
    iconTypeId: 11,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 1'
  },
  {
    iconTypeId: 12,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 2'
  },
  {
    iconTypeId: 13,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 3'
  },
  {
    iconTypeId: 14,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 4'
  },
  {
    iconTypeId: 15,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 5'
  },
  {
    iconTypeId: 16,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 6'
  },
  {
    iconTypeId: 17,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 7'
  },
  {
    iconTypeId: 18,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 8'
  },
  {
    iconTypeId: 19,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 9'
  },
  {
    iconTypeId: 20,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 10'
  },
  {
    iconTypeId: 21,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 11'
  },
  {
    iconTypeId: 22,
    linkTypeId: 0,
    regionTypeId: 0,
    value: 'Custom 12'
  },
  {
    iconTypeId: 26,
    linkTypeId: 11,
    regionTypeId: 16,
    platformId: 1,
    value: 'itilink'
  }
  // {
  //   iconTypeId: 24,
  //   linkTypeId: 14,
  //   regionTypeId: 17,
  //   value: 'Ipadapp'
  // },
  // {
  //   iconTypeId: 25,
  //   linkTypeId: 16,
  //   regionTypeId: 18,
  //   value: 'Jazzasset'
  // }
];

const intProperties = [
  'x',
  'y',
  'width',
  'height',
  'mediaHeight',
  'mediaWidth',
  'linkX',
  'linkY',
  'linkWidth',
  'linkHeight'
];

const dialogConfig = {
  hotspotIconDropDown,
  linkTypeDropDown,
  roleDropDown,
  iconDropDown,
  platform,
  intProperties
};

module.exports = dialogConfig;
