import React from 'react';
import { Button, Typography, withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { inject } from 'mobx-react';
import Framework, { shapes } from '@greenville/framework';
import * as constants from '../constants';
import UploadContent from '../../modules/contentManager/components/upload/UploadContent';

const styles = (theme) => ({
  container: {
    marginBottom: 20
  },
  uploadText: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },

  uploadButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  }
});

/*
Alert object details :
  message - ChooseFile helper text
  disabled - Disable text if requires inputs first
  uploadPath - Path where content needs to upload
*/
class ChooseFile extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    uploadPath: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      openUploadContent: false
    };
  }

  handleOpenUploadContent = () => {
    this.setState({ openUploadContent: true });
    Framework.getEventManager().on(constants.CM_BUCKET_CLOSE, () => {
      this.setState({ openUploadContent: false });
    });
    Framework.getEventManager().on(constants.CM_CREATE_NEW_BUCKET, () => {
      this.setState({ openUploadContent: true });
    });
  };

  closeUploadContent = () => {
    this.setState({ openUploadContent: false });
  };

  render() {
    const { openUploadContent } = this.state;
    const { classes, language, message, disabled, uploadPath } = this.props;
    return (
      <>
        <Grid container direction="column" alignItems="center" justify="space-between">
          <Grid container display="grid" direction="row" justifyContent="center" alignItems="center">
            <Grid item direction="row" className={classes.uploadText}>
              <Typography variant="h5">{message}</Typography>
            </Grid>
            <Grid item>
              <Button
                disabled={disabled}
                variant="outlined"
                color="primary"
                size="small"
                className={classes.uploadButton}
                onClick={() => this.handleOpenUploadContent()}
              >
                <FormattedMessage {...language.getText('contentmanager.CHOOSEFILES')} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {openUploadContent && (
          <UploadContent
            openUploadContent={openUploadContent}
            closeUploadContent={() => this.closeUploadContent}
            uploadEpubContentPath={uploadPath}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(ChooseFile));
