/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import Request from './models/Request';
import ExportRequest from './models/ExportRequest';
import MetaData from './models/MetaData';
import NewRequest from './models/NewRequest';
import NewRevision from './models/NewRevision';
import RequestQueueSearchService from './services/requestQueue/RequestQueueSearchService';
import ExportQueueSearchService from './services/exportQueue/ExportQueueSearchService';
import EtextStatusService from './services/metaData/EtextStatusService';
import EtextDivisionsService from './services/metaData/EtextDivisionsService';
import EtextDisciplineCodeService from './services/metaData/EtextDisciplineCodeService';
import LanguagesService from './services/metaData/LanguagesService';
import VendorService from './services/metaData/VendorService';
import ManagedbyService from './services/metaData/ManagedbyService';
import NewRequestService from './services/request/NewRequestService';
import NewRevisionService from './services/revision/NewRevisionService';
import NewRequestGetService from './services/request/NewRequestGetService';
import NewRevisionGetService from './services/revision/NewRevisionGetService';
import NewRequestUpdate from './services/request/NewRequestUpdate';
import NewRevisionUpdate from './services/revision/NewRevisionUpdate';
import NewRequestDeleteService from './services/request/NewRequestDeleteService';
import NewRevisionDeleteService from './services/revision/NewRevisionDeleteService';
import EtextContainer from './EtextContainer';

export default {
  path: [
    '/etext/conversion/workflow',
    '/etext/conversion/request',
    '/etext/conversion/revision'
    // '/etextdb/etextdetails'
  ],
  exact: true,
  services: [
    new RequestQueueSearchService(),
    new ExportQueueSearchService(),
    new EtextStatusService(),
    new EtextDivisionsService(),
    new EtextDisciplineCodeService(),
    new LanguagesService(),
    new VendorService(),
    new ManagedbyService(),
    new NewRequestService(),
    new NewRevisionService(),
    new NewRequestGetService(),
    new NewRevisionGetService(),
    new NewRequestUpdate(),
    new NewRevisionUpdate(),
    new NewRequestDeleteService(),
    new NewRevisionDeleteService()
  ],
  stores: [
    {
      name: 'request',
      spec: Request,
      saveLocally: false,
      initialState: {
        requestQueueSearch: {}
      }
    },
    {
      name: 'metaData',
      spec: MetaData,
      saveLocally: false,
      initialState: {
        statusValues: [],
        divisions: []
      }
    },
    {
      name: 'requestData',
      spec: NewRequest,
      saveLocally: false,
      initialState: {
        requestData: []
      }
    },
    {
      name: 'revisionData',
      spec: NewRevision,
      saveLocally: false,
      initialState: {
        revisionData: []
      }
    },
    {
      name: 'exportRequest',
      spec: ExportRequest,
      saveLocally: false,
      initialState: {
        exportQueueSearch: {}
      }
    }
  ],
  render: (props) => (
    <EtextContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
