/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField, FormHelperText, Select, MenuItem } from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormattedMessage } from 'react-intl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import eTextConfig from '../../../../common/config/eTextConfig';
// import { validate } from '@material-ui/pickers';

const styles = () => ({
  width: {
    width: '100%'
  },
  labelWidht: {
    width: '100%'
  },
  radioJustify: {
    justifyContent: 'space-between'
  },
  boxWidth: {
    width: '27%'
  }
});

const EtextGeneral = ({
  language,
  formFields,
  handleRadioChange,
  handleChange,
  formErrorFields,
  classes,
  disciplineCode,
  languages
}) => (
  <Grid container direction="row" spacing={3}>
    <Grid container item xs={12} direction="column">
      <Typography variant="h5">
        <FormattedMessage {...language.getText('eTextrequest.GENERAL')} />
      </Typography>
    </Grid>

    <Grid container item xs={6} direction="column">
      <TextField
        label={<FormattedMessage {...language.getText('eTextreivsion.ISBN-10')} />}
        margin="dense"
        required
        name="ebookISBN10"
        error={formErrorFields.ebookISBN10Error.length > 0}
        helperText={formErrorFields.ebookISBN10Error}
        value={formFields.ebookISBN10}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextreivsion.ISBN-13')} />}
        margin="dense"
        required
        name="ebookISBN13"
        error={formErrorFields.ebookISBN13Error.length > 0}
        helperText={formErrorFields.ebookISBN13Error}
        value={formFields.ebookISBN13}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextgeneral.CHARGEISBN')} />}
        margin="dense"
        name="chargeISBN"
        onChange={($event) => handleChange($event)}
        value={formFields.chargeISBN}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextgeneral.ISBNORID')} />}
        margin="dense"
        name="pimsID"
        onChange={($event) => handleChange($event)}
        value={formFields.pimsID}
        variant="outlined"
      />
      <br />

      <FormControl component="fieldset">
        <FormLabel>
          <Typography variant="body1">
            {' '}
            <FormattedMessage {...language.getText('eTextgeneral.GENERAL')} />
            <span> * </span> :
            {formFields.eTextRadioValueBool ? (
              <Typography variant="caption">
                <span id="req_text" style={{ color: '#ca4644' }}>
                  {' '}
                  <FormattedMessage {...language.getText('eTextconvergence.ATLEAST')} />
                </span>
              </Typography>
            ) : null}
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-label="position"
          onChange={($event) => handleRadioChange($event)}
          value={formFields.etextType}
          name="etextType"
        >
          <FormControlLabel
            className={classes.paddingTopZero}
            value="Demo/Partial eText"
            control={<Radio color="primary" />}
            label={
              <span className={classes.labelRadioGrp}>
                <FormattedMessage {...language.getText('eTextgeneral.DEMO')} />
              </span>
            }
          />
          <FormControlLabel
            className={classes.paddingTopZero}
            value="Full eText"
            control={<Radio color="primary" />}
            label={
              <span className={classes.labelRadioGrp}>
                <FormattedMessage {...language.getText('eTextgeneral.FULLETEXT')} />
              </span>
            }
          />
        </RadioGroup>
      </FormControl>

      <Typography variant="body1">
        <FormattedMessage {...language.getText('eTextrequest.DISCIPLINECODE')} />
        <span> * </span>
        {formFields.eTextDisciplineBool ? (
          <Typography variant="caption">
            <span id="req_text" style={{ color: '#ca4644' }}>
              {' '}
              <FormattedMessage {...language.getText('eTextconvergence.ATLEAST')} />
            </span>
          </Typography>
        ) : null}
      </Typography>
      {/* <Autocomplete
        options={disciplineCode.length > 0 && disciplineCode}
        getOptionLabel={(option) => option.value}
        id="auto-complete"
        autoHighlight
        autoSelect
        autoComplete
        onChange={($event) => handleChange($event)}
        includeInputInList
        renderInput={(params) => <TextField {...params} margin="normal" />}
      /> */}

      <Select name="disciplineCodeId" autoWidth="50%" onChange={($event) => handleChange($event)}>
        {disciplineCode.length > 0 &&
          disciplineCode.map((discipline) => {
            return <MenuItem value={discipline.id}>{discipline.value}</MenuItem>;
          })}
      </Select>
      <FormHelperText variant="filled">
        <Button size="small" style={{ color: '#005d83' }}>
          what&apos;s this ?
        </Button>
      </FormHelperText>
      <br />

      <Typography variant="body1">
        <FormattedMessage {...language.getText('eTextrequest.USER')} />
      </Typography>
      <Select
        name="rightLeft"
        onChange={($event) => handleChange($event)}
        value={formFields.rightLeft}
        defaultValue="Rightside"
      >
        {eTextConfig.userInterfaceOpt.map((opt) => {
          return <MenuItem value={opt.value}>{opt.value}</MenuItem>;
        })}
      </Select>
      <FormHelperText>In two-page view, first page of eText must appear on</FormHelperText>
    </Grid>
    <Grid container item xs={6} direction="column">
      <TextField
        label={<FormattedMessage {...language.getText('eTextgeneral.TOTAL')} />}
        margin="dense"
        type="number"
        required
        name="pageCount"
        error={formErrorFields.pageCountError.length > 0}
        helperText={formErrorFields.pageCountError}
        value={formFields.pageCount}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextreivsion.FOLDERNAME')} />}
        margin="dense"
        required
        name="folderFTP"
        error={formErrorFields.folderFTPError.length > 0}
        helperText={formErrorFields.folderFTPError}
        value={formFields.folderFTP}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('eTextgeneral.COMPOSITOR')} />}
        required
        margin="dense"
        name="compositor"
        error={formErrorFields.compositorError.length > 0}
        helperText={formErrorFields.compositorError}
        value={formFields.compositor}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      <br />

      <Typography variant="body1">
        <FormattedMessage {...language.getText('eTextrequest.TYPESOURCE')} />
        <span> * </span> :
        {formFields.sourceFileRadioValueBool ? (
          <Typography variant="caption">
            <span style={{ color: '#ca4644' }} id="req_text">
              {' '}
              <FormattedMessage {...language.getText('eTextconvergence.ATLEAST')} />
            </span>
          </Typography>
        ) : null}
      </Typography>
      <FormControl size="small" margin="dense" component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          onChange={($event) => handleRadioChange($event)}
          name="sourceFile"
          value={formFields.sourceFile}
        >
          <FormControlLabel
            className={classes.paddingTopZero}
            value="XML from ETM and Hi-res PDFs"
            control={<Radio color="primary" />}
            label={
              <span className={classes.labelRadioGrp}>
                <FormattedMessage {...language.getText('eTextgeneral.XML')} />
              </span>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            className={classes.paddingTopZero}
            value="Hi-res PDFs only"
            control={<Radio color="primary" />}
            label={
              <span className={classes.labelRadioGrp}>
                <FormattedMessage {...language.getText('eTextgeneral.HIRES')} />
              </span>
            }
          />
        </RadioGroup>
      </FormControl>

      <Typography variant="body1">
        <FormattedMessage {...language.getText('eTextrequest.eText')} />
        <span> * </span> :
        {formFields.eTextLanguage ? (
          <Typography variant="caption">
            <span style={{ color: '#ca4644' }} id="req_text">
              {' '}
              <FormattedMessage {...language.getText('eTextconvergence.ATLEAST')} />
            </span>
          </Typography>
        ) : null}
      </Typography>
      <Select
        name="languageID"
        onChange={($event) => handleChange($event)}
        value={formFields.languageID}
        defaultValue="Rightside"
      >
        {languages.length > 0 &&
          languages.map((lang) => {
            return <MenuItem value={lang.languageId}>{lang.languageText}</MenuItem>;
          })}
      </Select>
      <br />

      <Typography variant="body1">
        <FormattedMessage {...language.getText('eTextrequest.IPAD')} />
      </Typography>
      <Select
        name="ipadView"
        onChange={($event) => handleChange($event)}
        value={formFields.ipadView}
        defaultValue="Rightside"
      >
        {eTextConfig.yesNo.map((data) => {
          return <MenuItem value={data.id}>{data.value}</MenuItem>;
        })}
      </Select>
      <FormHelperText variant="filled">&quot;yes&quot; = available on Pearson Bookshelf</FormHelperText>
    </Grid>
  </Grid>
);

EtextGeneral.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  eTextConfig: PropTypes.string.isRequired,
  formFields: PropTypes.object.isRequired,
  formErrorFields: PropTypes.object.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  disciplineCode: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired
  //   emailValidate: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(EtextGeneral)));
