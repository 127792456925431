/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withStyles, IconButton } from '@material-ui/core';
import Framework, { shapes } from '@greenville/framework';
import { Icon } from '@greenville/mui-theme';

const styles = () => ({
  pearsonLogo: {
    fontSize: 48,
    '&:hover': {
      color: '#fffffe',
      opacity: 0.75
    }
  },
  defaultCursor: {
    cursor: 'default'
  }
});

const Logo = ({ id, language, classes, user }) => {
  const onClickLogo = () => {
    if (user.id !== '') {
      Framework.redirectTo('/dashboard');
    }
  };

  return (
    <IconButton
      id={`${id}-logo`}
      data-testid="logo"
      component="span"
      disabled={window.location.pathname.startsWith('/chatgptutility')}
      className={user.id === '' ? classes.defaultCursor : ''}
      color="primary"
      onClick={onClickLogo}
      aria-label={language.getMessage('common.a11y.LOGO')}
    >
      <Icon name="PearsonLogo" color="secondary" className={classes.pearsonLogo} />
    </IconButton>
  );
};

Logo.defaultProps = {
  id: 'topstrap'
};

Logo.propTypes = {
  id: PropTypes.string,
  language: shapes.language.isRequired,
  user: shapes.user.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(inject('language', 'user')(observer(Logo)));
