import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ChatgptConfig from '../../../../common/config/ChatgptConfig';
import * as constants from '../../../../common/constants';

const styles = () => ({
  headingClass: {
    padding: '15px',
    fontWeight: 'bold'
  },
  selectTenant: {
    justifyContent: 'center',
    alignItems: 'baseline'
  },
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const TenantList = (props) => {
  const history = useHistory();
  const { classes, resetTab } = props;
  const [tenantVal, setTenantVal] = useState('');
  const [isTenantRequired, setTenantRequired] = useState(false);

  const handleChange = (event) => {
    setTenantVal(event.target.value);
  };
  const handleSearch = () => {
    if (tenantVal === '1') {
      history.push('/chatgptutility/aistudytools');
    } else {
      history.push('/chatgptutility/channels');
    }
    resetTab();
  };
  const handleReset = () => {
    setTenantRequired(false);
    setTenantVal('');
  };

  return (
    <>
      <Paper>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              {constants.TENANT_LIST_TEXT}
            </Typography>
          </Box>
          <Box>
            <Grid container direction="row" alignItems="center" className={classes.selectTenant}>
              <Typography className={classes.headingClass}>{constants.SELECT_PRODUCT_LABEL}</Typography>
              <FormControl sx={{ minWidth: '32%', maxWidth: '90%' }} size="small">
                <InputLabel id="tenantLabelId">{constants.SELECT_PRODUCT_LABEL}</InputLabel>
                <Select label="Select Tenant" name="tenantId" value={tenantVal} onChange={handleChange}>
                  {ChatgptConfig.tenantData.map((item) => (
                    <MenuItem value={item.tenantId}>{item.title}</MenuItem>
                  ))}
                </Select>
                {isTenantRequired && <FormHelperText style={{ color: 'red' }}>Please Select Tenant</FormHelperText>}
              </FormControl>
            </Grid>
          </Box>
          <Box sx={{ p: 7 }}>
            <Grid container className={classes.buttonGroupStyle}>
              <ThemeProvider theme={themes}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleSearch}
                  className={classes.buttonStyle}
                  disabled={tenantVal === ''}
                >
                  {constants.TENANT_SEARCH_BUTTON}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleReset}
                  className={classes.buttonStyle}
                >
                  {constants.CHAT_RESET}
                </Button>
              </ThemeProvider>
            </Grid>
            <br />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

TenantList.propTypes = {
  classes: PropTypes.object.isRequired,
  resetTab: PropTypes.func.isRequired
};

export default withStyles(styles)(TenantList);
