const contentVersion = [
  {
    id: 0,
    value: '1.0'
  },
  {
    id: 1,
    value: ' 2.0'
  }
];

const contentType = [
  {
    id: 0,
    value: 'Optimized PDF',
    name: 'optimizedPdfFileUrl',
    url: ''
  },
  {
    id: 1,
    value: 'iPad PDF',
    name: 'ipadpdfsFileUrl',
    url: ''
  },
  // {
  //   id: 3,
  //   value: 'SWF',
  //   name: 'swfsFileUrl'
  // },
  {
    id: 2,
    value: 'Thumbnail',
    name: 'thumbnailsFileUrl',
    url: ''
  },
  {
    id: 3,
    value: 'Text',
    name: 'txtFileUrl',
    url: ''
  }
  // {
  //   id: 6,
  //   value: 'DL Images'
  // },
  // {
  //   id: 7,
  //   value: 'DL Masked Images'
  // },
  // {
  //   id: 8,
  //   value: 'DL Thumbnails'
  // }
];

const ContentConfig = {
  contentVersion,
  contentType
};

module.exports = ContentConfig;
