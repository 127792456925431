import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import _ from 'lodash';
import * as constants from '../../../common/constants';

const roleObj = types.model('roleObj', {
  prompt: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  safety: types.maybeNull(types.string),
  grounding: types.maybeNull(types.string),
  userTopic: types.maybeNull(types.string)
});

const contentfilterResponse = types.model('contentfilterResponse', {
  Category: types.maybeNull(types.string),
  Severity: types.maybeNull(types.string)
});

const blockListResult = types.model('blockListResult', {
  blocklistName: types.maybeNull(types.string),
  blockItemText: types.maybeNull(types.string)
});

const chatIntentBotResponse = types.model('chatIntentBotResponse', {
  format: types.maybeNull(types.string),
  intent: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  source_type: types.maybeNull(types.string),
  topic: types.maybeNull(types.string)
});

const promptsObj = types.model('promptsObj', {
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  status: types.maybeNull(types.string)
});

const pythonCodeObj = types.model('contentIdentityObj', {
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const contentFilterAllValues = types.model('contentFilterAllValues', {
  blocklistsMatchResults: types.maybeNull(types.optional(types.array(blockListResult), [])),
  Category_Severity: types.maybeNull(types.optional(types.array(contentfilterResponse), [])),
  chatIntentResponse: types.maybeNull(types.array(chatIntentBotResponse)),
  explainStandAloneBotResponse: types.maybeNull(types.string),
  problemSolveFinalResponse: types.maybeNull(types.array(types.string)),
  generatePythonResponse: types.maybeNull(types.optional(types.array(pythonCodeObj), []))
});

const subType = types.model('SubType', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.union(types.array(roleObj), types.string)),
  botResponse: types.maybeNull(types.union(contentFilterAllValues, types.array(types.string), types.string)),
  question: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  finalStatus: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const SubTypeResponse = types
  .model('SubTypeResponse', {
    SubTypeData: types.optional(types.array(subType), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHATGPT_SUB_TYPE_RESPONSE_DATA, {
        payload
      });
    },
    setResponse(response) {
      if (response.data) {
        const deleteNotprocessedObject = response.data;
        if (deleteNotprocessedObject.notProcessedRecord) {
          delete deleteNotprocessedObject.notProcessedRecord;
          delete deleteNotprocessedObject.isNextPageKey;
        }
        if (deleteNotprocessedObject.isNextPageKey) {
          delete deleteNotprocessedObject.isNextPageKey;
        }
        const data = _.flatten(Object.values(deleteNotprocessedObject))[0];
        if (data && data.type === 'chat_intent') {
          const { botResponse } = data;
          let processBotResponse = '';
          try {
            processBotResponse = [JSON.parse(botResponse[0])];
          } catch (err) {
            const splitTwoText = botResponse[0].split('[BAD-GPT]');
            const normGPT = JSON.parse(splitTwoText[0].split('[NORM-GPT]')[1]);
            const badGPT = JSON.parse(splitTwoText[1]);
            processBotResponse = [normGPT, badGPT];
          }
          data.botResponse = { chatIntentResponse: processBotResponse };
        }
        if (data) {
          self.SubTypeData.push(data);
        }
        if (data && data.type === constants.PROBLEM_SOLVE_VERIFIER) {
          Framework.getEventManager().publish(constants.SET_PROBLEM_SOLVE_DATA);
        }
        if (data && data.type === constants.PROBLEM_SOLVE_FINAL_RESPONSE) {
          Framework.getEventManager().publish(constants.SET_PROBLEM_SOLVE_FINAL_RESPONSE);
        }
      }
      //   Framework.getEventManager().publish(constants.SET_CHATGPT_SUB_TYPE_RESPONSE_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.SubTypeData, initialState);
      //   Framework.getEventManager().publish(constants.SET_CHATGPT_SUB_TYPE_RESPONSE_DATA);
    }
  }));

export default SubTypeResponse;
