/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { TextField, Typography, Fab } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

// const styles = () => ({

//   posClass: {
//     cursor: 'pointer'
//   }
// });

class Authors extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
    index: PropTypes.object.isRequired,
    firstName: PropTypes.object.isRequired,
    lastName: PropTypes.object.isRequired,
    otherName: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired

    //     language: shapes.language.isRequired
  };

  onRemove = (item) => {
    const { onRemove, index } = this.props;
    onRemove(index, item);
  };

  render() {
    const { firstName, lastName, otherName, handleChange, data, classes } = this.props;
    return (
      <div style={{ display: 'inline-flex' }}>
        <TextField
          //  className={`${classes.some} without-padding`}
          //  InputProps={{ classes: { input: classes.textfieldInput } }}
          // InputProps={{ className: classes.textsize }}
          onChange={handleChange}
          name="firstName"
          variant="outlined"
          margin="dense"
          label="FirstName"
          InputLabelProps={{
            shrink: true
          }}
          value={firstName}
        />
        <TextField
          //  className={`${classes.some} without-padding`}
          //   InputProps={{ classes: { input: classes.textfieldInput } }}
          // InputProps={{ className: classes.textsize }}
          name="lastName"
          onChange={handleChange}
          variant="outlined"
          margin="dense"
          label="LastName"
          InputLabelProps={{
            shrink: true
          }}
          value={lastName}
        />
        <TextField
          //  className={`${classes.some} without-padding`}
          //   InputProps={{ classes: { input: classes.textfieldInput } }}
          // InputProps={{ className: classes.textsize }}
          name="otherName"
          onChange={handleChange}
          variant="outlined"
          margin="dense"
          label="OtherName"
          InputLabelProps={{
            shrink: true
          }}
          value={otherName}
        />
        <div className={classes.deleteIcon}>
          <Typography display="inline">
            <Fab color="primary" aria-label="add" size="small" onClick={() => this.onRemove(data)}>
              <DeleteOutlineIcon />
            </Fab>
          </Typography>
        </div>
      </div>
    );
  }
}

export default Authors;
