import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const trendsData = types.model('trendsData', {
  bookId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  likeCount: types.maybeNull(types.number),
  disLikeCount: types.maybeNull(types.number),
  explainCount: types.maybeNull(types.number),
  summaryCount: types.maybeNull(types.number),
  quizMcqCount: types.maybeNull(types.number),
  blockWordsCount: types.maybeNull(types.number),
  unknownCount: types.maybeNull(types.number),
  unAnsweredCount: types.maybeNull(types.number),
  unAnsweredNoCSGDocCount: types.maybeNull(types.number),
  unAnsweredUnknownIntentCount: types.maybeNull(types.number),
  unAnsweredNoTopicFoundCount: types.maybeNull(types.number),
  unAnsweredProblemSolveCount: types.maybeNull(types.number)
});

// const trendsGetData = types.model('trendsGetData', {
//   trendsGetData: types.optional(trendsData, {})
// });

const Trends = types
  .model('Trends', {
    data: types.optional(types.array(trendsData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHATGPT_TRENDS_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.GET_CHATGPT_TRENDS_MODEL_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.GET_CHATGPT_TRENDS_MODEL_DATA);
    }
  }));

export default Trends;
