/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 */

const etextDataObj = types.model('etextDataObj', {
  revisionID: types.maybeNull(types.integer),
  requestID: types.maybeNull(types.integer),
  etextISBN10: types.maybeNull(types.string),
  etextISBN13: types.maybeNull(types.string),
  parentISBN10: types.maybeNull(types.string),
  parentISBN13: types.maybeNull(types.string),
  bookID: types.maybeNull(types.integer),
  globalBookID: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  author: types.maybeNull(types.string),
  divisionID: types.maybeNull(types.integer),
  uilanguage: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  addlEmail: types.maybeNull(types.string),
  chargeISBN: types.maybeNull(types.string),
  digitalLibrary: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  itemsExcludeDesc: types.maybeNull(types.string),
  productionNotes: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
  intlRegion: types.maybeNull(types.integer),
  copyright: types.maybeNull(types.string),
  vendor: types.maybeNull(types.string),
  managedBy: types.maybeNull(types.string),
  initialComments: types.maybeNull(types.string)
});

const etextScheduleObj = types.model('etextScheduleObj', {
  productionStatus: types.maybeNull(types.integer),
  requestFormSubmitted: types.maybeNull(types.string),
  estimatedProjectCompletion: types.maybeNull(types.string),
  folderName: types.maybeNull(types.string),
  projectInitiated: types.maybeNull(types.string),
  adjustedProjection: types.maybeNull(types.string),
  overAllCompletionDate: types.maybeNull(types.string),
  holdDays: types.maybeNull(types.integer),
  reviewCount: types.maybeNull(types.integer)
});

const etextRevisionObj = types.model('etextRevisionObj', {
  permissionChange: types.maybeNull(types.boolean),
  updatedHotspotLinks: types.maybeNull(types.boolean),
  updatedGlossaryContent: types.maybeNull(types.boolean),
  customBasketChange: types.maybeNull(types.boolean),
  nonModalAudioChange: types.maybeNull(types.boolean),
  pageAddOrSub: types.maybeNull(types.boolean),
  contentChange: types.maybeNull(types.boolean),
  updateTitleEditionAuthor: types.maybeNull(types.boolean),
  tocChange: types.maybeNull(types.boolean),
  coverImageChange: types.maybeNull(types.boolean),
  iPadViewChange: types.maybeNull(types.boolean),
  dlRequireChange: types.maybeNull(types.boolean),
  revProdTypeDemoPartial: types.maybeNull(types.boolean),
  otherRevChange: types.maybeNull(types.boolean),
  otherRevisionTypeText: types.maybeNull(types.string),
  revDescription: types.maybeNull(types.string)
});

const etextSpecialObj = types.model('etextSpecialObj', {
  insideFaceCover: types.maybeNull(types.string),
  insideBackCover: types.maybeNull(types.string),
  colorInserts: types.maybeNull(types.string),
  bonusChapters: types.maybeNull(types.string),
  tearCard: types.maybeNull(types.string),
  endPapers: types.maybeNull(types.string),
  othersCheck: types.maybeNull(types.string),
  others: types.maybeNull(types.string),
  suppSectionDesc: types.maybeNull(types.string)
});

const revisionDataCode = types.model('revisionDataCode', {
  etextGeneral: types.optional(etextDataObj, {}),
  etextScheduleStatus: types.optional(etextScheduleObj, {}),
  etextRevisionDetails: types.optional(etextRevisionObj, {}),
  etextSpecialSectionInfo: types.optional(etextSpecialObj, {})
});

// const revisionBookData = types.model('revisionBookData', {
//   bookRevisionStatus: types.maybeNull(types.boolean)
// });

const NewRevision = types
  .model('NewRevision', {
    dataRevision: types.optional(revisionDataCode, {}),
    dataBookRevision: types.maybeNull(types.boolean),
    dataRevisionStatus: types.maybeNull(types.boolean)
  })

  .actions((self) => ({
    fetchRevision(payload) {
      Framework.getEventManager().publish(constants.ETEXT_NEW_REVISION_GET, { payload });
    },

    setNewRevision(response) {
      applySnapshot(self.dataRevision, response.data);
    },

    setAlteredRevision(response) {
      applySnapshot(self.dataRevision, response);
    },

    setBookRevision(payload) {
      applySnapshot(self, { ...self, dataBookRevision: payload });
    },

    setRevisionStatus(payload) {
      applySnapshot(self, { ...self, dataRevisionStatus: payload });
    },

    savePayload(payload) {
      Framework.getEventManager().publish(constants.ETEXT_REVISION_POST, { payload });
    },

    saveUpdate(payload) {
      Framework.getEventManager().publish(constants.ETEXT_REVISION_UPDATE_POST, { payload });
    },

    deleteRevision(payload) {
      Framework.getEventManager().publish(constants.ETEXT_REVISION_DELETE_POST, { payload });
    }
  }));

export default NewRevision;
