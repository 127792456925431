/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
/* import {toJS} from 'mobx' */
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  Dialog,
  Paper,
  Box,
  DialogContent,
  DialogContentText,
  IconButton,
  Select,
  TextField,
  FormControl,
  MenuItem
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import { Tooltip } from '@greenville/mui-theme';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import CloseIcon from '@material-ui/icons/Close';
import Workflow from '../../models/Workflow';
import ViewCoverDialog from './ViewCoverDialog';
import ContentConfig from '../../../../common/config/ContentConfig';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import * as constants from '../../../../common/constants';
import Loader from '../../../../common/Loader';
/* import UsersPermissions from '../../../users/models/UsersPermissions'; */

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 1)
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 2px',
    height: '150vh'
  },
  expandedDialogContent: {
    padding: '4px 2px',
    height: '198vh'
  },
  dialogText: {
    color: '#6a7070',
    fontSize: 14,
    paddingLeft: theme.spacing(0.6)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  rightGridHeight: {
    height: '80vh',
    marginLeft: '9.5%'
  },
  rightGridNone: {
    display: 'none'
  },
  gridHeight: {
    height: '80vh'
  },
  fullWidthGridHeight: {
    height: '80vh'
  },
  gridNone: {
    display: 'none'
  },
  gridAlignWrap: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    flexWrap: 'inherit',
    height: '70vh'
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cover: {
    width: '63%',
    paddingLeft: '36.5%',
    paddingTop: theme.spacing(1)
  },
  coverStyle: {
    textDecorationLine: 'underline'
  },
  text: {
    paddingTop: '15%',
    paddingLeft: '40%'
  },
  navigationText: {
    paddingTop: '15%',
    textDecorationLine: 'underline'
  },
  versionFormControl: {
    paddingTop: theme.spacing(0.2),
    paddingLeft: theme.spacing(2.2),
    minWidth: 90
  },
  leftFormControl: {
    paddingLeft: theme.spacing(6.5),
    paddingTop: theme.spacing(0.7),
    minWidth: 100
  },
  rightFormControl: {
    paddingTop: theme.spacing(0.2),
    minWidth: 170
  },
  rightSideExpandIcon: {
    paddingTop: theme.spacing(0.7)
  },
  textField: {
    display: 'inline-flex',
    verticalAlign: 'text-top',
    width: '70%',
    height: '10%'
  },
  textfieldInput: {
    height: 9,
    fontSize: '14px',
    textAlign: 'center'
  },
  textGrid: {
    verticalAlign: 'text-top'
  },
  navigation: {
    cursor: 'pointer',
    display: 'inline-flex',
    verticalAlign: 'text-top'
  },
  navigationButton: {
    '& .MuiButton-label': {
      minWidth: '0px'
    }
  },
  rotateIcon: {
    paddingRight: '13%'
  },
  disabledButton: {
    color: '#cccccc',
    cursor: 'pointer',
    display: 'inline-flex',
    verticalAlign: 'text-top',
    paddingTop: '15%',
    textDecorationLine: 'underline'
  },
  headerPadding: {
    paddingTop: '2%'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

@inject('workflow', 'previewServiceStatus', 'previewVersionServiceStatus', 'userPermissions')
@observer
class Preview extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    workflow: shapes.modelOf(Workflow).isRequired,
    openTitlePreview: PropTypes.bool.isRequired,
    handlePreviewClose: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    previewServiceStatus: shapes.state.isRequired,
    previewVersionServiceStatus: shapes.state.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      coverImage: '',
      iPadCoverImage: '',
      pageNumberValue: 0,
      totalPageCount: 0,
      previewVersion: '',
      versions: [],
      gridFullWidth: false,
      gridWidthType: 'center',
      leftClass: '',
      rightClass: '',
      selectedVersion: '',
      logicalPageValue: '',
      rightFrameUrl: '',
      rightFrameFilter: '',
      previewUrls: [],
      iPadCoverType: false,
      openCover: false,
      rightFrameGridAlign: false
    };
    this.closeCoverPage = this.closeCoverPage.bind(this);
  }

  componentDidMount() {
    const { workflow, rowData } = this.props;

    workflow.fetchPreviewData(rowData, {});
    Framework.getEventManager().on(constants.CM_PREVIEW_DATA_FETCHED, () => {
      this.setState({
        coverImage: workflow.contentManagementPreviewData.cover.coverImage,
        iPadCoverImage: workflow.contentManagementPreviewData.cover.iPadCoverImage,
        versions: workflow.contentManagementPreviewData.previewVersion,
        logicalPageValue: workflow.contentManagementPreviewData.preview.logicalPage,
        pageNumberValue: workflow.contentManagementPreviewData.preview.pageNumber,
        totalPageCount: workflow.contentManagementPreviewData.preview.totalPageCount,
        previewVersion: workflow.contentManagementPreviewData.preview.previewVersion,
        previewUrls: workflow.contentManagementPreviewData.preview.previewUrls,
        selectedVersion: rowData.version,
        rightFrameFilter: ContentConfig.contentType[0].name,
        rightFrameUrl: workflow.contentManagementPreviewData.preview.previewUrls[ContentConfig.contentType[0].name]
      });
    });
  }

  versionFilter = () => {
    const { versions, previewVersion } = this.state;
    if (versions) {
      return versions.map((versionDetail) => (
        <MenuItem value={versionDetail.version}>{versionDetail.version}</MenuItem>
      ));
    }
    return <MenuItem value={previewVersion}>{previewVersion}</MenuItem>;
  };

  handleVersionChange = (versionValue) => {
    const { pageNumberValue } = this.state;
    const payload = {
      pageNumber: pageNumberValue,
      version: versionValue
    };
    this.pageNavigation(payload);
    this.setState({ selectedVersion: versionValue });
  };

  previousPage = () => {
    const { pageNumberValue, selectedVersion } = this.state;
    const payload = {
      pageNumber: pageNumberValue - 1,
      version: selectedVersion
    };
    this.pageNavigation(payload);
  };

  nextPage = () => {
    const { pageNumberValue, previewVersion } = this.state;
    const payload = {
      pageNumber: pageNumberValue + 1,
      version: previewVersion
    };
    this.pageNavigation(payload);
  };

  forwardWidth = () => {
    const { gridFullWidth } = this.state;
    if (gridFullWidth) {
      this.setState({
        gridFullWidth: false,
        gridWidthType: 'center',
        rightFrameGridAlign: true
      });
    } else {
      this.setState({
        gridFullWidth: true,
        gridWidthType: 'left',
        rightFrameGridAlign: false
      });
    }
  };

  reverseWidth = () => {
    const { gridFullWidth } = this.state;
    if (gridFullWidth) {
      this.setState({
        gridFullWidth: false,
        gridWidthType: 'center',
        rightFrameGridAlign: false
      });
    } else {
      this.setState({
        gridFullWidth: true,
        gridWidthType: 'right',
        rightFrameGridAlign: true
      });
    }
  };

  handleOnKeyUp = (e) => {
    const { logicalPageValue } = this.state;
    if (e.keyCode === 13) {
      this.navigateToPageByTextChange(logicalPageValue);
    }
  };

  handleChange = (value) => {
    const { previewUrls } = this.state;
    const urlValue = previewUrls[value];
    this.setState({
      rightFrameUrl: urlValue,
      rightFrameFilter: value
    });
  };

  handleNavigateToPageByTextChange = (searchTextValue) => {
    this.setState({ logicalPageValue: searchTextValue });
  };

  navigateToPageByTextChange = (logicalPageValue) => {
    const { pageNumberValue, selectedVersion } = this.state;
    const payload = {
      pageNumber: pageNumberValue,
      version: selectedVersion,
      logicalPage: logicalPageValue
    };
    this.pageNavigation(payload);
  };

  pageNavigation = (payload) => {
    const { workflow, rowData } = this.props;
    const { rightFrameFilter } = this.state;
    workflow.fetchPreviewTitleChanges(rowData.globalBookId, payload);
    Framework.getEventManager().on(constants.CM_TITLE_DATA_FETCHED_NAVIGATION, () => {
      this.setState({
        logicalPageValue: workflow.contentManagementPreviewData.preview.logicalPage,
        pageNumberValue: workflow.contentManagementPreviewData.preview.pageNumber,
        totalPageCount: workflow.contentManagementPreviewData.preview.totalPageCount,
        previewVersion: workflow.contentManagementPreviewData.preview.previewVersion,
        previewUrls: workflow.contentManagementPreviewData.preview.previewUrls,
        rightFrameUrl: workflow.contentManagementPreviewData.preview.previewUrls[rightFrameFilter]
      });
    });
  };

  closeCoverPage = () => {
    this.setState({ openCover: false, iPadCoverType: false });
  };

  openCoverPage(coverType) {
    if (coverType === 'iPad') {
      this.setState({ iPadCoverType: true, openCover: true });
    } else {
      this.setState({ openCover: true });
    }
  }

  render() {
    const {
      openTitlePreview,
      handlePreviewClose,
      language,
      classes,
      rowData,
      previewServiceStatus,
      previewVersionServiceStatus
    } = this.props;
    const {
      coverImage,
      iPadCoverImage,
      logicalPageValue,
      pageNumberValue,
      totalPageCount,
      previewVersion,
      gridFullWidth,
      gridWidthType,
      iPadCoverType,
      versions,
      selectedVersion,
      previewUrls,
      rightFrameFilter,
      rightFrameUrl,
      rightFrameGridAlign,
      openCover
    } = this.state;

    let { leftClass, rightClass } = this.state;
    let disableNext = false;
    let disablePrev = false;
    if (totalPageCount && pageNumberValue) {
      if (totalPageCount === pageNumberValue) {
        disableNext = true;
      }
      if (pageNumberValue === 1) {
        disablePrev = true;
      }
    }

    if (
      (gridFullWidth === true && gridWidthType === 'right') ||
      (gridFullWidth === false && gridWidthType === 'center')
    ) {
      rightClass = classes.rightGridHeight;
    } else {
      rightClass = classes.rightGridNone;
    }

    if (
      (gridFullWidth === true && gridWidthType === 'left') ||
      (gridFullWidth === false && gridWidthType === 'center')
    ) {
      leftClass = classes.gridHeight;
    } else {
      leftClass = classes.gridNone;
    }

    return (
      <>
        <Paper>
          <Box>
            <Dialog fullScreen open={openTitlePreview} onClose={handlePreviewClose()}>
              <DialogTitle
                id="customized-dialog-title"
                onClose={handlePreviewClose()}
                align="center"
                toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
              >
                <Typography variant="h2">
                  <FormattedMessage {...language.getText('label.VIS-A-VIS')} />
                </Typography>
              </DialogTitle>
              <LoadingHandler
                loading={previewServiceStatus.isPending}
                isError={
                  previewServiceStatus.isError &&
                  previewServiceStatus.error &&
                  previewServiceStatus.error.status === 401
                }
                errorContent={<StateErrorDisplay error={previewServiceStatus.error} showDetails />}
                content={
                  <>
                    <DialogContent>
                      <Typography gutterBottom>
                        <Paper elevation={3} className={classes.titleMargin}>
                          <Box>
                            <DialogTitle id="draggable-dialog-title">
                              <Grid container direction="row" style={{ alignItems: 'center' }}>
                                <Grid xs={4} container direction="column">
                                  <Grid container direction="row">
                                    <Grid item xs={3}>
                                      <Typography variant="h5" color="textPrimary">
                                        <FormattedMessage {...language.getText('label.GLOBAL_BOOK_ID')} />
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography className={classes.dialogText}>{rowData.globalBookId}</Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid container direction="row" className={classes.headerPadding}>
                                    <Grid item xs={3}>
                                      <Typography variant="h5" color="textPrimary">
                                        <FormattedMessage {...language.getText('label.VERSION')} />
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography className={classes.dialogText}>{previewVersion}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid xs={4} container direction="column">
                                  <Grid container direction="row" justify="center">
                                    <Grid item xs={3}>
                                      <Typography variant="h5" color="textPrimary">
                                        <FormattedMessage {...language.getText('label.ISBN')} />
                                        /
                                        <FormattedMessage {...language.getText('contentmanager.IDENTIFIER')} />
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography className={classes.dialogText}>{rowData.identifier}</Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid justify="center" container direction="row" className={classes.headerPadding}>
                                    <Grid item xs={3}>
                                      <Typography variant="h5" color="textPrimary">
                                        <FormattedMessage {...language.getText('label.TITLE')} />
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography className={classes.dialogText}>{rowData.bookTitle}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  xs={4}
                                  container
                                  justify="start"
                                  direction="column"
                                  style={{ alignContent: 'center' }}
                                >
                                  <Grid>
                                    <Button onClick={() => this.openCoverPage('cover')}>
                                      <Typography variant="h5" color="Primary" className={classes.coverStyle}>
                                        <FormattedMessage {...language.getText('contentmanager.VIEW_COVER')} />
                                      </Typography>
                                    </Button>
                                  </Grid>
                                  <Grid>
                                    <Button onClick={() => this.openCoverPage('iPad')}>
                                      <Typography variant="h5" color="Primary" className={classes.coverStyle}>
                                        <FormattedMessage {...language.getText('contentmanager.VIEW_IPAD')} />
                                      </Typography>
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </DialogTitle>
                          </Box>
                        </Paper>
                        <Paper elevation={3} className={classes.contentMargin}>
                          <Box>
                            <DialogContentText>
                              <LoadingHandler
                                loading={previewVersionServiceStatus.isPending}
                                isError={
                                  previewVersionServiceStatus.isError &&
                                  previewVersionServiceStatus.error &&
                                  previewVersionServiceStatus.error.status === 401
                                }
                                errorContent={
                                  <StateErrorDisplay error={previewVersionServiceStatus.error} showDetails />
                                }
                                content={
                                  <>
                                    <DialogContent
                                      className={gridFullWidth ? classes.expandedDialogContent : classes.dialogContent}
                                    >
                                      <Grid container direction="row" style={{ width: '100%' }}>
                                        <Grid justify="start" container direction="row" style={{ width: '35%' }}>
                                          <Grid>
                                            <Typography className={classes.text} variant="h5" color="textPrimary">
                                              <FormattedMessage {...language.getText('label.VERSION')} />
                                            </Typography>
                                          </Grid>
                                          <Grid>
                                            <FormControl className={classes.versionFormControl}>
                                              <Select
                                                name="version"
                                                onChange={($event) => this.handleVersionChange($event.target.value)}
                                                value={selectedVersion}
                                              >
                                                <MenuItem value={rowData.version}>{rowData.version}</MenuItem>
                                                {versions &&
                                                  versions.map((versionDetail) => (
                                                    <MenuItem value={versionDetail.version}>
                                                      {versionDetail.version}
                                                    </MenuItem>
                                                  ))}
                                              </Select>
                                            </FormControl>
                                          </Grid>

                                          <Grid className={classes.leftFormControl}>
                                            <Typography variant="h5">
                                              <FormattedMessage {...language.getText('label.SOURCE_PDF')} />
                                              <Button onClick={() => this.forwardWidth()}>
                                                {gridFullWidth ? (
                                                  <Tooltip
                                                    id="remove-tooltip"
                                                    title={
                                                      <FormattedMessage {...language.getText('common.COLLAPSE')} />
                                                    }
                                                    placement="bottom"
                                                  >
                                                    <Typography color="primary">
                                                      <FullscreenExitIcon fontSize="large" />
                                                    </Typography>
                                                  </Tooltip>
                                                ) : (
                                                  <Tooltip
                                                    id="remove-tooltip"
                                                    title={<FormattedMessage {...language.getText('common.EXPAND')} />}
                                                    placement="bottom"
                                                  >
                                                    <Typography color="primary">
                                                      <AspectRatioIcon fontSize="large" />
                                                    </Typography>
                                                  </Tooltip>
                                                )}
                                              </Button>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid justify="center" container direction="row" style={{ width: '30%' }}>
                                          <Grid>
                                            <Button
                                              className={classes.navigationButton}
                                              onClick={() => this.previousPage()}
                                              disabled={disablePrev}
                                            >
                                              <Typography
                                                className={
                                                  disablePrev ? classes.disabledButton : classes.navigationText
                                                }
                                                variant="h5"
                                                color="primary"
                                              >
                                                <NavigateBeforeIcon className={classes.navigation} fontSize="large" />
                                                <FormattedMessage {...language.getText('contentmanager.PREV')} />
                                              </Typography>
                                            </Button>
                                          </Grid>
                                          <Grid align="center" className={classes.textGrid}>
                                            <TextField
                                              align="center"
                                              margin="dense"
                                              name="title"
                                              className={classes.textField}
                                              InputProps={{ classes: { input: classes.textfieldInput } }}
                                              variant="outlined"
                                              style={{ fontSize: 5 }}
                                              value={logicalPageValue}
                                              onKeyUp={($event) => this.handleOnKeyUp($event)}
                                              onChange={($event) =>
                                                this.handleNavigateToPageByTextChange($event.target.value)
                                              }
                                            />
                                          </Grid>
                                          <Grid>
                                            <Button
                                              className={classes.navigationButton}
                                              onClick={() => this.nextPage()}
                                              disabled={disableNext}
                                            >
                                              <Typography
                                                className={
                                                  disableNext ? classes.disabledButton : classes.navigationText
                                                }
                                                variant="h5"
                                                color="primary"
                                              >
                                                <FormattedMessage {...language.getText('contentmanager.NEXT')} />
                                                <NavigateNextIcon className={classes.navigation} fontSize="large" />
                                              </Typography>
                                            </Button>
                                          </Grid>
                                        </Grid>
                                        <Grid justify="center" container direction="row" style={{ width: '35%' }}>
                                          <Grid>
                                            <Button
                                              onClick={() => this.reverseWidth()}
                                              className={classes.rightSideExpandIcon}
                                            >
                                              {gridFullWidth ? (
                                                <Tooltip
                                                  id="remove-tooltip"
                                                  title={<FormattedMessage {...language.getText('common.COLLAPSE')} />}
                                                  placement="bottom"
                                                >
                                                  <Typography color="primary">
                                                    <FullscreenExitIcon fontSize="large" />
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Tooltip
                                                  id="remove-tooltip"
                                                  title={<FormattedMessage {...language.getText('common.EXPAND')} />}
                                                  placement="bottom"
                                                >
                                                  <Typography color="primary">
                                                    <AspectRatioIcon fontSize="large" />
                                                  </Typography>
                                                </Tooltip>
                                              )}
                                            </Button>
                                            <FormControl className={classes.rightFormControl}>
                                              <Select
                                                name="OtherName"
                                                onChange={($event) => this.handleChange($event.target.value)}
                                                value={rightFrameFilter}
                                              >
                                                {ContentConfig.contentType.map((filterDetail) => (
                                                  <MenuItem value={filterDetail.name}>{filterDetail.value}</MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Box m={1}>
                                        <Grid container spacing={0} direction="row">
                                          <br /> <br /> <br /> <br /> <br /> <br />
                                          <Grid item xs={gridFullWidth ? 10 : 5} className={leftClass}>
                                            <iframe
                                              id="inlineFrameExample"
                                              title="Inline Frame Example"
                                              width={gridFullWidth ? '120%' : '117.5%'}
                                              height={gridFullWidth ? '230%' : '170%'}
                                              src={previewUrls.pdfFileUrl}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={gridFullWidth ? 10 : 5}
                                            className={
                                              gridFullWidth && rightFrameGridAlign
                                                ? classes.fullWidthGridHeight
                                                : rightClass
                                            }
                                            // style={{ marginLeft: '9.5%' }}
                                          >
                                            <iframe
                                              id="inlineFrameExample"
                                              title="Inline Frame Example"
                                              width={gridFullWidth ? '120%' : '117.5%'}
                                              height={gridFullWidth ? '230%' : '170%'}
                                              src={rightFrameUrl}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </DialogContent>
                                  </>
                                }
                                loadingContent={<Loader />}
                              />
                            </DialogContentText>
                          </Box>
                        </Paper>
                      </Typography>
                    </DialogContent>
                  </>
                }
                loadingContent={<Loader />}
              />
            </Dialog>
          </Box>
        </Paper>
        <ViewCoverDialog
          open={openCover}
          close={() => this.closeCoverPage}
          iPadCoverType={iPadCoverType}
          coverUrl={iPadCoverType ? iPadCoverImage : coverImage}
        />
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(observer(Preview)));
