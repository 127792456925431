/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../../common/constants';
import utils from '../../../../common/utils';

export default class BucketFoldersService extends BaseService {
  constructor() {
    super(constants.CM_GET_BUCKET_FOLDERS, 'getBuketFoldersStatus');
  }

  // eslint-disable-next-line no-unused-vars
  handleEvent(eventName, event) {
    return this.getClient('evergreen').post('/cm/ingestion/getbucketdata', event.data);
  }

  // eslint-disable-line class-methods-use-this
  handleResponse(response) {
    const { status } = response;
    if (status === 204) {
      Framework.getEventManager().publish(constants.CM_CREATE_NEW_BUCKET);
    } else {
      Framework.getStoreRegistry().getStore('upload').setbucketfolders(response.data);
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
      if (status === 500 && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
        Framework.getEventManager().publish(constants.CM_BUCKET_CLOSE);
      }
    }
  }
}
