// /*
//  * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
//  * Copyright © 2019 Pearson Education, Inc.
//  * All Rights Reserved.
//  *
//  * NOTICE:  All information contained herein is, and remains
//  * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
//  * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
//  * patent applications, and are protected by trade secret or copyright law.
//  * Dissemination of this information, reproduction of this material, and copying or distribution of this software
//  * is strictly forbidden unless prior written permission is obtained
//  * from Pearson Education, Inc.
//  */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import { Typography, Grid, Select, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import * as FormData from 'form-data';
import { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import CustomIcons from '../../models/CustomIcons';
import Breadcrumbs from '../commonitems/Breadcrumbs';
import Loader from '../../../../common/Loader';
/* import * as constants from '../../../../common/constants'; */
import UsersPermissions from '../../../users/models/UsersPermissions';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    width: '99%',
    padding: theme.spacing(0, 1)
  },

  spacearound: {
    display: 'flex',
    // padding: '0px 35px',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  textselectHeight: {
    marginTop: '5px',
    marginBottom: '5px',
    fontSize: 12
  },
  pointer: {
    cursor: 'pointer'
  },
  borderright: {
    borderRight: '0.5px solid #0003'
  },
  inputWidth: {
    width: '32%'
  }
});

@inject('customIcon', 'customIconGetStatus', 'customIconUploadStatus', 'userPermissions')
@observer
class CustomIcon extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    match: PropTypes.object.isRequired,
    customIcon: shapes.modelOf(CustomIcons).isRequired,
    customIconGetStatus: shapes.state.isRequired,
    customIconUploadStatus: shapes.state.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    const { customIcon } = this.props;
    this.state = {
      selectedFiles: null,
      uploadValue: '',
      currentCustomIconState: customIcon || {},
      selectedIconTypes: null,
      selectedTypeValues: 2,
      listOfSelectedIcons: []
    };
  }

  componentDidMount() {
    const { match, customIcon } = this.props;
    const { currentCustomIconState } = this.state;
    const { bookIcons, ipadIcons, selectedIconTypes } = currentCustomIconState;
    customIcon.fetch(match.params.bookid);
    let definedValue = [];
    definedValue =
      selectedIconTypes && selectedIconTypes.length > 0 && selectedIconTypes === 'N' ? ipadIcons : bookIcons;
    this.setState({
      listOfSelectedIcons: definedValue
    });
  }

  fileSelected = (event, text) => {
    const eve = event.target.files[0];
    this.setState((state) => ({
      ...state,
      selectedFiles: eve,
      uploadValue: text
    }));
  };

  checkType = (type) => {
    let output = '';
    if (type === 'eText') {
      output = 'Y';
    } else if (type === 'Ipad') {
      output = 'Y';
    } else {
      output = 'N';
    }
    return output;
  };

  fileUploader = (type, iconType) => {
    const { match } = this.props;
    const { selectedFiles, currentCustomIconState, selectedTypeValues } = this.state;
    const { bookIcons, ipadIcons } = currentCustomIconState;
    const {
      customIcon: { savePayload }
    } = this.props;

    if (selectedFiles) {
      const imageData = new FormData();
      imageData.append('iconImage', selectedFiles);
      imageData.append('iconName', selectedFiles.name);
      imageData.append('iconTypeId', selectedTypeValues);
      imageData.append('isIpad', iconType);
      imageData.append('id', match.params.bookid);

      savePayload(imageData, 'null');
    }

    const convertedArray = toJS(type === 'eText' ? bookIcons : ipadIcons);
    convertedArray.map((item) => {
      const updatedItems = item;
      if (item.iconTypeID === selectedTypeValues) {
        updatedItems.customIconPath = URL.createObjectURL(selectedFiles);
        updatedItems.useCustom = this.checkType(type);
        return updatedItems;
      }
      return updatedItems;
    });

    this.setState({
      currentCustomIconState: {
        ...currentCustomIconState,
        ...(type === 'eText' ? { bookIcons: toJS(convertedArray) } : { ipadIcons: toJS(convertedArray) })
      },
      selectedTypeValues: 2,
      uploadValue: ''
    });
  };

  handleChange = (i, data, e) => {
    const { currentCustomIconState } = this.state;
    const { ipadIcons, bookIcons } = currentCustomIconState;
    const definedValue = data === 'N' ? bookIcons : ipadIcons;

    let updatedObject = {};
    if (i === null) {
      updatedObject = {
        [e.target.name]: e.target.value
      };
      this.setState({
        currentCustomIconState: {
          ...currentCustomIconState,
          updatedObject,
          definedValue: [...definedValue]
        },
        selectedTypeValues: parseInt(e.target.value, 10)
      });
    }
  };

  setFilterValues = (filterConfig) =>
    filterConfig.map((filter) => <option value={filter.iconTypeID}>{filter.iconName}</option>);

  revertIcon(e, item, index, booksicon) {
    const { match } = this.props;
    const { currentCustomIconState } = this.state;
    const { ipadIcons } = currentCustomIconState;
    const tempDefault = toJS(ipadIcons);
    tempDefault[index].useCustom = 'N';
    const {
      customIcon: { defautltIcon }
    } = this.props;
    const imageData = new FormData();
    imageData.append('iconTypeId', item);
    imageData.append('isIpad', booksicon);
    imageData.append('id', match.params.bookid);

    defautltIcon(imageData, 'default');
    this.setState({
      currentCustomIconState: {
        ...currentCustomIconState,
        ipadIcons: toJS(tempDefault)
      }
    });
  }

  revertIconMobile(e, item, index, ipadsicon) {
    const { match } = this.props;
    const { currentCustomIconState } = this.state;
    const { bookIcons } = currentCustomIconState;
    const tempDefault = toJS(bookIcons);
    tempDefault[index].useCustom = 'N';
    const {
      customIcon: { defautltIcon }
    } = this.props;
    const imageData = new FormData();
    imageData.append('iconTypeId', item);
    imageData.append('isIpad', ipadsicon);
    imageData.append('id', match.params.bookid);

    this.setState({
      currentCustomIconState: {
        ...currentCustomIconState,
        bookIcons: toJS(tempDefault)
      }
    });

    defautltIcon(imageData, 'default');
  }

  render() {
    const { classes, language, match, customIconGetStatus, customIconUploadStatus, userPermissions } = this.props;
    const { currentCustomIconState, uploadValue } = this.state;
    const { iconList, ipadIcons, bookIcons } = currentCustomIconState;
    const permissionsList = toJS(userPermissions.permissionList.permissions);

    return (
      <LoadingHandler
        loading={customIconGetStatus.isPending || customIconUploadStatus.isPending}
        isError={customIconGetStatus.isError && customIconGetStatus.error && customIconGetStatus.error.status === 401}
        loadingContent={<Loader />}
        errorContent={<StateErrorDisplay error={customIconGetStatus.error} showDetails />}
        content={
          <div className={classes.root}>
            <Breadcrumbs
              data={<FormattedMessage {...language.getText('icon.CUSTOM_ICON')} />}
              id={match.params.bookid}
            />
            <br />
            <Paper elevation={3}>
              <Box p={1} bgcolor="background.paper">
                <Grid container spacing={3} direction="row" p={8}>
                  <Grid item xs={12} direction="row" p={8}>
                    <Typography align="center" variant="h4">
                      <FormattedMessage {...language.getText('icon.HEADER')} />
                    </Typography>
                    <br />
                    <Typography align="center" variant="subtitle1">
                      <FormattedMessage {...language.getText('icon.DESCRIPTION')} />
                    </Typography>
                  </Grid>

                  <Grid item xs={6} direction="row" p={8}>
                    <Typography align="center" variant="h4">
                      <FormattedMessage {...language.getText('icon.TEXT_ICONS')} />
                    </Typography>
                    <br />
                  </Grid>

                  <Grid item xs={6} direction="row" p={8}>
                    <Typography align="center" variant="h4">
                      <FormattedMessage {...language.getText('icon.MOBILE_ICONS')} />
                    </Typography>
                    <br />
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={6} direction="row" className={classes.spacearound}>
                    <div>
                      <Select
                        name="iconTypeID"
                        native
                        onChange={($event) => this.handleChange(null, 'Y', $event)}
                        variant="outlined"
                        margin="dense"
                        className={classes.textselectHeight}
                      >
                        {this.setFilterValues(iconList)}
                      </Select>
                    </div>
                    <div className={classes.inputWidth}>
                      <input type="file" onChange={($event) => this.fileSelected($event, 'ipad')} />
                    </div>
                    <div>
                      <TooltipCustom
                        title={
                          !permissionsList.includes('authoring_can_edit') ? (
                            <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                          ) : (
                            <FormattedMessage {...language.getText('contentmanager.UPLOAD_ETEXT_ICON')} />
                          )
                        }
                      >
                        <span>
                          <Button
                            variant="outlined"
                            disabled={uploadValue !== 'ipad' || !permissionsList.includes('authoring_can_edit')}
                            size="small"
                            color="primary"
                            onClick={() => this.fileUploader('eText', 'N')}
                          >
                            <FormattedMessage {...language.getText('contentmanager.UPLOAD')} />
                          </Button>
                        </span>
                      </TooltipCustom>
                    </div>
                  </Grid>
                  <Grid item xs={6} direction="row" p={8} className={classes.spacearound}>
                    <Select
                      name="iconTypeID"
                      native
                      onChange={($event) => this.handleChange(null, 'N', $event)}
                      variant="outlined"
                      margin="dense"
                      className={classes.textselectHeight}
                    >
                      {this.setFilterValues(iconList)}
                    </Select>

                    <div className={classes.inputWidth}>
                      <input type="file" onChange={($event) => this.fileSelected($event, 'eText')} />
                    </div>
                    <div>
                      <TooltipCustom
                        title={
                          !permissionsList.includes('authoring_can_edit') ? (
                            <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                          ) : (
                            <FormattedMessage {...language.getText('contentmanager.UPLOAD_MOBILE_ICON')} />
                          )
                        }
                      >
                        <span>
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={uploadValue !== 'eText' || !permissionsList.includes('authoring_can_edit')}
                            onLoad="true"
                            color="primary"
                            onClick={() => this.fileUploader('Ipad', 'Y')}
                            type="button"
                          >
                            <FormattedMessage {...language.getText('contentmanager.UPLOAD')} />
                          </Button>
                        </span>
                      </TooltipCustom>
                    </div>
                  </Grid>
                </Grid>
                <br />
                <br />

                <Grid container direction="row">
                  <Grid item xs={12} direction="row">
                    <Typography align="center" variant="h5">
                      <FormattedMessage {...language.getText('icon.PALETTE')} />
                    </Typography>
                    <br />
                    <Typography align="center" variant="subtitle2">
                      <FormattedMessage {...language.getText('icon.HOTSPOT_TYPE_TEXT')} />
                    </Typography>
                    <br />
                  </Grid>
                </Grid>

                <Grid container xs={12}>
                  <Grid container direction="row" item xs={6} className={classes.borderright}>
                    {bookIcons &&
                      bookIcons.map((item, index) => (
                        <Grid item xs={6} direction="row">
                          <List className={classes.root}>
                            <ListItem>
                              <ListItemText primary={item.type} />
                              <ListItemAvatar>
                                <img
                                  width="40px"
                                  height="40px"
                                  alt=""
                                  src={item.useCustom === 'Y' ? item.customIconPath : item.defaultIconPath}
                                />
                              </ListItemAvatar>
                              <ListItemAvatar>
                                {item.useCustom === 'Y' ? (
                                  <Tooltip title={<FormattedMessage {...language.getText('label.REVERT_BACK')} />}>
                                    <SettingsBackupRestoreIcon
                                      onClick={($event) => this.revertIconMobile($event, item.iconTypeID, index, 'N')}
                                      className={classes.pointer}
                                      style={{ fontSize: 22 }}
                                      color="error"
                                    />
                                  </Tooltip>
                                ) : null}
                              </ListItemAvatar>
                            </ListItem>
                          </List>
                        </Grid>
                      ))}
                  </Grid>
                  <Grid container direction="row" item xs={6}>
                    {ipadIcons &&
                      ipadIcons.map((item, index) => (
                        <Grid item xs={6} direction="row">
                          <List className={classes.root}>
                            <ListItem>
                              <ListItemText primary={item.type} />
                              <ListItemAvatar>
                                <img
                                  width="40px"
                                  height="40px"
                                  alt=""
                                  src={item.useCustom === 'Y' ? item.customIconPath : item.defaultIconPath}
                                />
                              </ListItemAvatar>
                              <ListItemAvatar>
                                {item.useCustom === 'Y' ? (
                                  <Tooltip title={<FormattedMessage {...language.getText('label.REVERT_BACK')} />}>
                                    <SettingsBackupRestoreIcon
                                      onClick={($event) => this.revertIcon($event, item.iconTypeID, index, 'Y')}
                                      className={classes.pointer}
                                      style={{ fontSize: 22 }}
                                      color="error"
                                    />
                                  </Tooltip>
                                ) : null}
                              </ListItemAvatar>
                            </ListItem>
                          </List>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>

                <br />
                <br />
                <br />
                <br />
                <Grid item xs={12} direction="row" p={8}>
                  <Typography align="center" variant="subtitle1">
                    <FormattedMessage {...language.getText('icon.INFO')} />
                  </Typography>
                  <br />
                </Grid>
              </Box>
            </Paper>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles)(observer(inject('language')(CustomIcon)));
