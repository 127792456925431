/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LtiLinkPreview from './LtiLinkPreview';
import HotspotUtils from '../common/HotspotUtils';
import pdfConstants from '../common/constants';
import dialogConfig from '../../../common/config/DialogConfig';
import UnSupportedPreview from './UnSupportedPreview';

class HotspotPreview extends Component {
  static propTypes = {
    regionData: PropTypes.object.isRequired,
    basePath: PropTypes.array.isRequired,
    goToPageNumber: PropTypes.func.isRequired,
    closePreviewHotspot: PropTypes.func.isRequired,
    pagePlaylist: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isLtiLink: false,
      isUnsupportedHotspot: false
    };
    this.ltiPlatformName = '';
    this.isInvaildHotspot = false;
  }

  componentDidMount() {
    const { regionData } = this.props;
    if (regionData) this.fetchRegionData(regionData);
  }

  // /* eslint-disable camelcase */
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const { regionData } = nextProps;
  //   this.fetchRegionData(regionData);
  // }
  // /* eslint-enable camelcase */

  /**
   * Method to fetch the hotspot region
   *
   * @param {Object} hotspotData
   */
  fetchRegionData = (hotspotData) => {
    let backLinkUrl = '';
    const { basePath } = this.props;
    const clickedRegionInfoObj = HotspotUtils.getRegionDetails(hotspotData);
    const isUnsupportedPreviewHotspots = [
      pdfConstants.HOTSPOT_TYPE.AUDIO,
      pdfConstants.HOTSPOT_TYPE.IMAGE,
      pdfConstants.HOTSPOT_TYPE.SPPASSET,
      pdfConstants.HOTSPOT_TYPE.VIDEO,
      pdfConstants.HOTSPOT_TYPE.GLOSSARY_TERM
    ];
    const isUnsupportedHotspot = [
      pdfConstants.HOTSPOT_TYPE.FLV,
      pdfConstants.HOTSPOT_TYPE.IPADAPP,
      pdfConstants.HOTSPOT_TYPE.JAZZASSET,
      pdfConstants.HOTSPOT_TYPE.SWF,
      pdfConstants.HOTSPOT_TYPE.FLASH
    ];
    const absoulteHotspots = [
      pdfConstants.HOTSPOT_TYPE.LTILINK,
      pdfConstants.HOTSPOT_TYPE.EMAIL,
      pdfConstants.HOTSPOT_TYPE.LTILINK
    ];
    // For LinkTypeID as URL, allow the url to be opened as new tab
    if (clickedRegionInfoObj.linkTypeId === pdfConstants.LINK_TYPE.URL) {
      clickedRegionInfoObj.hotspotType = pdfConstants.HOTSPOT_TYPE.URL;
    }
    if (isUnsupportedPreviewHotspots.includes(clickedRegionInfoObj.hotspotType)) {
      this.isInvaildHotspot = false;
      this.setState({ isUnsupportedHotspot: true });
    } else if (isUnsupportedHotspot.includes(clickedRegionInfoObj.hotspotType)) {
      this.isInvaildHotspot = true;
      this.setState({ isUnsupportedHotspot: true });
    } else {
      if (!clickedRegionInfoObj.hotspotType.includes(absoulteHotspots)) {
        if (
          clickedRegionInfoObj.linkUri &&
          !clickedRegionInfoObj.linkUri.startsWith('https://') &&
          !clickedRegionInfoObj.linkUri.startsWith('http://') &&
          !clickedRegionInfoObj.linkUri.startsWith('www.')
        ) {
          backLinkUrl = HotspotUtils.getBasepaths(clickedRegionInfoObj, basePath.basepathUrl);
          clickedRegionInfoObj.basepathUrl = backLinkUrl;
        }
      }
      this.renderHotspot(clickedRegionInfoObj);
    }
  };

  /**
   * Method to render hotspots
   *
   * @param {Object} hotspotDetails
   */
  renderHotspot = (hotspotDetails) => {
    let hotspotSourceUrl = '';
    if (
      hotspotDetails.basepathUrl !== null &&
      hotspotDetails.basepathUrl !== undefined &&
      hotspotDetails.basepathUrl !== ''
    ) {
      hotspotSourceUrl = hotspotDetails.basepathUrl + hotspotDetails.linkUri;
    } else {
      hotspotSourceUrl = hotspotDetails.linkUri;
    }
    if (hotspotSourceUrl.startsWith('www.')) {
      hotspotSourceUrl = `https://${hotspotSourceUrl}`;
    }
    switch (hotspotDetails.hotspotType) {
      case 'PAGENUMBER': {
        const { pagePlaylist, goToPageNumber } = this.props;
        const page = pagePlaylist.find((item) => item.pagenumber === hotspotDetails.linkUri);
        goToPageNumber(page && page.playOrder);
        break;
      }
      case 'EMAIL': {
        window.location = `mailto:${hotspotDetails.linkUri}`;
        break;
      }
      case 'DOCUMENT':
      case 'URL': {
        window.open(hotspotSourceUrl, '_blank');
        break;
      }
      case 'LTILINK': {
        const ltiPlatform = dialogConfig.platform.find((item) => item.id === hotspotDetails.platformId);
        if (ltiPlatform && ltiPlatform.value) {
          this.ltiPlatformName = ltiPlatform.value;
        }
        this.setState({ isLtiLink: true });
        break;
      }
      default: {
        break;
      }
    }

    if (hotspotDetails.hotspotType !== 'LTILINK') {
      const { closePreviewHotspot } = this.props;
      closePreviewHotspot();
    }
  };

  closeLtiLinkDialog = () => {
    const { closePreviewHotspot } = this.props;
    this.setState({ isLtiLink: false });
    closePreviewHotspot();
  };

  closeUnsupportedPreviewDialog = () => {
    const { closePreviewHotspot } = this.props;
    this.setState({ isUnsupportedHotspot: false });
    closePreviewHotspot();
  };

  render() {
    const { regionData } = this.props;
    const { isLtiLink, isUnsupportedHotspot } = this.state;
    return (
      <>
        {isLtiLink && (
          <LtiLinkPreview
            open
            handler={regionData.linkUri}
            platformName={this.ltiPlatformName}
            closeDialog={this.closeLtiLinkDialog}
          />
        )}
        {isUnsupportedHotspot && (
          <UnSupportedPreview
            open
            isInvaildHotspot={this.isInvaildHotspot}
            closeDialog={this.closeUnsupportedPreviewDialog}
          />
        )}
      </>
    );
  }
}

export default HotspotPreview;
