/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Typography
} from '@material-ui/core';

import { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { toJS } from 'mobx';
import { FormattedMessage } from 'react-intl';

import UserPrivilege from './UserPrivilege';
import Users from '../models/Users';
import AddEditUser from './common/AddEditUser';
import UserDialog from './UserDialog';
import Roles from '../models/Roles';
import Loader from '../../../common/Loader';
import ValidatePermissions from '../ValidatePermissions';
import TooltipCustom from '../../../common/components/TooltipCustom';
import UsersPermissions from '../models/UsersPermissions';

/**
 * A Component for the display of the Course Card.
 *
 * @param data the title of the course
 * @param btnText the title of the course
 * @param lastName the title of the course
 * @param role the title of the course
 * @param edit the title of the course
 * @return {*} the React pure component
 * @constructor
 */

const columns = [
  {
    id: 'userLogin',
    label: 'User Login',
    minWidth: 70,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'firstName',
    label: 'First Name',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'lastName',
    label: 'Last Name',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'role',
    label: 'Role',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 120,
    align: 'center',
    variant: 'outlined',
    color: 'primary',
    size: 'small',
    format: (value) => value.toLocaleString()
  }
];

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  addUser: {
    marginBottom: '20px',
    minWidth: 120
  },

  display: {
    display: 'flex'
  },
  posClass: {
    cursor: 'pointer',
    color: '#005d83',
    '&:hover': {
      fontWeight: 'bold',
      color: '#005d83',
      textDecoration: 'underline',
      textDecorationColor: '#005d83'
    }
  },
  customDisplay: {
    position: 'relative'
  },
  customText: {
    position: 'absolute',
    top: '10%',
    left: '80%',
    width: '50%'
  },
  fontSize: {
    fontSize: '15px',
    fontWeight: '400'
  }
});

const UsersList = ({
  classes,
  data,
  usersState,
  updateBreadcrumbs,
  pathUrl,
  userRoles,
  match,
  addUserStatus,
  editUserstatus,
  language,
  userPermissions
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openUserPrivilegeDialog, setopenUserPrivilegeDialog] = React.useState(false);
  const [showAddUserText, setshowAddUserText] = React.useState(false);
  const [showEditData, setshowEditData] = React.useState({});
  const [openNewUserDialog, setOpenNewUserDialog] = React.useState(false);
  const permissionsList = toJS(userPermissions.permissionList.permissions);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const history = useHistory();

  const closeUserDialog = () => {
    updateBreadcrumbs('List');
    pathUrl('');
    setOpenNewUserDialog(false);
  };

  const handleAddUserClick = () => {
    // setshowAddUserText(false);
    // setshowEditData({});
    updateBreadcrumbs('Add');
    pathUrl('add');
    setOpenNewUserDialog(true);
  };

  const closeShowAddEdit = () => {
    updateBreadcrumbs('List');
    pathUrl('');
    setopenUserPrivilegeDialog(false);
  };

  const closeUploadContent = () => {
    setopenUserPrivilegeDialog(false);
  };

  const saveUser = (userData) => {
    usersState.addUser(userData);
    setOpenNewUserDialog(false);
  };

  const updateUser = (userData) => {
    usersState.updateUser(userData);
    setshowAddUserText(false);
  };

  const UserEditData = (userid) => {
    usersState.userList.map((x) => {
      if (x.userId === userid) {
        return setshowEditData(x);
      }
      return null;
    });
  };

  const handleEditData = (row, column) => {
    if (column.id === 'action') {
      setshowAddUserText(true);
      UserEditData(row.userId);
      updateBreadcrumbs('Edit');
      pathUrl('edit');
    }
  };

  return (
    <ValidatePermissions givenPermissions="user_can_view">
      <>
        {history.location.pathname === '/users' && (
          <div>
            <Typography align="center" variant="h5">
              User List
            </Typography>{' '}
            <br />
            <br />
            <TooltipCustom
              title={
                !permissionsList.includes('ingestion_can_edit') ? (
                  <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                ) : (
                  <FormattedMessage {...language.getText('user.ADD_USER')} />
                )
              }
            >
              <span>
                <Button
                  id="newUserBtn"
                  className={classes.addUser}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleAddUserClick}
                  disabled={!permissionsList.includes('user_can_edit')}
                >
                  Add User
                </Button>
              </span>
            </TooltipCustom>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          className={classes.fontSize}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={`$(row.id)-${i}`}>
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={`$(column.id)-${index}`}
                              align={column.align}
                              onClick={() => handleEditData(row, column)}
                            >
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                              {column.id === 'role' ? (
                                <Typography
                                  onClick={() => setopenUserPrivilegeDialog(true)}
                                  variant="subtitle2"
                                  className={classes.posClass}
                                >
                                  (Permissions)
                                </Typography>
                              ) : (
                                ''
                              )}
                              {column.id === 'action' ? (
                                <TooltipCustom
                                  title={
                                    !permissionsList.includes('user_can_edit') ? (
                                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                    ) : (
                                      <FormattedMessage {...language.getText('user.EDIT_DETAILS')} />
                                    )
                                  }
                                >
                                  <span>
                                    <Button
                                      variant={column.variant}
                                      disabled={!permissionsList.includes('user_can_edit')}
                                      size={column.size}
                                      color={column.color}
                                    >
                                      EDIT
                                    </Button>
                                  </span>
                                </TooltipCustom>
                              ) : (
                                ''
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 30, 60, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
            <br />
            <br />
            <br />
          </div>
        )}

        {history.location.pathname === '/users/edit' && (
          <LoadingHandler
            loading={editUserstatus.isPending}
            isError={editUserstatus.isError && editUserstatus.error && editUserstatus.error.status === 401}
            content={
              showAddUserText && (
                <AddEditUser
                  closeShowAddEdit={closeShowAddEdit}
                  showAddUserText={showAddUserText}
                  showEditData={showEditData}
                  updateUser={updateUser}
                  userRoles={userRoles}
                  match={match}
                />
              )
            }
            loadingContent={<Loader />}
            errorContent={<StateErrorDisplay error={editUserstatus.error} showDetails />}
          />
        )}
        {history.location.pathname === '/users/add' && (
          <LoadingHandler
            loading={addUserStatus.isPending}
            isError={addUserStatus.isError && addUserStatus.error && addUserStatus.error.status === 401}
            content={
              openNewUserDialog && (
                <UserDialog closeUserDialog={closeUserDialog} saveUser={saveUser} userRoles={userRoles} />
              )
            }
            loadingContent={<Loader />}
            errorContent={<StateErrorDisplay error={addUserStatus.error} showDetails />}
          />
        )}
        {openUserPrivilegeDialog && <UserPrivilege closeUploadContent={closeUploadContent} />}
      </>
    </ValidatePermissions>
  );
};

UsersList.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  updateBreadcrumbs: PropTypes.func.isRequired,
  pathUrl: PropTypes.func.isRequired,
  usersState: shapes.modelOf(Users).isRequired,
  userRoles: shapes.modelOf(Roles).isRequired,
  match: PropTypes.object.isRequired,
  addUserStatus: PropTypes.object.isRequired,
  editUserstatus: PropTypes.object.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  language: shapes.language.isRequired
};

// export default withStyles(styles)(UsersList);
export default withStyles(styles)(inject('language', 'userPermissions')(observer(UsersList)));
