import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const data = types.model('data', {
  channelsId: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string)
});

const typeString = types.model('typeString', {
  S: types.maybeNull(types.string)
});
const lastEvaluvatedKey = types.model('lastEvaluvatedKey', {
  channelsId: types.maybeNull(typeString),
  serverDateTime: types.maybeNull(typeString),
  type: types.maybeNull(typeString),
  referenceId: types.maybeNull(typeString),
  userId: types.maybeNull(typeString)
});

const ExclusiveStartKey = types.model('ExclusiveStartKey', {
  ExclusiveStartKey: types.optional(lastEvaluvatedKey, {})
});

const ChannelsUtility = types
  .model('ChannelsUtility', {
    data: types.optional(types.array(data), []),
    isNextPageKey: types.optional(ExclusiveStartKey, {})
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHANNELS_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.CHANNELS_RESPONSE);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      applySnapshot(self.isNextPageKey, {});
      Framework.getEventManager().publish(constants.CHANNELS_RESPONSE);
    }
  }));

export default ChannelsUtility;
