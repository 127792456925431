/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import Footer from '../../common/Footer';
import Header from '../../common/header/Header';
import UnauthorizedBody from './UnauthorizedBody';

const styles = (theme) => ({
  unauthorized: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    marginLeft: '5%',
    top: 120
  },
  errorImg: {
    position: 'fixed',
    top: '35%',
    left: '25%'
  },
  errorTitleText: {
    marginBottom: 29,
    display: 'flex',
    alignItems: 'center'
  },
  errorBodyText: {
    marginBottom: 50
  },
  errorHeader: {
    backgroundColor: theme.palette.primary.dark,
    padding: '10px 52px 10px',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    '@media (max-width: 480px)': {
      padding: '10px 10px 10px'
    }
  }
});

const Unauthorized = ({ classes }) => {
  return (
    <>
      <Header />
      <Container>
        <UnauthorizedBody />
        <img
          data-testid="unauthorized-image"
          className={classes.errorImg}
          src="/illustration.svg"
          alt="Not Authorized"
        />
      </Container>
      <Footer />
    </>
  );
};

Unauthorized.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(inject('language')(observer(Unauthorized)));
