/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import { inject } from 'mobx-react';
import { DialogActions, Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Grid } from '@material-ui/core';

const styles = (theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    flex: 1,
    textAlign: 'left'
  },
  grid: {
    width: '85%',
    marginLeft: theme.spacing(3)
  },

  dialogText: {
    fontSize: '14px',
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: 400,
    lineHeight: 1.43
  }
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class UnSupportedPreview extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    closeDialog: PropTypes.func.isRequired,
    isInvaildHotspot: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      openDialog: true
    };
  }

  handleClose = () => {
    const { closeDialog } = this.props;
    closeDialog();
  };

  render() {
    const { classes, language, isInvaildHotspot } = this.props;
    const { openDialog } = this.state;
    return (
      <>
        <Dialog
          fullWidth="true"
          maxWidth="sm"
          open={openDialog}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar} color="primary">
            <Toolbar>
              <Typography variant="h3" className={classes.title} />
              <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Grid container className={classes.grid}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item xs={12} className={classes.dialogText}>
                {isInvaildHotspot ? (
                  <FormattedMessage {...language.getText('hotspot.HOTSPOT_UNSUPPORTED')} />
                ) : (
                  <FormattedMessage {...language.getText('hotspot.PREVIEW_UNSUPPORTED')} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <DialogActions>
            <Button id="okBtn" variant="outlined" color="primary" size="small" onClick={this.handleClose}>
              <FormattedMessage {...language.getText('common.OK')} />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(UnSupportedPreview));
