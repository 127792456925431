import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles, Portal } from '@material-ui/core';
import { shapes } from '@greenville/framework';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
import NotificationModel from './NotificationModel';

const styles = (theme) => ({
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});

/*
Alert object details :
  open - true/false
  type - error/warning/info/success
  message - text
  duration - alert will be visible for given time
*/
const Notification = ({ classes, notificationState }) => {
  const { open, type, message, duration } = notificationState;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    notificationState.set({
      open: false,
      type: '',
      message: '',
      duration: 0
    });
  };

  return (
    <Portal>
      <Snackbar
        className={classes.root}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <Alert className={classes.msgStyle} severity={type} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  notificationState: shapes.modelOf(NotificationModel).isRequired
};

export default withStyles(styles)(inject('notificationState')(observer(Notification)));
