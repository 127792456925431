/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {
  Typography,
  Dialog,
  Select,
  MenuItem,
  //   Table,
  //   TableBody,
  //   TableCell,
  //   TableContainer,
  //   TableHead,
  //   TableRow,
  DialogContent,
  TextField,
  Box,
  // DialogContentText,
  Paper,
  IconButton
} from '@material-ui/core';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import eTextConfig from '../../../../common/config/eTextConfig';
// import utils from '../../../../common/utils';
// import Loader from '../../../../common/Loader';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '90vh',
    maxWidth: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  labelWidht: {
    width: '100%'
  },
  bckground: {
    backgroundColor: '#d7d7d78f'
  },
  boxWidth: {
    width: '27%'
  },
  boxCoverWidth: {
    width: '90%'
  },
  paddingTopZeroCheckbox: {
    paddingTop: 0
  },
  spanClass: {
    fontSize: 12,
    color: '#808080',
    fontFamily: 'inherit'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

const ManageInfo = ({ language, classes, open, close }) => (
  <Dialog
    open={open}
    onClose={close()}
    fullWidth="true"
    fullScreen
    direction="column"
    classes={{ paper: classes.dialogPaper }}
    aria-labelledby="draggable-dialog-title"
  >
    <DialogTitle
      id="customized-dialog-title"
      onClose={close()}
      align="center"
      toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
    >
      <Typography variant="h3">Manage BookInfo</Typography>
    </DialogTitle>
    <br />

    <DialogContent>
      <Paper elevation={4}>
        <Box m={2} pt={1} pb={1}>
          <Grid container direction="row" justify="space-between">
            <Grid container item xs={12} justify="space-between">
              <Typography variant="h5" className={classes.heading} align="center" gutterBottom>
                Manage Book Info
              </Typography>
            </Grid>
            <Paper style={{ width: '100%' }}>
              <Box m={1}>
                <Grid container item xs={12} justify="space-between">
                  <Typography variant="h6" className={classes.heading} align="center">
                    Global Book ID/Book ID : <span className={classes.spanClass}> CM27955600</span>
                  </Typography>
                  <Typography variant="body1" className={classes.heading} align="center">
                    Identifier :
                  </Typography>
                  <Typography variant="body2" className={classes.heading} align="center">
                    Authors : <span className={classes.spanClass}> Badal Rai BR</span>
                  </Typography>
                </Grid>
                <Grid container item xs={12} justify="space-between">
                  <TextField label="Title" margin="dense" name="contactname" variant="outlined" />
                  <TextField
                    label="Publish Date"
                    margin="dense"
                    type="date"
                    defaultValue=""
                    name="contactname"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid container item xs={12} justify="space-between">
                  <TextField
                    label="Edition"
                    required
                    margin="dense"
                    name="contactname"
                    // value={formFields.contactname}
                    // onChange={($event) => handleChange($event)}
                    // value={workflowFields.identifier}
                    variant="outlined"
                  />
                  <TextField
                    label="Publisher Name"
                    margin="dense"
                    name="contactname"
                    // value={formFields.contactname}
                    // onChange={($event) => handleChange($event)}
                    // value={workflowFields.identifier}
                    variant="outlined"
                  />
                  <TextField
                    label="Subject"
                    margin="dense"
                    name="contactname"
                    // value={formFields.contactname}
                    // onChange={($event) => handleChange($event)}
                    // value={workflowFields.identifier}
                    variant="outlined"
                  />
                  <TextField
                    label="Imprint Name"
                    margin="dense"
                    name="contactname"
                    // value={formFields.contactname}
                    // onChange={($event) => handleChange($event)}
                    // value={workflowFields.identifier}
                    variant="outlined"
                  />
                </Grid>
                <Grid container item xs={12} justify="space-between">
                  <TextField
                    label="Isbn"
                    margin="dense"
                    name="contactname"
                    // value={formFields.contactname}
                    // onChange={($event) => handleChange($event)}
                    // value={workflowFields.identifier}
                    variant="outlined"
                  />
                  <TextField
                    label="ISBN13"
                    margin="dense"
                    name="contactname"
                    // value={formFields.contactname}
                    // onChange={($event) => handleChange($event)}
                    // value={workflowFields.identifier}
                    variant="outlined"
                  />
                  <TextField
                    label="ebookIsbn"
                    margin="dense"
                    name="contactname"
                    // value={formFields.contactname}
                    // onChange={($event) => handleChange($event)}
                    // value={workflowFields.identifier}
                    variant="outlined"
                  />
                  <TextField
                    label="ebookIsbn13"
                    margin="dense"
                    name="contactname"
                    // value={formFields.contactname}
                    // onChange={($event) => handleChange($event)}
                    // value={workflowFields.identifier}
                    variant="outlined"
                  />
                  {/* <TextField
                      label="Master Title"
                      margin="dense"
                      name="contactname"
                      // value={formFields.contactname}
                      // onChange={($event) => handleChange($event)}
                      // value={workflowFields.identifier}
                      variant="outlined"
                    />
                    <TextField
                      label="Description"
                      margin="dense"
                      name="contactname"
                      // value={formFields.contactname}
                      // onChange={($event) => handleChange($event)}
                      // value={workflowFields.identifier}
                      variant="outlined"
                    /> */}
                  {/* <TextField
                      label="Keywords"
                      margin="dense"
                      name="contactname"
                      // value={formFields.contactname}
                      // onChange={($event) => handleChange($event)}
                      // value={workflowFields.identifier}
                      variant="outlined"
                    /> */}
                </Grid>

                <Grid container direction="row" item xs={12} style={{ marginTop: '3%' }} justify="space-between">
                  <Box mb={0.5} className={classes.boxWidth}>
                    <Typography variant="subtitle2">
                      Orientation
                      {/* <FormattedMessage {...language.getText('eTextgeneral.DIVISION')} /> */}
                    </Typography>
                    <Select className={classes.labelWidht} name="twoPageView" defaultValue="0">
                      {eTextConfig.divisionOption.map((filter) => (
                        <MenuItem value={filter.id}>{filter.value}</MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box mb={0.5} className={classes.boxWidth}>
                    <Typography variant="subtitle2">
                      {' '}
                      TOC Recordered
                      {/* <FormattedMessage {...language.getText('eTextgeneral.LANGUAGE')} /> */}
                    </Typography>
                    <Select className={classes.labelWidht} name="twoPageView" defaultValue="Rightside">
                      {eTextConfig.divisionOption.map((filter) => (
                        <MenuItem value={filter.id}>{filter.value}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box mb={0.5} className={classes.boxWidth}>
                    <Typography variant="subtitle2">
                      {' '}
                      Book Locale/Language
                      {/* <FormattedMessage {...language.getText('eTextgeneral.VENDOR')} /> */}
                    </Typography>
                    <Select className={classes.labelWidht} name="twoPageView" defaultValue="">
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {eTextConfig.vendorOption.map((filter) => (
                        <MenuItem value={filter.id}>{filter.value}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box mb={0.5} className={classes.boxWidth}>
                    <Typography variant="subtitle2">
                      Copyright Year
                      {/* <FormattedMessage {...language.getText('eTextgeneral.MANAGEDBY')} /> */}
                    </Typography>
                    <Select name="twoPageView" className={classes.labelWidht} defaultValue="">
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {eTextConfig.manageOption.map((filter) => (
                        <MenuItem value={filter.id}>{filter.value}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box mb={0.5} className={classes.boxWidth}>
                    <Typography variant="subtitle2">
                      Glossary Grouping
                      {/* <FormattedMessage {...language.getText('eTextgeneral.SOURCE')} /> */}
                    </Typography>
                    <Select className={classes.labelWidht} name="twoPageView" defaultValue="">
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {eTextConfig.sourceOption.map((filter) => (
                        <MenuItem value={filter.id}>{filter.value}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>

                <Grid container item xs={12} justify="space-between">
                  <Typography variant="subtitle2" gutterBottom>
                    Description
                    {/* <FormattedMessage {...language.getText('eTextgeneral.SOURCE')} /> */}
                  </Typography>
                  <TextareaAutosize
                    rowsMin={4}
                    color="inherit"
                    className={classes.labelWidht}
                    aria-label="maximum height"
                    defaultValue=""
                  />
                  <Typography variant="subtitle2" gutterBottom>
                    Keywords
                    {/* <FormattedMessage {...language.getText('eTextgeneral.SOURCE')} /> */}
                  </Typography>
                  <TextareaAutosize
                    rowsMin={4}
                    color="inherit"
                    className={classes.labelWidht}
                    aria-label="maximum height"
                    defaultValue=""
                  />
                </Grid>
              </Box>
            </Paper>
            <br />
            <Grid container item xs={12} justify="space-between">
              <Typography variant="h5" className={classes.heading} align="center" gutterBottom>
                <br />
                Cover Art
              </Typography>
            </Grid>
            <Paper style={{ width: '100%' }}>
              <Box m={1}>
                <Grid container xs={12}>
                  <Grid item xs={4}>
                    <Box display="flex" m={0} flexDirection="column" width="90%">
                      <TextField
                        label="Thumbnail"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                      <TextField
                        label="Cover"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                      <TextField
                        label="Navigation Bar"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                      <TextField
                        label="Ipad Cover"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                      <TextField
                        label="Library Thumbnail"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4} spacing={0}>
                    <Box display="flex" m={0} flexDirection="column">
                      <Box mb={0.5} className={classes.boxCoverWidth}>
                        <Typography variant="subtitle2">
                          Type
                          {/* <FormattedMessage {...language.getText('eTextgeneral.SOURCE')} /> */}
                        </Typography>
                        <Select className={classes.labelWidht} name="twoPageView" defaultValue="">
                          <MenuItem disabled value="">
                            Select
                          </MenuItem>
                          {eTextConfig.sourceOption.map((filter) => (
                            <MenuItem value={filter.id}>{filter.value}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box mb={0.5} className={classes.boxCoverWidth}>
                        <Typography variant="subtitle2">
                          Type
                          {/* <FormattedMessage {...language.getText('eTextgeneral.SOURCE')} /> */}
                        </Typography>
                        <Select className={classes.labelWidht} name="twoPageView" defaultValue="">
                          <MenuItem disabled value="">
                            Select
                          </MenuItem>
                          {eTextConfig.sourceOption.map((filter) => (
                            <MenuItem value={filter.id}>{filter.value}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box mb={0.5} className={classes.boxCoverWidth}>
                        <Typography variant="subtitle2">
                          Type
                          {/* <FormattedMessage {...language.getText('eTextgeneral.SOURCE')} /> */}
                        </Typography>
                        <Select className={classes.labelWidht} name="twoPageView" defaultValue="">
                          <MenuItem disabled value="">
                            Select
                          </MenuItem>
                          {eTextConfig.sourceOption.map((filter) => (
                            <MenuItem value={filter.id}>{filter.value}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box mb={0.5} className={classes.boxCoverWidth}>
                        <Typography variant="subtitle2">
                          Type
                          {/* <FormattedMessage {...language.getText('eTextgeneral.SOURCE')} /> */}
                        </Typography>
                        <Select className={classes.labelWidht} name="twoPageView" defaultValue="">
                          <MenuItem disabled value="">
                            Select
                          </MenuItem>
                          {eTextConfig.sourceOption.map((filter) => (
                            <MenuItem value={filter.id}>{filter.value}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box mb={0.5} className={classes.boxCoverWidth}>
                        <Typography variant="subtitle2">
                          Type
                          {/* <FormattedMessage {...language.getText('eTextgeneral.SOURCE')} /> */}
                        </Typography>
                        <Select className={classes.labelWidht} name="twoPageView" defaultValue="">
                          <MenuItem disabled value="">
                            Select
                          </MenuItem>
                          {eTextConfig.sourceOption.map((filter) => (
                            <MenuItem value={filter.id}>{filter.value}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4} justify="center" alignItems="center">
                    <Box display="flex" m={0} flexDirection="column" width="90%">
                      <TextField
                        label="DPI"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                      <TextField
                        label="DPI"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                      <TextField
                        label="DPI"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                      <TextField
                        label="DPI"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                      <TextField
                        label="DPI"
                        margin="dense"
                        name="contactname"
                        // value={formFields.contactname}
                        // onChange={($event) => handleChange($event)}
                        // value={workflowFields.identifier}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <br />
            <Grid container item xs={12} justify="space-between">
              <Typography variant="h5" className={classes.heading} align="center" gutterBottom>
                <br />
                Author Details
              </Typography>
            </Grid>

            <Paper style={{ width: '100%' }}>
              <Box m={1}>
                <Grid container item xs={12} justify="space-between">
                  <Typography variant="h6" className={classes.heading} align="center">
                    FirstName :
                  </Typography>
                  <Typography variant="body1" className={classes.heading} align="center">
                    LastName :
                  </Typography>
                  <Typography variant="body2" className={classes.heading} align="center">
                    OtherName :
                  </Typography>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Box>
      </Paper>

      <br />

      <br />
      <br />
      <div>
        {' '}
        <Grid container direction="row" justify="center">
          <Button color="primary" style={{ marginRight: '15px' }} variant="outlined">
            Update BookInfo
          </Button>
          {/* <Button color="primary" style={{ marginRight: '15px' }} variant="outlined">
            Close
          </Button> */}
        </Grid>
      </div>
      <br />
      <br />
    </DialogContent>
  </Dialog>
);

ManageInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(ManageInfo)));
