/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { toJS } from 'mobx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Typography, Dialog, Select, MenuItem, DialogContent, TextField, Box, IconButton } from '@material-ui/core';
import { shapes } from '@greenville/framework';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import eTextConfig from '../../../../common/config/eTextConfig';
import UsersPermissions from '../../../users/models/UsersPermissions';

// import StandardEbook from '../../../contentManager/components/create/StandardEbook';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '90vh',
    maxWidth: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  labelWidht: {
    width: '100%'
  },
  bckground: {
    backgroundColor: '#d7d7d78f'
  },
  boxWidth: {
    width: '27%'
  },
  paddingTopZeroCheckbox: {
    paddingTop: 0
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;

  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

const EtextRevision = ({
  language,
  classes,
  openRevision,
  close,
  handlePanelChange,
  handleSave,
  expanded,
  divisions,
  etextRevForm,
  revisionData,
  handleChangeRevision,
  international,
  status,
  handleCheckboxRevision,
  handleRevDelete,
  manageInfo,
  vendorInfo,
  userPermissions
}) => (
  <Dialog
    open={openRevision}
    onClose={close()}
    fullWidth="true"
    fullScreen
    direction="column"
    classes={{ paper: classes.dialogPaper }}
    aria-labelledby="draggable-dialog-title"
  >
    <DialogTitle
      id="customized-dialog-title"
      onClose={close()}
      align="center"
      toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
    >
      <Typography variant="h3">
        {' '}
        <FormattedMessage {...language.getText('eTextrevision.REVISION')} />
      </Typography>
    </DialogTitle>
    <br />

    <DialogContent>
      <div className={classes.root}>
        {/* DO NOT DELETE
        <Accordion expanded={expanded === 'panel5'} onChange={() => handlePanelChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            className={classes.bckground}
          >
            <Typography variant="h5">
              <FormattedMessage {...language.getText('contentmanager.UPLOAD_CONTENT_IF_NOT_UPLOADED')} />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <StandardEbook {...uploadProps} isCMScreen={false} />
          </AccordionDetails>
        </Accordion> */}
        <Accordion expanded={expanded === 'panel1'} onChange={() => handlePanelChange('panel1')}>
          <AccordionSummary className={classes.bckground} expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}>
            <Typography variant="h5" className={classes.heading} align="center">
              <FormattedMessage {...language.getText('eTextrevision.GENERAL')} />
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container direction="row" justify="space-between">
              <Grid container item xs={12} justify="space-between">
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.REVISION')} />}
                  margin="dense"
                  name="revisionID"
                  onChange={(e) => handleChangeRevision(e, true)}
                  inputProps={{ readOnly: true }}
                  value={revisionData.etextGeneral.revisionID}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.REQUEST')} />}
                  required
                  margin="dense"
                  name="requestID"
                  inputProps={{ readOnly: true }}
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.requestID}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextrevision.ETEXTISBN10')} />}
                  margin="dense"
                  name="etextISBN10"
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.etextISBN10}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextrevision.ETEXTISBN13')} />}
                  margin="dense"
                  name="etextISBN13"
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.etextISBN13}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextrevision.PARENTISBN10')} />}
                  margin="dense"
                  name="parentISBN10"
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.parentISBN10}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextrevision.PARENTISBN13')} />}
                  margin="dense"
                  name="parentISBN13"
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.parentISBN13}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextreivsion.BOOKID')} />}
                  margin="dense"
                  name="bookID"
                  inputProps={{ readOnly: true }}
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.bookID}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.GLOABALBOOKID')} />}
                  margin="dense"
                  name="globalBookID"
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.globalBookID}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.CHARGEISBN')} />}
                  margin="dense"
                  name="chargeISBN"
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.chargeISBN}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.TITLE')} />}
                  margin="dense"
                  name="title"
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.title}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.AUTHOR')} />}
                  margin="dense"
                  name="author"
                  onChange={(e) => handleChangeRevision(e, true)}
                  value={revisionData.etextGeneral.author}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.COPYRIGHT')} />}
                  style={{ display: 'none' }}
                  margin="dense"
                  name="copyright"
                  value={revisionData.etextGeneral.copyright}
                  onChange={(e) => handleChangeRevision(e, true)}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.NAME')} />}
                  margin="dense"
                  name="name"
                  value={revisionData.etextGeneral.name}
                  onChange={(e) => handleChangeRevision(e, true)}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.EMAIL')} />}
                  margin="dense"
                  error={etextRevForm}
                  name="email"
                  value={revisionData.etextGeneral.email}
                  onChange={(e) => handleChangeRevision(e, true)}
                  variant="outlined"
                />
                <TextField
                  label={<FormattedMessage {...language.getText('eTextconversion.ADDITIONALEMAIL')} />}
                  margin="dense"
                  name="addlEmail"
                  value={revisionData.etextGeneral.addlEmail}
                  onChange={(e) => handleChangeRevision(e, true)}
                  variant="outlined"
                />
              </Grid>

              <Grid container direction="row" item xs={12} style={{ marginTop: '3%' }} justify="space-between">
                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle2">
                    <FormattedMessage {...language.getText('eTextgeneral.DIVISION')} />
                  </Typography>
                  <Select
                    className={classes.labelWidht}
                    name="divisionID"
                    defaultValue={revisionData.etextGeneral.divisionID}
                    onChange={(e) => handleChangeRevision(e, true)}
                  >
                    {divisions.length > 0 &&
                      divisions.map((filter) => <MenuItem value={filter.divisionId}>{filter.divisionText}</MenuItem>)}
                  </Select>
                </Box>

                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle1">
                    {' '}
                    <FormattedMessage {...language.getText('eTextgeneral.VENDOR')} />
                  </Typography>
                  <Select
                    className={classes.labelWidht}
                    name="vendor"
                    onChange={(e) => handleChangeRevision(e, true)}
                    defaultValue={revisionData.etextGeneral.vendor}
                  >
                    <MenuItem disabled value="">
                      <FormattedMessage {...language.getText('common.select')} />
                    </MenuItem>
                    {vendorInfo.length > 0 &&
                      vendorInfo.map((filter) => <MenuItem value={filter.vendorText}>{filter.vendorText}</MenuItem>)}
                  </Select>
                </Box>
                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle1">
                    <FormattedMessage {...language.getText('eTextgeneral.MANAGEDBY')} />
                  </Typography>
                  <Select
                    name="managedBy"
                    className={classes.labelWidht}
                    onChange={(e) => handleChangeRevision(e, true)}
                    defaultValue={revisionData.etextGeneral.managedBy}
                  >
                    <MenuItem disabled value="">
                      <FormattedMessage {...language.getText('common.select')} />
                    </MenuItem>
                    {manageInfo.length > 0 &&
                      manageInfo.map((filter) => (
                        <MenuItem value={filter.managedByText}>{filter.managedByText}</MenuItem>
                      ))}
                    {/* {eTextConfig.manageOption.map((filter) => (
                      <MenuItem value={filter.value}>{filter.value}</MenuItem>
                    ))} */}
                  </Select>
                </Box>
                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle1">
                    <FormattedMessage {...language.getText('eTextgeneral.SOURCE')} />
                  </Typography>
                  <Select
                    className={classes.labelWidht}
                    onChange={(e) => handleChangeRevision(e, true)}
                    name="source"
                    defaultValue={revisionData.etextGeneral.source}
                  >
                    <MenuItem disabled value="">
                      <FormattedMessage {...language.getText('common.select')} />
                    </MenuItem>
                    {eTextConfig.sourceOption.map((filter) => (
                      <MenuItem value={filter.value}>{filter.value}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle1">
                    <FormattedMessage {...language.getText('eTextgeneral.DIGITALLIBRARY')} />
                  </Typography>
                  <Select
                    className={classes.labelWidht}
                    name="digitalLibrary"
                    onChange={(e) => handleChangeRevision(e, true)}
                    defaultValue={revisionData.etextGeneral.digitalLibrary}
                  >
                    <MenuItem disabled value="">
                      <FormattedMessage {...language.getText('common.select')} />
                    </MenuItem>
                    {eTextConfig.yesNo.map((filter) => (
                      <MenuItem value={filter.id}>{filter.value}</MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box mb={0.5} className={classes.boxWidth}>
                  <Typography variant="subtitle1">
                    <FormattedMessage {...language.getText('eTextgeneral.INTLREGION')} />
                  </Typography>
                  <Select
                    className={classes.labelWidht}
                    name="intlRegion"
                    defaultValue={revisionData.etextGeneral.intlRegion}
                    onChange={(e) => handleChangeRevision(e, true)}
                  >
                    <MenuItem disabled value="">
                      <FormattedMessage {...language.getText('common.select')} />
                    </MenuItem>
                    {international.length > 0 &&
                      international.map((filter) => (
                        <MenuItem value={filter.internationalId}>{filter.internationalText}</MenuItem>
                      ))}
                  </Select>
                </Box>
              </Grid>
              <Grid container item xs={12} style={{ marginTop: '3%' }}>
                <Typography align="center" variant="body2" gutterBottom>
                  <br />
                  <FormattedMessage {...language.getText('eTextrevision.PRODUCTION')} />
                </Typography>
                <TextareaAutosize
                  rowsMin={8}
                  name="productionNotes"
                  onChange={(e) => handleChangeRevision(e, true)}
                  rowsMax={7}
                  color="inherit"
                  className={classes.labelWidht}
                  aria-label="maximum height"
                  defaultValue={revisionData.etextGeneral.productionNotes}
                />
              </Grid>
              <Grid container item xs={12}>
                <Typography align="center" variant="body2" gutterBottom>
                  <br />
                  <FormattedMessage {...language.getText('eTextrevision.INITIAL')} />
                </Typography>
                <TextField
                  multiline
                  inputProps={{ readOnly: true }}
                  input
                  fullWidth="true"
                  rows={4}
                  variant="outlined"
                  value={revisionData.etextGeneral.initialComments}
                  name="initialComments"
                  color="inherit"
                  onChange={(e) => handleChangeRevision(e, true)}
                  className={classes.labelWidht}
                  aria-label="maximum height"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={() => handlePanelChange('panel2')}>
          <AccordionSummary
            className={classes.bckground}
            expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="h5" className={classes.heading}>
              <FormattedMessage {...language.getText('eTextrevision.SCHEDULE')} />
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container item xs={12} direction="row" justify="space-between">
              <TextField
                label={<FormattedMessage {...language.getText('eTextconversion.REQFORM')} />}
                margin="dense"
                inputProps={{ readOnly: true }}
                name="requestFormSubmitted"
                value={revisionData.etextScheduleStatus.requestFormSubmitted}
                onChange={(e) => handleChangeRevision(e, 'status')}
                variant="outlined"
              />
              <TextField
                label={<FormattedMessage {...language.getText('eTextconversion.COMPLETION')} />}
                margin="dense"
                name="estimatedProjectCompletion"
                inputProps={{ readOnly: true }}
                value={revisionData.etextScheduleStatus.estimatedProjectCompletion}
                onChange={(e) => handleChangeRevision(e, 'status')}
                variant="outlined"
              />
              <TextField
                label={<FormattedMessage {...language.getText('eTextconversion.FOLDERNAME')} />}
                margin="dense"
                name="folderName"
                value={revisionData.etextScheduleStatus.folderName}
                onChange={(e) => handleChangeRevision(e, 'status')}
                variant="outlined"
              />
              <TextField
                label={<FormattedMessage {...language.getText('eTextconversion.REVIEWCOUNT')} />}
                margin="dense"
                name="reviewCount"
                value={revisionData.etextScheduleStatus.reviewCount}
                onChange={(e) => handleChangeRevision(e, 'status')}
                variant="outlined"
              />
              <TextField
                label={<FormattedMessage {...language.getText('eTextconversion.PROJECT')} />}
                margin="dense"
                name="projectInitiated"
                value={revisionData.etextScheduleStatus.projectInitiated}
                onChange={(e) => handleChangeRevision(e, 'status')}
                variant="outlined"
              />
              <TextField
                label={<FormattedMessage {...language.getText('eTextconversion.ADJUSTED')} />}
                margin="dense"
                name="adjustedProjection"
                value={revisionData.etextScheduleStatus.adjustedProjection}
                onChange={(e) => handleChangeRevision(e, 'status')}
                variant="outlined"
              />
              <TextField
                label={<FormattedMessage {...language.getText('eTextconversion.HOLDDAYS')} />}
                margin="dense"
                name="holdDays"
                type="number"
                value={revisionData.etextScheduleStatus.holdDays}
                onChange={(e) => handleChangeRevision(e, 'status')}
                variant="outlined"
              />
              <TextField
                label={<FormattedMessage {...language.getText('eTextconversion.OVERALLDATE')} />}
                margin="dense"
                name="overAllCompletionDate"
                value={revisionData.etextScheduleStatus.overAllCompletionDate}
                onChange={(e) => handleChangeRevision(e, 'status')}
                variant="outlined"
              />
              <Box mb={0.5} className={classes.boxWidth}>
                <Typography variant="subtitle1">
                  <FormattedMessage {...language.getText('eTextgeneral.PRODUCTION')} />
                </Typography>
                <Select
                  className={classes.labelWidht}
                  name="productionStatus"
                  defaultValue={revisionData.etextScheduleStatus.productionStatus}
                  onChange={(e) => handleChangeRevision(e, 'status')}
                >
                  <MenuItem disabled value="">
                    <FormattedMessage {...language.getText('common.select')} />
                  </MenuItem>
                  {status.length > 0 &&
                    status.map((filter) => <MenuItem value={filter.statusId}>{filter.statusText}</MenuItem>)}
                </Select>
              </Box>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={() => handlePanelChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className={classes.bckground}
          >
            <Typography variant="h5">
              <FormattedMessage {...language.getText('eTextrevision.REVISION_DETAILS')} />
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container direction="row" spacing={5}>
              <Grid container item xs={11} direction="column">
                <br />
                <FormControl component="fieldset">
                  <FormGroup row onChange={(e) => handleCheckboxRevision(e, 'revisionCheck')}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.permissionChange}
                          name="permissionChange"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.PERMISSION')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.updatedHotspotLinks}
                          name="updatedHotspotLinks"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.NEW')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.updatedGlossaryContent}
                          name="updatedGlossaryContent"
                          color="primary"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.NEWGLOSSARY')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.pageAddOrSub}
                          name="pageAddOrSub"
                          color="primary"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.PAGE')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.contentChange}
                          name="contentChange"
                          color="primary"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.CHANGE')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.updateTitleEditionAuthor}
                          name="updateTitleEditionAuthor"
                          color="primary"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.UPDATE')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.tocChange}
                          name="tocChange"
                          color="primary"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.TOCCHANGE')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.coverImageChange}
                          name="coverImageChange"
                          color="primary"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.CHANGECOVER')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.iPadViewChange}
                          name="iPadViewChange"
                          color="primary"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.IPADVIEW')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.dlRequireChange}
                          name="dlRequireChange"
                          color="primary"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextgeneral.DIGITALLIBRARY')} />}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextRevisionDetails.otherRevChange}
                          name="otherRevChange"
                          color="primary"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextrevision.OTHERS')} />}
                    />
                  </FormGroup>
                </FormControl>
                <TextField
                  margin="dense"
                  name="otherRevisionTypeText"
                  onChange={(e) => handleChangeRevision(e, 'revision')}
                  value={revisionData.etextRevisionDetails.otherRevisionTypeText}
                  variant="outlined"
                />
                <Typography variant="subtitle1" gutterBottom>
                  <FormattedMessage {...language.getText('eTextrevision.DESCRIPTION')} />
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  name="revDescription"
                  rowsMin={6}
                  defaultValue={revisionData.etextRevisionDetails.revDescription || ''}
                  onChange={(e) => handleChangeRevision(e, 'revision')}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={() => handlePanelChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className={classes.bckground}
          >
            {' '}
            <Typography variant="h5">
              <FormattedMessage {...language.getText('eTextrequest.SPECIAL')} />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" spacing={5}>
              <Grid container item xs={11} direction="column">
                <br />
                <FormControl component="fieldset">
                  <FormGroup row onChange={(e) => handleCheckboxRevision(e, 'specialValue')}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextSpecialSectionInfo.insideFaceCover === 'Y'}
                          name="insideFaceCover"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.INSIDE')} />}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextSpecialSectionInfo.insideBackCover === 'Y'}
                          name="insideBackCover"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.INSIDEBACK')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextSpecialSectionInfo.colorInserts === 'Y'}
                          name="colorInserts"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.COLORINSERTS')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextSpecialSectionInfo.bonusChapters === 'Y'}
                          name="bonusChapters"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.BONUSCHAPTER')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextSpecialSectionInfo.tearCard === 'Y'}
                          name="tearCard"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.TEARCARD')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextSpecialSectionInfo.endPapers === 'Y'}
                          name="endPapers"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextreivsion.ENDPAPERS')} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.paddingTopZeroCheckbox}
                          defaultChecked={revisionData.etextSpecialSectionInfo.othersCheck === 'Y'}
                          name="othersCheck"
                        />
                      }
                      label={<FormattedMessage {...language.getText('eTextrevision.OTHERS')} />}
                    />
                  </FormGroup>
                </FormControl>
                <TextField
                  margin="dense"
                  name="others"
                  onChange={(e) => handleChangeRevision(e, 'special')}
                  value={revisionData.etextSpecialSectionInfo.others}
                  variant="outlined"
                />
                <Typography variant="subtitle1" gutterBottom>
                  <FormattedMessage {...language.getText('eTextrevision.SUPPLEMENTAL')} />
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  rowsMin={6}
                  name="suppSectionDesc"
                  value={revisionData.etextSpecialSectionInfo.suppSectionDesc}
                  onChange={(e) => handleChangeRevision(e, 'special')}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <br />
      <br />
      <div>
        {' '}
        <Grid container direction="row" justify="center">
          <TooltipCustom
            title={
              !toJS(userPermissions.permissionList.permissions).includes('etext_can_edit') ? (
                <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
              ) : (
                <FormattedMessage {...language.getText('eTextrevision.UPDATE_ETEXT_REVISION')} />
              )
            }
          >
            <span>
              <Button
                color="primary"
                style={{ marginRight: '15px' }}
                variant="outlined"
                onClick={() => handleSave()}
                disabled={!toJS(userPermissions.permissionList.permissions).includes('etext_can_edit')}
              >
                <FormattedMessage {...language.getText('eTextrevision.UPDATE')} />
              </Button>
            </span>
          </TooltipCustom>

          <TooltipCustom
            title={
              !toJS(userPermissions.permissionList.permissions).includes('etext_can_delete') ? (
                <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
              ) : (
                <FormattedMessage {...language.getText('eTextrevision.DELETE_ETEXT_REVISION')} />
              )
            }
          >
            <span>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleRevDelete(revisionData.etextGeneral.revisionID)}
                disabled={!toJS(userPermissions.permissionList.permissions).includes('etext_can_delete')}
              >
                <FormattedMessage {...language.getText('common.DELETE')} />
              </Button>
            </span>
          </TooltipCustom>
        </Grid>
      </div>
      <br />
      <br />
    </DialogContent>
  </Dialog>
);

EtextRevision.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  openRevision: PropTypes.bool.isRequired,
  etextRevForm: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  handlePanelChange: PropTypes.func.isRequired,
  handleChangeRevision: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  handleCheckboxRevision: PropTypes.func.isRequired,
  vendorInfo: PropTypes.array.isRequired,
  manageInfo: PropTypes.array.isRequired,
  divisions: PropTypes.array.isRequired,
  international: PropTypes.array.isRequired,
  status: PropTypes.array.isRequired,
  // uploadProps: PropTypes.object.isRequired,
  revisionData: PropTypes.object.isRequired,
  handleRevDelete: PropTypes.func.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired
};

export default withStyles(styles)(inject('language', 'userPermissions')(observer(EtextRevision)));
