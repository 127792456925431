/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Typography, Button, Grid, Paper } from '@material-ui/core';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';
import BulkAction from '../models/BulkAction';
import Upload from '../../contentManager/models/Upload';
import ChooseFile from '../../../common/components/ChooseFile';

const styles = (theme) => ({
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -40
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  }
});
@inject('bulkAction', 'upload')
@observer
class BulkActionComp extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    handleOpenUploadContent: PropTypes.func.isRequired,
    bulkAction: shapes.modelOf(BulkAction).isRequired,
    upload: shapes.modelOf(Upload).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadContentPath: ''
    };
    this.createContentPath = this.createContentPath.bind(this);
  }

  createContentPath = () => {
    const uploadContentPathUrl = `${constants.BULK_ACTION_FOLDER}${constants.SEPARATOR}${utils.uuid()}${
      constants.SEPARATOR
    }`;
    this.setState({ uploadContentPath: uploadContentPathUrl });
  };

  handleClick = () => {
    const { bulkAction, upload } = this.props;
    const { uploadContentPath } = this.state;
    const result = upload.bucketFolders;
    const sheetName = result[0];
    const finalPath = `${uploadContentPath}${sheetName}`;
    bulkAction.fetchPath(finalPath);
  };

  render() {
    const { classes, language } = this.props;
    const { uploadContentPath } = this.state;
    if (!uploadContentPath) {
      this.createContentPath();
    }
    return (
      <>
        <div className={classes.container}>
          <Paper className={classes.paper} elevation={3}>
            <Grid container direction="column" alignItems="center" justify="space-between">
              <Typography variant="h3" align="center" gutterBottom spacing={3} className={classes.title}>
                <FormattedMessage {...language.getText('bulkutility.TITLE_BULKACTION')} />
              </Typography>
              <ChooseFile
                message={{ ...language.getText('bulkutility.UPLOAD_EXCEL') }.defaultMessage}
                disabled={false}
                uploadPath={uploadContentPath}
              />
              <Grid item className={classes.button}>
                <Button
                  disabled={false}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => this.handleClick()}
                >
                  <FormattedMessage {...language.getText('Start Processing')} />
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(BulkActionComp)));
