/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import _ from 'lodash';
import * as constants from '../../../common/constants';

const UserDetail = types.model('UserDetail', {
  userId: types.maybeNull(types.integer),
  userLogin: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  roleTypeId: types.maybeNull(types.integer)
});

const Users = types
  .model('Users', {
    userList: types.optional(types.array(UserDetail), [])
  })
  .actions((self) => ({
    fetch() {
      Framework.getEventManager().publish(constants.MANAGE_USERS_DATA_FETCHED, {});
    },
    addUser(addUserData) {
      Framework.getEventManager().publish(constants.ADD_USER_DATA, addUserData);
    },
    updateUser(data) {
      Framework.getEventManager().publish(constants.EDIT_USER_DATA, data);
    },
    set(response) {
      applySnapshot(self.userList, response.data);
    },

    setAddedUserData(addedUserResponse) {
      const updatedState = self;
      updatedState.userList.unshift(addedUserResponse.data);
      applySnapshot(self, updatedState);
    },

    setEditUserData(addedUserResponse) {
      const updatedState = self;
      const responseuserId = Number(addedUserResponse.data.userId);
      updatedState.userList = _.map(updatedState.userList, (list) => {
        return list.userId === responseuserId ? addedUserResponse.data : list;
      });
      applySnapshot(self, updatedState);
    }
  }));

export default Users;
