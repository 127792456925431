/* eslint-disable prefer-destructuring */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * A service to fetch the bookID asset details.
 * @author Vignesh Selvam
 */
export default class AssetService extends BaseService {
  constructor() {
    super(constants.ASSET_SYNC_REQUESTED, 'assetTocStatus');
  }

  handleEvent(eventName, event) {
    return this.getClient('evergreen').get(`/chatutility/getTOCData/${event.bookId}`);
  }

  handleResponse(response, event) {
    // console.log('response.data', response.data.data);
    const responseData = response.data && response.data.data;
    const tocData = {
      id: event.bookId,
      title: responseData.title,
      children: this.getTocContents(responseData.children, event.bookId)
      // slates: responseData.slates.length
      //   ? this.playlistSlateMapper(responseData.slates, responseData.children)
      //   : this.playlistMapper(responseData.children)
    };
    // console.log('tocData', tocData);
    // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('assetTOC').set(tocData, event);
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    // Framework.redirectTo(`/books/${event.payload.data.bookID}`);
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if ((status === 500 || status === 400) && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message ? data.error.message : data.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
  }

  playlistMapper(tocDataList) {
    const playlist = [];
    let prevHref = '';

    function drillToDeep(tocList, parentTitle) {
      tocList.map((item) => {
        let type;
        let uri;
        let assessmentId;
        let versionUrn;

        if (item.assessmentId) {
          assessmentId = item.assessmentId;
        }
        switch (item.type) {
          case 'quiz':
            type = 'quiz';
            break;
          case 'tdx':
            type = 'tdx';
            break;
          case 'cite-assessment':
            type = 'cite-assessment';
            uri = item.assessmentId;
            break;
          case 'learnosity-assessment':
            type = 'learnosity-assessment';
            versionUrn = item.href;
            break;
          case 'tdx-assessment':
            type = 'tdx-assessment';
            uri = item.assessmentId;
            break;
          case 'glossary':
            type = 'glossary';
            break;
          case 'footnotes':
            type = 'footnotes';
            break;
          default:
            type = 'page';
            assessmentId = null;
        }
        const list = {
          assessmentId,
          chapterId: item.chapterId,
          chapterTitle: parentTitle || item.title,
          href: item.href,
          id: item.id,
          title: item.title,
          type,
          parentItemId: item.parentItemId,
          playOrder: item.playOrder,
          uri,
          urn: item.urn,
          versionUrn
        };
        if (
          !(item.href && item.href === prevHref) ||
          item.type === 'cite-assessment' ||
          item.type === 'learnosity-assessment'
        ) {
          let dehashedURI = '';

          if (item.href && item.href.includes('#')) {
            dehashedURI = item.href.split('#')[0];
          }
          if (dehashedURI !== '' && dehashedURI === prevHref) {
            // remove duplicate page entry if dehashed href matches
            playlist.pop();

            // Update href and uri without hash
            list.href = dehashedURI;
            list.uri = dehashedURI;
          }
          playlist.push(list);
          prevHref = dehashedURI || item.href;
        }
        const { children } = item;

        if (children && children.length > 0) {
          drillToDeep(children, item.title);
        }

        return true;
      });
    }
    const tocData = tocDataList;

    if (tocData) {
      drillToDeep(tocData);
    }
    return playlist;
  }

  getTocContents(tocChildren, bookId, courseId, isPdfContent = false, languages = {}) {
    const audioContents = [];
    const contents = [];
    let audio = null;
    let assessmentId = '';
    // if book id is empty then course id will be used.
    // const id = bookId || courseId;
    let parentId = '';
    let titleAudio = null;

    function drillToDeep(item, chapterId) {
      const node = item;
      const href = node.href || node.uri || '';
      let hrefUri = href;

      if (['assessment', 'tdx', 'cite-assessment'].indexOf(node.type) !== -1) {
        assessmentId = node.uri;
      }
      if (!isPdfContent) {
        const index = href && href.indexOf('/OPS/');

        hrefUri = index >= 0 ? href.substr(index, href.length) : href;
      }
      // Custom Basket : Makes as empty when href comes as "undefined"
      if (hrefUri === 'undefined') {
        hrefUri = '';
      }
      if (node.audio && node.audio.length >= 1) {
        audio = [
          {
            audioIndex: '0',
            id: node.id,
            title: node.title,
            parentId,
            chapterId,
            src: node.audio,
            audioMetadata: node.audioTTSMetadata && node.audioTTSMetadata
          }
        ];
      }
      if (audio && audio.length === 1) {
        titleAudio = audio[0];
      } else {
        titleAudio = null;
      }

      // Audio duplicate issue fix
      if (node.children && node.children.length) {
        const childList = [];
        node.children.forEach((child) => {
          const childHref = child.href || child.uri || '';
          if (childHref && childHref.includes('#')) {
            childList.push(childHref.split('#')[0]);
          }
        });
        if (childList.includes(href)) {
          titleAudio = null;
        }
      }
      // End

      const list = {
        assessmentId,
        audio: titleAudio,
        chapterId,
        children: [],
        coPage: node.coPage === 'true',
        display: node.display !== false,
        hidden: node.optional === 'true',
        href: hrefUri,
        uri: hrefUri,
        id: node.id,
        label: node.level ? String(node.level) : '',
        parentItemId: parentId,
        playOrder: node.playOrder,
        chapterNumber: node.playOrder,
        title: node.title || node.label,
        type: node.type || '',
        urn: node.id || node.path,
        // PDF attributes
        linkTypeId: node.linkTypeId || '',
        linkUri: node.linkUri || '',
        linkX: node.linkX || '',
        linkY: node.linkY || '',
        name: node.name || '',
        regionTypeId: node.regionTypeId || '',
        sectionType: node.sectionType || '',
        contentChapterId: isPdfContent && node.chapterId ? node.chapterId : '',
        level: node.level || '',
        actualPageUri: node.actualPageUri || ''
      };

      if (audio && audio.length > 1) {
        audio.forEach((audioTrack, index) => {
          list.children.push({
            assessmentId,
            audio: audioTrack,
            chapterId,
            children: [],
            coPage: false,
            display: false,
            hidden: false,
            href: '',
            id: `${node.id}&&${index}`, // Added && to differentiate from numerical page ids
            label: node.level ? String(node.level + 1) : '',
            parentItemId: node.id,
            playOrder: '',
            title: `${languages.getText('audio')} ${index + 1}`,
            type: '',
            urn: `${node.id}&&${index}` // Added && to differentiate from numerical page ids
          });
        });
      }

      if (node.level === 1 && node.optional === 'true') {
        list.optional = node.optional;
      }

      if (audio && audio.length) {
        audio.forEach((audioObj) => {
          // Objects make to find next track for autoplay
          const audioInfo = {
            ...audioObj,
            isPlaying: false
          };

          audioContents.push(audioInfo);
        });
      }
      const children = node.items || node.children || node.content;

      if (children) {
        children.forEach((child) => {
          // Code removed because we discarded part in response
          parentId = list.id; // assigned here for get revel cite child id by parent
          const audioArr = [];
          if (child.audio && child.audio.length >= 1) {
            audioArr.push({
              audioIndex: '0',
              id: child.id,
              title: child.title,
              parentId,
              chapterId,
              src: child.audio,
              audioMetadata: child.audioTTSMetadata && child.audioTTSMetadata
            });
          }
          if (node.level >= '5') {
            list.audio = list.audio && list.audio.src ? list.audio : audioArr;
          }
          // eslint-disable-next-line no-param-reassign
          child.level = node.level + 1;
          if (child.uri || child.children || child.items) {
            list.children.push(drillToDeep(child, chapterId));
          }
        });
      }
      parentId = '';
      audio = null;

      return list;
    }
    if (tocChildren) {
      tocChildren.forEach((node) => {
        if (!node.part) {
          // eslint-disable-next-line no-param-reassign
          node.level = 1;
          const nodes = this.isAudioAvailableInChapter(drillToDeep(node, node.id));
          contents.push(nodes);
        }
      });
    }
    const optionalList = [];
    let tocList = contents.filter((chapter) => {
      if (chapter.optional === 'true') {
        optionalList.push(chapter);
      }

      return !chapter.optional;
    });

    tocList = Array.prototype.concat.apply(tocList, optionalList);
    // const tableOfContents = {
    //   id: bookId,
    //   list: tocList
    // };

    return tocList;
  }

  isAudioAvailableInChapter = (node) => {
    const drillToDeepAudio = (tocNode) => {
      if (tocNode.children) {
        return tocNode.children.find((item) => {
          if (item.audio) {
            return item.audio;
          }
          return drillToDeepAudio(item);
        });
      }
      return null;
    };
    const audio = drillToDeepAudio(node);

    // eslint-disable-next-line no-param-reassign
    node.isAudioAvailableInChapter = Boolean(audio);

    return node;
  };

  flattenToc = (tocDataList) => {
    const tocFlatten = [];

    const tocMapper = (tocData) => {
      tocData.forEach((toc) => {
        const tocList = {
          chapterId: toc.id,
          chapterTitle: toc.title,
          id: toc.id,
          href: toc.uri,
          uri: toc.uri,
          type: 'page',
          title: toc.title,
          playOrder: toc.playOrder
        };
        tocFlatten.push(tocList);
        if (toc.children) {
          tocMapper(toc.children);
        }
      });
    };
    tocMapper(tocDataList);

    return tocFlatten;
  };

  playlistSlateMapper(slates, tocChildren) {
    const flattenedToc = this.flattenToc(tocChildren);
    const playlist = [];

    slates.forEach((item) => {
      const chapterData = flattenedToc.find((toc) => toc.uri === item.parentUri);
      const pageData = {
        chapterId: (chapterData && chapterData.id) || item.id,
        chapterTitle: (chapterData && chapterData.title) || item.title,
        href: item.uri,
        id: item.id,
        title: item.title,
        type: 'page',
        uri: item.uri
      };

      playlist.push(pageData);
    });

    // console.log('playlist', playlist);

    return playlist;
  }
}
