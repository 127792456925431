/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { shapes, LoadingHandler } from '@greenville/framework';
import LoadingCard from './loader/LoadingCard';
import ErrorCard from './error/ErrorCard';
// import Home from '../model/Home';

const styles = () => ({
  loadingSkeleton: {
    borderRadius: 8,
    padding: '0 24px 12px 24px',
    '@media (max-width: 767px)': {
      width: 250,
      height: 80
    },
    '@media (max-width: 480px)': {
      paddingLeft: 12
    }
  },
  welcomeBox: {
    paddingBottom: 8
  },
  errorStyle: {
    display: 'flex',
    height: 18,
    width: '100%',
    backgroundColor: 'rgba(238, 238, 238, 0.1)',
    borderRadius: 8,
    justifyContent: 'center',
    paddingTop: 14,
    paddingBottom: 14
  }
});

@inject('language', 'user')
@observer
class WelcomeText extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    user: shapes.user.isRequired,
    // home: shapes.modelOf(Home).isRequired,
    hours: PropTypes.number.isRequired
  };

  render() {
    const { classes, language, user, hours } = this.props;

    let welcomeText = '';

    switch (hours) {
      case hours >= 2 && hours <= 5 && hours:
        welcomeText = 'home.WELCOME_EARLY';
        break;
      case hours > 5 && hours < 12 && hours:
        welcomeText = 'home.WELCOME_MORNING';
        break;
      case hours >= 12 && hours < 17 && hours:
        welcomeText = 'home.WELCOME_AFTERNOON';
        break;
      case hours >= 17 && hours < 22 && hours:
        welcomeText = 'home.WELCOME_EVENING';
        break;
      case (hours >= 22 || hours < 2) && hours:
        welcomeText = 'home.WELCOME_NIGHT';
        break;
      default:
        break;
    }
    return (
      <Box className={classes.welcomeBox}>
        <LoadingHandler
          loading={false}
          loadingContent={<LoadingCard skeletonStyle={classes.loadingSkeleton} height={45} width={695} />}
          isError={false}
          errorContent={<ErrorCard varient="secondary" message="error.INTRODUCTION" errorStyle={classes.errorStyle} />}
          content={
            <Typography variant="subtitle2">
              <FormattedMessage
                {...language.getText(welcomeText)}
                values={{
                  userName: user.fullName
                }}
              />
            </Typography>
          }
        />
      </Box>
    );
  }
}

export default withStyles(styles)(WelcomeText);
