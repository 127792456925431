import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
// import { Box } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
// import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@material-ui/core';
// import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
// import { toJS } from 'mobx';
import { shapes } from '@greenville/framework';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { inject } from 'mobx-react';
import * as constants from '../../../../common/constants';
import Summary from '../../models/Summary';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = () => ({
  flexNoSpace: {
    display: 'flex',
    alignItems: 'center'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  },
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'right',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    padding: '10px'
  },
  mainContainer: {
    border: '1px solid grey',
    padding: '23px',
    borderRadius: '4px',
    margin: '15px'
  },
  mainPadding: {
    padding: '15px'
  },
  textAreaSize: {
    width: '100%'
  },
  headings: {
    flex: 1
  },
  difficultyRating: {
    display: 'flex',
    paddingLeft: '50px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  IconItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '10px'
  },
  textTitle: {
    textAlign: 'center'
  }
});

function TocDialog(props) {
  const {
    open,
    onClose,
    classes,
    summaryData,
    // pageNumber,
    // totalSummaryNumbers,
    // handleNextClick,
    // handlePreviousClick,
    handleSummaryData,
    // isSummaryParagraph,
    chatgptSummaryUtility,
    title
    // summaryParagraph
  } = props;
  //   // const [selectedValue, setSelectedValue] = useState('');
  const [modifySummaryData, setModifySummaryData] = useState({});
  // const [modifySummaryParagraph, setModifySummaryParagraph] = useState('');
  const [summaryTextError, setSummaryTextError] = useState(false);

  const handleSummaryChange = (event) => {
    const summaryText = event.target.value;
    setModifySummaryData(summaryText);
    setSummaryTextError(false);
  };

  /*   const handleParagraphSummaryChange = (event) => {
    const summaryText = event.target.value;
    setModifySummaryParagraph(summaryText);
    setSummaryTextError(false);
  }; */

  useEffect(() => {
    if (summaryData) {
      setModifySummaryData(summaryData.summaryText);
    }
  }, [summaryData]);

  const handleCancel = () => {
    onClose();
    // setModifySummaryData(summaryData.question);
  };

  /* eslint-disable */
  const handleSave = async () => {
    const { bookId, productId, productModel, pageId, serverDateTime, version, type } = summaryData;
    const updateSummaryList = [];
    /* let modifyParagraphSummaryData = `<p>${modifySummaryParagraph
      .replaceAll('<li>', ' - ')
      .replaceAll('</li>', '')
      .replaceAll('<ul>', '')
      .replaceAll('</ul>', '')}</p>`; */
    const summaryUpdate = {
      bookId,
      productId,
      productModel,
      pageId,
      serverDateTime,
      version,
      lifeCycleStatus: constants.REVIEW_STATUS,
      type,
      summaryText: modifySummaryData
    };
    updateSummaryList.push(summaryUpdate);
    /*     if (isSummaryParagraph) {
      const paragraphUpdate = {
        bookId,
        productId,
        productModel,
        pageId,
        serverDateTime,
        version,
        lifeCycleStatus: constants.REVIEW_STATUS,
        type: 'paragraph',
        summaryText: modifyParagraphSummaryData
      };
      updateSummaryList.push(paragraphUpdate);
    } */
    await chatgptSummaryUtility.updateSummary(updateSummaryList);
    handleSummaryData({
      ...summaryData,
      lifeCycleStatus: constants.REVIEW_STATUS,
      summaryText: modifySummaryData
    });
    onClose();
  };
  return (
    <div>
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
        <div className={classes.flexBorder}>
          <div className={classes.flexContainer}>
            <div />
            <div>
              <h4 className={classes.headings}> Edit Summary</h4>
            </div>
            <div className={classes.icon}>
              <IconButton edge="start" color="default" onClick={onClose} aria-label="close">
                <Tooltip title="Close">
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h4>{title}</h4>
          </div>
          <div className={classes.mainPadding}>
            <TextareaAutosize
              className={classes.textAreaSize}
              id="outlined-basic"
              label="Update your summary here"
              variant="outlined"
              fullWidth
              aria-label="maximum height"
              placeholder="Maximum 4 rows"
              value={modifySummaryData}
              onChange={(e) => handleSummaryChange(e)}
              error={summaryTextError}
            />
          </div>
          <br />
          {/* modifySummaryParagraph && (
            <>
              <div className={classes.mainPadding}>
                <h4 className={classes.textTitle}> Paragraph </h4>
                <TextareaAutosize
                  className={classes.textAreaSize}
                  id="outlined-basic"
                  label="Update your summary here"
                  variant="outlined"
                  fullWidth
                  aria-label="maximum height"
                  placeholder="Maximum 4 rows"
                  value={modifySummaryParagraph}
                  onChange={(e) => handleParagraphSummaryChange(e)}
                  error={summaryTextError}
                />
              </div>
              <br />
            </>
          ) */}
          <div className={classes.flexContainerBottom}>
            {/*             <div>
              <Button
                onClick={() => handlePreviousClick()}
                size="small"
                style={{ width: '50px' }}
                disabled={pageNumber === 0}
              >
                <ChevronLeftIcon />
              </Button>
              <span color="secondary">{pageNumber + 1}</span> /<span color="secondary"> {totalSummaryNumbers}</span>
              <Button
                onClick={() => handleNextClick()}
                size="small"
                style={{ width: '50px' }}
                disabled={pageNumber + 1 === totalSummaryNumbers}
              >
                <ChevronRightIcon />
              </Button>
            </div> */}
            <div className={classes.ButtonClass}>
              <Stack direction="row" spacing={2}>
                <Button variant="contained" size="small" onClick={() => handleCancel()} sx={{ background: '#005d83' }}>
                  {constants.CANCEL}
                </Button>
                <Button variant="contained" size="small" onClick={() => handleSave()} sx={{ background: '#005d83' }}>
                  {constants.SAVE}
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

TocDialog.propTypes = {
  handleNextClick: PropTypes.func.isRequired,
  handleSummaryData: PropTypes.func.isRequired,
  handlePreviousClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  // isSummaryParagraph: PropTypes.bool.isRequired,
  summaryData: PropTypes.object.isRequired,
  pageNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  totalSummaryNumbers: PropTypes.number.isRequired,
  chatgptSummaryUtility: shapes.modelOf(Summary).isRequired
  // summaryParagraph: PropTypes.object
};

TocDialog.defaultProps = {
  open: true
  // summaryParagraph: {}
};

export default withStyles(styles)(inject('chatgptSummaryUtility')(TocDialog));
