/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Typography, withStyles, Grid } from '@material-ui/core';
import { shapes } from '@greenville/framework';

const styles = () => ({
  rootBox: {
    minHeight: 300
  },
  margin: {
    marginTop: 10,
    marginBottom: 10
  }
});

const NoDataFound = ({ language, classes, isError, error }) => (
  <Grid container alignItems="center" direction="column" justify="center" className={classes.rootBox}>
    <img data-testid="empty-search-img" src="/empty_search.svg" alt="No data found" />
    <Typography className={classes.margin} variant="h4">
      <FormattedMessage {...language.getText('common.NO_RESULT_FOUND')} />
    </Typography>
    {error !== 'SSR No Data in Queue' ? (
      <Typography variant="h6">
        {!isError && <FormattedMessage {...language.getText('common.NO_RESULT_FILTER_DESC')} />}
        {isError && <FormattedMessage {...language.getText('common.NO_RESULT_ERROR_DESC')} />}
      </Typography>
    ) : (
      <Typography style={{ fontSize: '12px' }}>
        <FormattedMessage {...language.getText('common.NO_RESULT_DATA_NOT_FOUND')} />
      </Typography>
    )}
  </Grid>
);

NoDataFound.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired
};

export default withStyles(styles)(inject('language')(observer(NoDataFound)));
