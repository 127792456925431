/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
// import { validate } from '@material-ui/pickers';

const styles = () => ({
  width: {
    width: '100%'
  },
  labelWidht: {
    width: '90%'
  },
  radioJustify: {
    justifyContent: 'space-between'
  }
});

const ContactInformation = ({ language, formFields, formErrorFields, handleChange }) => (
  <Grid container direction="row">
    <Typography align="center" variant="h5">
      <FormattedMessage {...language.getText('eTextrequest.CONTACT')} />
    </Typography>
    <br />
    <br />
    <Grid container item xs={12} direction="row" justify="space-between">
      <TextField
        label={<FormattedMessage {...language.getText('hotspot.NAME')} />}
        required
        error={formErrorFields.requestedByError.length > 0}
        helperText={formErrorFields.requestedByError}
        margin="dense"
        name="requestedBy"
        value={formFields.requestedBy}
        onChange={($event) => handleChange($event)}
        // value={workflowFields.identifier}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('common.PHONENUMBER')} />}
        required
        margin="dense"
        name="phone"
        type="number"
        error={formErrorFields.phoneError.length > 0}
        helperText={formErrorFields.phoneError}
        value={formFields.phone}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('common.EMAIL')} />}
        margin="dense"
        required
        type="email"
        name="email"
        // onBlur={() => emailValidate(formFields.email, 'emailaddress')}
        error={formErrorFields.emailError.length > 0}
        helperText={formErrorFields.emailError}
        value={formFields.email}
        onChange={($event) => handleChange($event)}
        // onBlur={() => validate(formFields.emailaddress, 'emailaddress')}
        variant="outlined"
      />
      <TextField
        label={<FormattedMessage {...language.getText('common.ADDEMAIL')} />}
        margin="dense"
        name="addlEmail"
        // required
        // error={formErrorFields.addlEmailError.length > 0}
        // helperText={formErrorFields.addlEmailError}
        value={formFields.addlEmail}
        onChange={($event) => handleChange($event)}
        variant="outlined"
      />
    </Grid>
    <br />
    <br /> <br />
    <br />
  </Grid>
);

ContactInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  data: PropTypes.string.isRequired,
  formFields: PropTypes.object.isRequired,
  formErrorFields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
  // emailValidate: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(ContactInformation)));
