/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import Grid from '@material-ui/core/Grid';
import { Typography, FormHelperText } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

// import { validate } from '@material-ui/pickers';

const styles = () => ({
  width: {
    width: '100%'
  },
  labelWidht: {
    width: '90%'
  },
  radioJustify: {
    justifyContent: 'space-between'
  }
});

const EtextOptions = ({ language, handleRadioChange, formFields }) => (
  <Grid container direction="row">
    <Grid container direction="row" spacing={5}>
      <Grid container item xs={6} direction="column">
        <FormControl margin="dense">
          <FormLabel>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('eTextrequest.GLOSARRY')} />
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-label="glossarySpreadSheet"
            onChange={($event) => handleRadioChange($event)}
            name="glossarySpreadSheet"
            value={formFields.glossarySpreadSheet}
          >
            <FormControlLabel
              value="Supplied"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.SUPPLIED')} />}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Not needed (XML from ETM is complete)"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.NOTETMTEXT')} />}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Not supplied (vendor will create from Glossary PDF)"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.NOTVENDORPDF')} />}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Not necessary for this title"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.NOTTITLE')} />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container item xs={6} direction="column">
        <FormControl margin="dense">
          <FormLabel>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('eTextrequest.Contents')} />
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-label="contentsLinks"
            onChange={($event) => handleRadioChange($event)}
            name="contentsLinks"
            value={formFields.contentsLinks}
          >
            <FormControlLabel
              value="Supplied"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.SUPPLIED')} />}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Not needed (XML from ETM is complete for Glossary ingestion)"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.NOTETMTEXT')} />}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Not necessary for this title"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.NOTTITLE')} />}
            />
          </RadioGroup>
          <FormHelperText variant="filled">
            <Button size="small" style={{ color: '#005d83' }}>
              what&apos;s this ?
            </Button>
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid container item xs={4} direction="column">
        <FormControl margin="dense">
          <FormLabel>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('eTextrequest.INDEX')} />
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-label="indexLinks"
            onChange={($event) => handleRadioChange($event)}
            name="indexLinks"
            value={formFields.indexLinks}
          >
            <FormControlLabel
              value="Vendor will manually link/hotspot Index"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.VENDORLINK')} />}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Not necessary for this title"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.NOTTITLE')} />}
              labelPlacement="end"
            />
          </RadioGroup>
          <FormHelperText variant="filled">
            <Button size="small" style={{ color: '#005d83' }}>
              <FormattedMessage {...language.getText('eTextconvergence.WHATTHIS')} />
            </Button>
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid container item xs={4} direction="column">
        <FormControl margin="dense">
          <FormLabel>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('eTextrequest.CROSS')} />
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-label="crossRefHotspot"
            onChange={($event) => handleRadioChange($event)}
            name="crossRefHotspot"
            value={formFields.crossRefHotspot}
          >
            <FormControlLabel
              value="Not needed (XML from ETM is complete)"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.NOTNEEDEDHOTSPOT')} />}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Not necessary for this title"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.NOTTITLE')} />}
              labelPlacement="end"
            />
          </RadioGroup>
          <FormHelperText variant="filled">
            <Button size="small" style={{ color: '#005d83' }}>
              <FormattedMessage {...language.getText('eTextconvergence.WHATTHIS')} />
            </Button>
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid container item xs={4} direction="column">
        <FormControl margin="dense">
          <FormLabel>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('eTextrequest.CUSTOM')} />
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-label="hotSpotSpreadSheet"
            onChange={($event) => handleRadioChange($event)}
            name="hotSpotSpreadSheet"
            value={formFields.hotSpotSpreadSheet}
          >
            <FormControlLabel
              value="Supplied"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.SUPPLIED')} />}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Not needed (XML from ETM is complete)"
              control={<Radio color="primary" />}
              label={<FormattedMessage {...language.getText('eTextconvergence.NOTTITLE')} />}
              labelPlacement="end"
            />
          </RadioGroup>
          <FormHelperText variant="filled">
            <Button size="small" style={{ color: '#005d83' }}>
              <FormattedMessage {...language.getText('eTextconvergence.WHATTHIS')} />
            </Button>
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
    <br />
    <br /> <br />
    <br />
  </Grid>
);

EtextOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired,
  formFields: PropTypes.object.isRequired
  // formErrorFields: PropTypes.object.isRequired,
  // handleChange: PropTypes.func.isRequired,
  // emailValidate: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(EtextOptions)));
