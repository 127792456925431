/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import _ from 'lodash';
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../../../common/constants';
/**
 * Mobx State Tree Store
 */
const Upload = types
  .model('Upload', {
    bucketFolders: types.optional(types.array(types.string), [])
  })
  .actions((self) => ({
    getBucketfolders(body) {
      Framework.getEventManager().publish(constants.CM_GET_BUCKET_FOLDERS, {
        data: body
      });
    },
    deleteBucketFiles(body) {
      Framework.getEventManager().publish(constants.CM_DELETE_BUCKET_FILES, {
        data: body
      });
    },
    createBucketFolders(param) {
      Framework.getEventManager().publish(constants.CM_CREATE_BUCKET_FOLDERS, {
        data: param
      });
    },
    setbucketfolders(response) {
      if (response.data.length <= 0) {
        const notification = {
          open: true,
          message: 'Upload content to this directory.',
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
      const obj = _.cloneDeep(toJS(self));
      obj.bucketFolders = response.data;
      applySnapshot(self, obj);
      Framework.getEventManager().publish(constants.CM_BUCKET_FOLDERS);
    },
    setAfterDeletedFiles(response) {
      const obj = _.cloneDeep(toJS(self));
      obj.bucketFolders = response.data;
      applySnapshot(self, obj);
    }
  }));

export default Upload;
