import * as constants from '../constants';

const ChannelsDataGridColumn = [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '190px'
  },
  {
    key: 'channelsId',
    name: constants.CHANNELS_ID,
    columnWidth: '160px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'question',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const ChannelsListingDataGridColumn = [
  {
    key: 'dateStamp',
    name: constants.GPT_TRENDS_DATE,
    columnWidth: '190px'
  },
  {
    key: 'channelsId',
    name: constants.CHANNELS_ID,
    columnWidth: '160px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'userCommand',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const EffectivenessDataGridColumn = [
  {
    key: 'input',
    name: 'Input',
    columnWidth: '250px'
  },
  {
    key: 'output',
    name: 'Output',
    columnWidth: '300px'
  },
  {
    key: 'Answer Relevance',
    name: 'Answer Relevance',
    columnWidth: '50px'
  },
  {
    key: 'Context Relevance',
    name: 'Context Relevance',
    columnWidth: '50px'
  },
  {
    key: 'Correctness',
    name: 'Correctness',
    columnWidth: '50px'
  },
  {
    key: 'Groundedness',
    name: 'Groundedness',
    columnWidth: '50px'
  }
];
const AdminPromptsDataGridColumn = [
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '150px'
  },
  {
    key: 'promptStart',
    name: 'Prompt Start',
    columnWidth: '150px'
  },
  {
    key: 'promptEnd',
    name: 'Prompt End',
    columnWidth: '150px'
  },
  {
    key: 'version',
    name: 'Version',
    columnWidth: '50px'
  },
  {
    key: 'status',
    name: 'Status',
    columnWidth: '50px'
  },
  {
    key: 'createdDate',
    name: 'Date Created',
    columnWidth: '50px'
  },
  {
    key: 'updatedDate',
    name: 'Date Updated',
    columnWidth: '50px'
  },
  {
    key: 'modifiedBy',
    name: 'Modified By',
    columnWidth: '50px'
  }
];

const promptConfig = [
  {
    role: 'user',
    content: ''
  },
  {
    role: 'system',
    content: ''
  },
  {
    role: 'assistant',
    content: ''
  }
];

const ColumnConfig = {
  ChannelsDataGridColumn,
  ChannelsListingDataGridColumn,
  EffectivenessDataGridColumn,
  AdminPromptsDataGridColumn,
  promptConfig
};

export default ColumnConfig;
