/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to pdf Hotspot
 *
 * @file Hotspot.js
 */

import { types, applySnapshot } from 'mobx-state-tree';
import { toJS } from 'mobx';
import _ from 'lodash';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * A mobx model for hotspot children
 *
 */

const commonHotspotProps = {
  glossary: types.maybeNull(types.union(types.string, types.integer, types.number)),
  height: types.maybeNull(types.union(types.string, types.integer, types.number)),
  iconTypeId: types.maybeNull(types.number),
  imageUri: types.maybeNull(types.string),
  isIcon: types.maybeNull(types.union(types.string, types.integer, types.number)),
  linkType: types.maybeNull(types.string, ''),
  linkTypeId: types.maybeNull(types.union(types.string, types.integer, types.number)),
  linkUri: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  regionId: types.maybeNull(types.union(types.string, types.integer, types.number)),
  regionTypeId: types.maybeNull(types.union(types.string, types.integer, types.number)),
  transparent: types.maybeNull(types.boolean),
  width: types.maybeNull(types.union(types.string, types.integer, types.number)),
  x: types.maybeNull(types.union(types.string, types.integer, types.number)),
  y: types.maybeNull(types.union(types.string, types.integer, types.number)),
  guid: types.maybeNull(types.string)
};
const ViewHotspot = types.model('ViewHotspot', {
  ...commonHotspotProps
});

const EditHotspot = types.model('EditHotspot', {
  ...commonHotspotProps,
  alternateMediaUri: types.maybeNull(types.string),
  assetLastModifiedDate: types.maybeNull(types.number),
  assetSize: types.maybeNull(types.union(types.string, types.integer, types.number)),
  description: types.maybeNull(types.string),
  downloadable: types.maybeNull(types.string),
  downloadUrl: types.maybeNull(types.union(types.string, types.integer, types.number)),
  globalBookId: types.maybeNull(types.string),
  hasPlatformIcon: types.maybeNull(types.string),
  iconType: types.maybeNull(types.union(types.string, types.integer, types.number)),
  imageName: types.maybeNull(types.union(types.string, types.integer, types.number)),
  isBrowserView: types.maybeNull(types.boolean),
  isIpad: types.maybeNull(types.boolean),
  linkHeight: types.maybeNull(types.number),
  linkSearch: types.maybeNull(types.union(types.string, types.integer, types.number)),
  linkTypeLocation: types.maybeNull(types.union(types.string, types.integer, types.number)),
  linkWidth: types.maybeNull(types.number),
  linkX: types.maybeNull(types.number),
  linkY: types.maybeNull(types.number),
  mediaHeight: types.maybeNull(types.number),
  mediaWidth: types.maybeNull(types.number),
  note: types.maybeNull(types.union(types.string, types.integer, types.number)),
  page: types.maybeNull(types.union(types.string, types.integer, types.number)),
  pageOrder: types.maybeNull(types.union(types.string, types.integer, types.number)),
  pdfPageNo: types.maybeNull(types.union(types.string, types.integer, types.number)),
  pearsonSmartPlayer: types.maybeNull(types.boolean),
  platformId: types.maybeNull(types.number),
  readyToPublish: types.maybeNull(types.boolean),
  regionType: types.maybeNull(types.union(types.string, types.integer, types.number)),
  roleType: types.maybeNull(types.union(types.string, types.integer, types.number)),
  roleTypeId: types.maybeNull(types.number),
  sequenceId: types.maybeNull(types.union(types.string, types.integer, types.number)),
  useCustom: types.maybeNull(types.string),
  bookPageNumber: types.maybeNull(types.string)
});

/**
 * A mobx model for PDF hotspot
 *
 */
const Hotspot = types
  .model('Hotspot', {
    viewHotspot: types.optional(types.array(ViewHotspot), []),
    editHotspot: types.optional(types.array(EditHotspot), []),
    bookId: types.maybeNull(types.string),
    pageOrder: types.maybeNull(types.string),
    forceRefreshData: types.optional(EditHotspot, {})
  })
  .views((self) => ({
    getEditHotspotData(regionId) {
      const hotspotId = parseInt(regionId.split('_')[1], 10);
      return toJS(self.editHotspot.find((item) => item.regionId === hotspotId));
    },

    getHotspotRegionList(bookdId, pageNum, preSaveHotspot = {}) {
      let childrenObj = toJS(self.viewHotspot);
      if (!_.isEmpty(preSaveHotspot) && childrenObj && childrenObj.length > 0) {
        childrenObj = childrenObj.map((item) => {
          if (item.regionId === preSaveHotspot.regionId) {
            return { ...preSaveHotspot };
          }
          return item;
        });
      }
      return [
        {
          id: bookdId,
          productId: bookdId,
          pageNo: pageNum,
          updatedTime: '',
          title: '',
          children: childrenObj
        }
      ];
    }
  }))
  .actions((self) => ({
    fetch(bookid, pageorder) {
      if (self.bookId === bookid && self.pageOrder === pageorder) {
        Framework.getEventManager().publish(constants.PDF_HOTSPOT_FETCHED, {});
      } else {
        Framework.getEventManager().publish(constants.PDF_HOTSPOT_REQUESTED, {
          bookid,
          pageorder
        });
      }
    },
    saveHotspotData(bookid, data, positionToggle, removeDuplicate) {
      Framework.getEventManager().publish(constants.PDF_SAVE_HOTSPOT_REQUESTED, {
        bookid,
        data,
        positionToggle,
        removeDuplicate
      });
    },

    deleteHotspotData(bookid, data) {
      Framework.getEventManager().publish(constants.PDF_DELETE_HOTSPOT_REQUESTED, {
        bookid,
        data
      });
    },

    /**
     * Set the PDF hotspot data to the store
     *
     * @param {Object} pdfHotspot
     */
    setHotspot(response, event) {
      const pdfHotspot = _.cloneDeep(response);
      let forceRefresh = {};
      if (pdfHotspot.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        forceRefresh = pdfHotspot[0];
        pdfHotspot.push(pdfHotspot[0]);
      }
      const updatedState = {
        viewHotspot: pdfHotspot,
        editHotspot: pdfHotspot,
        bookId: event.bookid,
        pageOrder: event.pageorder,
        forceRefreshData: forceRefresh
      };

      applySnapshot(self, updatedState);
      Framework.getEventManager().publish(constants.PDF_HOTSPOT_FETCHED, {});
    },

    setSaveHotspotData(response, event) {
      let pdfHotspot = toJS(self.editHotspot) || [];

      if (event.removeDuplicate) {
        pdfHotspot = pdfHotspot
          // eslint-disable-next-line dot-notation
          .map((e) => e['guid'])
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter((e) => pdfHotspot[e])
          .map((e) => pdfHotspot[e]);
      } else {
        const exists = toJS(pdfHotspot.find((item) => item.regionId === response.regionId));
        if (exists) {
          pdfHotspot = pdfHotspot.map((item) => {
            if (item.regionId === response.regionId) {
              return { ...response };
            }
            return item;
          });
        } else {
          pdfHotspot.push(response);
        }
      }

      const updatedState = {
        viewHotspot: pdfHotspot,
        editHotspot: pdfHotspot
      };

      applySnapshot(self, updatedState);
      Framework.getEventManager().publish(constants.PDF_HOTSPOT_FETCHED, {});
    },

    setDeleteHotspotData(response) {
      let pdfHotspot = toJS(self.editHotspot) || [];
      const exists = toJS(pdfHotspot.find((item) => item.regionId === response.data.regionId));

      if (exists) {
        pdfHotspot = pdfHotspot.filter((item) => item.regionId !== exists.regionId);
      }

      const updatedState = {
        viewHotspot: pdfHotspot,
        editHotspot: pdfHotspot
      };

      applySnapshot(self, updatedState);
      Framework.getEventManager().publish(constants.PDF_HOTSPOT_FETCHED, {});
    }
  }));

export default Hotspot;
