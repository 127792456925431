/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
// import { AuthorDetail, IsbnList } from '../models/Books';
// import { BookMetadata } from '../models/BookMetadata';

export default class BookMetadataGetService extends BaseService {
  constructor() {
    super(constants.BOOK_METADATA_FETCHED, 'bookMetadataStatus');
  }

  handleEvent(eventName, event) {
    if (event.bookID) {
      return this.getClient('evergreen').get(`/books/${event.bookID}/metadata`);
    }

    return null;
  }

  handleResponse(response, event) {
    // eslint-disable-line class-methods-use-this
    if (event.bookID) {
      Framework.getStoreRegistry().getStore('booksState').setBookMetadata(this.mapResponce(response));
    }
  }

  mapResponce(response) {
    if (
      response &&
      response.status &&
      response.status === 200 &&
      response.data &&
      response.data.status &&
      response.data.status === 'success' &&
      response.data.data &&
      response.data.data.bookID
    ) {
      if (parseInt(response.data.data.bookID, 10)) {
        const { data } = response.data;
        const modelData = {};

        modelData.bookID = +data.bookID;
        modelData.globalBookID = data.globalBookID ? data.globalBookID.toString() : '';
        modelData.title = data.title ? data.title.toString() : '';

        modelData.copyrightYear = data.copyrightYear ? data.copyrightYear.toString() : '';
        modelData.description = data.description ? data.description.toString() : '';
        modelData.hasTocFlatten = data.hasTocFlatten ? data.hasTocFlatten.toString() : '';
        modelData.minimumHotspotHeight = data.minimumHotspotHeight ? data.minimumHotspotHeight.toString() : '';
        modelData.twoPageLayoutLeft = data.twoPageLayoutLeft ? data.twoPageLayoutLeft.toString() : '';
        modelData.version = data.version ? data.version.toString() : '';

        modelData.numberOfLevels = data.numberOfLevels ? data.numberOfLevels.toString() : '';
        modelData.numberOfDisplayLevels = data.numberOfDisplayLevels ? data.numberOfDisplayLevels.toString() : '';

        modelData.bookEditionNumber = data.bookEditionNumber ? data.bookEditionNumber.toString() : '';
        modelData.bookEditionID = data.bookEditionID ? data.bookEditionID.toString() : '';
        modelData.bookEditionTypeID = data.bookEditionTypeID ? data.bookEditionTypeID.toString() : '';

        modelData.thumbnailArt = data.thumbnailArt ? data.thumbnailArt.toString() : '';
        modelData.thumbnailArtFormatID = data.thumbnailArtFormatID ? data.thumbnailArtFormatID.toString() : '';
        modelData.thumbnailDpi = data.thumbnailDpi ? data.thumbnailDpi.toString() : '';
        modelData.coverArt = data.coverArt ? data.coverArt.toString() : '';
        modelData.coverArtFormatID = data.coverArtFormatID ? data.coverArtFormatID.toString() : '';
        modelData.coverDpi = data.coverDpi ? data.coverDpi.toString() : '';
        modelData.navigationArt = data.navigationArt ? data.navigationArt.toString() : '';
        modelData.navigationArtFormatID = data.navigationArtFormatID ? data.navigationArtFormatID.toString() : '';
        modelData.navigationDpi = data.navigationDpi ? data.navigationDpi.toString() : '';
        modelData.pdfCoverArt = data.pdfCoverArt ? data.pdfCoverArt.toString() : '';
        modelData.pdfCoverFormatID = data.pdfCoverFormatID ? data.pdfCoverFormatID.toString() : '';
        modelData.pdfCoverDpi = data.pdfCoverDpi ? data.pdfCoverDpi.toString() : '';

        modelData.isbnList = this.mapIsbnList(data.isbnList);
        modelData.authorList = this.mapAuthorList(data.authorList);

        modelData.bookLevels = this.mapBookLevels(data.bookLevels);

        modelData.orientation = data.twoPageLayoutLeft ? data.twoPageLayoutLeft.toString() : 'E';

        return modelData;
      }
    }
    return null;
  }

  mapAuthorList = (authorList) => {
    if (authorList && Array.isArray(authorList) && authorList.length > 0) {
      return authorList.map((author) => ({
        firstName: author.FIRST_NAME ? author.FIRST_NAME : '',
        lastName: author.LAST_NAME ? author.LAST_NAME : '',
        otherName: author.NAME ? author.NAME : '',
        authorID: author.AUTHOR_ID ? author.AUTHOR_ID.toString() : ''
      }));
    }

    return [];
  };

  mapIsbnList = (isbnList) => {
    if (isbnList && Array.isArray(isbnList) && isbnList.length > 0) {
      return isbnList.map((isbn) => ({
        displayISBN: isbn.DISPLAY_ISBN ? isbn.DISPLAY_ISBN : '',
        isbnTypeID: isbn.ISBN_TYPE_ID ? isbn.ISBN_TYPE_ID.toString() : '',
        type: isbn.TYPE ? isbn.TYPE : ''
      }));
    }
    return [];
  };

  mapBookLevels = (bookLevels) => {
    if (bookLevels && Array.isArray(bookLevels) && bookLevels.length > 0) {
      return bookLevels.map((levels) => ({
        levelTypeID: levels.LEVEL_TYPE_ID ? levels.LEVEL_TYPE_ID : '',
        type: levels.NAME ? levels.NAME.toString() : '',
        name: levels.TYPE ? levels.TYPE : ''
      }));
    }
    return [];
  };
}
