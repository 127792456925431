import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
// import { Box } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { Typography } from '@material-ui/core';
import { Rating } from '@mui/material';
import { shapes } from '@greenville/framework';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { inject } from 'mobx-react';
import Toc from '../../models/Toc';
import * as constants from '../../../../common/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = () => ({
  flexNoSpace: {
    display: 'flex',
    alignItems: 'center'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  },
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  flexContainerBottom: {
    display: 'flex',
    // justifyContent: 'space-evenly',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    padding: '10px'
  },
  mainContainer: {
    border: '1px solid grey',
    padding: '23px',
    borderRadius: '4px',
    margin: '15px'
  },
  mainPadding: {
    padding: '15px'
  },
  headings: {
    flex: 1
  },
  difficultyRating: {
    display: 'flex',
    paddingLeft: '50px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  IconItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '10px'
  }
});

function TocDialog(props) {
  const {
    open,
    onClose,
    classes,
    questionData,
    pageNumber,
    totalPageNumber,
    handleNextClick,
    handlePreviousClick,
    chatgptTocUtility
  } = props;
  // const [selectedValue, setSelectedValue] = useState('');
  const [modifyQuestionData, setModifyQuestionData] = useState({});
  const [difficultyLevel, setDifficultyLevel] = useState(0);
  const [questionTextError, setQuestionTextError] = useState(false);

  /*   const handleRadioButtonChange = (event) => {
    if (modifyQuestionData) {
      const question = toJS(modifyQuestionData);
      const changeCorrectChoice = question.options.map((item) => {
        const questionItem = item;
        if (questionItem.id === parseInt(event.target.id, 10)) {
          questionItem.correct_choice = 'Yes';
        } else {
          questionItem.correct_choice = 'No';
        }
        return questionItem;
      });
      setModifyQuestionData({ ...modifyQuestionData, options: changeCorrectChoice });
    }
  }; */

  const handleQuestionChange = (event) => {
    const questionText = event.target.value;
    setModifyQuestionData((prev) => ({ ...prev, question_text: questionText }));
    setQuestionTextError(false);
  };

  const handleOptionChange = (event, type, itemInfo) => {
    const { value } = event.target;
    const { id } = itemInfo;
    if (modifyQuestionData) {
      const question = toJS(modifyQuestionData);
      const changeCorrectChoice = question.options.map((item) => {
        const questionItem = toJS(item);
        if (questionItem.id === id) {
          if (type === 'option_text') {
            questionItem.option_text = value;
          } else if (type === 'explanation') {
            questionItem.explanation = value;
          } else if (type === 'radio') {
            questionItem.correct_choice = 'Yes';
          }
        } else if (type === 'radio') {
          questionItem.correct_choice = 'No';
        }
        return questionItem;
      });
      setModifyQuestionData({ ...modifyQuestionData, options: changeCorrectChoice });
    }
  };

  useEffect(() => {
    if (questionData) {
      if (questionData.question) {
        setModifyQuestionData(questionData.question);
      }
      if (questionData.difficultyLevel) {
        setDifficultyLevel(questionData.difficultyLevel);
      }
    }
  }, [questionData]);

  const handleCancel = () => {
    onClose();
    // setModifyQuestionData(questionData.question);
  };

  /* eslint-disable */
  const handleSave = async () => {
    if (questionData) {
      let valueCheck = true;
      const { question_text, options } = modifyQuestionData;
      let radioOptionCount = 0;
      if (question_text) {
        setQuestionTextError(false);
      } else {
        setQuestionTextError(true);
        valueCheck = false;
      }
      if (options && options.length > 0) {
        options.forEach((item) => {
          if (item.correct_choice === 'Yes') {
            radioOptionCount += 1;
          }
          const error1 = item.option_text ? false : true;
          const error2 = item.explanation ? false : true;
          if (error1 || error2) {
            valueCheck = false;
          }
          return {
            ...item,
            error1,
            error2
          };
        });
      }
      if (valueCheck && radioOptionCount > 0) {
        const question = {
          ...questionData,
          question: modifyQuestionData,
          difficultyLevel,
          lifeCycleStatus: constants.REVIEW_STATUS
        };
        await chatgptTocUtility.updateMcqQuestion(question);
        onClose();
      }
    }
    /* eslint-enable */
  };

  const handleAdditionalOption = () => {
    const newOptionId = { id: modifyQuestionData.options.length + 1 };
    const question = toJS(modifyQuestionData);
    const newVal = [...question.options, newOptionId];
    setModifyQuestionData({ ...modifyQuestionData, options: newVal });
  };

  const DeleteOption = () => {
    const question = toJS(modifyQuestionData);
    if (question.options) {
      const newVal = question.options;
      newVal.pop();
      setModifyQuestionData({ ...modifyQuestionData, options: newVal });
    }
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
        <div className={classes.flexBorder}>
          <div className={classes.flexContainer}>
            <div />
            <div>
              <h4 className={classes.headings}> Edit Question</h4>
            </div>
            <div className={classes.icon}>
              <IconButton edge="start" color="default" onClick={onClose} aria-label="close">
                <Tooltip title="Close">
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </div>
          </div>
          <br />
          <div className={classes.mainPadding}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Update your question here"
              variant="outlined"
              value={modifyQuestionData && modifyQuestionData.question_text}
              onChange={(e) => handleQuestionChange(e)}
              error={questionTextError}
            />
          </div>
          <br />

          {modifyQuestionData &&
            modifyQuestionData.options &&
            modifyQuestionData.options.map((item) => (
              <>
                <div className={classes.mainContainer}>
                  <div>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Update your option here"
                      variant="outlined"
                      value={item.option_text}
                      onChange={(e) => handleOptionChange(e, 'option_text', item)}
                      error={item.error1}
                    />
                  </div>
                  <br />
                  <div>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Update your feedback here"
                      variant="outlined"
                      value={item.explanation}
                      onChange={(e) => handleOptionChange(e, 'explanation', item)}
                      error={item.error2}
                    />
                  </div>
                  <div className={classes.flexNoSpace}>
                    <h4>Correct Choice :</h4>
                    <Radio
                      checked={item.correct_choice === 'Yes'}
                      onChange={(e) => handleOptionChange(e, 'radio', item)}
                      value="Yes"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                  </div>{' '}
                </div>
              </>
            ))}
          <div className={classes.IconItem}>
            <Tooltip title="This will add block of Option/Feedback.MAX limit-4">
              <IconButton
                disabled={modifyQuestionData && modifyQuestionData.options && modifyQuestionData.options.length >= 4}
                onClick={() => handleAdditionalOption()}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>

            <IconButton
              disabled={modifyQuestionData && modifyQuestionData.options && modifyQuestionData.options.length <= 1}
              onClick={() => DeleteOption()}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </div>

          <div className={classes.flexContainerBottom}>
            <div className={classes.difficultyRating}>
              <Typography variant="h6">Difficutly : </Typography>
              <Rating
                name="simple-controlled"
                value={difficultyLevel}
                onChange={(event, newValue) => {
                  setDifficultyLevel(newValue);
                }}
              />
            </div>
            <div>
              <Button
                onClick={() => handlePreviousClick()}
                size="small"
                style={{ width: '50px' }}
                disabled={pageNumber === 0}
              >
                <ChevronLeftIcon />
              </Button>
              <span color="secondary">{pageNumber + 1}</span> /<span color="secondary"> {totalPageNumber}</span>
              <Button
                onClick={() => handleNextClick()}
                size="small"
                style={{ width: '50px' }}
                disabled={pageNumber + 1 === totalPageNumber}
              >
                <ChevronRightIcon />
              </Button>
            </div>
            <div className={classes.ButtonClass}>
              <Stack direction="row" spacing={2}>
                <Button variant="contained" size="small" onClick={() => handleCancel()} sx={{ background: '#005d83' }}>
                  {constants.CANCEL}
                </Button>
                <Button variant="contained" size="small" onClick={() => handleSave()} sx={{ background: '#005d83' }}>
                  {constants.SAVE}
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

TocDialog.propTypes = {
  handleNextClick: PropTypes.func.isRequired,
  handlePreviousClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  questionData: PropTypes.object.isRequired,
  pageNumber: PropTypes.number.isRequired,
  totalPageNumber: PropTypes.number.isRequired,
  chatgptTocUtility: shapes.modelOf(Toc).isRequired
};

TocDialog.defaultProps = {
  open: true
};

export default withStyles(styles)(inject('chatgptTocUtility')(TocDialog));
