import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const typesData = types.model('typesData', {
  bookId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string)
});

const ChatUtilityGetTypesModel = types
  .model('ChatUtilityGetTypesModel', {
    data: types.optional(types.array(typesData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHATGPT_TYPE_RESPONSE_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_CHATGPT_TYPE_RESPONSE_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
    }
  }));

export default ChatUtilityGetTypesModel;
