const appConfig = {
  bookFormat: 'PDF',
  applicationId: 'evg',
  isStudent: false,
  showAudio: false,
  showBackNav: true,
  showBookmark: false,
  showDrawer: true,
  showHeader: true,
  showHighlights: false,
  showNavigation: true,
  showPageLayout: false,
  showPref: true,
  showPrintPageNav: false,
  showSearch: false,
  showStudy: false,
  showTOC: true
};

class VegaConfig {
  getVegaConfig = () => {
    return appConfig;
  };
}

export default VegaConfig;
