/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { Button, Typography /* , Container  */ } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import Framework, { shapes } from '@greenville/framework';

import utils from '../../common/utils';
import * as events from '../../common/events';

const styles = (theme) => ({
  unauthorized: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    marginLeft: '5%',
    top: 120
  },
  errorImg: {
    position: 'fixed',
    top: '35%',
    left: '25%'
  },
  errorTitleText: {
    marginBottom: 29,
    display: 'flex',
    alignItems: 'center'
  },
  errorBodyText: {
    marginBottom: 50
  },
  errorHeader: {
    backgroundColor: theme.palette.primary.dark,
    padding: '10px 52px 10px',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    '@media (max-width: 480px)': {
      padding: '10px 10px 10px'
    }
  }
});

const UnauthorizedBody = ({ classes, language, showDashboard }) => {
  const onLogout = () => {
    utils.logEvent({
      event: events.USER_CARD,
      category: events.USER_CARD,
      action: events.USER_SIGNOUT,
      label: events.USER_SIGNOUT
    });
    Framework.logout();
  };
  const gotoDashboard = () => {
    Framework.redirectTo('/dashboard');
  };
  return (
    <main className={classes.unauthorized}>
      <>
        <Typography variant="h2" className={classes.errorTitleText}>
          <span>
            <WarningIcon style={{ color: '#DC143C', fontSize: 40 }} />
          </span>
          <b>
            <FormattedMessage {...language.getText('unauthorized.TITLE_TEXT')} />
          </b>
        </Typography>
        <Typography variant="h4" className={classes.errorBodyText}>
          <FormattedMessage {...language.getText('unauthorized.BODY_TEXT')} />
        </Typography>
        {showDashboard && (
          <Button id="back-button" size="large" variant="outlined" color="primary" onClick={gotoDashboard}>
            <FormattedMessage {...language.getText('common.GO_TO_DASHBOARD')} />
          </Button>
        )}
        {!showDashboard && (
          <Button id="back-button" size="large" variant="outlined" color="primary" onClick={onLogout}>
            <FormattedMessage {...language.getText('common.TRY_LOGIN_AGAIN')} />
          </Button>
        )}
      </>
    </main>
  );
};

UnauthorizedBody.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  showDashboard: PropTypes.bool
};
UnauthorizedBody.defaultProps = {
  showDashboard: false
};

export default withStyles(styles)(inject('language')(observer(UnauthorizedBody)));
