import Framework from '@greenville/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

const roleObj = types.model('roleObj', {
  content: types.maybeNull(types.string),
  role: types.maybeNull(types.string)
});

const adminPrompt = types.model('adminPrompt', {
  id: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  tenantName: types.maybeNull(types.string),
  tenantId: types.maybeNull(types.string),
  promptStart: types.maybeNull(types.array(types.optional(roleObj, {}))),
  promptEnd: types.maybeNull(types.array(types.optional(roleObj, {}))),
  version: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  createdDate: types.maybeNull(types.string),
  updatedDate: types.maybeNull(types.string),
  modifiedBy: types.maybeNull(types.string),
  comments: types.maybeNull(types.string)
});

const ChatgptAdminPromptsData = types
  .model('ChatgptAdminPromptsData', {
    data: types.optional(types.array(adminPrompt), [])
  })
  .actions((self) => ({
    fetchAdminPromptsData(payload) {
      Framework.getEventManager().publish(constants.GET_ADMIN_PROMPTS_DATA, {
        payload
      });
    },
    saveAdminPromptsData(payload) {
      Framework.getEventManager().publish(constants.SAVE_ADMIN_PROMPTS_DATA, {
        payload
      });
    },
    updateAdminPromptsData(payload) {
      Framework.getEventManager().publish(constants.EDIT_ADMIN_PROMPTS_DATA, {
        payload
      });
    },
    deleteAdminPromptsData(payload) {
      Framework.getEventManager().publish(constants.DELETE_ADMIN_PROMPTS_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.SET_ADMIN_PROMPTS_DATA);
    },
    setUpdatedAdminResponse() {
      Framework.getEventManager().publish(constants.SET_ADMIN_PROMPTS_UPDATED_DATA);
    }
  }));

export default ChatgptAdminPromptsData;
