/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { shapes } from '@greenville/framework';
import { Button, withStyles, Switch } from '@material-ui/core';
import { inject } from 'mobx-react';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = () => ({
  label: {
    fontSize: 15,
    fontWeight: 600
  }
});

const ToggleMenu = ({ language, classes, handleChange, disableMenu, languageProps }) => {
  const [checked, setChecked] = useState(false);

  const onChange = (event) => {
    setChecked(event.target.checked);
    handleChange(event.target.checked);
  };

  return (
    <Button>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={classes.label}>
        <FormattedMessage {...language.getText(languageProps.labelText)} />
      </label>
      <TooltipCustom title={<FormattedMessage {...language.getText(languageProps.tooltipText)} />}>
        <span>
          <Switch
            size="medium"
            color="primary"
            checked={checked}
            onChange={(event) => onChange(event)}
            disabled={disableMenu}
          />
        </span>
      </TooltipCustom>
    </Button>
  );
};

ToggleMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  handleChange: PropTypes.func.isRequired,
  disableMenu: PropTypes.bool.isRequired,
  languageProps: PropTypes.object.isRequired
};

export default withStyles(styles)(inject('language')(ToggleMenu));
