/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */
import React from 'react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import UnauthorizedBody from '../error/UnauthorizedBody';
import UsersPermissions from './models/UsersPermissions';
import Header from '../../common/header/Header';

const ValidatePermissions = ({
  children,
  givenPermissions,
  userPermissions,
  permissionsStatus,
  fallElse,
  showDashboardEnabled
}) => {
  const permission = toJS(userPermissions.permissionList.permissions);
  if (!permission.length) {
    return null;
  }
  if (!permissionsStatus.isPending && !permissionsStatus.isError) {
    if (permission && permission.length > 0 && permission.includes(givenPermissions)) {
      return children;
    }
    if (!permission.includes(givenPermissions) && fallElse) {
      return (
        <>
          <Header />
          <UnauthorizedBody showDashboard />
        </>
      );
    }
    return <UnauthorizedBody showDashboard={showDashboardEnabled} />;
  }
  return null;
};

ValidatePermissions.propTypes = {
  children: PropTypes.array.isRequired,
  givenPermissions: PropTypes.string.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  permissionsStatus: shapes.state.isRequired,
  fallElse: PropTypes.bool,
  showDashboardEnabled: PropTypes.bool
};

ValidatePermissions.defaultProps = {
  fallElse: false,
  showDashboardEnabled: true
};

export default inject('userPermissions', 'permissionsStatus')(observer(ValidatePermissions));
