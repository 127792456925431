const contentDesc = [
  {
    id: 0,
    value: 'Identifier',
    text: 'Y'
  },
  {
    id: 1,
    value: 'Title',
    text: 'Y'
  },
  {
    id: 2,
    value: 'Authors',
    text: 'Y'
  },
  {
    id: 3,
    value: 'WorkFlow',
    text: 'N'
  },
  {
    id: 4,
    value: 'Business Unit',
    text: 'N'
  },
  {
    id: 5,
    value: 'Jobs',
    text: 'N'
  },
  {
    id: 6,
    value: 'Global Book ID',
    text: 'Y'
  },
  {
    id: 7,
    value: 'Book ID',
    text: 'Y'
  }
];

const contentWorkflow = [
  {
    id: 'all',
    label: 'All'
  },
  {
    id: 'ebook',
    label: 'ebook+'
  },
  {
    id: 'Simple eBook',
    label: 'Simple eBook'
  }
];
const contentShowData = [
  {
    id: 'my',
    label: 'Show My Jobs'
  },
  {
    id: 'all',
    label: 'Show All Jobs'
  }
];
const ebookValues = [
  {
    id: 0,
    value: 'Glossary XML',
    text: 'Y'
  },
  {
    id: 1,
    value: 'Process All Chapters',
    text: 'Y'
  },
  {
    id: 2,
    value: 'PDF Cropping, SWF, Thumbnail & Text iPad PDF Processing',
    text: 'Y'
  },
  {
    id: 3,
    value: 'Low Res PDF generation',
    text: 'N'
  },
  {
    id: 4,
    value: 'Create Universal PDF',
    text: 'N'
  },
  {
    id: 5,
    value: 'Perform PDF Scaling',
    text: 'N'
  },
  {
    id: 6,
    value: 'Replace Selected pages',
    text: 'Y'
  },
  {
    id: 7,
    value: 'Process From Optimized PDF',
    text: 'Y'
  },
  {
    id: 8,
    value: 'Process Annotated PDF (Create Zoomarea)',
    text: 'Y'
  },
  {
    id: 9,
    value: 'Process For Digital Library',
    text: 'Y'
  }
];

const CustomConfig = {
  contentDesc,
  ebookValues,
  contentWorkflow,
  contentShowData
};

module.exports = CustomConfig;
