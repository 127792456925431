import { applySnapshot, types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const tocDetails = types.model('tocDetails', {
  sectionName: types.maybeNull(types.string),
  chapterId: types.maybeNull(types.string),
  chapterName: types.maybeNull(types.string),
  sectionId: types.maybeNull(types.string),
  pageId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  pageName: types.maybeNull(types.string)
});

const TocData = types.model('TocData', {
  toc: types.optional(types.array(tocDetails), []),
  bookId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string)
});

const ChatgptUtilityMapping = types
  .model('ChatgptUtilityMapping', {
    TocData: types.optional(types.array(TocData), [])
    // toc: types.optional(tocDetails,{})
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHATGPT_MAPPING_DATA, {
        payload
      });
    },
    setResponse(response) {
      if (response && response) {
        const { toc } = response;
        const tocValues = Object.values(toc);
        const listItem = [];
        tocValues.forEach((list) => {
          list.forEach((item) => {
            listItem.push(item);
          });
        });
        response.toc = listItem;
        self.TocData.push(response);
      }
      Framework.getEventManager().publish(constants.SET_CHATGPT_MAPPING_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.TocData, initialState);
    }
  }));

export default ChatgptUtilityMapping;
