/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import Button from '@material-ui/core/Button';
import { toJS, observable } from 'mobx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, Grid, TextField } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
// import Slider from '@material-ui/core/Slider';
import Books from '../../models/Books';
// import Input from '@material-ui/core/Input';
// import MenuBookIcon from '@material-ui/icons/MenuBook';
import Breadcrumbs from '../commonitems/Breadcrumbs';
import InterfaceModel from '../../models/InterfaceModel';
import Loader from '../../../../common/Loader';
import NotificationModel from '../../../../common/components/notification/NotificationModel';
import UsersPermissions from '../../../users/models/UsersPermissions';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import * as constants from '../../../../common/constants';

const styles = (theme) => ({
  root: {
    width: '99%',
    padding: theme.spacing(0, 1)
  },
  customWidth: {
    width: '95%'
  },
  gridMargin: {
    margin: theme.spacing(2, 0, 2, 2)
  },
  subgridMargin: {
    margin: theme.spacing(0, 0, 0, 4),
    flexFlow: 'initial'
  },
  textfieldInput: {
    fontSize: 13
  },
  pointer: {
    cursor: 'pointer'
  },
  formControl: {
    minWidth: 180,
    maxWidth: 300
  },
  colorPalette: {
    display: 'none'
  },
  swatchText: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  swatchNum: {
    marginRight: '12px'
  },
  labelName: {
    alignItems: 'center',
    paddingTop: 8
  }
});

@inject(
  'colorInterface',
  'colorLanguageStatus',
  'notificationState',
  'colorLanguagePostStatus',
  'booksState',
  'userPermissions'
)
@observer
class InterfaceColors extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    colorInterface: shapes.modelOf(InterfaceModel).isRequired,
    colorLanguageStatus: shapes.state.isRequired,
    classes: PropTypes.object.isRequired,
    colorLanguagePostStatus: shapes.state.isRequired,
    language: shapes.language.isRequired,
    notificationState: shapes.modelOf(NotificationModel).isRequired,
    booksState: shapes.modelOf(Books).isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    const { colorInterface, language } = this.props;
    this.state = {
      colorInterface,
      colorInterfaceLabelName: [
        {
          type: <FormattedMessage {...language.getText('interface.HOTSPOT_COLOR')} />,
          themeID: 8,
          themeValue: '',
          uniqueID: ''
        },
        {
          type: <FormattedMessage {...language.getText('interface.UNDERLINE_HOTSPOT_COLOR')} />,
          themeID: 16,
          themeValue: '',
          uniqueID: ''
        },
        {
          type: <FormattedMessage {...language.getText('interface.HOTSPOT_HOVER')} />,
          themeID: 17,
          themeValue: '',
          uniqueID: ''
        },
        {
          type: <FormattedMessage {...language.getText('interface.HOTSPOT_THICKNESS')} />,
          themeID: 18,
          themeValue: '',
          uniqueID: ''
        },
        {
          type: <FormattedMessage {...language.getText('interface.REGIONAL_HOTSPOT_ALPHA')} />,
          themeID: 14,
          themeValue: '',
          uniqueID: ''
        },
        {
          type: <FormattedMessage {...language.getText('interface.UNDERLINE_HOTSPOT')} />,
          themeID: 20,
          themeValue: '',
          uniqueID: ''
        }
      ],
      inputSlideValue: 10,
      inputValue: 0,
      sliderValue: false
    };
  }

  componentDidMount() {
    const { match, colorInterface, booksState } = this.props;
    const { bookDetail } = booksState;
    colorInterface.fetch(match.params.bookid);
    const changedInput = Math.round((bookDetail.pageCount * 10) / 100);
    this.setState({
      inputValue: changedInput
    });
  }

  handleChange = (e, textField) => {
    const { colorInterface } = this.state;
    const { languageInfo } = colorInterface;
    // const temp = languageInfo;

    // Commenting below as a part of EVG-391: Default setting for print should be N

    // if (e.target.name === 'print' && e.target.checked) {
    //   this.setState({
    //     sliderValue: true
    //   });
    // }

    let updatedObject = {};

    if (textField) {
      updatedObject = {
        [e.target.name]: e.target.value
      };
    } else {
      updatedObject = {
        [e.target.name]: e.target.checked ? 'Y' : 'N'
      };
    }
    this.setState({
      colorInterface: {
        ...colorInterface,
        languageInfo: { ...languageInfo, ...updatedObject }
      }
    });
  };

  handleSliderChange = (e, number, data) => {
    const slideValue = Math.round((data * number) / 100);
    this.setState({
      inputValue: slideValue,
      inputSlideValue: number
    });
  };

  handleInputChange = (e, data) => {
    const changedValue = e.target.value === 'NaN' || e.target.value === '' ? 0 : parseInt(e.target.value, 10);
    const inputMathValue = Math.round((changedValue / data) * 100);

    this.setState({
      inputValue: e.target.value,
      inputSlideValue: inputMathValue
    });
  };

  setFilterValues = (filterConfig) => filterConfig.map((filter) => <option value={filter.id}>{filter.value}</option>);

  swatchChange = (e, index, item) => {
    const { colorInterface } = this.state;
    const { colorInfo } = colorInterface;
    const { bookThemes } = colorInfo;
    const tempBookThemesValue = toJS(bookThemes);
    const matchedTheme = tempBookThemesValue.filter((filterItem) => filterItem.themeID === item.themeID);

    if (matchedTheme.length > 0) {
      if (item.themeID !== 14 && item.themeID !== 18) {
        matchedTheme[0].themeValue = e.target.value === '' ? '#000000' : e.target.value;
      } else {
        matchedTheme[0].themeValue = e.target.value === '' ? '0' : e.target.value;
      }
    } else {
      let newTheme;
      if (item.themeID !== 14 && item.themeID !== 18) {
        newTheme = {
          themeValue: e.target.value === '' ? '#000000' : e.target.value,
          themeID: item.themeID,
          uniqueID: null
        };
      } else {
        newTheme = {
          themeValue: e.target.value === '' ? '0' : e.target.value,
          themeID: item.themeID,
          uniqueID: null
        };
      }
      tempBookThemesValue.push(newTheme);
    }

    this.setState((state) => ({
      ...state,
      colorInterface: {
        ...state.colorInterface,
        colorInfo: {
          bookThemes: observable.array(tempBookThemesValue)
        }
      }
    }));
  };

  handleSave = () => {
    const { match } = this.props;
    const { colorInterface } = this.state;
    const updatedPayload = JSON.parse(JSON.stringify(toJS(colorInterface)));
    const changedProductPDZ = updatedPayload.languageInfo.pdzProductID;
    updatedPayload.languageInfo.pdzProductID = changedProductPDZ === '' ? null : changedProductPDZ;
    const payload = {
      data: updatedPayload,
      id: match.params.bookid
    };
    const {
      colorInterface: { savePayload }
    } = this.props;
    savePayload(payload);
  };

  getInterfaceValue = (themeID, bookThemes) => {
    let themeValue = '';
    const url = bookThemes.find((item) => item?.themeID === themeID);
    if (url) {
      themeValue = url.themeValue ? url.themeValue : '';
    }
    return themeValue;
  };

  render() {
    const { classes, language, match, colorLanguageStatus, colorLanguagePostStatus, userPermissions } = this.props;
    const { colorInterface, colorInterfaceLabelName } = this.state;
    const { colorInfo } = colorInterface;
    const { languageInfo } = colorInterface;
    const { bookThemes } = colorInfo;
    // const { bookDetail } = booksState;
    const permissionsList = toJS(userPermissions.permissionList.permissions);

    return (
      <>
        <LoadingHandler
          loading={colorLanguageStatus.isPending || colorLanguagePostStatus.isPending}
          isError={colorLanguageStatus.isError && colorLanguageStatus.error && colorLanguageStatus.error.status === 401}
          loadingContent={<Loader />}
          errorContent={<StateErrorDisplay error={colorLanguageStatus.error} showDetails />}
          content={
            <div className={classes.root}>
              <Breadcrumbs
                data={<FormattedMessage {...language.getText('desc.INTERFACE_COLORS')} />}
                id={match.params.bookid}
              />
              <br />
              <Paper elevation={3}>
                <br />
                <Typography align="center" variant="h5">
                  <FormattedMessage {...language.getText('interface.SCHEME')} />
                </Typography>
                <br />
                <Typography align="center" variant="subtitle2">
                  <FormattedMessage {...language.getText('interface.TEXT')} />
                </Typography>
                <br />

                {colorInterfaceLabelName.map((item, index) => (
                  <Grid container direction="row" alignItems="center" spacing={1} className={classes.subgridMargin}>
                    <Grid item xs={3}>
                      <Typography variant="h6" color="textPrimary">
                        {item.type}
                      </Typography>
                    </Grid>
                    {item.themeID !== 20 ? (
                      <Grid item xs={2}>
                        {item.themeID !== 14 && item.themeID !== 18 ? (
                          <TextField
                            margin="dense"
                            value={this.getInterfaceValue(item.themeID, bookThemes)}
                            className={classes.customWidth}
                            type="text"
                            InputProps={{
                              classes: { input: classes.textfieldInput },
                              inputProps: {
                                min: 0
                              }
                            }}
                            variant="outlined"
                            onChange={(e) => this.swatchChange(e, index, item)}
                          />
                        ) : (
                          <TextField
                            margin="dense"
                            value={this.getInterfaceValue(item.themeID, bookThemes)}
                            className={classes.customWidth}
                            type="number"
                            InputProps={{
                              classes: { input: classes.textfieldInput },
                              inputProps: {
                                min: 0,
                                max: item.themeID === 18 ? 10 : 99
                              }
                            }}
                            onInput={(e) => {
                              e.target.value =
                                item.themeID === 18
                                  ? Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 1)
                                  : Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 2);
                            }}
                            variant="outlined"
                            onChange={(e) => this.swatchChange(e, index, item)}
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={5}>
                        <FormControl component="fieldset" className={classes.formControl}>
                          <FormGroup row className={classes.labelName}>
                            <FormControlLabel
                              className={classes.labelName}
                              control={
                                <Checkbox
                                  onChange={(e) => this.handleChange(e, false)}
                                  name="isUnderLineHotspot"
                                  color="primary"
                                  checked={languageInfo.isUnderLineHotspot === 'Y'}
                                />
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    )}
                    {item.themeID !== 18 && item.themeID !== 14 && item.themeID !== 20 && (
                      <Grid item xs={1}>
                        <input
                          type="color"
                          value={this.getInterfaceValue(item.themeID, bookThemes)}
                          onChange={(e) => this.swatchChange(e, index, item)}
                        />
                      </Grid>
                    )}
                  </Grid>
                ))}
                <hr />

                <Grid container direction="row" alignItems="center" spacing={1} className={classes.subgridMargin}>
                  <Grid item xs={6}>
                    <Grid item xs={10}>
                      <Typography variant="h5" color="textPrimary">
                        <FormattedMessage {...language.getText('interface.PLAYER')} />
                      </Typography>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup row className={classes.labelName}>
                          <FormControlLabel
                            className={classes.labelName}
                            control={
                              <Checkbox
                                onChange={(e) => this.handleChange(e, false)}
                                name="audioPlayerOnereader"
                                color="primary"
                                checked={languageInfo.audioPlayerOnereader === 'Y'}
                              />
                            }
                            label={<FormattedMessage {...language.getText('interface.AUDIO_ONEREADER')} />}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10}>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup row className={classes.labelName}>
                          <FormControlLabel
                            className={classes.labelName}
                            control={
                              <Checkbox
                                onChange={(e) => this.handleChange(e, false)}
                                name="studyToolOnereader"
                                color="primary"
                                checked={languageInfo.studyToolOnereader === 'Y'}
                              />
                            }
                            label={<FormattedMessage {...language.getText('interface.STUDY_ONEREADER')} />}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10}>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup row className={classes.labelName} onChange={(e) => this.handleChange(e, false)}>
                          <FormControlLabel
                            className={classes.labelName}
                            control={
                              <Checkbox
                                name="serverSideEnabledOneReader"
                                color="primary"
                                checked={languageInfo.serverSideEnabledOneReader === 'Y'}
                                // checked="true"
                              />
                            }
                            label={
                              <FormattedMessage {...language.getText('interface.SERVER_SIDE_ENABLED_ONEREADER')} />
                            }
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={10}>
                      <Typography variant="h6" color="textPrimary">
                        <FormattedMessage {...language.getText('interface.PRINT_SELECTION')} />
                      </Typography>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup row className={classes.labelName} onChange={(e) => this.handleChange(e, false)}>
                          <FormControlLabel
                            className={classes.labelName}
                            control={
                              <Checkbox name="print" checked={languageInfo.print === constants.YES} color="primary" />
                            }
                            label={<FormattedMessage {...language.getText('interface.PRINTING_ENABLED')} />}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <br />
                    {/* Commenting below as a part of EVG-391: Default setting for print should be N */}
                    {/* 
                    {sliderValue && languageInfo.print === 'Y' ? (
                      <Grid item xs={10}>
                        <Typography>
                          <FormattedMessage {...language.getText('interface.PRINT_PAGE_COUNT')} /> :
                        </Typography>

                        <Grid item xs={10}>
                          <TextField
                            className={classes.input}
                            value={inputValue}
                            defaultValue={0}
                            helperText={
                              <Typography variant="h6">
                                <FormattedMessage {...language.getText('interface.PRINT_PAGE_TOTAL_COUNT')} /> : {''}
                                {bookDetail.pageCount}
                              </Typography>
                            }
                            styles={{ fontSize: '12px' }}
                            onChange={(e) => this.handleInputChange(e, bookDetail.pageCount)}
                            max={bookDetail.pageCount}
                            onInput={(e) => {
                              const inputON = e.target.value === '' ? 0 : e.target.value;
                              e.target.value = Math.min(bookDetail.pageCount, parseInt(inputON, 10))
                                .toString()
                                .slice(0, bookDetail.pageCount);
                            }}
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>

                        <br />
                        <Grid item xs>
                          <Slider
                            value={typeof inputSlideValue === 'number' ? inputSlideValue : ''}
                            onChange={(e, number) => this.handleSliderChange(e, number, bookDetail.pageCount)}
                            valueLabelDisplay="auto"
                            aria-labelledby="input-slider"
                            min={0}
                            max={100}
                            step={1}
                          />
                          <Typography variant="caption">
                            {' '}
                            <FormattedMessage {...language.getText('interface.SLIDER_PERCENTAGE')} />{' '}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      '' 
                     )} */}
                  </Grid>
                </Grid>

                <hr />

                <Grid container direction="row" alignItems="center" spacing={1} className={classes.subgridMargin}>
                  <Grid item xs={2}>
                    <Typography variant="h6" color="textPrimary">
                      <FormattedMessage {...language.getText('interface.PDZ_PRODUCT')} />
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      margin="dense"
                      name="pdzProductID"
                      value={languageInfo.pdzProductID}
                      onChange={(e) => this.handleChange(e, true)}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <br />
                <br />

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.paper}
                >
                  <Grid item>
                    <TooltipCustom
                      title={
                        !permissionsList.includes('authoring_can_edit') ? (
                          <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                        ) : (
                          <FormattedMessage {...language.getText('interface.SAVE_INTERFACE_SETTINGS')} />
                        )
                      }
                    >
                      <span>
                        <Button
                          style={{ backgroundColor: '#005d83' }}
                          variant="outlined"
                          color="primary"
                          onClick={this.handleSave}
                          disabled={!permissionsList.includes('authoring_can_edit')}
                        >
                          <FormattedMessage {...language.getText('common.SAVE')} />
                        </Button>
                      </span>
                    </TooltipCustom>
                  </Grid>
                  <Grid item>
                    <TooltipCustom
                      title={<FormattedMessage {...language.getText('interface.CANCEL_INTERFACE_SETTINGS')} />}
                    >
                      <Button
                        style={{ backgroundColor: '#005d83' }}
                        variant="outlined"
                        onClick={() => Framework.redirectTo(`/books/${match.params.bookid}`)}
                        color="primary"
                      >
                        <FormattedMessage {...language.getText('common.CANCEL')} />
                      </Button>
                    </TooltipCustom>
                  </Grid>
                </Grid>
                <br />
                <br />
              </Paper>
            </div>
          }
        />
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(InterfaceColors)));
