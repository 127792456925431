import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles
} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import React from 'react';
import * as constants from '../../../../../common/constants';
import ChannelsListing from './ChannelsListing';

const styles = () => ({
  root: {
    width: '100%'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif !important',
    fontWeight: '600 !important',
    fontSize: '14px !important'
  },
  radioButton: {
    textAlign: 'center'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: '14px !important'
  },
  tableDateWidth: {
    width: '110px'
  },
  tableBookWidth: {
    width: '120px'
  },
  tableTitleWidth: {
    width: '120px'
  },
  tableLinkWidth: {
    width: '80px'
  },
  tableFilterTextWidth: {
    width: '150px'
  },
  tableFilterLikeWidth: {
    width: '145px'
  },
  tableCommentsWidth: {
    width: '160px'
  },
  tableUnansWidth: {
    width: '185px'
  },
  tableTrendsWidth: {
    width: '185px'
  },
  tableQuizWidth: {
    width: '185px'
  },
  tableSummaryWidth: {
    width: '180px'
  },
  tableExplainWidth: {
    width: '165px'
  }
});

const ChannelsTrendsGrid = ({
  channelsUtilityTrendsData,
  channelsListingUtilityData,
  selectListingOption,
  classes,
  selectRadioOption,
  sorting,
  setOrder,
  setDirectionValue
}) => {
  return (
    <>
      {selectRadioOption && (
        <TableContainer component={Paper} className={classes.root}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableDateWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GPT_TRENDS_DATE}
                    <TableSortLabel
                      onClick={() => sorting('date')}
                      active
                      direction={setDirectionValue === 'date' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableBookWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.CHANNELS_ID}
                    <TableSortLabel
                      onClick={() => sorting('channelsId')}
                      active
                      direction={setDirectionValue === 'channelsId' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableSummaryWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GENERATE_VIDEO_SUMMARY_COUNT}
                    <TableSortLabel
                      onClick={() => sorting('videoSummaryCount')}
                      active
                      direction={setDirectionValue === 'videoSummaryCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableSummaryWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GENERATE_QUESTION_INTENT_COUNT}
                    <TableSortLabel
                      onClick={() => sorting('questionIntentCount')}
                      active
                      direction={setDirectionValue === 'questionIntentCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableSummaryWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.GENERATE_QUESTION_SOLUTION_COUNT}
                    <TableSortLabel
                      onClick={() => sorting('questionSolutionCount')}
                      active
                      direction={setDirectionValue === 'questionSolutionCount' && setOrder === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableSummaryWidth}>
                  <Typography className={classes.columnStyle} variant="h6">
                    {constants.QUESTION_STEP_SOLUTION_COUNT}
                    <TableSortLabel
                      onClick={() => sorting('questionStepsSolutionCount')}
                      active
                      direction={
                        setDirectionValue === 'questionStepsSolutionCount' && setOrder === 'DSC' ? 'asc' : 'desc'
                      }
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {channelsUtilityTrendsData &&
                Array.isArray(channelsUtilityTrendsData) &&
                channelsUtilityTrendsData.length > 0 &&
                channelsUtilityTrendsData.map((data) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={classes.radioButton}>
                          <Typography className={classes.tableText}>{data.dateStamp}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}>{data.channelsId}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}>{data.videoSummaryCount}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}>{data.questionIntentCount}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}>{data.questionSolutionCount}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}>{data.questionStepsSolutionCount}</Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {selectListingOption && (
        <Paper>
          <Grid item xs={12}>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
              <br />
              <ChannelsListing channelsListingUtilityData={channelsListingUtilityData} />
            </Box>
          </Grid>
        </Paper>
      )}
    </>
  );
};

ChannelsTrendsGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  channelsListingUtilityData: PropTypes.array.isRequired,
  channelsUtilityTrendsData: PropTypes.array.isRequired,
  selectListingOption: PropTypes.string.isRequired,
  sorting: PropTypes.func.isRequired,
  setDirectionValue: PropTypes.string.isRequired,
  selectRadioOption: PropTypes.string.isRequired,
  setOrder: PropTypes.bool.isRequired
};

export default withStyles(styles)(ChannelsTrendsGrid);
