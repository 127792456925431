import { types, applySnapshot } from 'mobx-state-tree';

const NotificationModel = types
  .model('NotificationState', {
    open: false,
    type: '',
    message: '',
    duration: 10000
  })
  .actions((self) => ({
    set(alert) {
      applySnapshot(self, alert);
    }
  }));

export default NotificationModel;
