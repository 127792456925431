/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const COURSE_DATA_FETCHED = 'COURSE_DATA_FETCHED';
const Home = types
  .model('Home', {
    id: types.string,
    startDate: types.maybeNull(types.integer),
    endDate: types.maybeNull(types.integer),
    enrollments: types.maybeNull(types.integer)
  })
  .views((self) => ({
    getProduct() {
      return self.products && self.products.length ? self.products[0] : {};
    }
  }))
  .actions((self) => ({
    fetch(id) {
      if (self.id === id) {
        Framework.getEventManager().publish(COURSE_DATA_FETCHED, {});
      } else {
        Framework.getEventManager().publish(constants.COURSE_SYNC_REQUESTED, {
          courseId: id
        });
      }
    }
  }));

export default Home;
