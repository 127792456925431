/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import {
  Typography,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  Paper
} from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import Button from '@material-ui/core/Button';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import utils from '../../../../common/utils';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.5),
      textAlign: 'center'
    }
  },
  historyIcon: {
    cursor: 'pointer',
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  selectIcon: {
    padding: '0 0 0 0'
  },
  titlePointer: {
    cursor: 'pointer'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13,
    top: theme.spacing(1)
  },
  previewTitle: {
    color: '#0000EE',
    textAlign: 'center',
    fontSize: 13,
    textDecoration: 'underline'
  },
  closeButton: {
    left: theme.spacing(0.25),
    top: theme.spacing(0.15),
    color: theme.palette.grey[800]
  },
  cancelButton: {
    fontSize: '11px',
    maxWidth: '25px',
    maxHeight: '18px',
    minWidth: '25px',
    minHeight: '18px',
    top: theme.spacing(0.2),
    color: 'black'
  }
});

const SearchResultTable = ({
  language,
  classes,
  data,
  titleData,
  handleViewHistory,
  handleTitlePreview,
  handleRadioChange,
  handleCancelIngestion
}) => {
  const [selectedValue, setSelectedValue] = React.useState(data[0]);

  useEffect(() => {
    handleRadioChange(selectedValue);
  });

  const handleChange = (row) => {
    setSelectedValue(row);
    handleRadioChange(row);
  };
  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table className={classes.metaRoot} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="h5">
                <FormattedMessage {...language.getText('common.SELECT')} />
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">
                <FormattedMessage {...language.getText('label.HISTORY')} />
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">
                <FormattedMessage {...language.getText('label.ACTION_USER')} />
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">
                <FormattedMessage {...language.getText('contentmanager.IDENTIFIER')} />/{' '}
                <FormattedMessage {...language.getText('label.ISBN_10')} />/{' '}
                <FormattedMessage {...language.getText('label.ISBN_13')} />
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">
                <FormattedMessage {...language.getText('label.GLOBAL_BOOK_ID')} />/{' '}
                <FormattedMessage {...language.getText('label.BOOK_ID')} />
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">
                <FormattedMessage {...language.getText('label.WORKFLOW')} />
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">
                <FormattedMessage {...language.getText('contentmanager.BUSINESS_UNIT')} />/{' '}
                <FormattedMessage {...language.getText('label.SUB_BUSINESS_UNIT')} />/{' '}
                <FormattedMessage {...language.getText('label.DIRECTORY')} />
              </Typography>
            </TableCell>
            <TableCell align="center" style={{ width: '15%' }}>
              <Typography variant="h5">
                <FormattedMessage {...language.getText('label.TITLE')} />
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">
                <FormattedMessage {...language.getText('label.PAGE_COUNT')} />
              </Typography>
            </TableCell>
            <TableCell align="center" style={{ width: '15%' }}>
              <Typography variant="h5">
                <FormattedMessage {...language.getText('label.AUTHOR')} />
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">
                <FormattedMessage {...language.getText('label.VERSION')} />
              </Typography>
            </TableCell>
            <TableCell align="center" style={{ width: '10%' }}>
              <Typography variant="h5">
                <FormattedMessage {...language.getText('label.STATUS')} />
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow>
              <TableCell>
                <TooltipCustom title={<FormattedMessage {...language.getText('common.SELECT')} />}>
                  <Radio
                    checked={selectedValue === row}
                    // defaultValue={data[0]}
                    size="small"
                    color="primary"
                    onChange={() => handleChange(row)}
                    value={selectedValue}
                    name="radioButton"
                    className={classes.selectIcon}
                    // inputProps={{ 'aria-label': 'A' }}
                  />
                </TooltipCustom>
              </TableCell>
              <TableCell>
                <TooltipCustom title={<FormattedMessage {...language.getText('label.HISTORY')} />}>
                  <HistoryIcon
                    fontSize="medium"
                    onClick={() => handleViewHistory(row)}
                    className={classes.historyIcon}
                  />
                </TooltipCustom>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableText} component="div">
                  {row.actionUser}
                </Typography>
              </TableCell>
              <TableCell size="small">
                <Typography className={classes.tableText} component="div">
                  {row.identifier}/
                </Typography>
                <Typography className={classes.tableText} component="div">
                  {row.isbn10}/
                </Typography>
                <Typography className={classes.tableText} component="div">
                  {row.isbn13}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableText} component="div">
                  {row.globalBookId}/
                </Typography>
                <Typography className={classes.tableText} component="div">
                  {row.bookId}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableText} component="div">
                  {row.workflow}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableText} component="div">
                  {row.businessUnit}/
                </Typography>
                <Typography className={classes.tableText} component="div">
                  {row.subBusinessUnit}/
                </Typography>
                <Typography className={classes.tableText} component="div">
                  {row.directory}
                </Typography>
              </TableCell>

              <TableCell className={classes.titlePointer}>
                {row.isPreview ? (
                  <Typography
                    onClick={() => handleTitlePreview(row, titleData)}
                    className={classes.previewTitle}
                    component="div"
                  >
                    {row.bookTitle}
                  </Typography>
                ) : (
                  <Typography className={classes.tableText} style={{ cursor: 'auto' }} component="div">
                    {row.bookTitle}
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                <Typography className={classes.tableText} component="div">
                  {row.bookPagecount}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableText} component="div">
                  {utils.authorFullName(row.authorsList)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableText} component="div">
                  {row.version}
                </Typography>
              </TableCell>
              <TableCell style={{ background: row.statusColor }}>
                <Typography className={classes.statusText} component="div">
                  {row.status}
                </Typography>
                {row.processStatus === 'Y' ? (
                  <TooltipCustom title={<FormattedMessage {...language.getText('label.CANCEL_INGESTION')} />}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.cancelButton}
                      color="text.primary"
                      onClick={() => handleCancelIngestion(row)}
                    >
                      <FormattedMessage {...language.getText('common.CANCEL')} />
                    </Button>
                  </TooltipCustom>
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SearchResultTable.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  data: PropTypes.array.isRequired,
  titleData: PropTypes.object.isRequired,
  handleViewHistory: PropTypes.func.isRequired,
  handleTitlePreview: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleCancelIngestion: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(SearchResultTable)));
