/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import HomeService from './services/HomeService';
import Home from './model/Home';
import HomeContainer from './HomeContainer';

export default {
  path: ['/home'],
  exact: true,
  services: [new HomeService()],
  stores: [
    {
      name: 'home',
      spec: Home,
      saveLocally: false,
      initialState: {
        id: ''
      }
    }
  ],
  render: (props) => <HomeContainer {...props} />
};
