/* eslint-disable react/prop-types */
import { LoadingHandler } from '@greenville/framework';
import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
// import Asset from '../../models/Asset';
import TOCDisplay from '../TOCDisplay';
import Loader from '../../../../common/Loader';

const ChatDataToc = (props) => {
  const { assetTOC, assetTocStatus, match, viewType } = props;
  useEffect(() => {
    // assetTOC.fetch(match === undefined ? 'BRNT-CCD1J3NNHS' : match.params.bookId);
    assetTOC.fetch(match.params.bookId);
  }, []);
  return (
    <>
      <LoadingHandler
        loading={assetTocStatus.isPending}
        loadingContent={<Loader />}
        content={<TOCDisplay assetTOC={assetTOC} tocBookId={match.params.bookId} viewType={viewType} />}
      />
    </>
  );
};

export default inject('assetTOC', 'assetTocStatus')(observer(ChatDataToc));
