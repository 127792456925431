/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import UsersService from './services/UsersService';
import AddUserService from './services/AddUserService';
import EditUserService from './services/EditUserService';
import Users from './models/Users';
import UsersContainer from './UsersContainer';
import AllUserRoleService from './services/AllUserRoleService';
import Roles from './models/Roles';
import UsersPermissions from './models/UsersPermissions';
import UserRolePermissionsService from './services/UserRolePermissionsService';
import UserIdService from './services/UserIdService';
import UserId from './models/UserId';

export default {
  path: ['/users', '/users/:userid', '/users/add'],
  exact: true,
  services: [
    new UsersService(),
    new AddUserService(),
    new AllUserRoleService(),
    new EditUserService(),
    new UserRolePermissionsService(),
    new UserIdService()
  ],
  stores: [
    {
      name: 'usersState',
      spec: Users,
      saveLocally: false,
      initialState: {
        userList: [],
        addUser: {},
        updateUser: {}
      }
    },
    {
      name: 'userRoles',
      spec: Roles,
      saveLocally: false,
      initialState: {
        usersRoles: []
      }
    },
    {
      name: 'userPermissions',
      spec: UsersPermissions,
      saveLocally: false,
      initialState: {
        permissionList: {}
      }
    },
    {
      name: 'userid',
      spec: UserId,
      saveLocally: false,
      initialState: {
        userdetails: {}
      }
    }
  ],

  render: (props) => (
    <UsersContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
