import React from 'react';
import ChatgptUtilityAdmin from './ChatgptUtilityAdmin';
import ValidatePermissions from '../../users/ValidatePermissions';

/* eslint-disable no-shadow */
function AdminView() {
  const showDashboardEnabled = false;
  return (
    <>
      <ValidatePermissions givenPermissions="admin_can_view" showDashboardEnabled={showDashboardEnabled}>
        <ChatgptUtilityAdmin />
      </ValidatePermissions>
    </>
  );
}

AdminView.propTypes = {};

AdminView.defaultProps = {};

export default AdminView;
