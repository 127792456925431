/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 */
const CreateAndValidate = types
  .model('CreateAndValidate', {
    contentPath: types.maybeNull(types.string),
    workflow: types.maybeNull(types.string),
    processValidateXML: types.maybeNull(types.optional(types.boolean, false)),
    processPdfCropping: types.maybeNull(types.optional(types.boolean, true)),
    processPdfLowRes: types.maybeNull(types.optional(types.boolean, false)),
    processPdfUniversal: types.maybeNull(types.optional(types.boolean, true)),
    processPdfScaling: types.maybeNull(types.optional(types.boolean, false)),
    processPdfOptimized: types.maybeNull(types.optional(types.boolean, true)),
    processPdfTextExtraction: types.maybeNull(types.optional(types.boolean, true))
  })
  .actions((self) => ({
    fetch() {
      Framework.getEventManager().publish(constants.CM_PREFERENCE_SERVICE);
    },
    fetchCreate(data) {
      Framework.getEventManager().publish(constants.CM_CREATE_SERVICE, { data });
    },
    fetchValidate(data) {
      Framework.getEventManager().publish(constants.CM_VALIDATE_SERVICE, { data });
    },
    set(response) {
      applySnapshot(self, response.data);
    },
    setCreate(response) {
      applySnapshot(self, response.data);
    },
    setValidate(response) {
      applySnapshot(self, response.data);
    }
  }));

export default CreateAndValidate;
