/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Framework, { shapes } from '@greenville/framework';
import { Select, Typography, Grid, TextField, Paper, Fab } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { FormattedMessage } from 'react-intl';
import { toJS, observable } from 'mobx';
import Authors from './Authors';
import Books from '../../models/Books';
import Breadcrumbs from '../commonitems/Breadcrumbs';
import MetaConfig from '../../../../common/config/MetaConfig';
import UsersPermissions from '../../../users/models/UsersPermissions';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  metaRoot: {
    '& .MuiTextField-root': {
      marginRight: theme.spacing(0.5)
    }
  },
  metaisbnRoot: {
    '& .MuiTextField-root': {
      marginRight: theme.spacing(0.5)
    },
    width: 100
  },
  metaroleRoot: {
    '& .MuiOutlinedInput-root': {
      marginTop: theme.spacing(0.7),
      height: 39
    }
  },
  metabtnRoot: {
    '& .MuiButton-containedPrimary': {
      backgroundColor: 'red'
    }
  },
  metaradioRoot: {
    '& .MuiFormControlLabel-label': {
      fontSize: 13,
      lineHeight: 1.7
    },
    '& .MuiFormControlLabel-root': {
      paddingTop: 5
    }
  },
  selectWidth: {
    width: '100%'
  },
  customWidth: {
    width: '95%'
  },
  textfieldInput: {
    fontSize: 12
  },
  textareaHeight: {
    marginTop: '5px'
  },
  textselectHeight: {
    marginTop: '5px',
    marginBottom: '5px',
    fontSize: 12
  },
  radioMargin: {
    paddingTop: '5px'
  },
  bookfontSize: {
    fontSize: 12
  },
  bookfontPadding: {
    padding: theme.spacing(0, 0, 0.8, 0)
  },
  deleteIcon: {
    display: 'inline-block',
    marginTop: 5
  }
});

@inject('booksStatus', 'userPermissions')
@observer
class Metadata extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    booksState: shapes.modelOf(Books).isRequired,
    booksStatus: shapes.state.isRequired,
    match: PropTypes.object.isRequired,
    savePayload: PropTypes.func.isRequired,
    addauthorsRow: PropTypes.func.isRequired,
    removeauthorsRow: PropTypes.func.isRequired,
    editMetadata: PropTypes.func.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
    // data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { booksState } = this.props;
    this.state = {
      tocValue: false,
      currentbooksState: booksState || {}
    };
  }

  componentDidMount() {
    const { booksState, match } = this.props;
    booksState.fetchBookMetadata(match.params.bookid);
  }

  tocChange = () => {
    const { tocValue } = this.state;
    this.setState({
      tocValue: !tocValue
    });
  };

  setFilterValues = (filterConfig) => filterConfig.map((filter) => <option value={filter.id}>{filter.value}</option>);

  setFilterValuesCustom = (filterConfig) => filterConfig.map((filter) => <option>{filter}</option>);

  checkbox = (value) => {
    let checkboxValue = 'O';
    switch (value) {
      case 'O':
        checkboxValue = 'O';
        break;
      case 'E':
        checkboxValue = 'E';
        break;
      default:
        checkboxValue = 'E';
    }
    return checkboxValue;
  };

  checkboxRadio = (value) => {
    let checkboxValue = 'N';
    switch (value) {
      case 'N':
        checkboxValue = 'N';
        break;
      case 'Y':
        checkboxValue = 'Y';
        break;
      default:
        checkboxValue = 'Y';
    }
    return checkboxValue;
  };

  handleSave = () => {
    const { currentbooksState } = this.state;
    const payload = {
      data: currentbooksState.bookMetadata
    };
    const { booksState } = this.props;
    booksState.bookMetadata.savePayload(payload);
  };

  onRemove = (i, item) => {
    const { removeauthorsRow } = this.props;
    removeauthorsRow(i, item);
  };

  handleChange = (i, data, e) => {
    const { currentbooksState } = this.state;
    const { bookMetadata } = currentbooksState;
    let updatedObject = {};

    if (i === null) {
      // if (data === 'numberOfLevels') {
      //   for (let i = 0; i < bookMetadata.numberOfLevels; i++) {
      //     this.state.booklevelSelected.push(bookMetadata.bookLevels[i])
      //   }
      //   // bookMetadata.bookLevels.forEach((element,index) => {
      //   //   if(index<bookMetadata.numberOfLevels){
      //   //       this.state.booklevelSelected.push(element)
      //   //   }
      //   // });
      // }
      updatedObject = {
        [e.target.name]: e.target.value
      };
      this.setState({
        currentbooksState: {
          ...currentbooksState,
          bookMetadata: { ...bookMetadata, ...updatedObject }
        }
      });
    } else {
      const tempMetadata = toJS(bookMetadata[data]);
      tempMetadata.map((item, index) => {
        const changedItem = item;
        if (index === i) {
          if (data === 'bookLevels') {
            if (e.target.name === 'levelTypeID') {
              changedItem[e.target.name] = parseInt(e.target.value, 10);
            } else {
              changedItem[e.target.name] = e.target.value;
            }
          } else {
            changedItem[e.target.name] = e.target.value;
          }
          return changedItem;
        }
        return changedItem;
      });
      updatedObject = {
        [data]: observable.array(tempMetadata)
        // [data]: tempIsbn
      };
      const { editMetadata } = this.props;
      editMetadata(updatedObject[data], data);
    }

    // if (data === 'authorList') {
    //   const { editMetadata } = this.props;
    //   editMetadata(updatedObject.authorList);
    // } else {
    //   this.setState({
    //     //  ...this.state,
    //     currentbooksState: {
    //       ...currentbooksState,
    //       bookMetadata: { ...bookMetadata, ...updatedObject }
    //     }
    //   });
    // }
  };

  addrow = () => {
    const { addauthorsRow } = this.props;
    const { currentbooksState } = this.state;
    const { bookMetadata } = currentbooksState;
    addauthorsRow(bookMetadata.authorList);
  };

  render() {
    // const { booksState, classes, booksStatus, language } = this.props;
    const { classes, language, match, userPermissions } = this.props;
    const { currentbooksState, tocValue } = this.state;
    const { bookMetadata } = currentbooksState;
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    return (
      <>
        {' '}
        <Breadcrumbs data={<FormattedMessage {...language.getText('label.EDIT')} />} id={match.params.bookid} />
        <br />
        <Paper className={classes.paper} elevation={3} mb={3}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} direction="row">
              <Typography variant="h3" align="center" gutterBottom spacing={3}>
                <FormattedMessage {...language.getText('metadata.TITLE_TEXT')} />
              </Typography>
              <br /> <br />
              <Grid container direction="row" alignItems="center" className={classes.bookfontPadding}>
                <Grid item xs={2}>
                  <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                    <FormattedMessage {...language.getText('label.GLOBAL_BOOK_ID')} />
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.bookfontSize}>{`${bookMetadata.globalBookID} ${'\u00A0'} ${'v'}${
                    bookMetadata.version
                  }`}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" className={classes.bookfontPadding}>
                <Grid item xs={2}>
                  <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                    <FormattedMessage {...language.getText('label.BOOK_ID')} />
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.bookfontSize}>{bookMetadata.bookID}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={2}>
                  <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                    <FormattedMessage {...language.getText('metadata.BOOKTITLE')} />
                    <span style={{ color: 'red' }}> * </span>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin="dense"
                    name="title"
                    className={classes.customWidth}
                    InputProps={{ classes: { input: classes.textfieldInput } }}
                    variant="outlined"
                    style={{ fontSize: 12 }}
                    value={bookMetadata.title}
                    onChange={($event) => this.handleChange(null, null, $event)}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={2}>
                  <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                    <FormattedMessage {...language.getText('metadata.BOOKEDIION')} />
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="bookEditionNumber"
                    className={`${classes.some} without-padding`}
                    margin="dense"
                    InputProps={{ classes: { input: classes.textfieldInput } }}
                    variant="outlined"
                    value={bookMetadata.bookEditionNumber}
                    onChange={($event) => this.handleChange(null, null, $event)}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={2}>
                  <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                    <FormattedMessage {...language.getText('metadata.BOOKEDITIONTYPE')} />
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    native
                    name="bookEditionTypeID"
                    // className={classes.customWidth}
                    styles={{ width: '95%' }}
                    value={bookMetadata.bookEditionTypeID}
                    variant="outlined"
                    margin="dense"
                    onChange={($event) => this.handleChange(null, null, $event)}
                    className={classes.textselectHeight}
                  >
                    {this.setFilterValues(MetaConfig.bookEditionTypeId)}
                  </Select>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={2}>
                  <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                    <FormattedMessage {...language.getText('metadata.AUTHORS')} />
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.metaRoot} alignItems="center">
                  {bookMetadata.authorList.map((person, index) => (
                    <>
                      <Authors
                        firstName={person.firstName}
                        lastName={person.lastName}
                        otherName={person.otherName}
                        classes={classes}
                        onRemove={this.onRemove}
                        handleChange={($event) => this.handleChange(index, 'authorList', $event)}
                        data={bookMetadata.authorList}
                        index={index}
                      />
                    </>
                  ))}
                  <br />
                  <Grid>
                    <Typography style={{ fontSize: '12px' }}>
                      <Fab color="primary" aria-label="add" size="small" onClick={() => this.addrow()}>
                        <AddIcon />
                      </Fab>
                      <span>
                        {' '}
                        <FormattedMessage {...language.getText('metadata.Author')} />
                      </span>
                    </Typography>
                  </Grid>
                  <br />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={2}>
                  <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                    <FormattedMessage {...language.getText('metadata.COPYRIGHTYEAR')} />
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    name="copyrightYear"
                    native
                    onChange={($event) => this.handleChange(null, null, $event)}
                    variant="outlined"
                    margin="dense"
                    value={bookMetadata.copyrightYear}
                    className={classes.textselectHeight}
                  >
                    {this.setFilterValuesCustom(MetaConfig.yearDropDown)}
                  </Select>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={2}>
                  <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                    <FormattedMessage {...language.getText('metadata.ISBN')} />
                  </Typography>
                </Grid>
                <Grid item xs={9} className={classes.metaisbnRoot}>
                  {bookMetadata.isbnList.map((data, i) => (
                    <>
                      <TextField
                        name="displayISBN"
                        onChange={($event) => this.handleChange(i, 'isbnList', $event)}
                        className={`${classes.some} without-padding`}
                        InputProps={{ classes: { input: classes.textfieldInput } }}
                        variant="outlined"
                        margin="dense"
                        label={data.type}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={data.displayISBN}
                      />
                    </>
                  ))}
                </Grid>
                <Grid container direction="row" alignItems="flex-start">
                  <Grid item xs={2}>
                    <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                      <FormattedMessage {...language.getText('metadata.DESCRIPTION')} />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextareaAutosize
                      className={classes.textareaHeight}
                      placeholder="Please enter the text"
                      rowsMin={3}
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="description"
                      rowsMax={5}
                      style={{ minWidth: '100%' }}
                      value={bookMetadata.description}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                      <FormattedMessage {...language.getText('metadata.ORIENTATION')} />
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <RadioGroup
                      row
                      aria-label="position"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      value={this.checkbox(bookMetadata.orientation)}
                    >
                      <FormControlLabel
                        style={{ paddingTop: '1%' }}
                        value="O"
                        className={classes.metaradioRoot}
                        name="orientation"
                        control={<Radio color="primary" />}
                        label="Verso"
                      />
                      <FormControlLabel
                        style={{ paddingTop: '1%' }}
                        value="E"
                        className={classes.metaradioRoot}
                        name="orientation"
                        control={<Radio color="primary" />}
                        label="Recto"
                      />
                    </RadioGroup>
                    {/* <p>  {bookMetadata.orientation}</p> */}
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                      <FormattedMessage {...language.getText('metadata.BOOKTOCFLATTEN')} />
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <RadioGroup
                      row
                      aria-label="position"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="hasTocFlatten"
                      value={this.checkboxRadio(bookMetadata.hasTocFlatten)}
                    >
                      <FormControlLabel
                        style={{ paddingTop: '1%' }}
                        className={classes.metaradioRoot}
                        value="Y"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        style={{ paddingTop: '1%' }}
                        className={classes.metaradioRoot}
                        value="N"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                      <FormattedMessage {...language.getText('metadata.BOOKTOCLEVEL')} />
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                      <FormattedMessage {...language.getText('metadata.BOOKTOCSELECT')} />
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Select
                      native
                      variant="outlined"
                      value={bookMetadata.numberOfLevels}
                      onChange={($event) => this.handleChange(null, 'numberOfLevels', $event)}
                      name="numberOfLevels"
                      margin="dense"
                      disabled={tocValue}
                      className={classes.textselectHeight}
                    >
                      {this.setFilterValues(MetaConfig.typeDropDown)}
                    </Select>
                  </Grid>
                </Grid>

                {bookMetadata.bookLevels.map((data, index) => (
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={2} />
                    <Grid item xs={1}>
                      <Typography
                        className={classes.textspancolor}
                        variant="subtitle2"
                        color={tocValue ? 'textSecondary' : 'textPrimary'}
                      >
                        <FormattedMessage {...language.getText('metadata.ROLE')} />
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Select
                        native
                        variant="outlined"
                        disabled={tocValue}
                        name="levelTypeID"
                        onChange={($event) => this.handleChange(index, 'bookLevels', $event)}
                        margin="dense"
                        value={data.levelTypeID}
                        className={classes.textselectHeight}
                      >
                        {this.setFilterValues(MetaConfig.roleDropDown)}
                      </Select>
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        disabled={tocValue}
                        className={`${classes.some} without-padding`}
                        value={data.type}
                        name="type"
                        onChange={($event) => this.handleChange(index, 'bookLevels', $event)}
                        InputProps={{ classes: { input: classes.textfieldInput } }}
                        // InputProps={{ className: classes.textsize }}
                        variant="outlined"
                        label="Label"
                        margin="dense"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}

                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography className={classes.textspancolor} variant="subtitle2" color="textPrimary">
                      <FormattedMessage {...language.getText('metadata.BOOKTITLE')} /> Cover Art
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.metaisbnRoot}>
                    <TextField
                      //  className={`${classes.some} without-padding`}
                      className={classes.customWidth}
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                      // InputProps={{ className: classes.textsize }}
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="thumbnailArt"
                      variant="outlined"
                      label="Thumbnail"
                      margin="dense"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={bookMetadata.thumbnailArt}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.metaroleRoot}>
                    <Select
                      native
                      style={{ width: '95%' }}
                      name="thumbnailArtFormatID"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      autoWidth="false"
                      variant="outlined"
                      className={classes.textselectHeight}
                      value={bookMetadata.thumbnailArtFormatID}
                      margin="dense"
                    >
                      {this.setFilterValues(MetaConfig.corverDropdown)}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      className={`${classes.some} without-padding`}
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                      // InputProps={{ className: classes.textsize }}
                      variant="outlined"
                      name="thumbnailDpi"
                      label="DPI"
                      value={bookMetadata.thumbnailDpi}
                      onChange={($event) => this.handleChange(null, null, $event)}
                      margin="dense"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2} />
                  <Grid item xs={3} className={classes.metaisbnRoot}>
                    <TextField
                      className={classes.customWidth}
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                      // InputProps={{ className: classes.textsize }}
                      variant="outlined"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="coverArt"
                      label="Cover"
                      margin="dense"
                      value={bookMetadata.coverArt}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.metaroleRoot}>
                    <Select
                      native
                      style={{ width: '95%' }}
                      autoWidth="false"
                      variant="outlined"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="coverArtFormatID"
                      value={bookMetadata.coverArtFormatID}
                      className={classes.textselectHeight}
                      margin="dense"
                    >
                      {this.setFilterValues(MetaConfig.corverDropdown)}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      className={`${classes.some} without-padding`}
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                      // InputProps={{ className: classes.textsize }}
                      variant="outlined"
                      label="DPI"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="coverDpi"
                      value={bookMetadata.coverDpi}
                      margin="dense"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2} />
                  <Grid item xs={3} className={classes.metaisbnRoot}>
                    <TextField
                      className={classes.customWidth}
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                      // InputProps={{ className: classes.textsize }}
                      variant="outlined"
                      label="Navigation art"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="navigationArt"
                      margin="dense"
                      value={bookMetadata.navigationArt}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.metaroleRoot}>
                    <Select
                      native
                      style={{ width: '95%' }}
                      autoWidth="false"
                      variant="outlined"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="navigationArtFormatID"
                      className={classes.textselectHeight}
                      margin="dense"
                      value={bookMetadata.navigationArtFormatID}
                    >
                      {this.setFilterValues(MetaConfig.corverDropdown)}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      className={`${classes.some} without-padding`}
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                      // InputProps={{ className: classes.textsize }}
                      variant="outlined"
                      label="DPI"
                      value={bookMetadata.navigationDpi}
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="navigationDpi"
                      margin="dense"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2} />
                  <Grid item xs={3} className={classes.metaisbnRoot}>
                    <TextField
                      className={classes.customWidth}
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                      // InputProps={{ className: classes.textsize }}
                      variant="outlined"
                      label="Mobile cover"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="pdfCoverArt"
                      value={bookMetadata.pdfCoverArt}
                      margin="dense"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.metaroleRoot}>
                    <Select
                      native
                      style={{ width: '95%' }}
                      autoWidth="false"
                      value={bookMetadata.pdfCoverFormatID}
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="pdfCoverFormatID"
                      variant="outlined"
                      className={classes.textselectHeight}
                      margin="dense"
                    >
                      {this.setFilterValues(MetaConfig.corverDropdown)}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      className={`${classes.some} without-padding`}
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                      // InputProps={{ className: classes.textsize }}
                      variant="outlined"
                      onChange={($event) => this.handleChange(null, null, $event)}
                      name="pdfCoverDpi"
                      label="DPI"
                      value={bookMetadata.pdfCoverDpi}
                      margin="dense"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography className={classes.textspancolor} variant="subtitle1" color="textPrimary">
                      <FormattedMessage {...language.getText('metadata.MINHOTSPOT')} />
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      className={`${classes.some} without-padding`}
                      styles={{ fontSize: '12px' }}
                      type="number"
                      onChange={($event) => this.handleChange(null, 'minimumHotspotHeight', $event)}
                      name="minimumHotspotHeight"
                      InputProps={{ classes: { input: classes.textfieldInput } }}
                      // InputProps={{ inputProps: { min: 7, max: "10" } }}
                      onInput={(e) => {
                        e.target.value = Math.max(7, parseInt(e.target.value, 10)).toString().slice(0, 2);
                      }}
                      min={7}
                      helperText="Min value should be 7"
                      variant="outlined"
                      margin="dense"
                      value={bookMetadata.minimumHotspotHeight}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.paper}
                >
                  <Grid item>
                    <TooltipCustom
                      title={
                        !permissionsList.includes('authoring_can_edit') ? (
                          <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                        ) : (
                          <FormattedMessage {...language.getText('metadata.SAVE_BOOK_METADATA')} />
                        )
                      }
                    >
                      <span>
                        <Button
                          style={{ backgroundColor: '#005d83' }}
                          variant="outlined"
                          color="primary"
                          onClick={this.handleSave}
                          disabled={!permissionsList.includes('authoring_can_edit')}
                        >
                          <FormattedMessage {...language.getText('common.SAVE')} />
                        </Button>
                      </span>
                    </TooltipCustom>
                  </Grid>
                  <Grid item>
                    <TooltipCustom
                      title={<FormattedMessage {...language.getText('metadata.CANCEL_EDIT_BOOK_METADATA')} />}
                    >
                      <Button
                        style={{ backgroundColor: '#005d83' }}
                        variant="outlined"
                        onClick={() => Framework.redirectTo(`/books/${match.params.bookid}`)}
                        color="primary"
                      >
                        <FormattedMessage {...language.getText('common.CANCEL')} />
                      </Button>
                    </TooltipCustom>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(Metadata)));
