/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import Button from '@material-ui/core/Button';
import { toJS, observable } from 'mobx';
import { Typography, Grid, TextField } from '@material-ui/core';
import MediaBasepaths from '../../models/MediaBasepaths';
import Breadcrumbs from '../commonitems/Breadcrumbs';
import Loader from '../../../../common/Loader';
import NotificationModel from '../../../../common/components/notification/NotificationModel';
import UsersPermissions from '../../../users/models/UsersPermissions';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    width: '99%',
    padding: theme.spacing(0, 1)
  },
  customWidth: {
    width: '95%'
  },
  gridMargin: {
    margin: theme.spacing(2, 0, 2, 2)
  },
  subgridMargin: {
    margin: theme.spacing(0.2, 0, 0, 2)
  },
  textfieldInput: {
    fontSize: 13
  },
  pointer: {
    cursor: 'pointer'
  }
});

@inject('basepath', 'mediaBasepathStatus', 'notificationState', 'mediaBasepathPostStatus', 'userPermissions')
@observer
class Basepath extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    basepath: shapes.modelOf(MediaBasepaths).isRequired,
    savePayload: PropTypes.func.isRequired,
    mediaBasepathStatus: shapes.state.isRequired,
    classes: PropTypes.object.isRequired,
    mediaBasepathPostStatus: shapes.state.isRequired,
    language: shapes.language.isRequired,
    editSwfdata: PropTypes.func.isRequired,
    notificationState: shapes.modelOf(NotificationModel).isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      modifiedBasepath: [],
      basepathLabelName: [
        {
          type: 'Audio-Text Synch',
          linkTypeID: 10,
          basepath: '',
          uniqueID: null
        },

        {
          type: 'Chromelessurl',
          linkTypeID: 15,
          basepath: '',
          uniqueID: null
        },

        {
          type: 'h264',
          linkTypeID: 13,
          basepath: '',
          uniqueID: null
        },

        {
          type: 'image',
          linkTypeID: 1,
          basepath: '',
          uniqueID: null
        },

        {
          type: 'mp3',
          linkTypeID: 4,
          basepath: '',
          uniqueID: null
        },

        {
          type: 'url',
          linkTypeID: 7,
          basepath: '',
          uniqueID: null
        },

        {
          type: 'virtuallearningasset',
          linkTypeID: 9,
          basepath: '',
          uniqueID: null
        }
      ]
    };
  }

  componentDidMount() {
    const { match, basepath } = this.props;
    basepath.fetch(match.params.bookid);
  }

  handleSave = () => {
    const { match } = this.props;
    const { modifiedBasepath } = this.state;
    const payload = {
      data: modifiedBasepath,
      id: match.params.bookid
    };
    // const { basepath } = this.props;
    // basepath.savePayload(payload);

    const {
      basepath: { savePayload }
    } = this.props;
    savePayload(payload);
  };

  handleChange = (i, data, e) => {
    const { basepath } = this.props;
    const { basepathUrl } = basepath;
    const { modifiedBasepath } = this.state;
    const { basepathLabelName } = this.state;
    // const { emptyLinkID } = this.state;
    let updatedObject = {};
    const tempIsbn = toJS(basepathUrl);

    let emptyLinkID = [];
    emptyLinkID = basepathLabelName.map((parentlinkid) => parentlinkid.type);
    const isbnType = tempIsbn.map((newItem) => newItem.type.toLowerCase());
    emptyLinkID.map((items) => {
      const availedType = isbnType.includes(items.toLowerCase());
      if (!availedType) {
        const filteredBasepathlabel = basepathLabelName.filter((parentItem) => parentItem.type === items);
        filteredBasepathlabel[0].uniqueID = null;
        filteredBasepathlabel[0].bookID = tempIsbn[0].bookID;
        tempIsbn.push(filteredBasepathlabel[0]);
      }
      return availedType;
    });

    tempIsbn.map((item) => {
      const changedItem = item;
      if (item.linkTypeID === i) {
        changedItem[e.target.name] = e.target.value;
        const itemAlreadyAdded = modifiedBasepath.find((parentitem) => item.linkTypeID === parentitem.linkTypeID);
        if (itemAlreadyAdded) {
          modifiedBasepath.map((subitem) => {
            const basepathSubItem = subitem;
            if (item.linkTypeID === basepathSubItem.linkTypeID) {
              basepathSubItem[e.target.name] = e.target.value;
              return basepathSubItem;
            }
            return basepathSubItem;
          });
        } else {
          modifiedBasepath.push(changedItem);
        }
        return changedItem;
      }
      return changedItem;
    });
    updatedObject = {
      basepathUrl: observable.array(tempIsbn)
    };
    const { editSwfdata } = this.props;
    editSwfdata(updatedObject.basepathUrl, 'basepathUrl');
  };

  getBasepathUrl = (linkTypeID, basepathUrl) => {
    let basepath = '';
    const url = basepathUrl.find((item) => item.linkTypeID === linkTypeID);
    if (url) {
      basepath = url.basepath ? url.basepath : '';
    }
    return basepath;
  };

  render() {
    const { classes, language, basepath, match, mediaBasepathStatus, mediaBasepathPostStatus, userPermissions } =
      this.props;
    const { basepathUrl } = basepath;
    const { basepathLabelName } = this.state;
    const permissionsList = toJS(userPermissions.permissionList.permissions);

    return (
      <>
        <LoadingHandler
          loading={mediaBasepathStatus.isPending || mediaBasepathPostStatus.isPending}
          isError={mediaBasepathStatus.isError && mediaBasepathStatus.error && mediaBasepathStatus.error.status === 401}
          loadingContent={<Loader />}
          errorContent={<StateErrorDisplay error={mediaBasepathStatus.error} showDetails />}
          content={
            <div className={classes.root}>
              <Breadcrumbs
                data={<FormattedMessage {...language.getText('basepath.BREAD_CRUMBS')} />}
                id={match.params.bookid}
              />
              <br />
              <Paper elevation={3}>
                <br />
                <Typography align="center" variant="h5">
                  <FormattedMessage {...language.getText('basepath.HEADER')} />
                </Typography>
                <br />
                <Typography align="center" variant="h5">
                  <FormattedMessage {...language.getText('basepath.ASSET_FILE_PATH')} />
                </Typography>
                <br />

                {basepathLabelName.map((eachItem, index) => (
                  <Grid key={index} container direction="row" alignItems="center" className={classes.subgridMargin}>
                    <Grid item xs={2}>
                      <Typography variant="h6" color="textPrimary">
                        {eachItem.type}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="basepath"
                        value={this.getBasepathUrl(eachItem.linkTypeID, basepathUrl)}
                        className={classes.customWidth}
                        InputProps={{ classes: { input: classes.textfieldInput } }}
                        variant="outlined"
                        onChange={($event) => this.handleChange(eachItem.linkTypeID, null, $event)}
                      />
                    </Grid>
                  </Grid>
                ))}

                <br />

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.paper}
                >
                  <Grid item>
                    <TooltipCustom
                      title={
                        !permissionsList.includes('authoring_can_edit') ? (
                          <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                        ) : (
                          <FormattedMessage {...language.getText('basepath.SAVE_BASEPATH')} />
                        )
                      }
                    >
                      <span>
                        <Button
                          className={classes.pointer}
                          style={{ backgroundColor: '#005d83' }}
                          variant="outlined"
                          color="primary"
                          onClick={this.handleSave}
                          disabled={!permissionsList.includes('authoring_can_edit')}
                        >
                          <FormattedMessage {...language.getText('common.SAVE')} />
                        </Button>
                      </span>
                    </TooltipCustom>
                  </Grid>
                  <Grid item>
                    <TooltipCustom title={<FormattedMessage {...language.getText('basepath.CANCEL_EDIT_BASEPATH')} />}>
                      <Button
                        style={{ backgroundColor: '#005d83' }}
                        variant="outlined"
                        onClick={() => Framework.redirectTo(`/books/${match.params.bookid}`)}
                        color="primary"
                        className={classes.pointer}
                      >
                        <FormattedMessage {...language.getText('common.CANCEL')} />
                      </Button>
                    </TooltipCustom>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          }
        />
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(Basepath)));
