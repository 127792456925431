/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 */

const etextDataObj = types.model('etextDataObj', {
  requestID: types.maybeNull(types.integer),
  etextISBN10: types.maybeNull(types.string),
  etextISBN13: types.maybeNull(types.string),
  parentISBN10: types.maybeNull(types.string),
  parentISBN13: types.maybeNull(types.string),
  bookID: types.maybeNull(types.integer),
  globalBookID: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  author: types.maybeNull(types.string),
  divisionID: types.maybeNull(types.integer),
  uilanguage: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  addlEmail: types.maybeNull(types.string),
  chargeISBN: types.maybeNull(types.string),
  ipadView: types.maybeNull(types.string),
  digitalLibrary: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  firstPage: types.maybeNull(types.string),
  itemsExcludeDesc: types.maybeNull(types.string),
  initialComments: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  productionNotes: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
  intlRegion: types.maybeNull(types.integer),
  copyright: types.maybeNull(types.string),
  vendor: types.maybeNull(types.string),
  managedBy: types.maybeNull(types.string)
});

const etextStatusDataObj = types.model('etextStatusDataObj', {
  productionStatus: 1,
  requestFormSubmitted: types.maybeNull(types.string),
  estimatedProjectCompletion: types.maybeNull(types.string),
  folderName: types.maybeNull(types.string),
  projectInitiated: types.maybeNull(types.string),
  adjustedProjection: types.maybeNull(types.string),
  overAllCompletionDate: types.maybeNull(types.string),
  reviewCount: types.maybeNull(types.integer),
  holdDays: types.maybeNull(types.integer)
});

const etextInfoDataObj = types.model('etextInfoDataObj', {
  sourceFile: types.maybeNull(types.string),
  compositor: types.maybeNull(types.string),
  disciplineCode: types.maybeNull(types.integer),
  pimsInfo: types.maybeNull(types.string),
  coverArt: types.maybeNull(types.string),
  nonPermissionedExclude: types.maybeNull(types.string),
  insideFaceCover: types.maybeNull(types.string),
  insideBackCover: types.maybeNull(types.string),
  colorInserts: types.maybeNull(types.string),
  bonusChapters: types.maybeNull(types.string),
  tearCard: types.maybeNull(types.string),
  endPapers: types.maybeNull(types.string),
  othersCheck: types.maybeNull(types.string),
  others: types.maybeNull(types.string),
  suppSectionDesc: types.maybeNull(types.string),
  tocSpreadSheet: types.maybeNull(types.string),
  glossarySpreadSheet: types.maybeNull(types.string),
  contentsLinks: types.maybeNull(types.string),
  indexLinks: types.maybeNull(types.string),
  crossRefHotspot: types.maybeNull(types.string),
  hotSpotSpreadSheet: types.maybeNull(types.string),
  custIcons: types.maybeNull(types.string),
  customBasketSpreadSheet: types.maybeNull(types.string),
  hotspotUI: types.maybeNull(types.string),
  underlineHotspot: types.maybeNull(types.string),
  underlineHotspotHover: types.maybeNull(types.string),
  visibleHotspotHex: types.maybeNull(types.string),
  underlineHotspotHex: types.maybeNull(types.string),
  underlineHotspotHoverHex: types.maybeNull(types.string),
  audioSync: types.maybeNull(types.string),
  etextType: types.maybeNull(types.string),
  pageCount: types.maybeNull(types.integer),
  removeCaption: types.maybeNull(types.string),
  retainCaptionList: types.maybeNull(types.string)
});

const etextRevisionDataObj = types.model('etextRevisionDataObj', {
  initialRevisionRequested: types.maybeNull(types.string),
  revisionID: types.maybeNull(types.integer),
  revisionStatus: types.maybeNull(types.integer),
  typesOfRevision: types.optional(types.array(types.string), [])
});

const requestDataCode = types.model('requestDataCode', {
  etextGeneral: types.optional(etextDataObj, {}),
  etextScheduleStatus: types.optional(etextStatusDataObj, {}),
  etextInfo: types.optional(etextInfoDataObj, {}),
  etextAvailableRevision: types.optional(types.array(etextRevisionDataObj), [])
});

const NewRequest = types
  .model('NewRequest', {
    dataRequest: types.optional(toJS(requestDataCode), {}),
    dataRequestStatus: types.maybeNull(types.boolean),
    dataUpdateStatus: types.maybeNull(types.boolean)
  })

  .actions((self) => ({
    fetchRequest(payload) {
      Framework.getEventManager().publish(constants.ETEXT_NEW_REQUEST_GET, { payload });
    },

    setNewRequest(response) {
      applySnapshot(self.dataRequest, response.data);
    },
    setAlteredRequest(response) {
      applySnapshot(self.dataRequest, response);
    },
    setRequestStatus(payload) {
      applySnapshot(self, { ...self, dataRequestStatus: payload });
    },
    setUpdateStatus(payload) {
      applySnapshot(self, { ...self, dataUpdateStatus: payload });
    },
    savePayload(payload) {
      Framework.getEventManager().publish(constants.ETEXT_REQUEST_POST, { payload });
    },
    saveUpdate(payload) {
      Framework.getEventManager().publish(constants.ETEXT_REQUEST_UPDATE_POST, { payload });
    },
    deleteRequest(payload) {
      Framework.getEventManager().publish(constants.ETEXT_REQUEST_DELETE_POST, { payload });
    }
  }));

export default NewRequest;
