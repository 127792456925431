/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import { toJS } from 'mobx';
import { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import Favorite from '@material-ui/icons/Favorite';
// import Books from '../models/Books'
// import { shapes } from '@greenville/framework';
import UsersPermissions from '../../users/models/UsersPermissions';
import TooltipCustom from '../../../common/components/TooltipCustom';

@inject('booksState', 'booksStatus', 'userPermissions')
@observer
class FavoriteIcon extends PureComponent {
  static propTypes = {
    favorite: PropTypes.array.isRequired,
    bookFavorite: PropTypes.func.isRequired,
    window: PropTypes.string.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired,
    language: shapes.language.isRequired
  };

  addToFavorite = () => {
    const { favorite, bookFavorite, window } = this.props;
    const updateFavorite = {
      bookID: favorite.bookID,
      favorite: {
        bookEditionID: favorite.bookEditionID,
        isFavorite: favorite.isFavorite !== 'Y'
      }
    };
    bookFavorite(updateFavorite, window);
  };

  render() {
    const { favorite, userPermissions, language } = this.props;
    const permissionsList = toJS(userPermissions.permissionList.permissions);

    return (
      <>
        <TooltipCustom
          title={
            !permissionsList.includes('authoring_can_edit') ? (
              <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
            ) : (
              <FormattedMessage {...language.getText('user.FAVORITE')} />
            )
          }
        >
          <span>
            <IconButton
              aria-label="Favorite"
              onClick={this.addToFavorite}
              disabled={!permissionsList.includes('authoring_can_edit')}
            >
              <Favorite fontSize="large" color={favorite.isFavorite === 'Y' ? 'error' : 'inherit'} />
            </IconButton>
          </span>
        </TooltipCustom>
      </>
    );
  }
}

export default withStyles()(observer(inject('language')(FavoriteIcon)));
