import { applySnapshot, types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const userDetails = types.model('userDetails', {
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  userId: types.maybeNull(types.string)
});

const ChatgptUtilityIESUser = types
  .model('ChatgptUtilityIESUser', {
    userData: types.optional(types.array(userDetails), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHATGPT_IES_USER_DATA, {
        payload
      });
    },
    setResponse(response) {
      if (response && response.data[0]) {
        const userData = response.data[0];
        self.userData.push(userData);
      }
      Framework.getEventManager().publish(constants.SET_CHATGPT_IES_USER_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.userData, initialState);
    }
  }));

export default ChatgptUtilityIESUser;
