/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Typography from '@material-ui/core/Typography';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import Grid from '@material-ui/core/Grid';
import { Typography, Select, Box, MenuItem } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
// import InputLabel from '@material-ui/core/InputLabel';
import { FormattedMessage } from 'react-intl';
// import Button from '@material-ui/core/Button';

const styles = () => ({
  width: {
    width: '100%'
  },
  labelWidht: {
    width: '90%'
  },
  radioJustify: {
    justifyContent: 'space-between'
  }
});

// @observer
// class FieldsCommon extends React.Component {
// static propTypes = {
//   classes: PropTypes.object.isRequired,
//   language: shapes.language.isRequired,
//   data: PropTypes.string.isRequired
// };

// render() {
// const { classes, language, data } = this.props;
// console.log('clases123', data);
// return (

const PublishingCompany = ({
  language,
  classes,
  handleRadioChange,
  handleSelectionChange,
  formFields,
  artsAndScience,
  professionalAndCareer,
  international,
  ptg
}) => (
  <Grid container direction="row">
    <Grid container item xs={12} direction="row">
      <Typography variant="h5">
        <FormattedMessage {...language.getText('eTextrequest.PUBLISH')} />
        {formFields.publishRadioValueBool ? (
          <Typography variant="caption">
            <span id="req_text" style={{ color: '#ca4644' }}>
              {' '}
              <FormattedMessage {...language.getText('eTextconvergence.ATLEAST')} />
            </span>
          </Typography>
        ) : null}
      </Typography>
    </Grid>
    <Grid container item xs={12} direction="row">
      <Box className={classes.width}>
        {/* <FormControl component="fieldset" margin="dense" className={classes.width}> */}
        <RadioGroup
          row
          aria-label="position"
          onChange={handleRadioChange}
          className={classes.radioJustify}
          name="divisionID"
          value={formFields.divisionID}
        >
          <div>
            <FormControlLabel
              labelPlacement="left"
              value={21}
              control={<Radio color="primary" />}
              label="Arts & Sciences"
            />
            <div>
              <Select
                value={formFields.divisionID === 21 ? formFields.divisionSource : ''}
                disabled={formFields.divisionID !== 21 || formFields.divisionID.length === 0}
                className={classes.labelWidht}
                defaultValue=""
                onChange={handleSelectionChange}
              >
                {artsAndScience.length > 0 &&
                  artsAndScience.map((filter) => (
                    <MenuItem value={filter.artsAndScienceText}>{filter.artsAndScienceText}</MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <div>
            <FormControlLabel value={22} control={<Radio color="primary" />} label="Professional & Career" />
            <div>
              <Select
                value={formFields.divisionID === 22 ? formFields.divisionSource : ''}
                disabled={formFields.divisionID !== 22 || formFields.divisionID.length === 0}
                defaultValue=""
                className={classes.labelWidht}
                onChange={handleSelectionChange}
              >
                {professionalAndCareer.length > 0 &&
                  professionalAndCareer.map((filter) => (
                    <MenuItem value={filter.professionalAndCareerText}>{filter.professionalAndCareerText}</MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <div>
            <FormControlLabel value={23} control={<Radio color="primary" />} label="International" />
            <div>
              <Select
                // name="Asia"
                value={formFields.divisionID === 23 ? formFields.divisionSource : ''}
                className={classes.labelWidht}
                defaultValue=""
                onChange={handleSelectionChange}
                disabled={formFields.divisionID !== 23 || formFields.divisionID.length === 0}
                // value={workflowFields.businessUnit}
              >
                {international.length &&
                  international.map((filter) => (
                    <MenuItem value={filter.internationalId}>{filter.internationalText}</MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <div>
            <FormControlLabel value={24} control={<Radio color="primary" />} label="PTG" />
            <div>
              <Select
                value={formFields.divisionID === 24 ? formFields.divisionSource : ''}
                disabled={formFields.divisionID !== 24 || formFields.divisionID.length === 0}
                onChange={handleSelectionChange}
                className={classes.labelWidht}
                defaultValue=""
              >
                {ptg.length > 0 && ptg.map((filter) => <MenuItem value={filter.ptgText}>{filter.ptgText}</MenuItem>)}
              </Select>
            </div>
          </div>
          <div>
            <FormControlLabel value={25} control={<Radio color="primary" />} label="PLS" />
          </div>
        </RadioGroup>
        {/* </FormControl> */}
      </Box>
    </Grid>
  </Grid>
);

PublishingCompany.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  divisionID: PropTypes.string.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  formFields: PropTypes.object.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  artsAndScience: PropTypes.array.isRequired,
  professionalAndCareer: PropTypes.array.isRequired,
  international: PropTypes.array.isRequired,
  ptg: PropTypes.array.isRequired
};

export default withStyles(styles)(inject('language')(observer(PublishingCompany)));
