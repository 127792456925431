import React from 'react';
import { Grid, Paper, styled, Card, CardHeader, CardContent } from '@material-ui/core';
import { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';
import { PropTypes } from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage } from 'react-intl';

const styles = () => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  },
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  headingItems: {
    border: '1px solid grey',
    padding: '10px'
  }
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

// /* eslint-disable */
const EffectivenessDialogComponent = (props) => {
  const { openChat, handleClose, dataValue, modalIndex, dataLength, handleModalChange, classes, language } = props;
  const handleNavigationChange = (nav) => {
    handleModalChange(nav);
  };

  const returnFeedbackData = (data) => {
    const feedbackTags = data.map((item) => {
      const filterValue = Object.fromEntries(
        Object.entries(item).filter(([key, value]) => value !== null && key !== null)
      );
      const numberProp = [];
      const cardContent = [];
      Object.keys(filterValue).forEach((key) => {
        const tag = (
          <Typography
            sx={{
              fontWeight: 'bold',
              display: 'flex',
              textTransform: 'capitalize',
              paddingLeft: '2px',
              textAlign: 'left'
            }}
          >
            {`${key}: `}{' '}
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: filterValue[key] }} paddingTop="3px" />
          </Typography>
        );
        if (typeof filterValue[key] === 'number') {
          numberProp.push(tag);
        } else {
          cardContent.push(tag);
          cardContent.push(<br />);
        }
      });
      return (
        <>
          <Grid item xs={6}>
            <Item>
              <Card>
                <CardHeader title={numberProp} />
                <CardContent style={{ overflowY: 'auto', minHeight: '200px', maxHeight: '200px' }}>
                  {cardContent}
                </CardContent>
              </Card>
            </Item>
          </Grid>
        </>
      );
    });
    return feedbackTags;
  };
  return (
    <>
      <Dialog fullScreen open={openChat} onClose={handleClose}>
        <Box sx={{ p: 2 }}>
          <Paper elevation={0}>
            <Grid container spacing={0} padding={0} className={classes.headingItems}>
              <Grid item xs={11}>
                <Stack
                  display="inline"
                  spacing={0}
                  justifyContent="center"
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <Button disabled={modalIndex === 0} onClick={() => handleNavigationChange('prev')} size="large">
                    <ChevronLeftIcon />
                  </Button>
                  <span color="secondary">{modalIndex + 1}</span> /<span color="secondary"> {dataLength}</span>
                  <Button disabled={modalIndex + 1 === dataLength} onClick={() => handleNavigationChange('next')}>
                    <ChevronRightIcon />
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <Typography align="right" variant="subtitle1">
                  <Box display="inline" sx={{ pl: 4 }}>
                    <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                      <Tooltip title="Close">
                        <CloseIcon />
                      </Tooltip>
                    </IconButton>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            {dataValue && (
              <Box className={classes.headingItems}>
                <Grid item container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid xs={6}>
                    <Item>
                      <Card style={{ overflowY: 'auto', minHeight: '210px', maxHeight: '210px' }}>
                        <CardHeader
                          style={{ background: '#34282808' }}
                          title={
                            <Typography variant="body1" fontWeight="bold">
                              <FormattedMessage {...language.getText('chatUtility.USER_INPUT')} />
                            </Typography>
                          }
                          align="left"
                        />
                        <CardContent style={{ overflowY: 'auto' }}>
                          <Typography sx={{ textAlign: 'left' }} variant="body1">
                            {dataValue.input}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Item>
                  </Grid>
                  <Grid xs={6}>
                    <Item>
                      <Card style={{ overflowY: 'auto', minHeight: '210px', maxHeight: '210px' }}>
                        <CardHeader
                          style={{ background: '#34282808' }}
                          title={
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                              <FormattedMessage {...language.getText('chatUtility.AI_RESPONSE')} />
                            </Typography>
                          }
                          align="left"
                        />
                        <CardContent style={{ overflowY: 'auto' }}>
                          <Typography
                            sx={{ textAlign: 'left' }}
                            dangerouslySetInnerHTML={{ __html: dataValue.output }}
                          />
                        </CardContent>
                      </Card>
                    </Item>
                  </Grid>
                </Grid>
                <br />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {returnFeedbackData(dataValue.feedbacks)}
                </Grid>
              </Box>
            )}
          </Paper>
        </Box>
      </Dialog>
    </>
  );
};

EffectivenessDialogComponent.defaultProps = {
  modalIndex: '',
  dataValue: {},
  dataLength: 0
};

EffectivenessDialogComponent.propTypes = {
  openChat: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataValue: PropTypes.object,
  dataLength: PropTypes.number,
  modalIndex: PropTypes.string,
  handleModalChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired
};

export default withStyles(styles)(inject('language')(observer(EffectivenessDialogComponent)));
