/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box, withStyles } from '@material-ui/core';
import { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import { Switch, Route, Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Header from '../../common/header/Header';
import WorkflowStatus from './components/workflow/WorkflowStatus';
import StandardEbook from './components/create/StandardEbook';
import Workflow from './models/Workflow';
import Upload from './models/Upload';
// import Updf from './components/pdf/Updf';
import TitleAction from './components/title/TitleAction';
import Breadcrumbs from '../books/components/commonitems/Breadcrumbs';
import UploadContent from './components/upload/UploadContent';
import ValidatePermissions from '../users/ValidatePermissions';

const styles = () => ({
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80
  }
});

@inject('workflow', 'upload', 'workflowSearchStatus', 'workflowHistoryStatus')
@observer
class ContentManagerContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    workflow: shapes.modelOf(Workflow).isRequired,
    upload: shapes.modelOf(Upload).isRequired,
    match: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    workflowSearchStatus: shapes.state.isRequired
  };

  constructor(props) {
    super(props);
    const { language } = this.props;
    this.state = {
      tabValue: 0,
      tabState: <FormattedMessage {...language.getText('contentmanager.STATUS')} />,
      radioTitle: {}
    };
    this.radioTitleDetail = this.radioTitleDetail.bind(this);
  }

  componentDidMount() {
    const { workflow, match } = this.props;
    workflow.fetch();
    const tabUrl = match.path.split('/').pop();
    if (tabUrl === 'create') {
      this.setState({
        tabValue: 1
      });
    } else if (tabUrl === 'title') {
      this.setState({
        tabValue: 2
      });
    } else if (tabUrl === 'uPDF') {
      this.setState({
        tabValue: 3
      });
    }
  }

  radioTitleDetail = (rowData) => {
    let object = {};
    if (rowData.identifier) {
      object = {
        identifier: rowData.identifier,
        businessUnitId: rowData.businessUnitId,
        businessUnit: rowData.businessUnit,
        subBusinessUnit: rowData.subBusinessUnit,
        directory: rowData.directory,
        workflowName: rowData.workflow
      };
      this.setState({ radioTitle: object });
    } else {
      this.setState({ radioTitle: object });
    }
  };

  handleTabChange = (e, value) => {
    const { language } = this.props;
    this.setState({
      tabValue: value
    });
    if (value === 0) {
      this.setState({
        tabState: <FormattedMessage {...language.getText('contentmanager.STATUS')} />
      });
    }
    if (value === 1) {
      this.setState({
        tabState: <FormattedMessage {...language.getText('contentmanager.INGEST_TAB')} />
      });
    }
    if (value === 2) {
      this.setState({
        tabState: <FormattedMessage {...language.getText('contentmanager.TITLE_TAB')} />
      });
    }
  };

  render() {
    const { classes, language, match } = this.props;
    const { tabValue, tabState, radioTitle } = this.state;

    return (
      <>
        <Header />
        <main className={classes.contentWrapper}>
          <ValidatePermissions givenPermissions="ingestion_can_view">
            <Box ml={3}>
              <Breadcrumbs
                subData={tabState}
                data={<FormattedMessage {...language.getText('label.INGESTION')} />}
                id={match.params.bookid}
              />
              <br />
            </Box>

            <ValidatePermissions givenPermissions="ingestion_can_view">
              <Paper elevation={3}>
                <AppBar position="static" color="default">
                  <Tabs
                    // value={0}
                    value={tabValue}
                    onChange={(e, data) => this.handleTabChange(e, data)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="Workflow Status Tabs"
                  >
                    <Tab
                      label={<FormattedMessage {...language.getText('contentmanager.WORKFLOW')} />}
                      component={Link}
                      to="/cm/ingestion/workflow"
                    />
                    <Tab
                      label={<FormattedMessage {...language.getText('contentmanager.INGESTION')} />}
                      component={Link}
                      to="/cm/ingestion/create"
                    />
                    <Tab
                      label={<FormattedMessage {...language.getText('contentmanager.TITLE_ACTION')} />}
                      component={Link}
                      to="/cm/ingestion/title"
                    />
                  </Tabs>
                </AppBar>
              </Paper>
            </ValidatePermissions>

            <Switch>
              <Route
                path="/cm/ingestion/workflow"
                exact
                render={() => (
                  <ValidatePermissions givenPermissions="ingestion_can_view">
                    <WorkflowStatus {...this.props} radioTitleDetail={this.radioTitleDetail} />
                  </ValidatePermissions>
                )}
              />

              <Route
                path="/cm/ingestion/create"
                exact
                render={() => (
                  <ValidatePermissions givenPermissions="ingestion_can_view">
                    <StandardEbook {...this.props} radioTitle={radioTitle} />{' '}
                  </ValidatePermissions>
                )}
              />

              <Route
                path="/cm/ingestion/title"
                exact
                render={() => (
                  <ValidatePermissions givenPermissions="ingestion_can_view">
                    {' '}
                    <TitleAction {...this.props} radioTitle={radioTitle} />{' '}
                  </ValidatePermissions>
                )}
              />

              <Route
                path="/cm/ingestion/upload"
                exact
                render={() => (
                  <ValidatePermissions givenPermissions="ingestion_can_view">
                    {' '}
                    <UploadContent {...this.props} />
                  </ValidatePermissions>
                )}
              />
            </Switch>
          </ValidatePermissions>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(observer(ContentManagerContainer)));
