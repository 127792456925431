import Framework, { shapes, LoadingHandler } from '@greenville/framework';
import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Button from '@mui/material/Button';
import axios from 'axios';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import DialogBox from '../../../../common/components/DialogBox';
import TableComponent from '../../../../common/components/Table';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import UsersPermissions from '../../../users/models/UsersPermissions';
import chatGptAdminPromptsData from '../../models/ChatGptAdminPrompts';
import AdminPromptDialog from './AdminPromptDialog';
import Loader from '../../../../common/Loader';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#005d83 !important',
    color: 'white !important'
  }
});

const AdminPromptComponent = (props) => {
  const classes = useStyles();
  const {
    chatgptAdminPrompts,
    ChatGptGetAdminPromptsStatus,
    ChatGptUtilityAddAdminStatus,
    ChatGptEditAdminPromptsStatus,
    userPermissions,
    language
  } = props;
  const history = useHistory();
  const [archived, setArchived] = useState(false);
  const [showCloneIcon, setShowCloneIcon] = useState(false);
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [rowItem, setRowItem] = useState('');
  const [itemName, setItemName] = useState('');
  const [archiveDialogContent, setArchiveDialogContent] = useState(null);
  const [promptsData, setPromptsData] = useState([]);
  const [archieveData, setArchievedData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [order, setOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');
  const [selectedValue, setSelectedValue] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [tenantKey, setTenantKey] = useState('');
  const [userToken, setUserToken] = useState('');
  const [apiLoading, setApiLoading] = useState(false);
  const permissionsList = toJS(userPermissions.permissionList.permissions);
  const getPromptsData = async () => {
    setApiLoading(true);
    const headers = {
      Authorization: `Bearer ${userToken}`
    };
    const response = await axios.post(
      `${env.EVERGREEN_API_BASE_URL}${constants.GET_ADMIN_PROMPTS_URL}`,
      {},
      { headers }
    );
    if (response && response.data && response.data.data) {
      const archieve = response.data.data.filter((item) => item.status === 'ARCHIVED');
      const unArchieve = response.data.data.filter((item) => item.status !== 'ARCHIVED');
      setArchievedData(archieve);
      setPromptsData(unArchieve);
      setArchived(true);
      setShowCloneIcon(true);
      setApiLoading(false);
    }
    // chatgptAdminPrompts.fetchAdminPromptsData();
    // /* eslint-disable camelcase */
    // Framework.getEventManager().on(constants.SET_ADMIN_PROMPTS_DATA, () => {
    //   const { data } = chatgptAdminPrompts;
    //   const combineDatatoJS = JSON.parse(JSON.stringify(toJS(data)));
    //   const archieve = combineDatatoJS.filter((item) => item.status === 'ARCHIVED');
    //   const unArchieve = combineDatatoJS.filter((item) => item.status !== 'ARCHIVED');
    //   setArchievedData(archieve);
    //   setPromptsData(unArchieve);
    //   setArchived(true);
    //   setShowCloneIcon(true);
    // });
  };

  useEffect(() => {
    const token = utils.getToken();
    setUserToken(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userToken)) {
      if (history.location.pathname === '/chatgptutility/channels/promptmgt') {
        setTenantKey('channels');
      }
      if (history.location.pathname === '/chatgptutility/aistudytools/promptmgt') {
        setTenantKey('AiStudyTool');
      }
      getPromptsData();
      Framework.getEventManager().on(constants.SET_ADMIN_PROMPTS_UPDATED_DATA, () => {
        getPromptsData();
      });
    }
  }, [userToken]);

  const sorting = (value, archivedStatus) => {
    const sortData = archivedStatus ? archieveData : promptsData;
    const promptArrow = ['promptStart', 'promptEnd'].includes(value);
    const dateFieldMatch = ['createdDate', 'updatedDate'].includes(value);
    if (order === 'ASC' && !promptArrow) {
      let sorted;
      if (dateFieldMatch) {
        sorted = [...sortData].sort((a, b) => a[value] && b[value] && new Date(a[value]) - new Date(b[value]));
      } else {
        sorted = [...sortData].sort((a, b) =>
          a[value] && b[value] && a[value].toLowerCase() > b[value].toLowerCase() ? 1 : -1
        );
      }
      if (archivedStatus) {
        setArchievedData(sorted);
      } else {
        setPromptsData(sorted);
      }
      setOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC' && !promptArrow) {
      let sorted;
      if (dateFieldMatch) {
        sorted = [...sortData].sort((a, b) => a[value] && b[value] && new Date(b[value]) - new Date(a[value]));
      } else {
        sorted = [...sortData].sort((a, b) =>
          a[value] && b[value] && a[value].toLowerCase() < b[value].toLowerCase() ? 1 : -1
        );
      }
      if (archivedStatus) {
        setArchievedData(sorted);
      } else {
        setPromptsData(sorted);
      }
      setOrder('ASC');
      setDirectionValue(value);
    }
  };

  const onColumnClick = (val, archivedStatus) => {
    sorting(val, archivedStatus);
  };

  const changeToRawText = (promptVal) => {
    const { promptStart, promptEnd } = promptVal;
    const promptStartRawText = promptStart?.map((item) => {
      return { ...item, content: item.content?.replaceAll(/\n/g, '\\n') };
    });
    const promptEndRawText = promptEnd?.map((item) => {
      return { ...item, content: item.content?.replaceAll(/\n/g, '\\n') };
    });
    return { ...promptVal, promptStart: promptStartRawText, promptEnd: promptEndRawText };
  };

  const onRowClick = (val) => {
    setIsEdit(true);
    setOpenDialog(true);
    setSelectedValue(changeToRawText(val));
  };

  const handleClose = () => {
    setOpenDialog(false);
    // getPromptsData();
  };

  // const handlePromptDialog = () => {
  //   setOpenDialog(false);
  // }

  const handleAddNewPrompts = () => {
    setOpenDialog(true);
    setSelectedValue({});
    setIsEdit(false);
  };

  const handleTextFieldHandler = (e) => {
    e.preventDefault();
    setItemName(e.target.value);
  };

  const handleArchivedclick = (event, item) => {
    setArchiveDialogContent('');
    setRowItem(item);
    setIsDialogboxOpen(true);
    event.stopPropagation();
  };

  const handleArchivedClose = () => {
    setIsDialogboxOpen(false);
    setItemName('');
    // getPromptsData();
  };

  const getNextVersion = (versionValue) => {
    const regex = /^(\d+)\.(\d+)\.(\d+)$/;
    const match = regex.exec(versionValue);
    const majorVersion = parseFloat(match[1]);
    const minorVersion = parseFloat(match[2]);
    const patchVersion = parseFloat(match[3]);
    return `${majorVersion}.${minorVersion}.${patchVersion + 1}`;
  };

  const handleClone = async (event, item) => {
    if (item.version) {
      const versionData = [...promptsData, ...archieveData]
        .filter((val) => val.type === item.type)
        .map((d) => ({
          version: d.version
        }));
      const sortedVersionData = versionData.sort((a, b) => {
        const lowNumArr = a.version.split('.').map(Number);
        const highNumArr = b.version.split('.').map(Number);
        /* eslint-disable-next-line */
        for (let i = 0; i < lowNumArr.length; i++) {
          if (lowNumArr[i] !== highNumArr[i]) {
            return highNumArr[i] - lowNumArr[i];
          }
        }
        return 0;
      });
      const payload = {
        type: item.type && item.type.toUpperCase(),
        status: constants.PROMPT_DRAFT_STATUS,
        version: getNextVersion(sortedVersionData[0].version),
        tenantId: tenantKey === 'AiStudyTool' ? constants.AI_STUDY_TOOLS_TENANT_ID : constants.CHANNELS_TENANT_ID,
        tenantName: tenantKey,
        promptStart: item.promptStart,
        promptEnd: item.promptEnd
      };
      await chatgptAdminPrompts.saveAdminPromptsData(payload);
    }
    event.stopPropagation();
    handleClose();
  };

  const handleConfirmAction = async (confirmed) => {
    if (confirmed && itemName) {
      const payload = {
        ...rowItem,
        type: rowItem.type && rowItem.type.toUpperCase(),
        status: 'LIVE',
        comments: itemName
      };
      await chatgptAdminPrompts.updateAdminPromptsData(payload);
    }
  };
  return (
    <>
      <LoadingHandler
        loading={
          // promptsData.length === 0 ||
          apiLoading ||
          ChatGptGetAdminPromptsStatus.isPending ||
          ChatGptUtilityAddAdminStatus.isPending ||
          ChatGptEditAdminPromptsStatus.isPending
        }
        loadingContent={<Loader />}
        content={
          (promptsData.length > 0 || archieveData.length > 0) && (
            <Paper style={{ padding: '10px' }}>
              <Grid container spacing={2} style={{ flexDirection: 'column' }}>
                <Box display="flex" style={{ justifyContent: 'flex-end', marginTop: '20px', marginRight: '30px' }}>
                  <TooltipCustom
                    title={
                      !permissionsList.includes('admin_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('admin.ADD_NEW_PROMPT')} />
                      )
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddNewPrompts}
                        disabled={!permissionsList.includes('admin_can_edit')}
                        className={classes.button}
                      >
                        Add New Prompt
                      </Button>
                    </span>
                  </TooltipCustom>
                </Box>
              </Grid>
              <Box sx={{ p: 1 }}>
                <Typography variant="h5" align="center" gutterBottom spacing={9}>
                  Prompt
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <TableComponent
                  columns={ColumnConfig.AdminPromptsDataGridColumn}
                  data={promptsData}
                  onColumnClick={onColumnClick}
                  order={order}
                  onRowClick={onRowClick}
                  directionValue={directionValue}
                  userEmailDetails=""
                  onMouseEnter={() => {}}
                  showCloneIcon={showCloneIcon}
                  handleClone={handleClone}
                  disableRepublish={!permissionsList.includes('admin_can_edit')}
                />
              </Box>
              <br />
              <Box sx={{ p: 1 }}>
                <Typography variant="h5" align="center" gutterBottom spacing={9}>
                  Archived
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <TableComponent
                  columns={ColumnConfig.AdminPromptsDataGridColumn}
                  data={archieveData}
                  onColumnClick={onColumnClick}
                  order={order}
                  onRowClick={onRowClick}
                  directionValue={directionValue}
                  userEmailDetails=""
                  onMouseEnter={() => {}}
                  archived={archived}
                  handleArchivedclick={handleArchivedclick}
                  disableRepublish={!permissionsList.includes('admin_can_edit')}
                />
              </Box>
            </Paper>
          )
        }
      />
      {openDialog && (
        <AdminPromptDialog
          openDialog={openDialog}
          handleClose={handleClose}
          selectedValue={selectedValue}
          isEdit={isEdit}
          tenantKey={tenantKey}
          permissionsList={permissionsList}
          language={language}
        />
      )}
      {isDialogboxOpen && (
        <DialogBox
          open={isDialogboxOpen}
          handleClose={handleArchivedClose}
          title={constants.ADMIN_PROMPT_RESTORE_DIALOG_TEXT}
          content={archiveDialogContent}
          handleConfirm={handleConfirmAction}
          handleTextFieldHandler={handleTextFieldHandler}
          itemName={itemName}
        />
      )}
    </>
  );
};

AdminPromptComponent.propTypes = {
  chatgptAdminPrompts: shapes.modelOf(chatGptAdminPromptsData).isRequired,
  ChatGptGetAdminPromptsStatus: shapes.state.isRequired,
  ChatGptUtilityAddAdminStatus: shapes.state.isRequired,
  ChatGptEditAdminPromptsStatus: shapes.state.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  language: shapes.language.isRequired
};

export default observer(
  inject(
    'chatgptAdminPrompts',
    'ChatGptGetAdminPromptsStatus',
    'ChatGptUtilityAddAdminStatus',
    'ChatGptEditAdminPromptsStatus',
    'userPermissions',
    'language'
  )(AdminPromptComponent)
);
