/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { shapes } from '@greenville/framework';
import { Route, Redirect } from 'react-router-dom';
import Books from './models/Books';
import Header from '../../common/header/Header';
import BookDetail from './components/detail/BookDetail';
import MetaData from './components/metadata/MetaData';
import Basepath from './components/specifybasepath/Basepath';
import MediaBasepaths from './models/MediaBasepaths';
import CustomIcon from './components/icon/CustomIcon';
import InterfaceModel from './models/InterfaceModel';
import InterfaceColors from './components/colors/InterfaceColors';
import ValidatePermissions from '../users/ValidatePermissions';
import BooksComponent from './components/list/BooksComponent';
import CustomIcons from './models/CustomIcons';

const styles = () => ({
  root: {
    flexGrow: 1
  },
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80
  }
});

@inject('booksState', 'basepath', 'customIcon', 'colorInterface', 'mediaBasepathStatus', 'colorLanguageStatus')
@observer
class BookContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    booksState: shapes.modelOf(Books).isRequired,
    basepath: shapes.modelOf(MediaBasepaths).isRequired,
    colorInterface: shapes.modelOf(InterfaceModel).isRequired,
    customIcon: shapes.modelOf(CustomIcons).isRequired,
    match: PropTypes.object.isRequired,
    mediaBasepathStatus: shapes.state.isRequired,
    colorLanguageStatus: shapes.state.isRequired
    // booksStatus: shapes.state.isRequired
  };

  constructor(props) {
    super(props);
    this.bookSearch = this.bookSearch.bind(this);
    this.bookFavorite = this.bookFavorite.bind(this);
    this.state = {
      on: false,
      filter: {
        name: '',
        value: ''
      }
    };

    this.addauthorsRow = this.addauthorsRow.bind(this);
    this.removeauthorsRow = this.removeauthorsRow.bind(this);
    this.editMetadata = this.editMetadata.bind(this);
    this.editSwfdata = this.editSwfdata.bind(this);
  }

  componentDidMount() {
    const defaultSearch = {
      search: {
        isFavorite: true
      }
    };
    const { booksState } = this.props;
    booksState.fetch(defaultSearch);
    // asset.fetch(match.params.bookid);
  }

  toggleview = () => {
    const { on } = this.state;
    this.setState({
      on: !on
    });
  };

  addauthorsRow(item) {
    const { booksState } = this.props;
    booksState.bookMetadata.addauthorsRow(item);
  }

  removeauthorsRow(item) {
    const { booksState } = this.props;
    booksState.bookMetadata.removeauthorsRow(item);
  }

  editMetadata(data, type) {
    const { booksState } = this.props;
    booksState.bookMetadata.editMetadata(data, type);
  }

  editSwfdata(data, type) {
    const { basepath } = this.props;
    basepath.editSwfdata(data, type);
  }

  bookSearch(searchFilter, filter) {
    const { booksState } = this.props;
    booksState.fetch(searchFilter);
    this.setState({
      filter
    });
  }

  bookFavorite(favorite, window) {
    const { booksState } = this.props;
    booksState.updateBookFavorite(favorite, window);
  }

  render() {
    const { classes } = this.props;
    const { on, filter } = this.state;

    return (
      <>
        <Header />

        <main className={classes.contentWrapper} id="mainContent" role="main">
          <Container maxWidth="xl">
            <Route exact path="/books/">
              <Redirect to="/books" />
            </Route>

            <Route
              path="/books"
              exact
              render={() => (
                <ValidatePermissions givenPermissions="authoring_can_view">
                  <BooksComponent
                    toggle={on}
                    toggleView={this.toggleview}
                    bookSearch={this.bookSearch}
                    bookFavorite={this.bookFavorite}
                    appliedFilter={filter}
                  />
                </ValidatePermissions>
              )}
            />

            <Route
              path="/books/:bookid"
              exact
              render={() => (
                <ValidatePermissions givenPermissions="authoring_can_view">
                  <BookDetail {...this.props} bookFavorite={this.bookFavorite} />
                </ValidatePermissions>
              )}
            />

            <Route
              path="/books/:bookid/edit"
              exact
              render={() => (
                <ValidatePermissions givenPermissions="authoring_can_view">
                  <MetaData
                    {...this.props}
                    addauthorsRow={this.addauthorsRow}
                    removeauthorsRow={this.removeauthorsRow}
                    editMetadata={this.editMetadata}
                  />
                </ValidatePermissions>
              )}
            />

            <Route
              path="/books/:bookid/basepath"
              exact
              render={() => (
                <ValidatePermissions givenPermissions="authoring_can_view">
                  <Basepath {...this.props} editSwfdata={this.editSwfdata} />{' '}
                </ValidatePermissions>
              )}
            />

            <Route
              path="/books/:bookid/interfacecolors"
              exact
              render={() => (
                <ValidatePermissions givenPermissions="authoring_can_view">
                  <InterfaceColors
                    {...this.props}
                    // editSwfdata={this.editSwfdata}
                  />
                </ValidatePermissions>
              )}
            />

            {/* <Route path="/books/:bookid/customicon" exact render={() => <CustomIcon {...this.props} />} /> */}
            {/* <Route path="/books/:bookid/interfacecolors" exact render={() => <InterfaceColors {...this.props} />} /> */}
            <Route
              path="/books/:bookid/customicon"
              exact
              render={() => (
                <ValidatePermissions givenPermissions="authoring_can_view">
                  {' '}
                  <CustomIcon {...this.props} />{' '}
                </ValidatePermissions>
              )}
            />
          </Container>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(BookContainer);
