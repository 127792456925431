/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { shapes } from '@greenville/framework';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  Dialog,
  IconButton,
  Slide,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = (theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    flex: 1,
    textAlign: 'left'
  },
  grid: {
    width: '85%',
    marginLeft: theme.spacing(3)
  },
  textFields: {
    height: 31,
    fontSize: 12,
    width: '30ch'
  },
  textfieldInput: {
    height: 11,
    fontSize: '14px'
  },
  selectfieldInput: {
    height: 31,
    fontSize: 14,
    width: '26ch'
  },
  divider: {
    margin: '0px 24px 12px 24px'
  },
  fontClass: {
    display: 'inline-flex'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  dialogPaper: {
    maxWidth: '80%'
  }
});

const TableHeaderCell = withStyles(() => ({
  root: {
    fontSize: 14,
    fontWeight: 600
  }
}))(TableCell);

const rows = [
  {
    authors: 'View',
    user: <CheckCircleOutlineIcon style={{ color: '#005d83' }} />,
    ingestion: '-',
    authoring: <CheckCircleOutlineIcon style={{ color: '#005d83' }} />,
    reports: '-',
    eText: <CheckCircleOutlineIcon style={{ color: '#005d83' }} />
  },
  {
    authors: 'Edit',
    user: <CheckCircleOutlineIcon style={{ color: '#005d83' }} />,
    ingestion: '-',
    authoring: <CheckCircleOutlineIcon style={{ color: '#005d83' }} />,
    reports: '-',
    eText: <CheckCircleOutlineIcon style={{ color: '#005d83' }} />
  },
  {
    authors: 'Delete',
    user: <CheckCircleOutlineIcon style={{ color: '#005d83' }} />,
    ingestion: '-',
    authoring: <CheckCircleOutlineIcon style={{ color: '#005d83' }} />,
    reports: '-',
    eText: <CheckCircleOutlineIcon style={{ color: '#005d83' }} />
  }
];

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class UserPrivilege extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    closeUserPrivilegeDialog: PropTypes.func.isRequired,
    closeUploadContent: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      openDialog: true
    };
  }

  handleClose = () => {
    const { closeUserPrivilegeDialog } = this.props;
    closeUserPrivilegeDialog();
  };

  render() {
    const { classes, language, closeUploadContent } = this.props;
    const { openDialog } = this.state;

    return (
      <>
        <Dialog
          fullWidth="true"
          classes={{ paper: classes.dialogPaper }}
          id="alert-dialog-title"
          open={openDialog}
          onClose={closeUploadContent}
          TransitionComponent={Transition}
        >
          <DialogTitle align="center" onClose={closeUploadContent}>
            <Typography align="center" variant="h3">
              {' '}
              <FormattedMessage {...language.getText('user.ROLES')} />
            </Typography>
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography gutterBottom variant="h6">
                <FormattedMessage {...language.getText('user.ROLENAME')} />
                <Typography style={{ fontSize: 14 }} variant="caption">
                  Admin
                </Typography>
              </Typography>
              <br />
              <Typography gutterBottom variant="h6">
                <FormattedMessage {...language.getText('user.DESC')} />
                <Typography variant="caption" style={{ fontSize: 14 }}>
                  <FormattedMessage {...language.getText('user.ADMIN_TEXT')} />
                </Typography>
              </Typography>
              <br />
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableHeaderCell variant="h6" align="center">
                        <FormattedMessage {...language.getText('user.PERMISSION')} />
                      </TableHeaderCell>
                      <TableHeaderCell size="medium" align="center">
                        <FormattedMessage {...language.getText('user.ETEXT_PROJECT')} />
                      </TableHeaderCell>
                      <TableHeaderCell size="small" align="center">
                        <FormattedMessage {...language.getText('dashboard.CM_LABEL')} />
                      </TableHeaderCell>
                      <TableHeaderCell align="center">
                        <FormattedMessage {...language.getText('user.PREVIEW')} />
                      </TableHeaderCell>
                      <TableHeaderCell align="center">
                        <FormattedMessage {...language.getText('user.REPORTS')} />
                      </TableHeaderCell>
                      <TableHeaderCell align="center" variant="head">
                        <FormattedMessage {...language.getText('user.USER_MANAGEMENT')} />
                      </TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row" align="center">
                          <Typography variant="h6">{row.authors}</Typography>
                        </TableCell>
                        <TableCell align="center">{row.user}</TableCell>
                        <TableCell align="center">{row.ingestion}</TableCell>
                        <TableCell align="center">{row.authoring}</TableCell>
                        <TableCell align="center">{row.reports}</TableCell>
                        <TableCell align="center">{row.eText}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(UserPrivilege));
