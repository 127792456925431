import React from 'react';
import ValidatePermissions from '../../../users/ValidatePermissions';
import AdminPromptComponent from './AdminPromptComponent';

/* eslint-disable no-shadow */
function AdminPromptView() {
  const showDashboardEnabled = false;
  return (
    <>
      <ValidatePermissions givenPermissions="admin_can_view" showDashboardEnabled={showDashboardEnabled}>
        <AdminPromptComponent />
      </ValidatePermissions>
    </>
  );
}

AdminPromptView.propTypes = {};

AdminPromptView.defaultProps = {};

export default AdminPromptView;
