/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles, Box } from '@material-ui/core';
import { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import { Switch, Route, Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Header from '../../common/header/Header';
import Request from './models/Request';
import RequestQueue from './components/request/RequestQueue';
import ConvergenceForm from './components/convergence/ConvergenceForm';
import RevisionForm from './components/revision/RevisionForm';
import Breadcrumbs from '../books/components/commonitems/Breadcrumbs';
import ValidatePermissions from '../users/ValidatePermissions';
// import RequestDetail from './components/details/RequestDetail';
// import StandardEbook from './components/create/StandardEbook';
// import Workflow from './models/Workflow';
// import Updf from './components/pdf/Updf';
// import TitleAction from './components/title/TitleAction';

const styles = () => ({
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80
  }
});

@inject('workflowSearchStatus', 'workflowHistoryStatus')
@observer
class EtextContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    request: shapes.modelOf(Request).isRequired,
    match: PropTypes.object.isRequired,
    language: shapes.language.isRequired
  };

  constructor(props) {
    super(props);
    const { language } = this.props;
    this.state = {
      tabValue: 0,
      tabState: <FormattedMessage {...language.getText('eTextgeneral.REQUEST')} />
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    // workflow.fetch();
    const tabUrl = match.path.split('/').pop();
    if (tabUrl === 'request') {
      this.setState({
        tabValue: 1
      });
    } else if (tabUrl === 'revision') {
      this.setState({
        tabValue: 2
      });
    }
  }

  handleTabChange = (e, value) => {
    const { language } = this.props;
    this.setState({
      tabValue: value
    });
    if (value === 0) {
      this.setState({
        tabState: <FormattedMessage {...language.getText('eTextgeneral.REQUEST')} />
      });
    }
    if (value === 1) {
      this.setState({
        tabState: <FormattedMessage {...language.getText('eTextgeneral.NEW')} />
      });
    }
    if (value === 2) {
      this.setState({
        tabState: <FormattedMessage {...language.getText('eTextgeneral.REVISION')} />
      });
    }
  };

  render() {
    const { classes, match, language } = this.props;
    const { tabValue, tabState } = this.state;

    // const tabUrl = match.path.split('/').pop();
    // if (tabUrl === 'request') {
    //   this.setState({
    //     tabValue: 0
    //   });
    // }
    // this.handleTabChange();

    return (
      <>
        <Header />

        <main className={classes.contentWrapper}>
          <ValidatePermissions givenPermissions="etext_can_view">
            <Box ml={3}>
              <Breadcrumbs
                subData={tabState}
                data={<FormattedMessage {...language.getText('label.REQUEST')} />}
                id={match.params.bookid}
              />
              <br />
            </Box>

            <ValidatePermissions givenPermissions="etext_can_view">
              <Paper elevation={3}>
                <AppBar position="static" color="default">
                  <Tabs
                    // value={0}
                    value={tabValue}
                    onChange={(e, data) => this.handleTabChange(e, data)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="Workflow Status Tabs"
                  >
                    <Tab
                      label={<FormattedMessage {...language.getText('eTextgeneral.REQUEST')} />}
                      component={Link}
                      to="/etext/conversion/workflow"
                    />
                    <Tab
                      label={<FormattedMessage {...language.getText('eTextgeneral.NEW')} />}
                      component={Link}
                      to="/etext/conversion/request"
                    />
                    <Tab
                      label={<FormattedMessage {...language.getText('eTextgeneral.REVISION')} />}
                      component={Link}
                      to="/etext/conversion/revision"
                    />
                  </Tabs>
                </AppBar>
              </Paper>{' '}
            </ValidatePermissions>

            <Switch>
              <Route
                path="/etext/conversion/workflow"
                exact
                render={() => (
                  <ValidatePermissions givenPermissions="etext_can_view">
                    <RequestQueue {...this.props} handleTabChange={this.handleTabChange} />{' '}
                  </ValidatePermissions>
                )}
              />

              <Route
                path="/etext/conversion/request"
                exact
                render={() => (
                  <ValidatePermissions givenPermissions="etext_can_view">
                    <ConvergenceForm {...this.props} />{' '}
                  </ValidatePermissions>
                )}
              />

              <Route
                path="/etext/conversion/revision"
                exact
                render={() => (
                  <ValidatePermissions givenPermissions="etext_can_view">
                    <RevisionForm {...this.props} />{' '}
                  </ValidatePermissions>
                )}
              />
            </Switch>
          </ValidatePermissions>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(observer(EtextContainer)));
