/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 *
 * @author Vignesh Selvam
 */

const Children = types.model('Children', {
  title: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  id: types.maybeNull(types.union(types.string, types.integer, types.number)),
  uri: types.maybeNull(types.union(types.string, types.integer, types.number)),
  href: types.maybeNull(types.union(types.string, types.integer, types.number)),
  playOrder: types.maybeNull(types.union(types.string, types.integer, types.number)),
  sectionType: types.maybeNull(types.string),
  children: types.array(types.late(() => Children)),
  pageno: types.maybeNull(types.union(types.string, types.integer, types.number)),
  linkTypeID: types.maybeNull(types.union(types.string, types.integer, types.number)),
  regionTypeID: types.maybeNull(types.union(types.string, types.integer, types.number)),
  role: types.maybeNull(types.string),
  optional: types.maybeNull(types.union(types.string, types.boolean)), // TODO Remove union for optional
  versionId: types.maybeNull(types.string),
  parentId: types.maybeNull(types.string),
  parentVersionId: types.maybeNull(types.string),
  usageType: types.maybeNull(types.string),
  chapterId: types.maybeNull(types.string),
  parentItemId: types.maybeNull(types.string),
  level: types.maybeNull(types.union(types.string, types.number))
});

/**
 * A mobx model for asset slates
 *
 */
const Slates = types.model('Slates', {
  chapterUri: types.maybeNull(types.string),
  parentUri: types.maybeNull(types.string),
  moduleUri: types.maybeNull(types.string),
  pageno: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for pageno
  optional: types.maybeNull(types.union(types.string, types.boolean)), // TODO Remove union for optional
  id: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for id
  type: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  uri: types.maybeNull(types.string),
  playOrder: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for playorder
  parentid: types.maybeNull(types.union(types.string, types.integer, types.number)),
  chapterId: types.maybeNull(types.string),
  chapterTitle: types.maybeNull(types.string)
});

const AssetTOC = types
  .model('AssetTOC', {
    id: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    children: types.array(types.compose(Children)),
    slates: types.maybeNull(types.array(Slates))
  })
  .actions((self) => ({
    fetch(bookId) {
      Framework.getEventManager().publish(constants.ASSET_SYNC_REQUESTED, { bookId });
    },
    set(asset) {
      applySnapshot(self, asset);
    },
    getTocJSON() {
      const jsonObj = {
        content: {
          mainTitle: self.title,
          thumbnail:
            'https://images.contentful.com/tbx6i45kvpo5/28WYeWCc3aauKkWkiYaua2/489832c2bdb06b7245479e887ccfea06/cite_elements_cover',
          list: self.getTocAndAudioContents(self.children).tableOfContents.list
        }
      };
      return jsonObj;
    },
    getTocAndAudioContents(tocChildren, bookId, courseId, isPdfContent = false, languages = {}) {
      const audioContents = [];
      const contents = [];
      let audio = null;
      let assessmentId = '';
      // if book id is empty then course id will be used.
      const id = bookId || courseId;
      let parentId = '';
      let titleAudio = null;

      function drillToDeep(item, chapterId) {
        const node = item;
        const href = node.href || node.uri || '';
        let hrefUri = href;

        if (['assessment', 'tdx', 'cite-assessment'].indexOf(node.type) !== -1) {
          assessmentId = node.uri;
        }
        if (!isPdfContent) {
          const index = href && href.indexOf('/OPS/');

          hrefUri = index >= 0 ? href.substr(index, href.length) : href;
        }
        // Custom Basket : Makes as empty when href comes as "undefined"
        if (hrefUri === 'undefined') {
          hrefUri = '';
        }
        if (node.audio && node.audio.length >= 1) {
          audio = [
            {
              audioIndex: '0',
              id: node.id,
              title: node.title,
              parentId,
              chapterId,
              src: node.audio,
              audioMetadata: node.audioTTSMetadata && node.audioTTSMetadata
            }
          ];
        }
        if (audio && audio.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          titleAudio = audio[0];
        } else {
          titleAudio = null;
        }

        // Audio duplicate issue fix
        if (node.children && node.children.length) {
          const childList = [];
          node.children.forEach((child) => {
            const childHref = child.href || child.uri || '';
            if (childHref && childHref.includes('#')) {
              childList.push(childHref.split('#')[0]);
            }
          });
          if (childList.includes(href)) {
            titleAudio = null;
          }
        }
        // End

        const list = {
          assessmentId,
          audio: titleAudio,
          chapterId,
          children: [],
          coPage: node.coPage === 'true',
          display: node.display !== false,
          hidden: node.optional === 'true',
          href: hrefUri,
          id: node.id,
          label: node.level ? String(node.level) : '',
          parentItemId: parentId,
          parentId,
          playOrder: node.playOrder,
          chapterNumber: node.playOrder,
          title: node.title || node.label,
          type: node.type || '',
          urn: node.id || node.path,
          // PDF attributes
          linkTypeId: node.linkTypeId || '',
          linkUri: node.linkUri || '',
          linkX: node.linkX || '',
          linkY: node.linkY || '',
          name: node.name || '',
          regionTypeId: node.regionTypeId || '',
          sectionType: node.sectionType || '',
          contentChapterId: isPdfContent && node.chapterId ? node.chapterId : '',
          level: node.level || '',
          actualPageUri: node.actualPageUri || ''
        };

        if (audio && audio.length > 1) {
          audio.forEach((audioTrack, index) => {
            list.children.push({
              assessmentId,
              audio: audioTrack,
              chapterId,
              children: [],
              coPage: false,
              display: false,
              hidden: false,
              href: '',
              id: `${node.id}&&${index}`, // Added && to differentiate from numerical page ids
              label: node.level ? String(node.level + 1) : '',
              parentItemId: node.id,
              playOrder: '',
              title: `${languages.getText('audio')} ${index + 1}`,
              type: '',
              urn: `${node.id}&&${index}` // Added && to differentiate from numerical page ids
            });
          });
        }

        if (node.level === 1 && node.optional === 'true') {
          list.optional = node.optional;
        }

        if (audio && audio.length) {
          audio.forEach((audioObj) => {
            // Objects make to find next track for autoplay
            const audioInfo = {
              ...audioObj,
              isPlaying: false
            };

            audioContents.push(audioInfo);
          });
        }
        const children = node.items || node.children || node.content;

        if (children) {
          children.forEach((child) => {
            // Code removed because we discarded part in response
            parentId = list.id; // assigned here for get revel cite child id by parent
            const audioArr = [];
            if (child.audio && child.audio.length >= 1) {
              audioArr.push({
                audioIndex: '0',
                id: child.id,
                title: child.title,
                parentId,
                chapterId,
                src: child.audio,
                audioMetadata: child.audioTTSMetadata && child.audioTTSMetadata
              });
            }
            if (node.level >= 5) {
              list.audio = list.audio && list.audio.src ? list.audio : audioArr;
            }
            // eslint-disable-next-line no-param-reassign
            child.level = node.level + 1;
            if (child.uri || child.children || child.items) {
              list.children.push(drillToDeep(child, chapterId));
            }
          });
        }
        parentId = '';
        audio = null;

        return list;
      }
      if (tocChildren) {
        tocChildren.forEach((node) => {
          if (!node.part) {
            // eslint-disable-next-line no-param-reassign
            node.level = 1;
            const nodes = self.isAudioAvailableInChapter(drillToDeep(node, node.id));
            contents.push(nodes);
          }
        });
      }
      const optionalList = [];
      let tocList = contents.filter((chapter) => {
        if (chapter.optional === 'true') {
          optionalList.push(chapter);
        }

        return !chapter.optional;
      });

      tocList = Array.prototype.concat.apply(tocList, optionalList);
      const tableOfContents = {
        id,
        list: tocList
      };

      return {
        audioContents,
        tableOfContents
      };
    },
    isAudioAvailableInChapter(node) {
      const drillToDeepAudio = (tocNode) => {
        if (tocNode.children) {
          return tocNode.children.find((item) => {
            if (item.audio) {
              return item.audio;
            }
            return drillToDeepAudio(item);
          });
        }
        return null;
      };
      const audio = drillToDeepAudio(node);

      // eslint-disable-next-line no-param-reassign
      node.isAudioAvailableInChapter = Boolean(audio);

      return node;
    },
    getPlaylist() {
      return toJS(self.slates);
    }
  }));

export default AssetTOC;
