/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import utils from '../../../../common/utils';
import * as constants from '../../../../common/constants';

export default class NewRevisionUpdate extends BaseService {
  constructor() {
    super(constants.ETEXT_REVISION_UPDATE_POST, 'newRevisionUpdateStatus');
  }

  handleEvent(eventName, event) {
    return this.getClient('evergreen').post('/etextproject/update/revisionform', event.payload.data.dataRevision);
  }

  async handleResponse(response) {
    // eslint-disable-line class-methods-use-this
    // Framework.getStoreRegistry().getStore('cmmetaData').setDirectory(response.data);
    if (response.data.status === 'success') {
      const notification = {
        open: true,
        message: 'Revision form Updated successfully!',
        type: 'info'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
      Framework.getEventManager().publish(constants.ETEXT_DB_UPDATE_REVISION, {});
      Framework.redirectTo('/etext/conversion/workflow');
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
      if (status === 500 && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
  }
}
