/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import CloseIcon from '@material-ui/icons/Close';
import { toJS } from 'mobx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  Paper,
  Select,
  Slide,
  TextField,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { shapes } from '@greenville/framework';
import Draggable from 'react-draggable';
import DialogConfig from '../../../common/config/DialogConfig';
import ConfirmationDialog from '../../../common/components/ConfirmationDialog/Confirmation';
import UsersPermissions from '../../users/models/UsersPermissions';
import TooltipCustom from '../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: 'rgb(242 242 242)'
    }
  },
  appBar: {
    position: 'relative'
  },
  dialogHead: {
    padding: '10px 0'
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  title: {
    flex: 1,
    textAlign: 'end',
    marginRight: '30px'
  },
  itemControl: {
    marginLeft: theme.spacing(2),
    minWidth: 150,
    alignItems: 'center',
    textAlign: 'left'
  },
  textfieldRight: {
    marginRight: theme.spacing(1)
  },
  coordinatesLabel: {
    margin: '0 4px 0 23px',
    fontWeight: 'bold'
  },
  coordinatesInput: {
    width: '6ch',
    fontSize: 12
  },
  incDec: {
    width: '0',
    padding: '0 !important',
    minWidth: '30px',
    '& span': {
      minWidth: '30px'
    }
  },
  gridWidth: {
    width: 100
  },
  selectWidth: {
    height: 31,
    fontSize: 12,
    width: '100%'
  },
  textfieldInput: {
    height: 11,
    fontSize: '12px'
  },
  textFields: {
    width: '6.0ch',
    marginRight: 2
  },
  textnameInput: {
    height: 12,
    fontSize: 12
  },
  coordinateFont: {
    fontSize: 12,
    padding: '4px',
    textAlign: 'center'
  },
  customInput: {
    padding: '5px',
    textAlign: 'center',
    fontSize: 12
  },
  closeBtn: {
    padding: 0,
    margin: '0 10px !important'
  },
  closeText: {
    fontSize: '10px',
    fontStyle: 'italic',
    cursor: 'inherit',
    textAlign: 'right',
    lineHeight: 2
  },
  moveText: {
    fontSize: '11px',
    fontStyle: 'italic',
    marginRight: 'auto'
  },
  accordionDetails: {
    padding: '0 32px 22px'
  },
  accordionContainer: {
    padding: 12,
    backgroundColor: '#f5f5f5'
  },
  accordion: {
    minHeight: 48
  },
  actions: {
    margin: '0 6px 6px !important'
  },
  actionBtn: {
    padding: '2px 4px'
  }
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

@inject('userPermissions')
@observer
class HotspotDialog extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    closeEditHotspot: PropTypes.func.isRequired,
    disabledProps: PropTypes.array.isRequired,
    requiredProps: PropTypes.array.isRequired,
    saveHotspot: PropTypes.func.isRequired,
    deleteHotspot: PropTypes.func.isRequired,
    language: shapes.language.isRequired,
    preUpdateHotspot: PropTypes.func.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    const { data, disabledProps, requiredProps } = this.props;
    this.state = {
      openDialog: true,
      positionAccordion: true,
      disabledArr: disabledProps || [],
      requiredArr: requiredProps || [],
      hotspotData: data || {},
      pageHotspotProps: Number(data.iconTypeId) === 1,
      on: false,
      confirmDeleteDialog: false
    };
    this.hasHotspotGuid = data && data.guid != null;
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  changeAccordion = () => {
    const { positionAccordion } = this.state;
    this.setState({ positionAccordion: !positionAccordion });
  };

  handleClickOpen = () => {
    const { openDialog } = this.state;
    this.setState({ openDialog: !openDialog });
  };

  handleClose = () => {
    const { closeEditHotspot } = this.props;
    closeEditHotspot();
  };

  handleSave = () => {
    const { saveHotspot } = this.props;
    const { hotspotData, requiredArr } = this.state;
    const err = this.validateSave(requiredArr);
    if (err) {
      saveHotspot(hotspotData);
    }
  };

  handleDelete = () => {
    this.setState({ confirmDeleteDialog: true });
  };

  handleConfirm = (confirmResponse) => {
    const { deleteHotspot } = this.props;
    const { hotspotData } = this.state;
    this.setState({ confirmDeleteDialog: false });
    if (confirmResponse) {
      const deleteRegionData = {
        deleteRegion: {
          regionId: hotspotData.regionId,
          globalBookId: hotspotData.globalBookId,
          guid: hotspotData.guid,
          pageNumber: hotspotData.page,
          linkValue: hotspotData.linkUri
        }
      };
      deleteHotspot(deleteRegionData);
    }
  };

  handleChange = (e) => {
    const { hotspotData, on } = this.state;
    const { preUpdateHotspot } = this.props;
    let typeValue = null;
    if (hotspotData[e.target.name] === '0') {
      this.setState({
        on: !on
      });
    } else {
      this.setState({
        on: false
      });
    }
    let updatedObject = {};
    const { intProperties } = DialogConfig;
    if (intProperties.includes(e.target.name)) {
      const intValue = Number.isNaN(Number(e.target.value)) ? 0 : Number(e.target.value);
      updatedObject = {
        [e.target.name]: intValue
      };
    } else if (e.target.name === 'linkTypeId') {
      typeValue = this.getTypeValues(e.target.value);
      if (typeValue) {
        if (e.target.value === '11' || e.target.value === 11) {
          updatedObject = {
            regionTypeId: typeValue[0].id.toString(),
            [e.target.name]: e.target.value,
            platformId: hotspotData.platformId ? hotspotData.platformId : DialogConfig.platform[0].id
          };
        } else {
          updatedObject = {
            regionTypeId: typeValue[0].id.toString(),
            [e.target.name]: e.target.value
          };
        }
      } else {
        updatedObject = {
          regionTypeId: DialogConfig.linkTypeDropDown[0].id.toString(),
          [e.target.name]: e.target.value
        };
      }
    } else {
      updatedObject = {
        [e.target.name]: e.target.value
      };
    }

    const updatedHotspotObj = { ...hotspotData, ...updatedObject };
    this.setState({
      hotspotData: updatedHotspotObj
    });

    if (e.target.name === 'x' || e.target.name === 'y' || e.target.name === 'height' || e.target.name === 'width') {
      preUpdateHotspot(updatedHotspotObj);
    }

    if (e.target.name === 'linkTypeId') {
      this.handleDisableAndRequired(e.target.value);
    }
  };

  handleCheckbox = (e) => {
    const { hotspotData } = this.state;
    const updatedObject = {
      [e.target.name]: e.target.checked
    };
    this.setState({
      hotspotData: { ...hotspotData, ...updatedObject }
    });
  };

  handleCordinates = (name, value) => {
    const { hotspotData } = this.state;
    const { preUpdateHotspot } = this.props;
    const updatedObject = {
      [name]: value
    };
    const updatedHotspotData = { ...hotspotData, ...updatedObject };
    this.setState({ hotspotData: updatedHotspotData });
    preUpdateHotspot(updatedHotspotData);
  };

  handleDisableAndRequired = (linkTypeID) => {
    let disabled = null;
    let required = null;
    DialogConfig.linkTypeDropDown.forEach((item) => {
      if (item.id === Number(linkTypeID)) {
        disabled = item.disabled;
        required = item.required;
      }
    });
    if (disabled && required) {
      this.setState({ disabledArr: disabled, requiredArr: required });
    }
  };

  setFilterValues = (filterConfig) => filterConfig.map((filter) => <option value={filter.id}>{filter.value}</option>);

  setTypeValues = (value) => {
    const typeValue = this.getTypeValues(value);
    if (typeValue) {
      return typeValue.map((type) => <option value={type.id}>{type.value}</option>);
    }
    return <option value={DialogConfig.linkTypeDropDown[0].id}>{DialogConfig.linkTypeDropDown[0].value}</option>;
  };

  getTypeValues = (value) => {
    let typeValues = null;
    DialogConfig.linkTypeDropDown.forEach((item) => {
      if (item.id === Number(value)) {
        typeValues = item.link;
      }
    });
    return typeValues;
  };

  validateRequired = (value) => {
    const { hotspotData } = this.state;
    return (
      hotspotData[value] === null ||
      hotspotData[value].length === 0 ||
      hotspotData[value].length === '' ||
      hotspotData[value].length === '' ||
      hotspotData[value] === 0 ||
      hotspotData[value] === '0' ||
      hotspotData[value] === '' ||
      hotspotData[value] === ''
    );
  };

  validateSave = (requiredArr) => {
    let bool = true;
    let required = false;
    requiredArr.forEach((array) => {
      required = this.validateRequired(array);
      if (required) {
        bool = false;
      }
    });
    return bool;
  };

  render() {
    const { classes, language, userPermissions } = this.props;
    const { openDialog, disabledArr, requiredArr, hotspotData } = this.state;
    const { pageHotspotProps, confirmDeleteDialog, positionAccordion } = this.state;
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    return (
      <form className={classes.root}>
        {confirmDeleteDialog && (
          <ConfirmationDialog
            message={{ ...language.getText('hotspot.DELETE_CONFIRMATION') }.defaultMessage}
            open
            callback={this.handleConfirm}
          />
        )}
        <Dialog
          fullWidth="true"
          maxWidth="sm"
          open={openDialog}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <AppBar className={classes.appBar} style={{ cursor: 'move' }} id="draggable-dialog-title" color="primary">
            <Grid className={classes.dialogHead} container xs={12}>
              <Grid item xs={8}>
                <Typography variant="h2" style={{ cursor: 'inherit' }} className={classes.title}>
                  <b>
                    <FormattedMessage {...language.getText('hotspot.HOTSPOT_PROPERTIES')} />
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                  <Typography variant="span" className={classes.closeText}>
                    <FormattedMessage {...language.getText('message.CLOSE')} />
                  </Typography>
                  <IconButton
                    color="inherit"
                    className={classes.closeBtn}
                    onClick={this.handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </AppBar>
          <div className={classes.accordionContainer}>
            <Accordion
              className={classes.accordion}
              expanded={positionAccordion}
              onChange={() => this.changeAccordion()}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="position-content"
                id="hotspot-position-id"
              >
                <Typography className={classes.accordionHeading}>
                  <b>
                    <FormattedMessage {...language.getText('hotspot.LABEL_POSITIONING')} />
                  </b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Grid container direction="row" alignItems="center" spacing={3}>
                  <Grid>
                    <Typography className={classes.coordinatesLabel} variant="span" color="textPrimary">
                      <FormattedMessage {...language.getText('hotspot.X')} />
                    </Typography>
                    <ButtonGroup size="small">
                      <Button
                        className={classes.incDec}
                        color="primary"
                        value={hotspotData.x}
                        onClick={() => this.handleCordinates('x', hotspotData.x + 1)}
                      >
                        +
                      </Button>
                      <TextField
                        name="x"
                        className={classes.coordinatesInput}
                        InputProps={{ classes: { input: classes.coordinateFont } }}
                        value={hotspotData.x}
                        onChange={this.handleChange}
                      />
                      <Button
                        className={classes.incDec}
                        color="primary"
                        value={hotspotData.x}
                        onClick={() => this.handleCordinates('x', hotspotData.x - 1)}
                      >
                        -
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid>
                    <Typography className={classes.coordinatesLabel} variant="span" color="textPrimary">
                      <FormattedMessage {...language.getText('hotspot.Y')} />
                    </Typography>
                    <ButtonGroup size="small" aria-label="small outlined button group">
                      <Button
                        className={classes.incDec}
                        color="primary"
                        onClick={() => this.handleCordinates('y', hotspotData.y + 1)}
                      >
                        +
                      </Button>
                      <TextField
                        name="y"
                        className={classes.coordinatesInput}
                        value={hotspotData.y}
                        onChange={this.handleChange}
                        InputProps={{ classes: { input: classes.coordinateFont } }}
                      />
                      <Button
                        className={classes.incDec}
                        color="primary"
                        onClick={() => this.handleCordinates('y', hotspotData.y - 1)}
                      >
                        -
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid>
                    <Typography className={classes.coordinatesLabel} variant="span" color="textPrimary">
                      <FormattedMessage {...language.getText('hotspot.W')} />
                    </Typography>
                    <ButtonGroup disabled={!pageHotspotProps} size="small" aria-label="small outlined button group">
                      <Button
                        className={classes.incDec}
                        color="primary"
                        onClick={() => this.handleCordinates('width', hotspotData.width + 1)}
                      >
                        +
                      </Button>
                      <TextField
                        name="width"
                        className={classes.coordinatesInput}
                        value={hotspotData.width}
                        onChange={this.handleChange}
                        InputProps={{ classes: { input: classes.coordinateFont } }}
                      />
                      <Button
                        className={classes.incDec}
                        color="primary"
                        onClick={() => this.handleCordinates('width', hotspotData.width - 1)}
                      >
                        -
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid>
                    <Typography className={classes.coordinatesLabel} variant="span" color="textPrimary">
                      <FormattedMessage {...language.getText('hotspot.H')} />
                    </Typography>
                    <ButtonGroup disabled={!pageHotspotProps} size="small" aria-label="small outlined button group">
                      <Button
                        className={classes.incDec}
                        color="primary"
                        onClick={() => this.handleCordinates('height', hotspotData.height + 1)}
                      >
                        +
                      </Button>
                      <TextField
                        name="height"
                        size="small"
                        className={classes.coordinatesInput}
                        value={hotspotData.height}
                        onChange={this.handleChange}
                        InputProps={{ classes: { input: classes.coordinateFont } }}
                      />
                      <Button
                        className={classes.incDec}
                        color="primary"
                        onClick={() => this.handleCordinates('height', hotspotData.height - 1)}
                      >
                        -
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="metadata-content"
                id="hotspot-metadata-id"
              >
                <Typography className={classes.accordionHeading}>
                  <b>
                    <FormattedMessage {...language.getText('hotspot.LABEL_METADATA')} />
                  </b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Grid container xs={12}>
                  <Grid item xs={6}>
                    <Grid container direction="row" alignItems="center" spacing={0}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.LINK_TYPE')} />
                          <span style={{ color: 'red' }}> * </span>
                        </Typography>
                      </Grid>
                      <Grid xs={5} className={classes.gridWidth}>
                        <Select
                          native
                          variant="outlined"
                          error={hotspotData.linkTypeId === '0' || hotspotData.linkTypeId === 0}
                          value={hotspotData.linkTypeId}
                          onChange={(e) => this.handleChange(e, 'linkTypeId')}
                          inputProps={{
                            name: 'linkTypeId'
                          }}
                          margin="dense"
                          className={classes.selectWidth}
                        >
                          {this.setFilterValues(DialogConfig.linkTypeDropDown)}
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={0}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.LINK_VALUE')} />
                          <span style={{ color: 'red' }}> * </span>
                        </Typography>
                      </Grid>
                      <Grid xs={5} className={classes.gridWidth}>
                        <TextField
                          name="linkUri"
                          error={this.validateRequired('linkUri')}
                          disabled={!!disabledArr.includes('linkUri')}
                          value={hotspotData.linkUri}
                          onChange={(e) => this.handleChange(e)}
                          margin="dense"
                          InputProps={{ classes: { input: classes.textfieldInput } }}
                          variant="outlined"
                          className={classes.selectWidth}
                        />
                      </Grid>
                    </Grid>

                    <Grid container direction="row" alignItems="center" spacing={0}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.LINK_XYWH')} />
                        </Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Grid container justify="left" spacing={0}>
                          <Grid item>
                            <TextField
                              name="linkX"
                              className={classes.textFields}
                              disabled={!!disabledArr.includes('linkXYWH')}
                              value={hotspotData.linkX}
                              onChange={this.handleChange}
                              InputProps={{ classes: { input: classes.customInput } }}
                              margin="dense"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              name="linkY"
                              className={classes.textFields}
                              disabled={!!disabledArr.includes('linkXYWH')}
                              value={hotspotData.linkY}
                              onChange={this.handleChange}
                              InputProps={{ classes: { input: classes.customInput } }}
                              margin="dense"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              name="linkWidth"
                              className={classes.textFields}
                              disabled={!!disabledArr.includes('linkXYWH')}
                              value={hotspotData.linkWidth}
                              onChange={this.handleChange}
                              InputProps={{ classes: { input: classes.customInput } }}
                              margin="dense"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              name="linkHeight"
                              className={classes.textFields}
                              disabled={!!disabledArr.includes('linkXYWH')}
                              value={hotspotData.linkHeight}
                              onChange={this.handleChange}
                              InputProps={{ classes: { input: classes.customInput } }}
                              margin="dense"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={0}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.MEDIA_WH')} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="mediaWidth"
                          className={classes.textFields}
                          disabled={!!disabledArr.includes('mediaWH')}
                          value={hotspotData.mediaWidth}
                          onChange={this.handleChange}
                          InputProps={{ classes: { input: classes.customInput } }}
                          margin="dense"
                          variant="outlined"
                        />

                        <TextField
                          name="mediaHeight"
                          className={classes.textFields}
                          disabled={!!disabledArr.includes('mediaWH')}
                          value={hotspotData.mediaHeight}
                          onChange={this.handleChange}
                          InputProps={{ classes: { input: classes.customInput } }}
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={0}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.SEARCH_STRING')} />
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <TextField
                          name="linkSearch"
                          disabled={!!disabledArr.includes('linkSearch')}
                          value={hotspotData.linkSearch}
                          onChange={this.handleChange}
                          margin="dense"
                          InputProps={{ classes: { input: classes.textfieldInput } }}
                          variant="outlined"
                          className={classes.selectWidth}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={0}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.TRANSPARENT')} />
                        </Typography>
                      </Grid>
                      <Grid>
                        <Checkbox
                          name="transparent"
                          color="primary"
                          disabled={!pageHotspotProps}
                          checked={hotspotData.transparent}
                          defaultChecked="false"
                          onChange={this.handleCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* 2nd Column */}
                  <Grid item xs={6}>
                    <Grid container direction="row" alignItems="center" spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.TYPE')} />
                          <span style={{ color: 'red' }}> * </span>
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={classes.gridWidth}>
                        <Select
                          native
                          error={this.validateRequired('regionTypeId')}
                          style={{ width: '100%' }}
                          className={classes.selectWidth}
                          variant="outlined"
                          margin="dense"
                          value={hotspotData.regionTypeId}
                          onChange={(e) => this.handleChange(e)}
                          inputProps={{
                            name: 'regionTypeId'
                          }}
                        >
                          {this.setTypeValues(hotspotData.linkTypeId)}
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.ROLE')} />
                          <span style={{ color: 'red' }}> * </span>
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={classes.gridWidth}>
                        <Select
                          native
                          error={this.validateRequired('roleTypeId')}
                          style={{ width: '100%' }}
                          className={classes.selectWidth}
                          variant="outlined"
                          margin="dense"
                          value={hotspotData.roleTypeId}
                          onChange={(e) => this.handleChange(e, 'roleTypeId')}
                          inputProps={{
                            name: 'roleTypeId'
                          }}
                        >
                          {this.setFilterValues(DialogConfig.roleDropDown)}
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.ICON')} />
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={classes.gridWidth}>
                        <Select
                          native
                          disabled={!!pageHotspotProps}
                          style={{ width: '100%' }}
                          className={classes.selectWidth}
                          variant="outlined"
                          margin="dense"
                          value={hotspotData.iconTypeId}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'iconTypeId'
                          }}
                        >
                          {this.setFilterValues(DialogConfig.iconDropDown)}
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.NAME')} />
                          {requiredArr.includes('name') ? <span style={{ color: 'red' }}> * </span> : ''}
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <TextField
                          name="name"
                          error={requiredArr.includes('name') && this.validateRequired('name')}
                          disabled={!!disabledArr.includes('name')}
                          value={hotspotData.name}
                          onChange={(e) => this.handleChange(e)}
                          margin="dense"
                          InputProps={{ classes: { input: classes.textnameInput } }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.GLOSS_ENTRY_ID')} />
                          {requiredArr.includes('glossary') ? <span style={{ color: 'red' }}> * </span> : ''}
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <TextField
                          name="glossary"
                          error={requiredArr.includes('glossary') && this.validateRequired('glossary')}
                          disabled={!!disabledArr.includes('glossary')}
                          value={hotspotData.glossary}
                          onChange={this.handleChange}
                          margin="dense"
                          InputProps={{ classes: { input: classes.textnameInput } }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {hotspotData.linkTypeId !== '11' && hotspotData.linkTypeId !== 11 ? (
                      <></>
                    ) : (
                      <Grid container direction="row" alignItems="center" spacing={3}>
                        <Grid item xs={5}>
                          <Typography variant="h4" color="textPrimary">
                            <FormattedMessage {...language.getText('hotspot.PLATFORM')} />
                          </Typography>
                        </Grid>
                        <Grid xs={4} className={classes.gridWidth}>
                          <Select
                            native
                            value={hotspotData.platformId}
                            onChange={this.handleChange}
                            inputProps={{
                              name: 'platformId'
                            }}
                            style={{ width: '100%' }}
                            className={classes.selectWidth}
                            variant="outlined"
                            margin="dense"
                          >
                            {this.setFilterValues(DialogConfig.platform)}
                          </Select>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container direction="row" alignItems="center" spacing={3}>
                      <Grid item xs={6}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.READY_TO_PUBLISH')} />
                        </Typography>
                      </Grid>
                      <Grid>
                        <Checkbox
                          name="readyToPublish"
                          color="primary"
                          checked={hotspotData.readyToPublish}
                          disabled={!!disabledArr.includes('readyToPublish')}
                          onChange={this.handleCheckbox}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="h4" color="textPrimary">
                          <FormattedMessage {...language.getText('hotspot.DESCRIPTION')} />
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <TextField
                          name="description"
                          margin="dense"
                          InputProps={{ classes: { input: classes.textnameInput } }}
                          variant="outlined"
                          value={hotspotData.description}
                          disabled={!!disabledArr.includes('description')}
                          onChange={this.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
          <DialogActions className={classes.actions}>
            <Typography className={classes.moveText}>
              <FormattedMessage {...language.getText('message.DRAG_OR_REPOSITION')} />
            </Typography>
            <TooltipCustom
              title={
                !permissionsList.includes('authoring_can_edit') ? (
                  <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                ) : (
                  <FormattedMessage {...language.getText('common.SAVE_REQUEST')} />
                )
              }
            >
              <span>
                <Button
                  className={classes.actionBtn}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={this.handleSave}
                  disabled={!permissionsList.includes('authoring_can_edit')}
                >
                  <FormattedMessage {...language.getText('common.SAVE')} />
                </Button>
              </span>
            </TooltipCustom>

            <TooltipCustom title={<FormattedMessage {...language.getText('common.CANCEL_REQUEST')} />}>
              <Button
                className={classes.actionBtn}
                variant="outlined"
                color="primary"
                size="small"
                onClick={this.handleClose}
              >
                <FormattedMessage {...language.getText('common.CANCEL')} />
              </Button>
            </TooltipCustom>
            <TooltipCustom
              title={
                !permissionsList.includes('authoring_can_delete') ? (
                  <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                ) : (
                  <FormattedMessage {...language.getText('common.DELETE')} />
                )
              }
            >
              <span>
                {this.hasHotspotGuid && (
                  <Button
                    className={classes.actionBtn}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={this.handleDelete}
                    disabled={!permissionsList.includes('authoring_can_delete')}
                  >
                    <FormattedMessage {...language.getText('common.DELETE')} />
                  </Button>
                )}
              </span>
            </TooltipCustom>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(HotspotDialog)));
