const eTextConversionValue = {
  reqNo: 16722,
  eTextIsbn: 'esbn10',
  eTextIsbns: 'esbn13',
  parentIsbn: 'p10',
  parentIsbns: 'p131',
  title: 'Strategic Analysis and Action Evergreen QA',
  authors: 'Authors',
  copyright: 2020,
  name: 'Name',
  email: 'daria_borzova@epam.com',
  req: '9/9/21',
  estimated: '9/9/21',
  folderName: 'Schedule'
};

const yesNo = [
  {
    id: 0,
    value: 'XML from ETM and Hi-res PDFs'
  },

  {
    id: 1,
    value: 'Hi-res PDFs only'
  }
];

const coverArtSupplied = [
  {
    id: 'Supplied',
    value: 'Supplied'
  },
  {
    id: 'Not supplied',
    value: 'Not supplied'
  }
];

const etextYesNo = [
  {
    id: 'Y',
    value: 'Yes'
  },
  {
    id: 'N',
    value: 'No'
  }
];

const etextDemo = [
  {
    id: 0,
    value: 'Demo/Partial eText'
  },
  {
    id: 1,
    value: 'Full eText'
  }
];

const etextTocSpreadsheet = [
  {
    id: 0,
    value: 'Supplied'
  },
  {
    id: 1,
    value: 'Not needed (XML from ETM is complete)'
  },
  {
    id: 2,
    value: 'Not supplied (vendor will create from TOC PDF)'
  },
  {
    id: 3,
    value: 'Not supplied (vendor will use pagination chart)'
  }
];

const etextGlossary = [
  {
    id: 0,
    value: 'Supplied'
  },
  {
    id: 1,
    value: 'Not needed (XML from ETM is complete)'
  },
  {
    id: 2,
    value: 'Not supplied (vendor will create from Glossary PDF)'
  },
  {
    id: 2,
    value: 'Not necessary for this title'
  }
];

const etextCrossRef = [
  {
    id: 1,
    value: 'Not needed (XML from ETM is complete)'
  },
  {
    id: 2,
    value: 'Not necessary for this title'
  }
];

const etextHotspotSpreadsheet = [
  {
    id: 1,
    value: 'Supplied'
  },
  {
    id: 2,
    value: 'Not needed (XML from ETM is complete)'
  },
  {
    id: 3,
    value: 'Not necessary for this title'
  }
];

const etextContentLink = [
  {
    id: 1,
    value: 'Supplied'
  },
  {
    id: 2,
    value: 'Not needed (XML from ETM is complete for Glossary ingestion)'
  },
  {
    id: 3,
    value: 'Not necessary for this title'
  }
];

const etextHotspot = [
  {
    id: 0,
    value: 'Visible hotspots (default peach color)'
  },
  {
    id: 1,
    value: 'Visible hotspots, other color'
  },
  {
    id: 2,
    value: 'Transparent'
  },
  {
    id: 3,
    value: 'Underline hotspots'
  }
];

const etextIndexLink = [
  {
    id: 0,
    value: 'Vendor will manually link/hotspot Index'
  },
  {
    id: 1,
    value: 'Not necessary for this title'
  }
];

const etextAudioSynch = [
  {
    id: 0,
    value: 'Vendor will link/hotspot entries on contents page'
  },
  {
    id: 1,
    value: 'Not necessary for this title'
  }
];

const eTextConversionConfig = {
  eTextConversionValue,
  yesNo,
  coverArtSupplied,
  etextDemo,
  etextYesNo,
  etextTocSpreadsheet,
  etextGlossary,
  etextContentLink,
  etextIndexLink,
  etextHotspot,
  etextAudioSynch,
  etextCrossRef,
  etextHotspotSpreadsheet
};

module.exports = eTextConversionConfig;
