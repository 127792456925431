/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class BooksService extends BaseService {
  constructor() {
    super(constants.BOOK_SEARCH_DATA_FETCHED, 'booksStatus');
  }

  handleEvent(eventName, event) {
    if (event.id) {
      return this.getClient('evergreen').get(`/books/${event.id}`);
    }
    if (event.favorite) {
      return this.getClient('evergreen').post(`/books/${event.favorite.bookID}/favorite`, event.favorite);
    }
    if (event.bookID && event.model === 'BOOK_HISTORY') {
      return this.getClient('evergreen').get(`/books/${event.bookID}/bookhistory`);
    }
    return this.getClient('evergreen').post('/books', event);
  }

  handleResponse(response, event) {
    // eslint-disable-line class-methods-use-this
    if (event.id) {
      Framework.getStoreRegistry().getStore('booksState').setBook(response.data);
    } else if (event.favorite && event.window === constants.INTERMEDIARY) {
      Framework.getStoreRegistry().getStore('booksState').setFavoriteDetail(response.data);
    } else if (event.favorite && event.window === constants.DASHBOARD) {
      Framework.getStoreRegistry().getStore('booksState').setFavoriteList(response.data);
    } else if (event.bookID && event.model === 'BOOK_HISTORY') {
      Framework.getStoreRegistry().getStore('booksState').setBookHistory(response.data);
    } else {
      Framework.getStoreRegistry().getStore('booksState').set(response.data);
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
    }
  }
}
