/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
// import { shapes } from '@greenville/framework';
// import Home from './model/Home';
import Header from '../../common/header/Header';
import Footer from '../../common/Footer';
import WelcomeText from './components/WelcomeText';
import ErrorStatus from './components/error/ErrorStatus';

const styles = () => ({
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80,
    padding: '0 0 12px 32px',
    '@media (max-width: 480px)': {
      paddingLeft: 12
    }
  }
});

@inject('home')
@observer
class HomeContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
    // home: shapes.modelOf(Home).isRequired
  };

  render() {
    const { classes } = this.props;

    const currentDate = new Date();
    const hours = currentDate.getHours();

    const handleClick = () => {};

    return (
      <>
        <ErrorStatus />
        <Header />
        <main className={classes.contentWrapper} id="mainContent" role="main">
          <WelcomeText onMessageClick={handleClick} hours={hours} />
        </main>
        <Footer />
      </>
    );
  }
}

export default withStyles(styles)(HomeContainer);
