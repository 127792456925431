/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PdfViewerContainer from './PdfViewerContainer';
import TocService from './services/TocService';
import HotspotService from './services/HotspotService';
import SaveHotspotService from './services/SaveHotspotService';
import DeleteHotspotService from './services/DeleteHotspotService';
import Asset from './model/Asset';
import Hotspot from './model/Hotspot';

export default {
  path: ['/books/:bookid/preview', '/books/:bookid/preview/:pageid'],
  exact: true,
  services: [new TocService(), new HotspotService(), new SaveHotspotService(), new DeleteHotspotService()],
  stores: [
    {
      name: 'asset',
      spec: Asset,
      saveLocally: false,
      initialState: {
        assetToc: {}
      }
    },
    {
      name: 'hotspot',
      spec: Hotspot,
      saveLocally: false,
      initialState: {}
    }
  ],
  render: (props) => <PdfViewerContainer {...props} />
};
