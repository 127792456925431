/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent,
  DialogContentText,
  Paper,
  Box,
  IconButton
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import utils from '../../../../common/utils';
import Loader from '../../../../common/Loader';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '60vh',
    maxWidth: '80%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

const HistoryDialog = ({ language, classes, openhistory, close, rowData, workflowHistory, workflowHistoryStatus }) => (
  <Paper>
    <Box>
      <Dialog
        open={openhistory}
        onClose={close()}
        fullWidth="true"
        direction="column"
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={close()}
          align="center"
          toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
        >
          <Typography variant="h3">
            <FormattedMessage {...language.getText('contentmanager.WORKFLOW_HISTORY')} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            <LoadingHandler
              loading={workflowHistoryStatus.isPending || workflowHistoryStatus.isPending}
              isError={
                workflowHistoryStatus.isError &&
                workflowHistoryStatus.error &&
                workflowHistoryStatus.error.status === 401
              }
              errorContent={<StateErrorDisplay error={workflowHistoryStatus.error} showDetails />}
              content={
                <>
                  <Paper elevation={3} className={classes.titleMargin}>
                    <Box>
                      <DialogTitle id="draggable-dialog-title">
                        <Grid container direction="row" className={classes.bookfontPadding}>
                          <Grid xs={2}>
                            <Typography variant="h6" color="textPrimary">
                              <FormattedMessage {...language.getText('label.GLOBAL_BOOK_ID')} />
                            </Typography>
                          </Grid>
                          <Grid xs={4}>
                            <Typography className={classes.bookInfoText}>{rowData.globalBookId}</Typography>
                          </Grid>
                          <Grid xs={3}>
                            <Typography variant="h6" color="textPrimary">
                              <FormattedMessage {...language.getText('contentmanager.IDENTIFIER')} />/{' '}
                              <FormattedMessage {...language.getText('label.ISBN_10')} />/{' '}
                              <FormattedMessage {...language.getText('label.ISBN_13')} />
                            </Typography>
                          </Grid>
                          <br />
                          <Grid xs={3} zeroMinWidth>
                            <Typography className={classes.bookInfoText} style={{ overflowWrap: 'break-word' }}>
                              {rowData.identifier}/
                            </Typography>
                            <Typography className={classes.bookInfoText}>{rowData.isbn10}/</Typography>
                            <Typography className={classes.bookInfoText}>{rowData.isbn13}</Typography>
                          </Grid>

                          <Grid xs={2} className={classes.titleAuthorPadding}>
                            <Typography variant="h6" color="textPrimary">
                              <FormattedMessage {...language.getText('label.TITLE')} />
                            </Typography>
                          </Grid>
                          <Grid xs={4} className={classes.titleAuthorPadding} zeroMinWidth>
                            <Typography className={classes.bookInfoText} style={{ overflowWrap: 'break-word' }}>
                              {rowData.bookTitle}
                            </Typography>
                          </Grid>

                          <Grid xs={3} className={classes.titleAuthorPadding}>
                            <Typography variant="h6" color="textPrimary">
                              <FormattedMessage {...language.getText('label.AUTHOR')} />
                            </Typography>
                          </Grid>
                          <Grid xs={3} className={classes.titleAuthorPadding}>
                            <Typography className={classes.bookInfoText}>
                              {rowData.authorsList && utils.authorFullName(rowData.authorsList)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </DialogTitle>
                    </Box>
                  </Paper>
                  <Paper elevation={3} className={classes.contentMargin}>
                    <Box m={-0.5}>
                      <DialogContent className={classes.dialogContent}>
                        <DialogContentText>
                          <TableContainer className={classes.root} component={Paper}>
                            <Table className={classes.metaRoot} stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center" style={{ width: '18%' }}>
                                    <Typography variant="h6">
                                      <FormattedMessage {...language.getText('label.DATE')} />
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center" style={{ width: '10%' }}>
                                    <Typography variant="h6">
                                      <FormattedMessage {...language.getText('label.ACTION_USER')} />
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center" style={{ width: '10%' }}>
                                    <Typography variant="h6">
                                      <FormattedMessage {...language.getText('label.VERSION')} />
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center" style={{ width: '10%' }}>
                                    <Typography variant="h6">
                                      <FormattedMessage {...language.getText('label.BOOK_ID')} />
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell align="center" style={{ width: '10%' }}>
                                    <Typography variant="h6">
                                      <FormattedMessage {...language.getText('label.UPDF_URL')} />
                                    </Typography>
                                  </TableCell> */}
                                  <TableCell align="center" style={{ width: '40%' }}>
                                    <Typography variant="h6">
                                      <FormattedMessage {...language.getText('label.STATUS')} />
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {workflowHistory &&
                                  workflowHistory.length > 0 &&
                                  workflowHistory.map((row) => (
                                    <TableRow>
                                      <TableCell>
                                        <Typography className={classes.tableText} component="div">
                                          {row.date}
                                        </Typography>
                                      </TableCell>
                                      <TableCell size="small">
                                        <Typography className={classes.tableText} component="div">
                                          {row.actionUser}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography className={classes.tableText} component="div">
                                          {row.version}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography className={classes.tableText} component="div">
                                          {row.bookId}
                                        </Typography>
                                      </TableCell>
                                      {/* <TableCell>
                                        <Typography className={classes.tableText} component="div">
                                          {row.updfEpsUrl ? <a href={row.updfEpsUrl}>URL</a> : ''}
                                        </Typography>
                                      </TableCell> */}
                                      <TableCell style={{ background: row.statusColor }}>
                                        <Typography className={classes.statusText} component="div">
                                          {row.status}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </DialogContentText>
                      </DialogContent>
                    </Box>
                  </Paper>
                </>
              }
              loadingContent={<Loader />}
            />
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  </Paper>
);

HistoryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  openhistory: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  workflowHistory: PropTypes.array.isRequired,
  workflowHistoryStatus: PropTypes.object.isRequired
};

export default withStyles(styles)(inject('language')(observer(HistoryDialog)));
