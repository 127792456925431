/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { Typography, Dialog, DialogContent, Paper, Box, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  shapes
  // , LoadingHandler, StateErrorDisplay
} from '@greenville/framework';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import TooltipCustom from '../../../../common/components/TooltipCustom';
// import utils from '../../../../common/utils';
// import Loader from '../../../../common/Loader';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  dialogPaper: {
    minHeight: '95%',
    maxWidth: '77%'
  },
  iPadDialogPaper: {
    minHeight: '95vh',
    maxWidth: '47%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  iPadFrame: {
    width: '550px',
    height: '800px'
  },
  coverFrame: {
    width: '1000px',
    height: '1200px',
    padding: theme.spacing(1, 1, 0, 1.3)
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

const ViewCoverDialog = ({ language, classes, open, close, iPadCoverType, coverUrl }) => (
  <Paper>
    <Box>
      <Dialog
        open={open}
        onClose={close()}
        fullWidth="true"
        direction="column"
        classes={{ paper: iPadCoverType ? classes.iPadDialogPaper : classes.dialogPaper }}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={close()}
          align="center"
          toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
        >
          <Typography variant="h3">
            {iPadCoverType ? (
              <FormattedMessage {...language.getText('label.IPAD_COVER_PAGE')} />
            ) : (
              <FormattedMessage {...language.getText('label.COVER_PAGE')} />
            )}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            <Paper elevation={3}>
              <Box>
                <DialogTitle>
                  <Grid justify="center" container direction="row">
                    <Grid item>
                      {iPadCoverType ? (
                        <iframe
                          title="iPadcover"
                          className={classes.iPadFrame}
                          src={coverUrl}
                          frameBorder="0"
                          scrolling="no"
                        />
                      ) : (
                        <img alt="coverImage" src={coverUrl} />
                      )}
                    </Grid>
                  </Grid>
                </DialogTitle>
              </Box>
            </Paper>
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  </Paper>
);

ViewCoverDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  iPadCoverType: PropTypes.bool.isRequired,
  coverUrl: PropTypes.string.isRequired
};
// ViewCoverDialog.defaultProps = {
//   coverPage: ''
// };

export default withStyles(styles)(inject('language')(observer(ViewCoverDialog)));
