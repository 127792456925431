import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const propertyResponse = types.model('propertyResponse', {
  id: types.maybeNull(types.string),
  display_name: types.maybeNull(types.string)
});

const groupResponse = types.model('groupResponse', {
  name: types.maybeNull(types.string),
  properties: types.maybeNull(types.array(propertyResponse, []))
});

const ChatgptAdmin = types
  .model('ChatgptAdmin', {
    tenantName: types.maybeNull(types.string),
    groups: types.maybeNull(types.array(groupResponse, []))
  })
  .actions((self) => ({
    fetchTenantData(payload) {
      Framework.getEventManager().publish(constants.GET_ADMIN_DATA, {
        payload
      });
    },
    saveData(payload) {
      Framework.getEventManager().publish(constants.SAVE_ADMIN_DATA, {
        payload
      });
    },
    updateData(payload) {
      Framework.getEventManager().publish(constants.EDIT_ADMIN_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.SET_ADMIN_DATA);
    }
  }));

export default ChatgptAdmin;
