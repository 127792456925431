/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import ServerSideProcessService from './services/ServerSideProcessService';
import ServerSideProcess from './models/ServerSideProcess';
import ServerSideProcessContainer from './ServerSideProcessContainer';
import GetServerSideProcessDataService from './services/GetServerSideProcessDataService';
import CancelServerSideProcessService from './services/CancelServerSideProcessService';

export default {
  path: '/pdf/queue/startpreprocess',
  exact: true,
  services: [
    new ServerSideProcessService(),
    new GetServerSideProcessDataService(),
    new CancelServerSideProcessService()
  ],
  stores: [
    {
      name: 'serverSideProcessState',
      spec: ServerSideProcess,
      saveLocally: false,
      initialState: {
        serverSideProcess: {}
      }
    }
  ],
  render: (props) => (
    <ServerSideProcessContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
