/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Index file for all environment setting files.
 */
import base from './base';
import local from './local';
import dev from './dev';
import stg from './stg';
import prod from './prod';

let env;

switch (window.location.hostname) {
  case 'localhost':
  case 'local.evergreen.pearson.com':
    env = local;
    break;
  case 'evergreen-dev.pearson.com':
    env = dev;
    break;
  case 'evergreen-stg.pearson.com':
    env = stg;
    break;
  // Production
  case 'evergreen.pearson.com':
    env = prod;
    break;
  default:
    env = local;
}

/**
 * Override the base values with the environment specific values.
 */
export default { ...base, ...env };
