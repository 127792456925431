/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */
/**
 * Newrelic class for inject the code
 */

import env from '../../common/env';

export default class newrelic {
  /**
   * Inject Newrelic script.
   */
  static init() {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = `${env.NEWRELIC_CONFIG_NAME}?env=${JSON.stringify(env.NEWRELIC_CONFIG)}`;
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
