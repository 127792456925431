/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { shapes } from '@greenville/framework';
import PropTypes from 'prop-types';
import {
  Typography,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6),
      textAlign: 'center'
    }
  },
  historyIcon: {
    cursor: 'pointer',
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  titlePointer: {
    cursor: 'pointer'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  clickableLink: {
    cursor: 'pointer',
    color: '#0000EE',
    textAlign: 'center',
    fontSize: 13,
    textDecoration: 'underline'
  }
});

const SearchResultTable = ({ language, classes, data, handleeTextData, handleeTextRevision }) => (
  <TableContainer className={classes.root} component={Paper}>
    <Table className={classes.metaRoot} stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell align="center">
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.REQUEST_NO')} />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.ISBN_10')} />/{' '}
              <FormattedMessage {...language.getText('label.ISBN_13')} />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5" style={{ overflowWrap: 'break-word' }}>
              <FormattedMessage {...language.getText('label.GLOBAL_BOOK_ID')} />/{' '}
              <FormattedMessage {...language.getText('label.BOOK_ID')} />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.TITLE')} />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.AUTHOR')} />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.ORIGINAL_REQUEST_DATE')} />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.REVISION_NO')} />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.REQUESTED_BY')} />
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.STATUS')} />
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <TableRow key={row.name}>
            <TableCell
              className={classes.clickableLink}
              component="th"
              scope="row"
              onClick={() => handleeTextData(row.requestNo)}
            >
              {row.requestNo}
            </TableCell>
            <TableCell>
              {row.ebookISBN10 !== null && (
                <Typography className={classes.tableText} component="div">
                  {row.ebookISBN10}
                  {row.ebookISBN13 !== null && '/'}
                </Typography>
              )}
              {row.ebookISBN13 !== null && (
                <Typography className={classes.tableText} component="div">
                  {row.ebookISBN13}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              {row.globalBookId !== null && (
                <Typography className={classes.tableText} component="div">
                  {row.globalBookId}
                  {row.bookId !== null && '/'}
                </Typography>
              )}
              {row.bookId !== null && (
                <Typography className={classes.tableText} component="div">
                  {row.bookId}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              <Typography className={classes.tableText} component="div">
                {row.title}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableText} component="div">
                {row.author}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableText} component="div">
                {row.originalRequestDate}
              </Typography>
            </TableCell>
            <TableCell className={classes.clickableLink} onClick={() => handleeTextRevision(row.revisionNo)}>
              <Typography className={classes.tableText} component="div">
                {row.revisionNo}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableText} component="div">
                {row.requestedBy}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableText} component="div">
                {row.status}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

SearchResultTable.propTypes = {
  language: shapes.language.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  handleeTextData: PropTypes.func.isRequired,
  handleeTextRevision: PropTypes.func.isRequired
};

export default withStyles(styles)(inject('language')(observer(SearchResultTable)));
