/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class CustomIconUploadService extends BaseService {
  constructor() {
    super(constants.CUSTOM_ICONS_UPLOAD, 'customIconUploadStatus');
  }

  handleEvent(eventName, event) {
    if (event.payload) {
      const iconChangedValue =
        event.data === 'null'
          ? `books/${event.payload.get('id')}/customicon/upload`
          : `books/${event.payload.get('id')}/customicon/default`;
      const mappedData = this.mapPayload(event.payload);
      return this.getClient('evergreen').post(iconChangedValue, mappedData);
    }
    return null;
  }

  async handleResponse(response, event) {
    // eslint-disable-line class-methods-use-this
    const responseText =
      event.data === 'null' ? 'CustomIcon data saved successfully' : 'CustomIcon reverted data saved successfully';
    const notification = {
      open: true,
      message: responseText,
      type: 'info'
    };
    Framework.getStoreRegistry().getStore('notificationState').set(notification);
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
      if (status === 500 && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
  }

  mapPayload(payload) {
    // eslint-disable-line class-methods-use-this
    return payload;
  }
}
