/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Local environment settings.
 */
const API_BASE_URL = 'https://evergreen-dev.pearson.com/api/v1';
// const API_BASE_URL = 'http://localhost:3000/api/v1';
// const API_BASE_URL = 'http://127.0.0.1:3030/api/v1';
const local = {
  ENVIRONMENT: 'local',
  PARTNER_ENV: 'local',
  EVERGREEN_API_BASE_URL: API_BASE_URL,
  INT_BASE_URL: API_BASE_URL
};

export default local;
