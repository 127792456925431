/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 */

const customIconObj = types.model('customIconObj', {
  customIconPath: types.maybeNull(types.string),
  defaultIconPath: types.maybeNull(types.string),
  iconTypeID: types.maybeNull(types.integer),
  type: types.maybeNull(types.string),
  useCustom: types.maybeNull(types.string)
});

const iconListObj = types.model('iconListObj', {
  iconName: types.maybeNull(types.string),
  iconTypeID: types.maybeNull(types.integer)
});

const CustomIcons = types
  .model('CustomIcons', {
    bookIcons: types.array(customIconObj, []),
    iconList: types.array(iconListObj, []),
    ipadIcons: types.array(customIconObj, [])
  })
  .actions((self) => ({
    fetch(id) {
      Framework.getEventManager().publish(constants.CUSTOM_ICON_FETCHED, { id });
    },
    set(response) {
      applySnapshot(self.bookIcons, response.data.bookIcons);
    },
    seticonList(response) {
      applySnapshot(self.iconList, response.data.iconList);
    },
    setipadIcons(response) {
      applySnapshot(self.ipadIcons, response.data.ipadIcons);
    },

    savePayload(payload, data) {
      Framework.getEventManager().publish(constants.CUSTOM_ICONS_UPLOAD, { payload, data });
    },

    defautltIcon(payload, data) {
      Framework.getEventManager().publish(constants.CUSTOM_ICONS_DEFAULT_UPLOAD, { payload, data });
    }
  }));

export default CustomIcons;
