import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const questionObj = types.model('QuestionResponse', {
  explanation: types.maybeNull(types.string),
  correct_choice: types.maybeNull(types.string),
  id: types.maybeNull(types.integer),
  option_text: types.maybeNull(types.string)
});

const QuestionResponse = types.model('QuestionResponse', {
  options: types.maybeNull(types.optional(types.array(questionObj), [])),
  question_text: types.maybeNull(types.string)
});

const tocData = types.model('tocData', {
  // id: types.maybeNull(types.string),
  bookId: types.maybeNull(types.string),
  productId: types.maybeNull(types.string),
  productModel: types.maybeNull(types.string),
  sourceId: types.maybeNull(types.string),
  sourceType: types.maybeNull(types.string),
  sourceTitle: types.maybeNull(types.string),
  questionId: types.maybeNull(types.string),
  difficultyLevel: types.maybeNull(types.number),
  lifeCycleStatus: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  question: types.optional(QuestionResponse, {}),
  modifiedBy: types.maybeNull(types.string)
});

const Toc = types
  .model('Toc', {
    data: types.optional(types.array(tocData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHATGPT_TOC_DATA, {
        payload
      });
    },
    updateMcqQuestion(payload) {
      Framework.getEventManager().publish(constants.UPDATE_MCQ_QUESTION_DATA, {
        payload
      });
    },
    updateMcqQuestionStatus(payload) {
      Framework.getEventManager().publish(constants.MCQ_QUESTION_UPDATE_STATUS, {
        payload
      });
    },
    DeleteMcqQuestion(payload) {
      Framework.getEventManager().publish(constants.MCQ_QUESTION_DELETE_STATUS, {
        payload
      });
    },
    setupdateMcqQuestionResponse(response) {
      Framework.getEventManager().publish(constants.SET_UPDATE_MCQ_QUESTION_DATA, {
        response
      });
    },
    AddMcqQuestion(payload) {
      Framework.getEventManager().publish(constants.ADD_MCQ_QUESTION_DATA, {
        payload
      });
    },
    setAddMcqQuestionResponse(response) {
      Framework.getEventManager().publish(constants.SET_UPDATE_MCQ_QUESTION_DATA, {
        response
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_CHATGPT_TOC_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_CHATGPT_TOC_DATA);
    }
  }));

export default Toc;
