/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { shapes } from '@greenville/framework';
import {
  Typography,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button
} from '@material-ui/core';
import ServerSideStatus from '../../../common/config/ServerSideStatusConfig';
// import TooltipCustom from '../../../common/components/TooltipCustom';
// import utils from '../../../../common/utils';

const styles = (theme) => ({
  root: {
    width: '100%',
    bottom: theme.spacing(2)
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.5),
      textAlign: 'center'
    }
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  }
});

const ServerSideSearchTable = ({ classes, language, data, handleCancel }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const setStatusValue = (processStatus) =>
    ServerSideStatus.status.filter((y) => y.id === processStatus).map((x) => x.value);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <TableContainer className={classes.root} component={Paper}>
        <Table className={classes.metaRoot} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.GLOBAL_BOOK_ID')} />/{' '}
                  <FormattedMessage {...language.getText('label.BOOK_ID')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.VERSION')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.SERVER_SIDE_ENABLED')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.SERVER_SIDE_PROCESSED')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.DATE_CREATED')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.DATE_UPDATED')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.STATUS')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('common.CANCEL_PROCESS')} />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow>
                <TableCell>
                  <Typography className={classes.tableText} component="div">
                    {row.globalBookId}/
                  </Typography>
                  <Typography className={classes.tableText} component="div">
                    {row.bookId}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableText} component="div">
                    {row.version}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableText} component="div">
                    {row.serverSideEnabled}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableText} component="div">
                    {row.serverSideProcessed}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableText} component="div">
                    {row.createdDate}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableText} component="div">
                    {row.updatedDate}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableText} component="div">
                    {setStatusValue(row.serverSideProcessStatus)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Button variant="outlined" color="primary" size="small" onClick={() => handleCancel(row)}>
                    <FormattedMessage {...language.getText('common.CANCEL')} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 30, 60, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

ServerSideSearchTable.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  start: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default withStyles(styles)(observer(inject('language')(ServerSideSearchTable)));
