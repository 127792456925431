import React, { useState } from 'react';
import PropTypes from 'prop-types';

function LinkMenu({ paths, separator, onClick, styles, activeStyle }) {
  const [ishover, setIshover] = useState(false);

  const buttonStyle = {
    borderStyle: 'none',
    fontWeight: 'bold',
    fontSize: 'medium',
    cursor: ishover ? 'pointer' : 'auto'
  };

  const handleClick = (path) => {
    onClick(path);
    // if (onClick) {
    //   onclick(path);
    // }
  };

  return (
    <div style={styles}>
      {paths.map((breadcrumb, index) => (
        <span key={breadcrumb.path}>
          {index === paths.length - 1 ? (
            <span style={activeStyle}>{breadcrumb.title}</span>
          ) : (
            <button
              // href={breadcrumb.path}
              type="button"
              style={index === paths.length - 1 ? activeStyle : buttonStyle}
              onClick={() => handleClick(breadcrumb.path)}
              onMouseEnter={() => setIshover(true)}
            >
              {breadcrumb.title}
            </button>
          )}
          {index < paths.length - 1 && <span style={{ margin: '0 5px' }}> {separator}</span>}
        </span>
      ))}
    </div>
  );
}

LinkMenu.propTypes = {
  paths: PropTypes.array.isRequired,
  styles: PropTypes.object.isRequired,
  activeStyle: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  separator: PropTypes.string.isRequired
};

export default LinkMenu;
