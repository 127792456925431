/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class EntitlementService extends BaseService {
  constructor() {
    super(constants.MODULE_LIST_FETCHED, 'entitlementStatus');
  }

  // eslint-disable-next-line no-unused-vars
  handleEvent(eventName, event) {
    if (event.payload && event.payload.requestPayload && !event.payload.isPublish) {
      return this.getClient('evergreen').post(
        `/books/${event.payload.bookID}/entitlement/save`,
        event.payload.requestPayload
      );
    }
    if (event.payload && event.payload.requestPayload && event.payload.isPublish) {
      return this.getClient('evergreen').post(
        `/books/${event.payload.bookID}/entitlement/publish`,
        event.payload.requestPayload
      );
    }
    return this.getClient('evergreen').get(`/books/${event.bookID}/entitlement`);
  }

  handleResponse(response, event) {
    // eslint-disable-line class-methods-use-this
    if (event.bookID) {
      Framework.getStoreRegistry().getStore('entitlementState').set(response.data.data);
    }
    if (event.payload && event.payload.requestPayload) {
      Framework.getStoreRegistry().getStore('entitlementState').setSavePublish(response.data.data);
      if (event.payload.isPublish) {
        Framework.redirectTo(`/books/${event.payload.bookID}`);
      }
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
    }
  }
}
