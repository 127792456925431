/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  // Framework,
  shapes
} from '@greenville/framework';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  // Typography,
  List,
  ListItem
} from '@material-ui/core';
import {
  ProfileDisplay
  // Icon
} from '@greenville/mui-theme';

const UserProfileStyles = () => ({
  header: {
    paddingBottom: 14
  },
  divider: {
    margin: '0px 24px'
  },
  menuItem: {
    padding: '14px 22px',
    justifyContent: 'space-between'
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 14,
    paddingBottom: 14
  }
});

const UserProfile = ({ id, onLogoutRequest, language, user, classes, ...rest }) => (
  // const onClickAccount = () => {
  //   Framework.redirectTo('/users');
  // };
  <List id={id} {...rest}>
    <ListItem id={`${id}-profile`} className={classes.header}>
      <ProfileDisplay id={`${id}-profile-display`} name={user.fullName} email={user.email} avatarText={user.initials} />
    </ListItem>
    {/* <Divider className={classes.divider} />
      <ListItem
        id={`${id}-account-link`}
        button
        className={classes.menuItem}
        onClick={
          () => onClickAccount()
        }
      >
        <div>
          <Typography variant="h5">
            <FormattedMessage
              {...language.getText('user.USER_MANAGEMENT_LABEL')}
            />
          </Typography>
          <Typography variant="subtitle1">
            <FormattedMessage
              {...language.getText('user.USER_MANAGEMENT_DESC')}
            />
          </Typography>
        </div>
        <Icon name="ChevronRight" />
      </ListItem> */}
    <Divider className={classes.divider} />
    <ListItem className={classes.action}>
      <Button id={`${id}-logout-button`} variant="outlined" color="primary" onClick={onLogoutRequest}>
        <FormattedMessage {...language.getText('user.SIGNOUT')} />
      </Button>
    </ListItem>
  </List>
);
UserProfile.defaultProps = {
  id: 'user-profile'
};

UserProfile.propTypes = {
  id: PropTypes.string,
  onLogoutRequest: PropTypes.func.isRequired,
  language: shapes.language.isRequired,
  user: shapes.user.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(UserProfileStyles)(UserProfile);
