import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/* eslint-disable */
const FeedbackContent = types.model('FeedbackContent', {
  term: types.maybeNull(types.string),
  definition: types.maybeNull(types.string)
});

const flashcardData = types.model('flashcardData', {
  bookId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  reasonForDisLike: types.optional(types.array(types.string), []),
  feedbackContent: types.optional(FeedbackContent, {})
});

const roleObj = types.model('roleObj', {
  prompt: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  safety: types.maybeNull(types.string),
  grounding: types.maybeNull(types.string),
  userTopic: types.maybeNull(types.string)
});

const quizObj = types.model('quizObj', {
  optionsList: types.optional(types.array(types.string), []),
  question: types.maybeNull(types.string)
});

const questionObj = types.model('feedbackObj', {
  option: types.maybeNull(types.string),
  correct_choice: types.maybeNull(types.string)
});

const feedbackObj = types.model('feedbackObj', {
  answer: types.maybeNull(types.string),
  options: types.maybeNull(types.optional(types.array(questionObj), [])),
  question: types.maybeNull(types.string),
  feedback: types.maybeNull(types.optional(types.array(types.string), []))
});

const botResponseArray = types.model('botResponseArray', {
  botSummary: types.optional(types.array(types.string), []),
  processedSummary: types.optional(types.array(types.string), [])
});

const SummaryResponse = types.model('SummaryResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  botResponse: types.maybeNull(types.optional(botResponseArray, {})),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.optional(types.array(types.string), [])),
  comments: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  reasonForDisLike: types.optional(types.array(types.string), []),
  feedbackContent: types.maybeNull(types.optional(types.array(types.string), []))
});

const SummaryTopic = types.model('SummaryTopic', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.optional(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const mcqDistract = types.model('mcqDistract', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.optional(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const DiscussResponse = types.model('DiscussResponse', {
  bookId: types.maybeNull(types.string),
  botResponse: types.optional(types.array(types.string), []),
  question: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), []),
  reasonForDisLike: types.optional(types.array(types.string), [])
});

const QuizResponse = types.model('QuizResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.string),
  botResponse: types.maybeNull(types.array(types.optional(quizObj, {}))),
  feedbackContent: types.maybeNull(types.array(types.optional(feedbackObj, {}))),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), []),
  reasonForDisLike: types.optional(types.array(types.string), [])
});

const ChatEnrichmentResponse = types.model('ChatEnrichmentResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const ContentClassifierResponse = types.model('ContentClassifierResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const ChatIntentResponse = types.model('ChatIntentResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const FlashcardBotResponse = types.model('FlashcardBotResponse', {
  answer: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string)
});

const FlashcardsResponse = types.model('FlashcardsResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.optional(FlashcardBotResponse, {}))),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const TopicResponse = types.model('TopicResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const SummaryEnrichmentResponse = types.model('SummaryEnrichmentResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const QuizValidateResponse = types.model('QuizValidateResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), [])
});

const QuizIntentResponse = types.model('QuizIntentResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.optional(types.array(types.maybeNull(types.string)), [])
});

const McqResponse = types.model('McqResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const McqMisconceptionsResponse = types.model('McqMisconceptionsResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const McqSkillIdentifierResponse = types.model('McqSkillIdentifierResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const McqQuestionGenerationResponse = types.model('McqQuestionGenerationResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const QuizMcqResponse = types.model('QuizMcqResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), [])
});

const QuizFreeResponse = types.model('QuizFreeResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.array(types.string), [])
});

const FreeResponse = types.model('FreeResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
});

const typeString = types.model('typeString', {
  S: types.maybeNull(types.string)
});

const lastEvaluvatedKey = types.model('lastEvaluvatedKey', {
  bookId: types.maybeNull(typeString),
  serverDateTime: types.maybeNull(typeString),
  type: types.maybeNull(typeString),
  feedbackId: types.maybeNull(typeString),
  userId: types.maybeNull(typeString)
});

const ExclusiveStartKey = types.model('ExclusiveStartKey', {
  ExclusiveStartKey: types.optional(lastEvaluvatedKey, {})
});

const Feedback = types
  .model('Feedback', {
    isGetAllBookData: types.optional(types.boolean, false),
    explainNextPageKey: types.optional(ExclusiveStartKey, {}),
    problemSolveNextPageKey: types.optional(ExclusiveStartKey, {}),
    quizNextPageKey: types.optional(ExclusiveStartKey, {}),
    summaryNextPageKey: types.optional(ExclusiveStartKey, {}),
    listOfType: types.optional(types.array(types.string), []),
    summary: types.optional(types.array(SummaryResponse), []),
    discuss: types.optional(types.array(DiscussResponse), []),
    quiz: types.optional(types.array(QuizResponse), []),
    chat_enrichment: types.optional(types.array(ChatEnrichmentResponse), []),
    chat_intent: types.optional(types.array(ChatIntentResponse), []),
    content_classifier: types.optional(types.array(ContentClassifierResponse), []),
    flashcards: types.optional(types.array(FlashcardsResponse), []),
    topic: types.optional(types.array(TopicResponse), []),
    summary_enrichment: types.optional(types.array(SummaryEnrichmentResponse), []),
    summaryTopic: types.optional(types.array(SummaryTopic), []),
    quizValidateIntent: types.optional(types.array(QuizValidateResponse), []),
    quizIntent: types.optional(types.array(QuizIntentResponse), []),
    mcq: types.optional(types.array(McqResponse), []),
    mcqMisconceptions: types.optional(types.array(McqMisconceptionsResponse), []),
    mcqSkillIdentifier: types.optional(types.array(McqSkillIdentifierResponse), []),
    mcqQuestionGeneration: types.optional(types.array(McqQuestionGenerationResponse), []),
    quizMcq: types.optional(types.array(QuizMcqResponse), []),
    quizFreeResponse: types.optional(types.array(QuizFreeResponse), []),
    freeResponse: types.optional(types.array(FreeResponse), []),
    mcqDistract: types.optional(types.array(mcqDistract), []),
    isNextPageKey: types.optional(ExclusiveStartKey, {}),
    problemSolve: types.optional(types.array(SummaryResponse), []),
    problemSolveRectifier: types.optional(types.array(SummaryResponse), []),
    flashCardData: types.optional(types.array(flashcardData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      if (self.isGetAllBookData) {
        this.setListOfType(payload.type);
      }
      Framework.getEventManager().publish(constants.GET_CHATGPT_FEEDBACK_DATA, {
        payload
      });
    },
    resetPageNextKey() {
      self.explainNextPageKey = {};
      self.problemSolveNextPageKey = {};
      self.quizNextPageKey = {};
      self.summaryNextPageKey = {};
      self.listOfType = [];
    },
    getAllBookStatus() {
      return self.isGetAllBookData;
    },
    setAllBookSearch(bookSearch) {
      let boolValue = false;
      if (bookSearch.allBookSearch) {
        boolValue = true;
      }
      self.isGetAllBookData = boolValue;
    },
    setListOfType(type) {
      self.listOfType.push(type);
    },
    setResponse(response) {
      if (self.isGetAllBookData && response.data) {
        const { isNextPageKey, ...arrayValue } = response.data;
        const objectArray = _.flatMapDeep(Object.values(arrayValue));
        const firstObject = objectArray ? objectArray[0] : {};
        switch (firstObject?.type) {
          case constants.SUMMARY_TYPE:
            applySnapshot(self.summaryNextPageKey, isNextPageKey);
            break;
          case constants.FEEDBACK_QUIZ_TYPE:
            applySnapshot(self.quizNextPageKey, isNextPageKey);
            break;
          case constants.PROBLEM_SOLVE:
            applySnapshot(self.problemSolveNextPageKey, isNextPageKey);
            break;
          case constants.FEEDBACK_EXPLAIN_TYPE:
            applySnapshot(self.explainNextPageKey, isNextPageKey);
            break;
          default:
        }
      } else {
        applySnapshot(self.isNextPageKey, response.data.isNextPageKey);
        applySnapshot(self.listOfType, []);
      }
      const finalValue = { ...self, ...response.data };
      applySnapshot(self, finalValue);
      Framework.getEventManager().publish(constants.GPT_FEEDBACK_RESPONSE);
    },
    resetStoreValues() {
      if (!self.isGetAllBookData) {
        const initialState = [];
        applySnapshot(self.discuss, initialState);
        applySnapshot(self.quiz, initialState);
        applySnapshot(self.summary, initialState);
        applySnapshot(self.chat_enrichment, initialState);
        applySnapshot(self.chat_intent, initialState);
        applySnapshot(self.content_classifier, initialState);
        applySnapshot(self.flashcards, initialState);
        applySnapshot(self.topic, initialState);
        applySnapshot(self.summary_enrichment, initialState);
        applySnapshot(self.summaryTopic, initialState);
        applySnapshot(self.quizValidateIntent, initialState);
        applySnapshot(self.quizIntent, initialState);
        applySnapshot(self.mcq, initialState);
        applySnapshot(self.mcqMisconceptions, initialState);
        applySnapshot(self.mcqSkillIdentifier, initialState);
        applySnapshot(self.mcqQuestionGeneration, initialState);
        applySnapshot(self.quizMcq, initialState);
        applySnapshot(self.quizFreeResponse, initialState);
        applySnapshot(self.freeResponse, initialState);
        applySnapshot(self.mcqDistract, initialState);
      }
      Framework.getEventManager().publish(constants.GPT_FEEDBACK_RESPONSE);
    }
  }));

export default Feedback;
