/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Framework, { shapes } from '@greenville/framework';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import * as constants from '../../../../common/constants';
import utils from '../../../../common/utils';
import FavoriteIcon from '../Favorite';

const styles = () => ({
  root: {
    maxWidth: 245
  },
  area: {
    height: '100'
  },
  content: {
    padding: 4
  },
  media: {
    margin: 4,
    height: 165,
    backgroundSize: '95px 115px',
    backgroundColor: '#f5f4f4'
  },
  space: {
    justifyContent: 'space-evenly'
  },
  favicon: {
    position: 'absolute',
    top: '2px',
    right: '2px'
  }
});

const BookGridItem = ({ classes, data, bookFavorite, language }) => (
  <Card className={classes.root}>
    <CardActionArea className={classes.area}>
      <CardMedia
        className={classes.media}
        image={data.thumbnail}
        title={data.title}
        onClick={() => {
          Framework.redirectTo(`books/${data.bookID}`);
        }}
      />
      <CardContent className={classes.content}>
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
          title={utils.authorFullName(data.authorList)}
          onClick={() => {
            Framework.redirectTo(`books/${data.bookID}`);
          }}
        >
          {utils.authorFullName(data.authorList)}
        </Typography>
        <Typography
          title={data.title + utils.getEditionTypeValue(data.editionTypeID)}
          variant="body2"
          component="div"
          noWrap
          gutterBottom
          onClick={() => {
            Framework.redirectTo(`books/${data.bookID}`);
          }}
        >
          {data.title}
          {utils.getEditionTypeValue(data.editionTypeID)}
        </Typography>
        <Typography
          title={data.bookEditionNumber}
          variant="body2"
          component="div"
          gutterBottom
          noWrap
          onClick={() => {
            Framework.redirectTo(`books/${data.bookID}`);
          }}
        >
          <b>
            <FormattedMessage {...language.getText('label.EDITION')} />:{' '}
          </b>
          {data.bookEditionNumber.trim() && data.bookEditionNumber}
          {!data.bookEditionNumber.trim() && 'N/A'}
        </Typography>
        <div className={classes.favicon}>
          <FavoriteIcon favorite={data} bookFavorite={bookFavorite} window={constants.DASHBOARD} />
        </div>
      </CardContent>
    </CardActionArea>
  </Card>
);

BookGridItem.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  bookFavorite: PropTypes.func,
  language: shapes.language.isRequired
};

BookGridItem.defaultProps = {
  data: [],
  bookFavorite: ''
};

export default withStyles(styles)(observer(inject('language')(BookGridItem)));
