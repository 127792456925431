/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import Framework from '@greenville/framework';
import env from '../../common/env';
import BooksService from './services/BooksService';
import Books from './models/Books';
import BooksContainer from './BooksContainer';
import BookMetadataSaveService from './services/BookMetadataSaveService';
import BookMetadataGetService from './services/BookMetadataGetService';
import MediaBasepathService from './services/MediaBasepathService';
import MediaBasepathSaveservice from './services/MediaBasepathSaveservice';
import ColorInterfaceGetService from './services/ColorInterfaceGetService';
import MediaBasepaths from './models/MediaBasepaths';
import InterfaceModel from './models/InterfaceModel';
import ColorInterfaceSaveService from './services/ColorInterfaceSaveService';
import CustomIconUploadService from './services/CustomIconUploadService';
import CustomIconDefaultService from './services/CustomIconDefaultService';
import CustomIcons from './models/CustomIcons';
import CustomIconGetService from './services/CustomIconGetService';
import NotificationModel from '../../common/components/notification/NotificationModel';

Framework.getClientManager().add({
  name: 'evergreen',
  authHeader: 'authorization',
  timeout: env.EVERGREEN_API_TIMEOUT,
  baseUrl: env.EVERGREEN_API_BASE_URL,
  tokenPrefix: 'Bearer '
});

export default {
  path: [
    '/books',
    '/books/:bookid',
    '/books/:bookid/bookhistory',
    '/books/:bookid/edit',
    '/books/:bookid/basepath',
    '/books/:bookid/interfacecolors',
    '/books/:bookid/customicon'
  ],
  exact: true,
  services: [
    new BooksService(),
    new BookMetadataGetService(),
    new BookMetadataSaveService(),
    new MediaBasepathService(),
    new MediaBasepathSaveservice(),
    new ColorInterfaceGetService(),
    new ColorInterfaceSaveService(),
    new CustomIconUploadService(),
    new CustomIconDefaultService(),
    new CustomIconGetService()
  ],
  stores: [
    {
      name: 'booksState',
      spec: Books,
      saveLocally: false,
      initialState: {
        bookList: [],
        bookDetail: {},
        bookFavorite: {},
        bookHistory: [],
        bookMetadata: {}
      }
    },
    {
      name: 'basepath',
      spec: MediaBasepaths,
      saveLocally: false,
      initialState: {
        basepathUrl: []
      }
    },
    {
      name: 'notificationState',
      spec: NotificationModel,
      saveLocally: false,
      initialState: {
        notificationState: {}
      }
    },
    {
      name: 'colorInterface',
      spec: InterfaceModel,
      saveLocally: false,
      initialState: {
        interfaceState: []
      }
    },
    {
      name: 'customIcon',
      spec: CustomIcons,
      saveLocally: false,
      initialState: {
        customIconState: []
      }
    }
  ],
  render: (props) => (
    <BooksContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
