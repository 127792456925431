import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
// import { Box } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { shapes } from '@greenville/framework';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { Rating } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Toc from '../../models/Toc';
import * as constants from '../../../../common/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = () => ({
  buttons: {
    padding: '5px 0px',
    marginRight: '0.5rem'
  },
  flexNoSpace: {
    display: 'flex',
    alignItems: 'center'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  },
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey'
  },
  mainContainer: {
    border: '1px solid grey',
    padding: '23px',
    borderRadius: '4px',
    margin: '15px'
  },
  mainPadding: {
    padding: '15px'
  },
  headings: {
    flex: 1
  },
  circleStyle: {
    justifyContent: 'end',
    display: 'flex',
    margin: '2%',
    cursor: 'pointer'
  },
  difficultyLevel: {
    display: 'flex',
    alignItems: 'center'
  }
});

function TocAddDialog(props) {
  const { open, onClose, classes, chatgptTocUtility, bookDetails } = props;
  const [difficultyLevel, setDifficultyLevel] = useState(1);
  // const [errorText, setErrorText] = useState(null);
  // const [showErrors, setShowErrors] = useState(false);
  const [questionErrorText, setQuestionErrorText] = useState(false);
  const [divElements, setDivElements] = useState([
    { id: 1, textFieldValue1: '', textFieldValue2: '', error1: false, error2: false, radioValue: 'No' }
  ]);
  const [textQuestion, setTextQuestion] = useState('');

  const handleClose = () => {
    onClose();
  };

  const handleSave = async () => {
    let textCheck = true;
    let questionError = false;

    if (!textQuestion.trim()) {
      setQuestionErrorText(true);
      questionError = true;
    } else {
      setQuestionErrorText(false);
    }
    let radioOptionCount = 0;
    const updateDivElements = divElements.map((item) => {
      if (item.radioValue === 'Yes') {
        radioOptionCount += 1;
      }
      const error1 = !item.textFieldValue1.trim();
      const error2 = !item.textFieldValue2.trim();
      if (error1 || error2) {
        textCheck = false;
      }
      return {
        ...item,
        error1,
        error2
      };
    });
    setDivElements(updateDivElements);

    // divElements.forEach((item) => {
    //   if (!item.textFieldValue1.trim() || !item.textFieldValue2.trim()) {
    //     // setErrorText(item);
    //     textCheck = false;
    //   }
    // });
    if (textCheck && !questionError && radioOptionCount > 0) {
      const allFieldsFilled = divElements.every((item) => item.textFieldValue1.trim() && item.textFieldValue2.trim());
      if (allFieldsFilled) {
        const formattedData = {
          bookId: bookDetails.bookId,
          productId: bookDetails.productDataId,
          productModel: 'ETEXT_EPUB_BRONTE',
          sourceId: bookDetails.sourceId,
          sourceType: 'page',
          sourceTitle: '',
          lifeCycleStatus: 'DRAFT',
          version: '1.0.0',
          question: {
            question_text: textQuestion,
            options: divElements.map((div) => ({
              id: div.id,
              option_text: div.textFieldValue1,
              correct_choice: div.radioValue,
              explanation: div.textFieldValue2
            }))
          },
          difficultyLevel
        };
        await chatgptTocUtility.AddMcqQuestion(formattedData);
        onClose();
      }
    }
  };

  const handleAppendDiv = () => {
    const newId = divElements.length + 1;
    const newDiv = { id: newId, textFieldValue1: '', textFieldValue2: '', radioValue: 'No' };
    setDivElements([...divElements, newDiv]);
    // setErrorText(null);
  };

  const handleTextField1Change = (id, value) => {
    const updatedDivElements = divElements.map((div) =>
      div.id === id ? { ...div, textFieldValue1: value, error1: !value.trim() } : div
    );
    setDivElements(updatedDivElements);
  };

  const handleTextField2Change = (id, value) => {
    const updatedDivElements = divElements.map((div) =>
      div.id === id ? { ...div, textFieldValue2: value, error2: !value.trim() } : div
    );
    setDivElements(updatedDivElements);
  };

  const handleRadioChange = (id, value) => {
    const updatedDivElements = divElements.map((div) =>
      div.id === id ? { ...div, radioValue: value } : { ...div, radioValue: 'No' }
    );
    setDivElements(updatedDivElements);
  };

  const handleTextQuestion = (value) => {
    setTextQuestion(value);
    setQuestionErrorText(false);
  };

  // console.log('books', bookDetails);

  return (
    <div>
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
        <div className={classes.flexBorder}>
          <div className={classes.flexContainer}>
            <div />
            <div>
              <h4 className={classes.headings}> Add New Question</h4>
            </div>
            <div className={classes.icon}>
              <IconButton edge="start" color="default" onClick={onClose} aria-label="close">
                <Tooltip title="Close">
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </div>
          </div>
          <br />
          <div className={classes.mainPadding}>
            <TextField
              fullWidth
              label="Add your question here"
              required
              variant="outlined"
              error={questionErrorText}
              value={textQuestion}
              onChange={(e) => handleTextQuestion(e.target.value)}
            />
          </div>
          <br />
          {divElements.map((div) => (
            <div key={div.id} className={classes.mainContainer}>
              <div>
                <TextField
                  fullWidth
                  id={`outlined-basic-${div.id}`}
                  label="Add your option here"
                  // error={errorText && errorText === div.id && (!div.textFieldValue1 || !div.textFieldValue2)}
                  error={div.error1}
                  required
                  variant="outlined"
                  value={div.textFieldValue1}
                  onChange={(e) => handleTextField1Change(div.id, e.target.value)}
                />
              </div>
              <br />
              <div>
                <TextField
                  fullWidth
                  id={`outlined-basic-${div.id}`}
                  label="Add your feedback here"
                  required
                  error={div.error2}
                  // error={errorText && errorText === div.id && (!div.textFieldValue1 || !div.textFieldValue2)}
                  variant="outlined"
                  value={div.textFieldValue2}
                  onChange={(e) => handleTextField2Change(div.id, e.target.value)}
                />
              </div>
              <div className={classes.flexNoSpace}>
                <h4>Correct Choice :</h4>
                <Radio
                  checked={div.radioValue === 'Yes'}
                  onChange={(e) => handleRadioChange(div.id, e.target.value)}
                  value="Yes"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'A' }}
                />
              </div>
            </div>
          ))}
          <div className={classes.circleStyle} disabled>
            <Tooltip title="This will add block of Option/Feedback.MAX limit-4">
              <IconButton onClick={handleAppendDiv} disabled={divElements.length >= 4}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.flexContainerBottom}>
            <div className={classes.difficultyLevel}>
              <h4>{constants.GPT_DIFFICULTY}: </h4>
              <Rating
                name="simple-controlled"
                value={difficultyLevel}
                onChange={(event, newValue) => {
                  setDifficultyLevel(newValue);
                }}
              />
            </div>
            <div className={classes.flexNoSpace}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  sx={{ background: '#005d83' }}
                  className={classes.buttons}
                  onClick={handleClose}
                >
                  {constants.CANCEL}
                </Button>
                <Button
                  size="small"
                  onClick={handleSave}
                  color="primary"
                  variant="contained"
                  sx={{ background: '#005d83' }}
                  className={classes.buttons}
                >
                  {constants.SAVE}
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

TocAddDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  bookDetails: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  chatgptTocUtility: shapes.modelOf(Toc).isRequired
};

export default withStyles(styles)(inject('chatgptTocUtility')(TocAddDialog));
// export default withStyles(styles)(inject(TocAddDialog));
